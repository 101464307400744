import React from "react"
import { Box, List, Paper, Stack } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { EmptyInboxAside } from "./empty-inbox-aside"
import { MemberAccordion } from "./member-accordion"

const useStyles = makeStyles((theme) =>
  createStyles({
    cohortChatAccordionListWrapper: {
      display: "flex",
      flex: "1 1 1px",
      overflow: "hidden",
      position: "relative",
    },
    paperRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRadius: 0,
      boxShadow: "none",
      overflow: "hidden",
      background: "transparent",
    },
    listRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflow: "auto",
    },
    listContent: {
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: 0,
    },
  }),
)

export const CohortGroupChatAside = observer(() => {
  const { cohortStore } = useStores()
  const { currentCohort } = cohortStore
  const { registeredParticipants: participants } = currentCohort

  const classes = useStyles()

  if (participants.length === 0) {
    return <EmptyInboxAside isGroupInbox />
  }

  return (
    <>
      <Paper className={classes.paperRoot}>
        <Box className={classes.cohortChatAccordionListWrapper}>
          <Stack className={classes.listRoot}>
            <List className={classes.listContent}>
              {participants.map((member) => (
                <MemberAccordion key={member.id} member={member} />
              ))}
            </List>
          </Stack>
        </Box>
      </Paper>
    </>
  )
})
