import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-recipe-modal"
import { useFormState } from "../admin-config-form/useFormState"

const useRecipeForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newRecipe,
    apiAdminCreateRecipe,
    apiAdminEditRecipe,
    resetNewRecipe,
  } = adminMslEventStoreModel

  const { createRecipeErrors: errors } = newRecipe

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewRecipe()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.ADD_NEW_RECIPE) {
        await apiAdminCreateRecipe()
        setAndShowSnackbar({
          text: "Successfully created recipe !",
          severity: "success",
        })
      } else {
        await apiAdminEditRecipe()
        setAndShowSnackbar({
          text: "Successfully edited recipe !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  return { loading, submitted, onCancel, onSubmit, values: newRecipe, errors }
}

export { useRecipeForm }
