import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { Topic } from "../../models/msl-event/topic"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"

import { useTopicForm } from "./useTopicForm"
import { formConfig } from "./form-config"

interface CreateMslTopicModalProps {
  open: boolean
  closeModal: () => void
}

const CreateMslTopicModal = observer(
  ({ open, closeModal }: CreateMslTopicModalProps) => {
    const {
      loading,
      submitted,
      values,
      errors,
      onCancel,
      onSubmit,
    } = useTopicForm({
      closeModal,
      isModalOpen: open,
    })

    return (
      <FormModal
        open={open}
        modalTitle={"Create Topic"}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={"Create"}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Topic>
            submitted={submitted}
            values={values}
            errors={errors}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateMslTopicModal }
