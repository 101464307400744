import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"

export const VerificationCodeModel = types
  .model("VerificationCode")
  .props({
    id: types.identifier,
    mobilePhone: types.optional(types.maybeNull(types.string), ""),
    email: types.optional(types.maybeNull(types.string), ""),
    code: types.optional(types.maybeNull(types.number), null),
    claimed: types.optional(types.boolean, false),
    twilioMessageID: types.optional(types.maybeNull(types.string), ""),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
  })
  .views((self) => ({
    get formattedCreatedAt() {
      return !!self.createdAt ? moment(self.createdAt).format("lll") : null
    },
  }))

export interface VerificationCode
  extends Instance<typeof VerificationCodeModel> {}
export interface VerificationCodeSnapshot
  extends SnapshotOut<typeof VerificationCodeModel> {}
