import React from "react"

import { Stack, Theme, IconButton, Typography } from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { Person } from "../../models/person"
import useMoreDetailsButton from "../member-card/useMoreDetailsButton"
import { typography } from "../../services/theme/typography"
import { ReactComponent as WalmartIcon } from "../../assets/images/walmart_icon.svg"
import { MemberDetails } from "./details"
import { SectionLayout } from "./layout"
import { Conditions } from "./conditions"
import { Dates } from "./dates"
import { Notes } from "./notes"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: "107px",
      width: "107px",
    },
    companyLogo: {
      width: "101px",
      height: "24px",
      marginLeft: theme.spacing(2),
    },
    closeButton: {
      alignSelf: "flex-start",
    },
    lastSeenContainer: {
      width: "106px",
      height: "26px",
      borderRadius: "49px",
      border: "1px solid #00CC7E",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: theme.spacing(3),
    },
    lastSeenLabel: {
      fontSize: theme.spacing(1.5),
      fontWeight: 400,
      color: "#00CC7E",
      ...typography.circularXXMedium,
    },
    name: {
      fontSize: theme.spacing(4),
      fontWeight: 500,
      color: "#2D353B",
      ...typography.circularXXMedium,
    },
    mainDetail: {
      fontSize: theme.spacing(2),
      fontWeight: 400,
      color: "#485359",
      ...typography.circularXXMedium,
    },
    section: {
      backgroundColor: "#F8FAFA",
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2, 3),
      borderRadius: theme.spacing(2.5),
    },
    buttonLabel: {
      fontSize: theme.spacing(2.2),
      fontWeight: 500,
    },
  }),
)

interface ParticipantPreviewProps {
  member: Person
  onClose: () => void
}

const ParticipantPreview = ({ member, onClose }: ParticipantPreviewProps) => {
  const classes = useStyles()
  const { MoreDetailsButton } = useMoreDetailsButton(member)

  const {
    avatarURL,
    employer,
    firstName,
    lastName,
    email,
    mobilePhone,
    formattedConditionsList,
    coachingStartDate,
    coachingEndDate,
    notes,
  } = member

  return (
    <Stack>
      <Stack flexDirection="row" justifyContent="space-between" mb={1.75}>
        <Stack flexDirection="row" alignItems="center">
          <img src={avatarURL} alt="avatar" className={classes.avatar} />
          {employer === "Walmart" && (
            <WalmartIcon className={classes.companyLogo} />
          )}
        </Stack>

        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Stack>
        <Stack flexDirection="row" alignItems="center">
          <Typography className={classes.name}>
            {firstName} {lastName ? `.${lastName[0]}` : ""}
          </Typography>

          {/* <div className={classes.lastSeenContainer}>
            <Typography className={classes.lastSeenLabel}>
              2 days ago
            </Typography>
          </div> */}
        </Stack>

        <Typography className={classes.mainDetail} mb={1} mt={2}>
          {email}
        </Typography>
        <Typography className={classes.mainDetail} mb={3}>
          {mobilePhone?.length > 0 ? mobilePhone : "N/A"}
        </Typography>
      </Stack>

      <SectionLayout>
        <MemberDetails member={member} />
      </SectionLayout>

      <SectionLayout>
        <Conditions conditions={formattedConditionsList} />
      </SectionLayout>

      <SectionLayout>
        <Dates startDate={coachingStartDate} endDate={coachingEndDate} />
      </SectionLayout>

      <SectionLayout>
        <Notes notes={notes.slice()} />
      </SectionLayout>

      <MoreDetailsButton
        variant="outlined"
        fullWidth
        className={classes.buttonLabel}
      />
    </Stack>
  )
}

export { ParticipantPreview }
