import { types } from "mobx-state-tree"

export const formData = types.custom<FormData, FormData | null>({
  name: "formData",

  fromSnapshot(value: FormData) {
    return value || null
  },

  toSnapshot(value: FormData) {
    return value
  },

  isTargetType(value: FormData | null): boolean {
    return value instanceof FormData || value === null
  },

  getValidationMessage(value: FormData): string {
    if (value instanceof FormData) {
      return ""
    } else {
      return "Item doesn't look like a file type"
    }
  },
})
