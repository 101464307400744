import React, { memo } from "react"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Button, Typography } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"

import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
  }),
)

const AdminTableHeaderComponent = ({
  tableLabel,
  addNewButtonLabel,
  addNewButtonOnClick = () => {},
}) => {
  const classes = useStyles()

  return (
    <>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>{tableLabel}</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addNewButtonOnClick}>
          {addNewButtonLabel}
        </Button>
      </Stack>
    </>
  )
}

export const AdminTableHeader = memo(AdminTableHeaderComponent)
