import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-nudge-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useNudgesForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newNudge,
    nudgeTypes,
    resetNewNudge,
    apiAdminCreateNudge,
    apiAdminEditNudge,
  } = adminMslEventStoreModel

  const { type, createNudgeErrors: errors } = newNudge

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewNudge()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.ADD_NEW_NUDGE) {
        await apiAdminCreateNudge()
        setAndShowSnackbar({
          text: "Successfully created tile !",
          severity: "success",
        })
      } else {
        await apiAdminEditNudge()
        setAndShowSnackbar({
          text: "Successfully edited tile !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "title",
      label: "Title",
      required: true,
      onChangeMethod: "setTitle",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "body",
      onChangeMethod: "setBody",
      label: "Body",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter body here",
      },
    },
    {
      fieldName: "type",
      onChangeMethod: "setType",
      label: "Type",
      fieldType: FieldTypes.Select,
      componentProps: {
        options: nudgeTypes.slice(),
        value: type,
        getOptionLabel: (option: { id: string; label: string }) => {
          return option
        },
        Input: {
          placeholder: "Select Type",
        },
      },
    },

    {
      fieldName: "route",
      onChangeMethod: "setRoute",
      label: "Route",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter route here",
      },
    },
    {
      fieldName: "routeParam",
      onChangeMethod: "setRouteParam",
      label: "Route Param",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter route param here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "defaultSendAt",
      onChangeMethod: "setDefaultSendAt",
      label: "Default send at",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "displayName",
      onChangeMethod: "setDisplayName",
      label: "Display Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter display name here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "active",
      onChangeMethod: "setIsActive",
      label: "Is Active ?",
      fieldType: FieldTypes.Switch,
    },
  ]

  return { loading, submitted, onCancel, onSubmit, formConfig }
}

export { useNudgesForm }
