import { useRef } from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"
import { debounce } from "rambdax"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-idea-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { Tag } from "../../models/tag"
import { TriIdeaRecipe } from "../../models/tri-idea-recipe"
import { TriIdeaProduct } from "../../models/tri-idea-product"

const useTriIdeaForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newTriIdea,
    triIdeaTypes: typesOptions,
    tags: tagsOptions,
    triIdeaRecipesPagination,
    triIdeaProductsPagination,
    apiAdminCreateTriIdea,
    apiAdminEditTriIdea,
    apiAdminUpdateTriIdeaType,
    apiAdminAddRecipeToTriIdea,
    apiAdminAddProductToTriIdea,
    apiAdminAddTagToTriIdea,
    apiAdminDeleteTriIdeaProduct,
    apiAdminDeleteTriIdeaRecipe,
    apiAdminDeleteTriIdeaTag,
    resetNewTriIdea,
  } = adminMslEventStoreModel

  const { apiAdminGetTriIdeaRecipes } = triIdeaRecipesPagination
  const { apiAdminGetTriIdeaProducts } = triIdeaProductsPagination

  const {
    type,
    recipe,
    product,
    tags,
    setTags,
    setRecipe,
    setProduct,
    createTriIdeaErrors: errors,
  } = newTriIdea

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const handleRecipeChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriIdeaRecipe,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdeaRecipe>,
  ) => {
    event.preventDefault()

    const nativeEvent = event.nativeEvent as InputEvent
    const clearedWithKeyboard =
      nativeEvent?.inputType === "deleteContentBackward"

    try {
      if (reason === "selectOption") {
        const { option } = details
        const isNewRecipe = recipe?.id !== value.id

        if (isNewRecipe) {
          setRecipe(option)
          if (recipe?.id) {
            await apiAdminDeleteTriIdeaRecipe(newTriIdea.id, recipe.id)
          }

          await apiAdminAddRecipeToTriIdea(newTriIdea.id, option)
          setAndShowSnackbar({
            text: `Successfully added ${
              option.title ?? option.suggesticSearchKeyword
            } recipe !`,
          })
        }
      }

      if (reason === "clear" && !clearedWithKeyboard) {
        setRecipe(null)
        await apiAdminDeleteTriIdeaRecipe(newTriIdea.id, recipe.id)
        setAndShowSnackbar({
          text: `Successfully removed ${recipe.title} recipe !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleProductChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriIdeaProduct,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdeaProduct>,
  ) => {
    event.preventDefault()

    const nativeEvent = event.nativeEvent as InputEvent
    const clearedWithKeyboard =
      nativeEvent?.inputType === "deleteContentBackward"

    try {
      if (reason === "selectOption") {
        const isNewProduct = product?.id !== value.id

        if (isNewProduct) {
          const { option } = details
          setProduct(option)

          if (product?.id) {
            await apiAdminDeleteTriIdeaProduct(newTriIdea.id, product.id)
          }

          await apiAdminAddProductToTriIdea(newTriIdea.id, option.id)
          setAndShowSnackbar({
            text: `Successfully added ${option.displayName} product !`,
          })
        }
      }
      if (reason === "clear" && !clearedWithKeyboard) {
        setProduct(null)
        await apiAdminDeleteTriIdeaProduct(newTriIdea.id, product.id)
        setAndShowSnackbar({
          text: `Successfully removed ${product.displayName} product !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToTriIdea(newTriIdea.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriIdeaTag(newTriIdea.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const debouncedSearch = useRef(
    debounce(async (query: string, setQuery, searchAPI) => {
      try {
        if (query.trim().length) {
          setQuery(query)
          await searchAPI()
        }
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, 500),
  )

  const handleProductSearch = async (_, value, isEndReached) => {
    const { next, setQuery, resetPagination } = triIdeaProductsPagination

    if (isEndReached) {
      if (next) {
        await apiAdminGetTriIdeaProducts()
      }
    } else {
      resetPagination()
      debouncedSearch.current(value, setQuery, apiAdminGetTriIdeaProducts)
    }
  }

  const handleRecipeSearch = async (_, value, isEndReached) => {
    const { next, setQuery, resetPagination } = triIdeaRecipesPagination

    if (isEndReached) {
      if (next) {
        await apiAdminGetTriIdeaRecipes()
      }
    } else {
      resetPagination()
      debouncedSearch.current(value, setQuery, apiAdminGetTriIdeaRecipes)
    }
  }

  const onCancel = () => {
    resetNewTriIdea()
    closeModal()
  }

  const handleIdeaTypeChange = async (_, value: string) => {
    if (mode === ModalMode.EDIT) {
      try {
        await apiAdminUpdateTriIdeaType(value)
        setAndShowSnackbar({
          text: "Successfully edited idea type !",
          severity: "success",
        })
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    } else {
      newTriIdea.setType(value)
    }
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.CREATE) {
        await apiAdminCreateTriIdea()
        setAndShowSnackbar({
          text: "Successfully created idea !",
          severity: "success",
        })
      } else {
        await apiAdminEditTriIdea()
        setAndShowSnackbar({
          text: "Successfully edited idea !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const triIdeaFormConfig = [
    {
      fieldName: "personID",
      onChangeMethod: "setPersonID",
      label: "Person ID",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter person ID",
      },
    },

    {
      fieldName: "title",
      onChangeMethod: "setTitle",
      label: "Title",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title",
        disabled: type === "recipe",
      },
    },

    {
      fieldName: "summary",
      onChangeMethod: "setSummary",
      label: "Summary",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter summary",
      },
    },

    {
      fieldName: "type",
      onChangeMethod: "setType",
      label: "Type",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        options: typesOptions,
        disableClearable: true,
        value: type,
        onChange: handleIdeaTypeChange,
        getOptionLabel: (option: string) => option,
        Input: {
          placeholder: "Select idea type",
        },
      },
    },

    {
      ...(type === "recipe" && {
        fieldName: "recipes",
        label: "Recipes",
        fieldType: FieldTypes.Select,
        componentProps: {
          options: triIdeaRecipesPagination.recipes.slice(),
          value: recipe,
          loading: triIdeaRecipesPagination.loading,
          renderOption: (props, option: TriIdeaRecipe) => (
            <li {...props} key={`${option.id}_${props["aria-rowindex"]}`}>
              {option.title ?? option.suggesticSearchKeyword}
            </li>
          ),
          getOptionLabel: (option: TriIdeaRecipe) => {
            return option.title ?? option.suggesticSearchKeyword
          },
          onInputChange: (_, value) => handleRecipeSearch(_, value, false),
          onScrollEnd: () => handleRecipeSearch(null, null, true),
          onChange: handleRecipeChange,
          Input: {
            placeholder: "Select a Recipe",
          },
        },
        showOnlyOnEditMode: true,
      }),
    },

    {
      ...(type === "product" && {
        fieldName: "products",
        label: "Product",
        fieldType: FieldTypes.Select,
        componentProps: {
          options: triIdeaProductsPagination.products.slice(),
          value: product,
          loading: triIdeaProductsPagination.loading,
          onChange: handleProductChange,
          onInputChange: (_, value) => handleProductSearch(_, value, false),
          onScrollEnd: () => handleProductSearch(null, null, true),
          renderOption: (props, option: TriIdeaProduct) => (
            <li {...props} key={option.id}>
              {option.displayName}
            </li>
          ),
          getOptionLabel: (option: TriIdeaProduct) => {
            return option.displayName
          },
          Input: {
            placeholder: "Select Recipe",
          },
        },
        showOnlyOnEditMode: true,
      }),
    },

    {
      fieldName: "active",
      onChangeMethod: "setActive",
      label: "Active",
      fieldType: FieldTypes.Switch,
    },

    {
      fieldName: "metdata",
      onChangeMethod: "setMetadata",
      label: "Metadata",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter metadata UUID",
      },
    },

    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    newTriIdea,
    loading,
    submitted,
    onCancel,
    onSubmit,
    values: newTriIdea,
    errors,
    triIdeaFormConfig,
  }
}

export { useTriIdeaForm }
