import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { ChatMessageModel, ChatMessage } from "../chat-message/chat-message"
import {
  DirectChatMessagesMeta,
  DirectChatMessagesMetaModel,
} from "./direct-chat-messages-meta"

export const DirectChatMessagesModel = types
  .model("DirectChatMessages")
  .props({
    messages: types.optional(types.array(ChatMessageModel), []),
    meta: types.optional(DirectChatMessagesMetaModel, () =>
      DirectChatMessagesMetaModel.create({}),
    ),
    newMessages: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setChats(value: Array<ChatMessage>) {
      self.messages.replace(value)
    },
    setMeta(value: DirectChatMessagesMeta) {
      self.meta = value
    },
    appendChat(value: ChatMessage) {
      self.messages.replace([...self.messages, value])
    },
    resetChats() {
      self.messages.clear()
    },
  }))
  .actions((self) => ({
    removeDirectChatMessage(messageId: string) {
      self.messages.remove(self.messages.find((c) => c.id === messageId))
    },
  }))
  .views((self) => ({
    getParticipantByRoomID(roomId: string) {
      return self.meta.participants.find((p) => p.personID === roomId)
    },
  }))

export interface DirectChatMessages
  extends Instance<typeof DirectChatMessagesModel> {}
export interface DirectChatMessagesSnapshot
  extends SnapshotOut<typeof DirectChatMessagesModel> {}
