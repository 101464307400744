import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import {
  Grid,
  Theme,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import CloseIcon from "@mui/icons-material/Close"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import EditIcon from "@mui/icons-material/Edit"
import TextsmsIcon from "@mui/icons-material/Textsms"
import { ReactComponent as DeleteIcon } from "../../assets/images/delete_icon.svg"
import MemberIdDropdown from "./member-id-dropdown"
import ActivityButton from "./activity-button"
import ContactInfoSection from "./contact-info-section"
import PersonTriSelector from "./person-tri-selector"
import DeleteDialog from "./delete-dialog"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { FullPersonDetails } from "../../models/admin-user-search/full-person-details"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { PersonInvitations } from "../person-invitations"
import { PersonVerificationCodes } from "../person-verification-codes"
import { AdminUpdatePersonDetails } from "../admin-update-person-details"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      backgroundColor: "#F5F6F7",
      flexDirection: "column",
    },
    innerContainer: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      position: "relative",
    },
    content: {
      padding: `
      ${theme.spacing(4)} 
      ${theme.spacing(4)} 
      0px 
      ${theme.spacing(4)}`,
    },
    emptyContainer: {
      background: "#fff",
    },
    loadingContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    closeButtonPosition: {
      position: "absolute",
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
    closeButton: {
      padding: 0,
      color: "#000000",
    },
    closeIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    userInfoColumn: {
      marginTop: theme.spacing(4),
    },
    personName: {
      fontWeight: "bold",
      fontSize: 35,
      lineHeight: "41px",
      color: "#039CAD",
      alignSelf: "center",
    },

    seeAllPostsActive: {
      background: "#0A8052",
      "&:hover": {
        background: "#0A8052",
        color: "#fff",
      },
      "&:active": {
        background: "#0A8052",
        color: "#fff",
      },
    },
    seeAllPostsTextActive: {
      color: "#fff !important",
    },
  }),
)

export const AdminPersonAside = observer(() => {
  const classes = useStyles()
  const [deletePopUpEl, setDeletePopUpEl] = useState<HTMLButtonElement | null>(
    null,
  )
  //flag used to show person invitations component
  const [showPersonInvitations, togglePersonInvitations] = useState(false)

  //flag used to show person verification codes component
  const [showPersonVerificationCodes, togglePersonVerificationCodes] = useState(
    false,
  )

  //flag used to show admin update person details component
  const [
    showAdminUpdatePersonDetails,
    toggleAdminUpdatePersonDetails,
  ] = useState(false)

  const openDeletePopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeletePopUpEl(event.currentTarget)
  }

  const closeDeletePopup = () => {
    setDeletePopUpEl(null)
  }

  const { adminToolsStore } = useStores()
  const {
    loadingSelectedPerson,
    selectedPerson,
    resetSelectedPerson,
    showSelectedPersonPosts,
    toggleShowSelectedPersonPosts,
    programsList,
    apiAdminUnBanUser,
    apiAdminBanUser,
    manuallyToggleUserBannedOnPosts,
    apiAdminDeleteUser,
    apiAdminUnsubscribeEmail,
    apiAdminGetSelectedPersonInvitations,
    apiAdminGetAllPrograms,
  } = adminToolsStore

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  useEffect(() => {
    apiAdminGetAllPrograms()
  }, [apiAdminGetAllPrograms])

  //reset all content flags on selected person update
  useEffect(() => {
    togglePersonInvitations(false)
    togglePersonVerificationCodes(false)
    toggleAdminUpdatePersonDetails(false)
  }, [selectedPerson?.person?.id])

  if (loadingSelectedPerson) {
    return (
      <Grid
        container
        className={clsx(classes.container, classes.emptyContainer)}>
        <Grid className={classes.loadingContainer}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  if (!selectedPerson) {
    return (
      <Grid
        container
        className={clsx(classes.container, classes.emptyContainer)}>
        <Grid className={classes.innerContainer}></Grid>
      </Grid>
    )
  }

  const { person } = selectedPerson as FullPersonDetails
  const {
    id: personID,
    profile,
    firstName,
    email,
    deleted,
    toggleDeleted,
  } = person
  const { isPersonBanned, emailUnsubscribed, toggleEmailUnsubscribed } = profile

  const onCloseClick = () => {
    //if person invitations flag is active, disable it
    if (showPersonInvitations) {
      togglePersonInvitations(false)
    }
    //if verification codes flag is active, disable it
    else if (showPersonVerificationCodes) {
      togglePersonVerificationCodes(false)
    }
    //if admin update person details flag is active, disable it
    else if (showAdminUpdatePersonDetails) {
      toggleAdminUpdatePersonDetails(false)
    } else {
      //otherwise reset selected person
      resetSelectedPerson()
    }
  }

  //handle opening person invitation component and fetching program invitations of the selected person
  const openProgramInvitations = () => {
    apiAdminGetSelectedPersonInvitations()
    togglePersonInvitations(true)
  }

  const toggleBan = async () => {
    try {
      //manually toggle user ban
      manuallyToggleUserBannedOnPosts({ personID })
      if (isPersonBanned) {
        await apiAdminUnBanUser({ userId: personID })
      } else {
        await apiAdminBanUser({ userId: personID })
      }
    } catch (error) {
      //revert toggle if api fails
      manuallyToggleUserBannedOnPosts({ personID })
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  const unsubscribePerson = async () => {
    try {
      //manually toggle email unsubscribe flag on a person
      toggleEmailUnsubscribed()
      await apiAdminUnsubscribeEmail({ email })
    } catch (error) {
      //revert toggle when api fails
      toggleEmailUnsubscribed()
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      throw error.message
    }
  }

  const deletePerson = async () => {
    try {
      //manually toggle delete attribute on a person
      toggleDeleted()
      await apiAdminDeleteUser({ userId: personID })
    } catch (error) {
      //revert toggle when api fails
      toggleDeleted()
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      throw error.message
    }
  }

  const isPopupDeleteOpen = Boolean(deletePopUpEl)

  const renderPersonContent = () => {
    if (showPersonInvitations) {
      return (
        <Stack spacing={3} flex={1}>
          <PersonInvitations />
        </Stack>
      )
    }
    if (showPersonVerificationCodes) {
      return (
        <Stack spacing={3} flex={1}>
          <PersonVerificationCodes />
        </Stack>
      )
    }
    if (showAdminUpdatePersonDetails) {
      return (
        <Stack spacing={3} flex={1}>
          <AdminUpdatePersonDetails />
        </Stack>
      )
    }
    return (
      <Stack spacing={3} className={classes.content}>
        <Stack direction="row" spacing={1}>
          <ActivityButton
            label="Mute Person"
            activeLabel="MUTED"
            isActive={isPersonBanned}
            onClick={toggleBan}
            Icon={VisibilityOffOutlinedIcon}
          />

          <ActivityButton
            label="Delete Account"
            activeLabel="Account Deleted"
            isActive={deleted}
            disabled={deleted}
            onClick={openDeletePopup}
            Icon={DeleteIcon}
          />

          {isPopupDeleteOpen && (
            <DeleteDialog
              id={isPopupDeleteOpen ? "person-popup-delete" : undefined}
              open={isPopupDeleteOpen}
              handleClose={closeDeletePopup}
              anchorEl={deletePopUpEl}
              onDelete={deletePerson}
              personName={firstName}
            />
          )}
        </Stack>

        <Stack direction="row" spacing={1}>
          <Link to="/ftadmin/posts">
            <ActivityButton
              label="See all Posts"
              activeLabel="See all Posts"
              isActive={showSelectedPersonPosts}
              onClick={() =>
                toggleShowSelectedPersonPosts(!showSelectedPersonPosts)
              }
              rootActiveStyle={classes.seeAllPostsActive}
              textActiveStyle={classes.seeAllPostsTextActive}
            />
          </Link>

          <ActivityButton
            label="Unsubscribe from Emails"
            activeLabel="Unsubscribed"
            isActive={emailUnsubscribed}
            disabled={emailUnsubscribed}
            onClick={unsubscribePerson}
          />
        </Stack>

        <Stack direction="row" spacing={1}>
          <ActivityButton
            label="Edit Person Details"
            Icon={EditIcon}
            onClick={() => toggleAdminUpdatePersonDetails(true)}
          />

          <ActivityButton
            label="Show Verification Codes"
            Icon={TextsmsIcon}
            onClick={() => togglePersonVerificationCodes(true)}
          />
        </Stack>

        <ContactInfoSection
          selectedPerson={selectedPerson as FullPersonDetails}
        />

        <PersonTriSelector
          selectedPerson={selectedPerson as FullPersonDetails}
          programsList={programsList}
          openProgramInvitations={openProgramInvitations}
        />

        <div />
      </Stack>
    )
  }

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.innerContainer}>
        <div className={classes.closeButtonPosition}>
          <IconButton className={classes.closeButton} onClick={onCloseClick}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>

        <Stack className={classes.content}>
          <Stack className={classes.userInfoColumn} spacing={3}>
            <Typography className={classes.personName}>{firstName}</Typography>

            <MemberIdDropdown
              selectedPerson={selectedPerson as FullPersonDetails}
            />
          </Stack>
        </Stack>

        {renderPersonContent()}
      </Grid>
    </Grid>
  )
})
