import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import moment from "moment"

import * as customTypes from "../types"
import { MslEvent, MslEventModel } from "../msl-event"
import { Tag, TagModel } from "../tag"
import { validate, ValidationRules } from "../../utils/validate"
import { UploadFileModel, UploadFile } from "../upload-file"

export const SeriesModel = types
  .model("Series")
  .props({
    id: types.string,
    name: types.optional(types.maybeNull(types.string), ""),
    description: types.optional(types.maybeNull(types.string), ""),
    imageUrl: types.optional(types.maybeNull(types.string), ""),
    isFeatured: types.optional(types.maybeNull(types.boolean), false),
    isPublished: types.optional(types.maybeNull(types.boolean), false),
    publishStartTime: types.optional(customTypes.iso8601, null),
    publishEndTime: types.optional(customTypes.iso8601, null),
    events: types.optional(types.array(MslEventModel), []),
    tags: types.optional(types.array(TagModel), []),
    script: types.optional(types.maybeNull(types.string), ""),
    searchKeywords: types.optional(types.maybeNull(types.string), ""),
    uploadFiles: types.optional(
      types.model({
        imageUrl: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "imageUrl",
          }),
        ),
      }),
      {},
    ),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setDescription(value: string) {
      self.description = value
    },
    setImageUrl(value: string) {
      self.imageUrl = value
    },
    setIsFeatured(value: boolean) {
      self.isFeatured = value
    },
    setIsPublished(value: boolean) {
      self.isPublished = value
    },
    setPublishStartTime(value: Date) {
      self.publishStartTime = value
    },
    setPublishEndTime(value: Date) {
      self.publishEndTime = value
    },
    setEvents(value: MslEvent[]) {
      self.events.replace(value.map((e) => clone(e)))
    },
    setTags(value: Tag[]) {
      self.tags.replace(value.map((e) => clone(e)))
    },
    setScript(value: string) {
      self.script = value
    },
    setSearchKeywords(value: string) {
      self.searchKeywords = value
    },
    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },

    setContentUrl(id: "imageUrl", value: string) {
      self[id] = value
    },

    reset() {
      self.id = "-1"
      self.name = ""
      self.description = ""
      self.imageUrl = null
      self.isFeatured = false
      self.isPublished = false
      self.publishStartTime = null
      self.publishEndTime = null
      self.searchKeywords = ""
      self.events.replace([])
      self.tags.replace([])
    },
  }))
  .views((self) => ({
    get createSeriesErrors() {
      return validate(CREATE_NEW_SERIES_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
    get formattedPublishStartDate() {
      if (Boolean(self.publishStartTime)) {
        return moment(self.publishStartTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get formattedPublishEndDate() {
      if (Boolean(self.publishEndTime)) {
        return moment(self.publishEndTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get isSeriesInProgress() {
      //if the series is currently in progress
      //('isPublished = true' AND
      //    ((publishStartDate is in the past AND publishedEndDate is in the future) OR (the publishStartDate is in the past AND the publishedEndDate is null)))
      return (
        !!self.publishStartTime &&
        //first condition
        ((moment(self.publishStartTime).isBefore(moment()) &&
          moment(self.publishEndTime).isAfter(moment())) ||
          //second condition
          (moment(self.publishStartTime).isBefore(moment()) &&
            !self.publishEndTime))
      )
    },
  }))
  .views((self) => ({
    get isCurrentlyPublished() {
      return self.isPublished && self.isSeriesInProgress
    },
  }))

const CREATE_NEW_SERIES_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  imageUrl: {
    fileUpload: {},
  },
  publishStartTime: {
    presence: { allowEmpty: false, message: "required" },
    validateStartEndDates: {
      compare: "publishEndTime",
      message: "Publish Start Time should be before the Publish End Time",
    },
  },
  events: {
    validateEventsSequence: {},
  },
}

export interface Series extends Instance<typeof SeriesModel> {}
export interface SeriesSnapshot extends SnapshotOut<typeof SeriesModel> {}
