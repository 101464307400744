import React from "react"
import { Theme } from "@material-ui/core"
import FlagIcon from "@mui/icons-material/Flag"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { useStores } from "../../models/root-store"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flag: {
      color: "#F23D3C",
      marginRight: theme.spacing(0.5),
    },
  }),
)

interface FlagIndicatorProps {
  personID: string
}

const FlagIndicator = ({ personID }: FlagIndicatorProps) => {
  const classes = useStyles()
  const { pilotTrainerStore } = useStores()

  const { flaggedParticipantConversations } = pilotTrainerStore

  if (flaggedParticipantConversations[personID]) {
    return <FlagIcon className={classes.flag} />
  }

  return <></>
}

export { FlagIndicator }
