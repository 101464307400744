import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"

import { genderOptions } from "../../config/constants"

export const BiometricsModel = types
  .model("Biometrics")
  .props({
    age: types.maybeNull(types.number),
    biologicalSex: types.maybeNull(types.string),
    createdAt: types.maybeNull(customTypes.iso8601),
    height: types.maybeNull(types.number),
    heightUnits: types.maybeNull(types.string),
    weight: types.maybeNull(types.number),
    weightUnits: types.maybeNull(types.string),
  })
  .views((self) => ({
    get personGenderLabel(): string | null {
      return self.biologicalSex
        ? genderOptions.find((opt) => opt.value === self.biologicalSex).label
        : null
    },
  }))
export interface Biometrics extends Instance<typeof BiometricsModel> {}
export interface BiometricsSnapshot
  extends SnapshotOut<typeof BiometricsModel> {}
