import React, { useState } from "react"
import clsx from "clsx"
import moment from "moment"
import { Button, Popover, Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { typography } from "../../services/theme/typography"
import { updateDateValue } from "./utils"
import { CustomCalendar } from "./custom-calendar"
import { CustomTimePicker } from "./custom-time-picker"

const useStyles = makeStyles((theme) =>
  createStyles({
    toggleButton: {
      padding: 0,
      "&:hover": {
        background: "transparent",
      },
    },
    dialogContainer: {
      minWidth: 424,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
      padding: `
      ${theme.spacing(4)}
      ${theme.spacing(3)}
      ${theme.spacing(6)}
      ${theme.spacing(3)}
      `,
      background: "#EBEEF0",
      boxShadow: "0px 7px 10px rgba(45, 53, 59, 0.15)",
      borderRadius: theme.spacing(1),
    },
    actionButtonsRow: {
      width: "100%",
      padding: `0 ${theme.spacing(3)}`,
    },
    actionButton: {
      flex: 1,
      height: 45,
      borderRadius: theme.spacing(0.5),
      ...typography.circularXXMedium,
    },
  }),
)

export interface DateTimePickerDialogProps {
  children?: React.ReactNode
  toggleButtonClassName?: string
  value: Date
  onChange: (newValue: moment.Moment) => void
}

const DateTimePickerDialog = ({
  children,
  toggleButtonClassName,
  value,
  onChange,
}: DateTimePickerDialogProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [localValue, setLocalValue] = useState(new Date())

  /**
   * Method to open the date time picker and to set the default value of the calendar from the current value
   */
  const openDateTimePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (Boolean(value)) {
      setLocalValue(value)
    }
    setAnchorEl(event.currentTarget)
  }

  const closeDateTimePicker = () => {
    setAnchorEl(null)
  }

  const onCalendarChange = (value: Date) => {
    const newValue = updateDateValue(value, localValue)
    setLocalValue(newValue.toDate())
  }

  const onTimePickerChange = (value: Date) => {
    setLocalValue(value)
  }

  const save = () => {
    const newValue = moment(localValue)
    onChange(newValue)

    closeDateTimePicker()
  }

  const open = Boolean(anchorEl)
  const id = open ? "date-time-picker-popover" : undefined

  return (
    <>
      <Button
        className={clsx(classes.toggleButton, toggleButtonClassName)}
        aria-describedby={id}
        onClick={openDateTimePicker}>
        {children}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeDateTimePicker}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.dialogContainer,
        }}>
        <Stack spacing={4} width="100%" alignItems="center">
          <CustomCalendar onChange={onCalendarChange} value={localValue} />

          {open && (
            <CustomTimePicker
              onChange={onTimePickerChange}
              value={localValue}
            />
          )}

          <Stack
            direction="row"
            spacing={2.5}
            className={classes.actionButtonsRow}>
            <Button
              variant="outlined"
              className={classes.actionButton}
              onClick={closeDateTimePicker}>
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.actionButton}
              onClick={save}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  )
}

export { DateTimePickerDialog }
