import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { TOKEN_KEY } from "../../config/constants"
import { saveString } from "../../utils"
import { withEnvironment, withRootStore } from "../extensions"
import { AuthRoles } from "../login-store/authentication"
import { validate, ValidationRules } from "../../utils/validate"

const VERSION = 1

/******************************************************************
 *
 *
 * SignupStore Model
 *
 *
 * ***************************************************************/

export const SignupStoreModel = types
  .model("SignupStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    email: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    passwordConfirm: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value
    },
    setFirstName(value: string) {
      self.firstName = value
    },
    setLastName(value: string) {
      self.lastName = value
    },
    setPassword(value: string) {
      self.password = value
    },
    setPasswordConfirm(value: string) {
      self.passwordConfirm = value
    },

    reset() {
      self.email = ""
      self.firstName = ""
      self.lastName = ""
      self.password = ""
      self.passwordConfirm = ""
    },
  }))
  .actions((self) => ({
    async apiSignup(role: AuthRoles = AuthRoles.User) {
      const { email, firstName, lastName, password, environment, rootStore } = self
      const { api } = environment
      const { siteStore } = rootStore
      try {
        const { data, kind } = await api.signup({
          email,
          firstName,
          lastName,
          password,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        //@ts-ignore
        const token = data?.authentication.token
        //@ts-ignore
        const person = data?.person
        api.setAuthorizationHeader(token)
        api.setPersonId(person.id)
        api.setSiteId(siteStore.currentSiteId)
        await saveString(TOKEN_KEY, token)
        return Promise.resolve()
      } catch (error) {
        throw error
      }
    },
  }))
  .views((self) => ({
    get signupErrors() {
      return validate(SIGNUP_VALIDATION_RULES, self)
    },
  }))

const SIGNUP_VALIDATION_RULES: ValidationRules = {
  email: {
    presence: { allowEmpty: false, message: "Required." },
    length: { minimum: 4, message: "At least 4 characters are required." },
    noEmoji: {},
  },
  firstName: {
    presence: { allowEmpty: false, message: "Required." },
  },
  lastName: {
    presence: { allowEmpty: false, message: "Required." },
  },
  password: {
    presence: { allowEmpty: false, message: "Required." },
    length: { minimum: 6, message: "At least 6 characters are required." },
    noEmoji: {},
  },
  passwordConfirm: {
    presence: { allowEmpty: false, message: "Required." },
    length: { minimum: 6, message: "At least 6 characters are required." },
    noEmoji: {},
    equality: {
      attribute: "password",
      message: "Make sure your password matches.",
    },
  },
}

export interface SignupStore extends Instance<typeof SignupStoreModel> {}
export interface SignupStoreSnapshot extends SnapshotOut<typeof SignupStoreModel> {}
