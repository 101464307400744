import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme, CircularProgress, Stack } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { AdminTableHeader } from "../admin-table-header"
import { CustomTooltip, ShortText } from "../admin-msl-events-list/short-text"
import { CategoriesActions } from "./categories-actions"
import { CreateCategoryModal } from "./create-category-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableTrainerCircleImage: {
      width: "100%",
      maxWidth: 120,
    },
  }),
)

const AdminCategoriesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateCategoryModalOpen, toggleCreateCategoryModalOpen] = useState(
    false,
  )

  const { adminMslEventStoreModel } = useStores()
  const {
    categories,
    apiAdminGetCategories,
    apiAdminGetMslEvents,
    apiAdminGetTags,
    resetNewCategory,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetCategories()
        await apiAdminGetMslEvents()
        await apiAdminGetTags()
      } catch (e) {
      } finally {
        toggleFirstLoading(false)
      }
    }
    initialFetch()
  }, [apiAdminGetCategories, apiAdminGetMslEvents, apiAdminGetTags])

  const openCreateCategoryModal = () => {
    toggleCreateCategoryModalOpen(true)
  }

  const closeCreateCategoryModal = () => {
    toggleCreateCategoryModalOpen(false)
  }

  const addNewCategory = () => {
    resetNewCategory()
    openCreateCategoryModal()
  }

  return (
    <div className={classes.root}>
      <LogsTable
        tableLabel="Categories"
        tableAriaLabel="categories-table"
        tablePaginationProps={{
          count: !!categories ? categories.length : 0,
        }}
        defaultRowsPerPage={25}
        CustomTableHeader={({ tableLabel }) => (
          <AdminTableHeader
            tableLabel={tableLabel}
            addNewButtonLabel="New category"
            addNewButtonOnClick={addNewCategory}
          />
        )}>
        {({ page, rowsPerPage }) => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "25%" }} isStartEndPoint>
                    Name
                  </HeaderCell>

                  <HeaderCell style={{ width: "25%" }}>Description</HeaderCell>

                  <HeaderCell style={{ width: "25%" }}>Image</HeaderCell>

                  <HeaderCell style={{ width: "25%" }}>
                    Publish status
                  </HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(categories.slice().length) ? (
                  categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((categoryItem) => (
                      <TableRow key={categoryItem.id}>
                        <BodyCell isStartEndPoint>{categoryItem.name}</BodyCell>

                        <BodyCell>
                          <ShortText
                            value={categoryItem.description || "N/A"}
                            textLimit={50}
                          />
                        </BodyCell>

                        <BodyCell>
                          <Stack spacing={1}>
                            <CustomTooltip title={categoryItem.imageUrl}>
                              {!!categoryItem.imageUrl ? (
                                <img
                                  src={categoryItem.imageUrl}
                                  alt="categories"
                                  className={classes.tableTrainerCircleImage}
                                />
                              ) : (
                                <span>N/A</span>
                              )}
                            </CustomTooltip>
                          </Stack>
                        </BodyCell>

                        <BodyCell>
                          <CategoriesActions
                            categoryItem={categoryItem}
                            openCreateCategoryModal={openCreateCategoryModal}
                          />
                        </BodyCell>
                      </TableRow>
                    ))
                ) : (
                  <Box pl={3} pt={3}>
                    {firstLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EmptyState title="When you create some categories, they will appear here" />
                    )}
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>

      <CreateCategoryModal
        open={isCreateCategoryModalOpen}
        closeModal={closeCreateCategoryModal}
      />
    </div>
  )
})

export { AdminCategoriesList }
