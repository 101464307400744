import React from "react"
import { Typography } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    detailTitle: {
      fontWeight: 700,
      fontSize: theme.spacing(1.75),
      ...typography.circularXXBook,
    },
  }),
)

export const DetailTitle = ({ title }: { title: string }) => {
  const classes = useStyles()
  return <Typography className={classes.detailTitle}>{title}</Typography>
}
