import moment from "moment"
import { ChatMessage } from "../../models/chat-message"
/**
 *
 * @param messages - full messages list of a chat
 * @returns a new list with messages with a label date for the first message of a each date
 */
export const getGroupedMessagesByDate = (
  messages: Array<ChatMessage>,
): Array<ChatMessage> => {
  //Reverse the list to the ascending order (from the earlier message to the latest message)
  return [...messages]
    .reverse()
    .reduce((acc: Array<ChatMessage>, nextMessage: ChatMessage) => {
      //generate date label based on next message date
      const dateLabel = moment(nextMessage.createdAt).format(
        "MMMM Do YYYY h:mm A",
      )

      //check if that label is already on the messages list
      const messagesGroupIndex = acc.findIndex(
        (message) => message.dateLabel === dateLabel,
      )

      //if no, add the label to the message
      if (messagesGroupIndex === -1) {
        acc.push({
          ...nextMessage,
          log: nextMessage.log ? { ...nextMessage.log } : null,
          createdAtFromNow: nextMessage.createdAtFromNow,
          dateLabel,
        })
      } else {
        acc.push({
          ...nextMessage,
          log: nextMessage.log ? { ...nextMessage.log } : null,
          createdAtFromNow: nextMessage.createdAtFromNow,
        })
      }

      return acc
    }, [])
    .reverse()
}
