export const getUrlFileType = (url: string) => {
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "webp",
    "tiff",
  ]
  const videoExtensions = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "wmv",
    "flv",
    "webm",
    "mpeg",
    "mpg",
    "m4v",
  ]
  const audioExtensions = ["mp3", "wav", "ogg", "aac", "flac", "wma", "m4a"]

  const fileExtension = url.split(".").pop().toLowerCase()

  if (imageExtensions.includes(fileExtension)) {
    return "image"
  } else if (videoExtensions.includes(fileExtension)) {
    return "video"
  } else if (audioExtensions.includes(fileExtension)) {
    return "audio"
  } else {
    return "unknown"
  }
}
