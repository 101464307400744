export const typography = {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: "-0.24px",
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "-0.06px",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px",
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: "-0.05px",
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  overline: {
    fontWeight: 500,
  },
  circularXXBlack: {
    fontFamily: "CircularXX-Black",
  },
  circularXXBlackItalic: {
    fontFamily: "CircularXX-BlackItalic",
  },
  circularXXBold: {
    fontFamily: "CircularXX-Bold",
  },
  circularXXBoldItalic: {
    fontFamily: "CircularXX-BoldItalic",
  },
  circularXXBook: {
    fontFamily: "CircularXX-Book",
  },
  circularXXBookItalic: {
    fontFamily: "CircularXX-BookItalic",
  },
  circularXXExtraBlack: {
    fontFamily: "CircularXX-ExtraBlack",
  },
  circularXXExtraBlackItalic: {
    fontFamily: "CircularXX-ExtraBlackItalic",
  },
  circularXXItalic: {
    fontFamily: "CircularXX-Italic",
  },
  circularXXLight: {
    fontFamily: "CircularXX-Light",
  },
  circularXXLightItalic: {
    fontFamily: "CircularXX-LightItalic",
  },
  circularXXMedium: {
    fontFamily: "CircularXX-Medium",
  },
  circularXXMediumItalic: {
    fontFamily: "CircularXX-MediumItalic",
  },
  circularXXRegular: {
    fontFamily: "CircularXX-Regular",
  },
  circularXXThin: {
    fontFamily: "CircularXX-Thin",
  },
  circularXXThinItalic: {
    fontFamily: "CircularXX-ThinItalic",
  },
}
