import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridColDef, GridCellProps } from "@mui/x-data-grid"

import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import {
  CreateDidYouKnowTileModal,
  ModalMode,
} from "./create-did-you-know-tile-modal"
import { DidYouKnowTile } from "../../models/did-you-know-tile"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "30%",
      maxWidth: "30%",
    },
    columnHeader: {
      flex: 1,
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
  }),
)

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "imageView",
    headerName: "Image",
  },
  {
    field: "fact",
    headerName: "Fact",
  },
  {
    field: "active",
    headerName: "Is Active",
  },
  {
    field: "edit",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]

const DidYouKnowTilesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateTileModalOpen, toggleCreateTileModalOpen] = useState(false)
  const [tileModalMode, setTileModalMode] = useState<ModalMode>(
    ModalMode.ADD_NEW_TILE,
  )

  const { adminMslEventStoreModel } = useStores()
  const {
    didYouKnowTiles,
    setNewDidYouKnowTile,
    apiAdminGetDidYouKnowTiles,
    resetNewDidYouKnowTile,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetDidYouKnowTiles()
      } catch (e) {}
      toggleFirstLoading(false)
    }
    initialFetch()
  }, [apiAdminGetDidYouKnowTiles])

  const openCreateTilesModal = () => {
    resetNewDidYouKnowTile()
    setTileModalMode(ModalMode.ADD_NEW_TILE)
    toggleCreateTileModalOpen(true)
  }

  const openEditTileModal = useCallback((event: DidYouKnowTile) => {
    setNewDidYouKnowTile(event)
    setTileModalMode(ModalMode.EDIT_TILE)
    toggleCreateTileModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTileModal = () => {
    toggleCreateTileModalOpen(false)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditTileModal={openEditTileModal}
          tileID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>
          Did you know tiles
        </Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateTilesModal}>
          Add tile
        </Button>
      </Stack>

      <CustomGridTable
        rows={didYouKnowTiles.slice()}
        columns={columns}
        aria-label="tiles-table"
        cell={MemoTableCell}
        emptyStateLabel="When you create some tiles, they will appear here"
        loading={firstLoading}
        classes={{
          columnHeader: classes.columnHeader,
        }}
      />

      <CreateDidYouKnowTileModal
        open={isCreateTileModalOpen}
        closeModal={closeCreateTileModal}
        mode={tileModalMode}
      />
    </div>
  )
})

export { DidYouKnowTilesList }
