import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import { PostModel } from "../post"
import {
  ProgramInvitation,
  ProgramInvitationModel,
} from "../program-invitation"
import { VerificationCode, VerificationCodeModel } from "../verification-code"
import { UserChatModel } from "./user-chat"
import { BasicPerson, BasicPersonModel } from "./basic-person"
import { BasicTriModel } from "./basic-tri"
import { sortPosts } from "../admin-tools-store/utils"
import { DESCENDING } from "../admin-tools-store/filter-types"

export const FullPersonDetailsModel = types
  .model("FullPersonDetails")
  .props({
    coachChats: types.maybeNull(UserChatModel),
    cohortChats: types.maybeNull(UserChatModel),
    currentTri: types.maybeNull(BasicTriModel),
    gratitudePosts: types.optional(types.array(PostModel), []),
    intentionPosts: types.optional(types.array(PostModel), []),
    lastCheckin: types.maybeNull(customTypes.iso8601),
    person: types.optional(BasicPersonModel, () => BasicPersonModel.create({})),
    verificationCodes: types.optional(types.array(VerificationCodeModel), []),
    programInvitations: types.optional(types.array(ProgramInvitationModel), []),
    loadingProgramInvitations: false,
  })
  .actions((self) => ({
    setProgramInvitations(value: ProgramInvitation[]) {
      self.programInvitations.replace(value)
    },
    setVerificationCodes(value: VerificationCode[]) {
      self.verificationCodes.replace(value)
    },
    toggleLoadingProgramInvitations(value: boolean) {
      self.loadingProgramInvitations = value
    },
    setPerson(value: BasicPerson) {
      self.person = value
    },
  }))
  .views((self) => ({
    get totalUserPosts() {
      return [...self.gratitudePosts, ...self.intentionPosts]
    },
    get formattedLastCheckIn() {
      try {
        if (!self.lastCheckin) {
          return "N/A"
        }
        const formattedDate = moment(self.lastCheckin)
        //filter weird dates with year 1 or year 0
        if (formattedDate.get("year") < 1800) {
          return "N/A"
        }

        return formattedDate.format("MMM D, YYYY")
      } catch (error) {
        return "N/A"
      }
    },
  }))
  .views((self) => ({
    get lastPostDate() {
      if (self.totalUserPosts.length === 0) {
        return "N/A"
      }
      const sortedList = sortPosts(self.totalUserPosts, DESCENDING)
      return sortedList[0].formattedPostDate
    },
  }))

export interface FullPersonDetails
  extends Instance<typeof FullPersonDetailsModel> {}
export interface FullPersonDetailsSnapshot
  extends SnapshotOut<typeof FullPersonDetailsModel> {}
