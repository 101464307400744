import React from "react"
import { Grid, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { AdminPeopleSearchWrapper } from "../../components/admin-people-search-wrapper"
import { AdminPersonAside } from "../../components/admin-person-aside"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
    },
    root: {
      display: "flex",
      flex: 1,
    },
    searchListContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      borderRight: "1px solid #B0BABF",
    },
    personDetailsContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  }),
)

const AdminPeopleSearch = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={8} className={classes.searchListContainer}>
        <AdminPeopleSearchWrapper />
      </Grid>
      <Grid item xs={12} md={4} className={classes.personDetailsContainer}>
        <AdminPersonAside />
      </Grid>
    </Grid>
  )
}

export default AdminPeopleSearch
