import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridColDef, GridCellProps } from "@mui/x-data-grid"
import AddIcon from "@mui/icons-material/Add"

import { useStores } from "../../models/root-store"
import { CustomGridTable, TableCell } from "../custom-grid-table"
import { typography } from "../../services/theme/typography"
import { CellComponent } from "./cell-component"
import { CreateInvitationModal } from "./create-invitation-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    columnHeader: {
      flex: 1,
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
    tableCell: {
      width: "30%",
      maxWidth: "30%",
    },
  }),
)

const columns: GridColDef[] = [
  {
    field: "email",
    headerName: "Email",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "invitationURL",
    headerName: "Invitation URL",
  },
  {
    field: "groupID",
    headerName: "Group ID",
  },
  {
    field: "acceptedBy",
    headerName: "Accepted By",
  },
]

const AdminInvitationsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [
    isCreateInvitationModalOpen,
    toggleCreateInvitationModalOpen,
  ] = useState(false)

  const { adminMslEventStoreModel } = useStores()
  const {
    invitationsPagination,
    apiAdminGetInvitations,
    apiAdminGetNonDppGroups,
  } = adminMslEventStoreModel

  const {
    page: currentPage,
    limit,
    invitations,
    isEndReached,
    loading,
    resetPagination,
    setPage,
  } = invitationsPagination

  const openCreateInvitationModal = () => {
    toggleCreateInvitationModalOpen(true)
  }

  const closeCreateInvitationModal = () => {
    toggleCreateInvitationModalOpen(false)
  }

  const handlePaginationChange = async ({ page, pageSize }) => {
    if (!isEndReached && currentPage < page + 1) {
      setPage(currentPage + 1)
      await apiAdminGetInvitations()
    }
  }

  useEffect(() => {
    const initialFetch = async () => {
      try {
        resetPagination()
        await apiAdminGetInvitations()
        await apiAdminGetNonDppGroups()
      } catch (e) {}
      toggleFirstLoading(false)
    }
    initialFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAdminGetInvitations, apiAdminGetNonDppGroups])

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent {...props} />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Invitations</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateInvitationModal}>
          New invitation
        </Button>
      </Stack>

      <CustomGridTable
        rows={invitations.slice()}
        columns={columns}
        aria-label="invitations-table"
        cell={MemoTableCell}
        emptyStateLabel="When you create some invitations, they will appear here"
        loading={loading || firstLoading}
        classes={{
          columnHeader: classes.columnHeader,
        }}
        onPaginationModelChange={handlePaginationChange}
        rowCount={invitations.slice().length + (isEndReached ? 0 : 20)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
      />

      <CreateInvitationModal
        open={isCreateInvitationModalOpen}
        closeModal={closeCreateInvitationModal}
      />
    </div>
  )
})

export { AdminInvitationsList }
