import React, { ReactNode } from "react"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { typography } from "../../services/theme/typography"

export const dashboardHeaderHeight = 64

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      width: "100%",
      height: dashboardHeaderHeight,
      background: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      padding: `0 ${theme.spacing(5)}`,
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    screenTitle: {
      fontSize: 20,
      letterSpacing: "0.04em",
      color: theme.palette.text.primary,
      ...typography.circularXXBold,
    },
    icon: {
      marginLeft: theme.spacing(2),
      display: "flex",
    },
  }),
)

interface DashboardHeaderBarProps {
  title: string
  icon?: ReactNode
  children?: ReactNode
}

const DashboardHeaderBar = ({
  children,
  title,
  icon,
}: DashboardHeaderBarProps) => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <div className={classes.titleContainer}>
        <div className={classes.screenTitle}>{title}</div>

        {Boolean(icon) && <div className={classes.icon}>{icon}</div>}
      </div>

      {children}
    </div>
  )
}

export { DashboardHeaderBar }
