import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { Series } from "../../models/series"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useSeriesForm } from "./use-series-form"

interface CreateSeriesModalProps {
  open: boolean
  closeModal: () => void
}

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new series",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit series", buttonLabel: "Save" },
}
const CreateSeriesModal = observer(
  ({ open, closeModal }: CreateSeriesModalProps) => {
    const {
      isEditMode,
      values,
      errors,
      loading,
      submitted,
      onCancel,
      onSubmit,
      formConfig,
    } = useSeriesForm({ closeModal, isModalOpen: open })

    const modalMode = isEditMode ? ModalMode.EDIT : ModalMode.CREATE

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[modalMode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[modalMode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Series>
            submitted={submitted}
            values={values}
            errors={errors}
            formConfig={formConfig}
            isEditMode={isEditMode}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateSeriesModal }
