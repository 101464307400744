import React, { useState, useEffect } from "react"
import clsx from "clsx"
import {
  Box,
  Theme,
  Avatar,
  IconButton,
  Popover,
  Typography,
  Stack,
  Button,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "inline-block",
    },
    personAvatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    editFlag: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      background: theme.palette.common.white,
      borderRadius: "50%",
      position: "absolute",
      bottom: "-10px",
      right: 0,
    },
    editIcon: {
      color: theme.palette.info.main,
      fontSize: theme.spacing(2.5),
    },
    pickerContainer: {
      minWidth: 453,
      minHeight: 356,
      boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
      borderRadius: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    pickerTitle: {
      fontSize: theme.spacing(3.25),
      lineHeight: theme.spacing(4),
      color: "#485359",
      ...typography.circularXXBold,
      marginBottom: theme.spacing(3),
    },
    formContent: {
      alignItems: "center",
      padding: theme.spacing(3),
    },
    avatarsContainer: {
      flexWrap: "wrap",
      maxWidth: 450,
    },
    optionAvatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: theme.spacing(2),
      opacity: 0.2,
      "&:hover": {
        opacity: 1,
      },
    },
    selectedAvatar: {
      opacity: 1,
      border: "2px solid #08A777",
    },

    formButtons: {
      padding: theme.spacing(2),
      background: "#EBEEF0",
      flex: "initial",
    },
    actionButton: {
      width: 154,
      height: 45,
    },
  }),
)

const AvatarPicker = () => {
  const classes = useStyles()
  const { loginStore, personStore } = useStores()
  const { person, apiUpdateAuthedPersonAvatar } = loginStore
  const { apiGetAvatarsList, avatarsList } = personStore

  const [pickerEl, setPickerEl] = React.useState<HTMLButtonElement | null>(null)
  const [localSelectedAvatar, setLocalSelectedAvatar] = useState("")
  const [isLoading, toggleLoading] = useState(false)

  // const openPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setPickerEl(event.currentTarget)
  // }

  const handlePickerClose = () => {
    setPickerEl(null)
  }

  const open = Boolean(pickerEl)
  const id = open ? "avatar-picker" : undefined

  const updateAvatar = async () => {
    try {
      toggleLoading(true)
      await apiUpdateAuthedPersonAvatar(localSelectedAvatar)
      toggleLoading(false)
      handlePickerClose()
    } catch (error) {
      toggleLoading(false)
      throw error
    }
  }

  useEffect(() => {
    apiGetAvatarsList()
  }, [apiGetAvatarsList])

  //handle local avatar reset on picker open
  useEffect(() => {
    if (open) {
      setLocalSelectedAvatar(person.profile.avatarImageURL)
    }
  }, [open, person.profile.avatarImageURL])

  return (
    <Box className={classes.container}>
      <img
        src={person.profile.avatarImageURL}
        className={classes.personAvatar}
        alt="avatar"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={pickerEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.pickerContainer,
        }}>
        <Stack flex={1} direction="column" className={classes.formContent}>
          <Typography className={classes.pickerTitle}>
            Profile Picture
          </Typography>

          <Stack direction="row" className={classes.avatarsContainer}>
            {avatarsList.map((avatar) => (
              <IconButton
                sx={{ p: 0 }}
                onClick={() => setLocalSelectedAvatar(avatar)}>
                <Avatar
                  key={avatar}
                  src={avatar}
                  className={clsx(classes.optionAvatar, {
                    [classes.selectedAvatar]: avatar === localSelectedAvatar,
                  })}
                />
              </IconButton>
            ))}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          flex={1}
          spacing={4}
          className={classes.formButtons}>
          <Button
            onClick={handlePickerClose}
            variant="outlined"
            size="large"
            className={classes.actionButton}>
            Cancel
          </Button>

          <Button
            endIcon={
              isLoading && <CircularProgress color="secondary" size={24} />
            }
            onClick={updateAvatar}
            disabled={isLoading}
            variant="contained"
            size="large"
            className={classes.actionButton}>
            Confirm
          </Button>
        </Stack>
      </Popover>
    </Box>
  )
}

export { AvatarPicker }
