import moment from "moment"

export const noop = () => {}

export function percentageDiff(a: number, b: number) {
  return 100 * Math.abs((a - b) / ((a + b) / 2))
}

export function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value)
}

export const transformToTimeValue = (date: moment.Moment) => {
  if (!date) {
    return null
  }
  return date.toDate()
}

/**
 * Match the message preview timestamp to the timestamp displayed on the 1:1 chats for messages that are older than "today".
 */
export const getMessageTimeLabel = (messageDate: Date, dateLabel: string) => {
  const isCurrentDate = moment().diff(moment(messageDate), "hours") < 24
  if (isCurrentDate) {
    return moment(messageDate).format("hh:mm a")
  }

  return dateLabel
}

export const roundToTwoDecimal = (a: number) => {
  return Math.round(a * 100) / 100
}

export const isKeywordIncluded = (searchKeyword: string, label: string) => {
  if ((searchKeyword || "").trim().length === 0) return true

  return (label || "")
    .toLocaleLowerCase()
    .includes((searchKeyword || "").toLocaleLowerCase())
}
