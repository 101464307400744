import { types } from "mobx-state-tree"
import moment from "moment"

export const iso8601 = types.custom<string, Date | null>({
  name: "iso8601",

  fromSnapshot(value: string) {
    const valueMoment = moment(value)
    if (!valueMoment.isValid()) {
      return null
    }
    return valueMoment.toDate()
  },

  toSnapshot(value: Date | null) {
    if (value === null) {
      return ""
    }

    return moment(value).toISOString()
  },

  isTargetType(value: string | Date | null): boolean {
    return value instanceof Date || value === null
  },

  getValidationMessage(value: string): string {
    if (typeof value === "string") {
      return ""
    } else {
      return `'${value}' doesn't look like a valid ISO8601 date string`
    }
  },
})
