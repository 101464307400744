import React, { useState, useEffect } from "react"
import clsx from "clsx"
import {
  Theme,
  Stack,
  Typography,
  Switch,
  SwitchProps,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import EditIcon from "@material-ui/icons/Edit"
import { FormFields } from "./utils"
import { typography } from "../../services/theme/typography"

interface FormInputProps {
  inputContainerStyle?: string
  label: string
  fieldName: FormFields
  fieldValue: boolean
  updateFieldValue: (newValue: boolean) => void
  openCustomEditMode?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  required?: boolean
  showSubmitButtonsColumn?: boolean
  switchProps?: SwitchProps
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
      color: theme.palette.common.black,
    },
    requiredStar: {
      color: theme.palette.error.main,
    },
    formControlRoot: {
      flex: 1,
      maxWidth: 345,
    },
    editButton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: theme.spacing(0.5),
    },
    editIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.info.main,
    },
    buttonsRow: {
      marginLeft: theme.spacing(4),
    },
    buttonsColumn: {
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      padding: theme.spacing(1.5, 2),
      lineHeight: theme.spacing(2),
      ...typography.circularXXMedium,
    },
    saveButton: {
      padding: theme.spacing(1.5, 3.25),
      lineHeight: theme.spacing(2),
      border: `1px solid ${theme.palette.info.main}`,
      ...typography.circularXXMedium,
    },
    loadingProgress: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
  }),
)

export const FormSwitch = ({
  label,
  fieldName,
  fieldValue,
  updateFieldValue,
  openCustomEditMode,
  required,
  inputContainerStyle,
  switchProps,
  showSubmitButtonsColumn,
}: FormInputProps) => {
  const classes = useStyles()
  const [isEditMode, toggleEditMode] = useState(false)
  const [switchValue, setSwitchValue] = useState(false)
  const [isLoading, toggleLoading] = useState(false)

  const openEditMode = () => {
    toggleEditMode(true)
  }

  const closeEditMode = () => {
    toggleEditMode(false)
  }

  //handle field reset to current field value on edit mode close
  useEffect(() => {
    if (!isEditMode) {
      setSwitchValue(fieldValue)
    }
  }, [fieldValue, isEditMode])

  const onFieldSave = async () => {
    try {
      toggleLoading(true)
      await updateFieldValue(switchValue)
      toggleLoading(false)
      closeEditMode()
    } catch (error) {
      toggleLoading(false)
      throw error
    }
  }

  return (
    <Stack spacing={0.5} className={inputContainerStyle}>
      <Typography className={classes.label}>
        {label}{" "}
        {Boolean(required) && <span className={classes.requiredStar}>*</span>}
      </Typography>

      <Stack
        direction={isEditMode && showSubmitButtonsColumn ? "column" : "row"}>
        <div className={classes.formControlRoot}>
          <Switch
            id={fieldName}
            name={fieldName}
            checked={switchValue}
            defaultChecked={switchValue}
            onChange={(e, checked) => setSwitchValue(checked)}
            disabled={!isEditMode}
            {...switchProps}
          />
        </div>

        {isEditMode ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            className={clsx({
              [classes.buttonsRow]: !showSubmitButtonsColumn,
              [classes.buttonsColumn]: showSubmitButtonsColumn,
            })}>
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={closeEditMode}>
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.saveButton}
              disabled={isLoading}
              onClick={onFieldSave}>
              Save
              {isLoading && (
                <CircularProgress
                  size={20}
                  className={classes.loadingProgress}
                />
              )}
            </Button>
          </Stack>
        ) : (
          <IconButton
            className={classes.editButton}
            onClick={
              Boolean(openCustomEditMode) ? openCustomEditMode : openEditMode
            }>
            <EditIcon className={classes.editIcon} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
