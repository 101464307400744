import { GridColDef } from "@mui/x-data-grid"

import {
  FILTER_RECIPE,
  FILTER_HACK,
  FILTER_ITERATION,
  FILTER_PRODUCT,
  FILTER_INSIGHT,
  FILTER_SETUP,
  FILTER_VIDEO,
} from "../../models/tri-ideas-pagination/filter-types"

export const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Title",
    sortingOrder: ["asc", null],
    filterable: false,
  },
  {
    field: "summary",
    headerName: "Summary",
    sortable: false,
    filterable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
    type: "singleSelect",
    valueOptions: [
      FILTER_RECIPE,
      FILTER_HACK,
      FILTER_ITERATION,
      FILTER_PRODUCT,
      FILTER_INSIGHT,
      FILTER_SETUP,
      FILTER_VIDEO,
    ],
  },
  {
    field: "active",
    headerName: "Active",
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    filterable: false,
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    sortable: false,
    filterable: false,
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]
