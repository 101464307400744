import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const TriIdeaRecipeModel = types.model("TriIdeaRecipe").props({
  id: types.optional(types.string, ""),
  title: types.optional(types.maybeNull(types.string), null),
  suggesticSearchKeyword: types.optional(types.maybeNull(types.string), null),
})

export interface TriIdeaRecipe extends Instance<typeof TriIdeaRecipeModel> {}
export interface TriIdeaRecipeSnapshot
  extends SnapshotOut<typeof TriIdeaRecipeModel> {}
