import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

export const RecipeModel = types
  .model("Recipe")
  .props({
    id: types.identifier,
    title: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    suggesticSearchKeyword: types.optional(types.maybeNull(types.string), ""),
    suggesticRecipeId: types.optional(types.maybeNull(types.string), ""),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setSuggesticSearchKeyword(value: string) {
      self.suggesticSearchKeyword = value
    },
    setSuggesticRecipeId(value: string) {
      self.suggesticRecipeId = value
    },
    setTitle(value: string) {
      self.title = value
    },
    setLabel(value: string) {
      self.label = value
    },
    reset() {
      self.suggesticSearchKeyword = ""
      self.suggesticRecipeId = ""
    },
  }))
  .views((self) => ({
    get createRecipeErrors() {
      return validate(CREATE_NEW_RECIPE_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_RECIPE_RULES: ValidationRules = {
  suggesticSearchKeyword: {
    presence: { allowEmpty: false, message: "required" },
  },
  suggesticRecipeId: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface Recipe extends Instance<typeof RecipeModel> {}
export interface NudgesSnapshot extends SnapshotOut<typeof RecipeModel> {}
