import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import { Typography } from "@material-ui/core"
import { useStores } from "../../models/root-store"
import { Cohort } from "../../models/cohort"
import { clone } from "mobx-state-tree"

interface CohortSwitcherProps {}
export const CohortSwitcher = observer((props: CohortSwitcherProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { cohortStore } = useStores()
  const { cohorts, currentCohort, setCurrentCohort } = cohortStore

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCohortClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    cohort: Cohort,
  ) => {
    handleClose()
    // shallow copy so that mobx doesn't complain
    // about a node existing twice in the node tree.
    setCurrentCohort(clone(cohort))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        startIcon={<MenuOpenIcon />}
        aria-controls="site-switcher"
        aria-haspopup="true"
        onClick={handleClick}>
        {!!currentCohort && currentCohort.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {cohorts.map((cohort) => {
          return (
            <MenuItem
              key={`site-${cohort.id}`}
              onClick={(event) => {
                handleCohortClick(event, cohort)
              }}>
              <Typography variant="inherit"> {cohort.name}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
})
