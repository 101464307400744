import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as Sentry from "@sentry/react"
import { TOKEN_KEY } from "../../config/constants"
import { saveString } from "../../utils"
import { withEnvironment, withRootStore } from "../extensions"
import { Person, PersonModel } from "../person"
import { Authentication, AuthenticationModel } from "./authentication"

const VERSION = 1

export interface PartialParams {
  id?: string
  firstName?: string
  lastName?: string
  mobilePhone?: string | null
  countryCode?: string
  email?: string
  zipCode?: string
}

/******************************************************************
 *
 *
 * LoginStore Model
 *
 *
 * ***************************************************************/

export const LoginStoreModel = types
  .model("LoginStore")
  .extend(withEnvironment)
  .extend(withRootStore)

  .props({
    version: VERSION,
    email: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    authentication: types.optional(AuthenticationModel, () =>
      AuthenticationModel.create(),
    ),
    person: types.optional(PersonModel, () => PersonModel.create({ id: "-1" })),
  })
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value
    },
    setPassword(value: string) {
      self.password = value
    },
    setAuthentication(value: Authentication) {
      self.authentication = value
    },
    setPerson(value: Person) {
      self.person = value
    },
    reset() {
      self.email = ""
      self.password = ""
      self.person = PersonModel.create({ id: "-1" })
      self.authentication = AuthenticationModel.create()
    },
  }))
  .actions((self) => ({
    async apiLogin() {
      const { api } = self.environment
      const { siteStore } = self.rootStore
      try {
        const { data, kind } = await api.login(self.email, self.password)

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        //@ts-ignore
        const token = data?.authentication.token
        //@ts-ignore
        const person = data?.person
        //@ts-ignore
        const authentication = data?.authentication

        saveString(TOKEN_KEY, token)
        api.setAuthorizationHeader(token)
        api.setPersonId(person.id)
        api.setSiteId(siteStore.currentSiteId)

        self.setAuthentication(authentication)
        self.setPerson(person)
      } catch (error) {
        throw error
      }
    },

    async onLoginSuccess() {
      const { siteStore, cohortStore } = self.rootStore
      const { api } = self.environment
      await siteStore.apiGetSites()
      const [firstSite] = siteStore.sitesAsArray
      if (firstSite) {
        siteStore.setCurrentSiteId(firstSite.id)
        api.setSiteId(siteStore.currentSiteId)
        await cohortStore.apiGetCohorts()
      }
      Sentry.setUser({ email: self.person.email, id: self.person.id })
    },

    async apiForgotPassword() {
      const { api } = self.environment
      try {
        const { data, kind } = await api.forgotPassword(self.email)

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }
      } catch (error) {
        throw error
      }
    },

    async apiResetPassword(token: string) {
      const { api } = self.environment
      try {
        const { data, kind } = await api.resetPassword({
          email: self.email,
          password: self.password,
          token,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }
      } catch (error) {
        throw error
      }
    },

    async apiChangePassword(password: string) {
      const { api } = self.environment
      try {
        const { data, kind } = await api.changePassword({
          password,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }
      } catch (error) {
        throw error
      }
    },

    async contactSupport({
      email,
      message,
    }: {
      email: string
      message: string
    }) {
      const { api } = self.environment
      const isUserAuthenticated = self.authentication.token

      try {
        if (isUserAuthenticated) {
          const response = await api.contactAuthenticatedSupport({
            message,
          })

          if (response?.kind !== "ok") {
            //@ts-ignore
            throw new Error(data?.reason)
          }
        } else {
          const response = await api.contactUnAuthenticatedSupport({
            requesterEmail: email,
            message,
          })

          if (response?.kind !== "ok") {
            //@ts-ignore
            throw new Error(response?.data?.reason)
          }
        }
      } catch (error) {
        throw error
      }
    },

    async apiUpdateAuthedPerson(partialParams: PartialParams) {
      const { api } = self.environment
      try {
        const {
          id,
          firstName,
          lastName,
          email,
          mobilePhone,
          countryCode,
          zipCode,
        } = self.person

        //the api requries all selected fields to be available on the payload, even if they aren't updated
        //since in most cases only one field from selected fields will be updated, we are creating a payload object with the existing values
        //and we are destructuring `partialParams` at the end to override the field(s) which will be update
        const payload = {
          id,
          firstName,
          lastName,
          email,
          mobilePhone,
          countryCode,
          zipCode,
          ...partialParams,
        }

        //if `mobilePhone` field is empty convert it to null
        if (payload.mobilePhone.trim?.()?.length === 0) {
          payload.mobilePhone = null
        }

        const { kind, data } = await api.updatePerson(payload)

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        self.setPerson(data as Person)
      } catch (error) {
        throw error
      }
    },

    async apiUpdateAuthedPersonAvatar(avatarURL: string) {
      const { api } = self.environment
      try {
        const { kind } = await api.updatePersonAvatar({
          avatarURL,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        self.person.profile.setAvatarImageUrl(avatarURL)
      } catch (error) {
        throw error
      }
    },

    async apiUpdateMotivation(cohortId: string, motivation: string) {
      const { api } = self.environment
      const { kind } = await api.updateCoachMotivation(cohortId, {
        motivation,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.person?.setMotivation?.(motivation)
    },
  }))
  .views((self) => ({
    get isAuthenticated() {
      return !!self.authentication.token
    },
  }))

export interface LoginStore extends Instance<typeof LoginStoreModel> {}
export interface LoginStoreSnapshot
  extends SnapshotOut<typeof LoginStoreModel> {}
