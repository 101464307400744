import React, { useState, useEffect, useCallback, useRef } from "react"
import { Box, CircularProgress } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import {
  List as VList,
  ListChildComponentProps,
  AutoSizer,
  InfiniteLoader,
  CellMeasurerCache,
  CellMeasurer,
} from "react-virtualized"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { FeedListItem } from "./feed-list-item"

const useStyles = makeStyles((theme) =>
  createStyles({
    boxListWrapper: {
      display: "flex",
      flex: "1 1 1px",
      overflow: "hidden",
      position: "relative",
      paddingTop: theme.spacing(4),
    },
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: theme.spacing(1),
    },
  }),
)

const _cache = new CellMeasurerCache({
  defaultHeight: 75,
  fixedWidth: true,
})

export const AdminFeedList = observer(() => {
  const classes = useStyles()
  const listRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const { adminToolsStore } = useStores()
  const {
    feedSourceList,
    feedPostsLimit,
    loadMorePosts,
    hasNextPage,
    resetDateRangeFilter,
    apiGetInitialFeedPosts,
  } = adminToolsStore

  useEffect(() => {
    const initialFetch = async () => {
      setLoading(true)
      resetDateRangeFilter()
      try {
        await apiGetInitialFeedPosts()

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    initialFetch()
  }, [resetDateRangeFilter, apiGetInitialFeedPosts])

  const isRowLoaded = useCallback(
    ({ index }: ListChildComponentProps) => !!feedSourceList[index],
    [feedSourceList],
  )

  const handleLoadMore = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      await loadMorePosts()

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style, parent } = props

    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={0}
        key={index}
        rowIndex={index}
        parent={parent}>
        <FeedListItem index={index} style={style} />
      </CellMeasurer>
    )
  }

  //that's because loadMoreRows is triggered only when rowCount is higher than actual number of posts
  const rowCount = hasNextPage
    ? feedSourceList.length + feedPostsLimit
    : feedSourceList.length

  useEffect(() => {
    listRef.current?.recomputeRowHeights?.()
    _cache?.clearAll?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedSourceList])

  return (
    <Box className={classes.boxListWrapper}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={handleLoadMore}
        rowCount={rowCount}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => {
              return (
                <VList
                  ref={listRef}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  deferredMeasurementCache={_cache}
                  rowHeight={_cache.rowHeight}
                  rowRenderer={renderRow}
                  rowCount={feedSourceList.length}
                />
              )
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>

      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </Box>
  )
})
