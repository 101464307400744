import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"

const VERSION = 1

export enum MembersTableLayout {
  Cards = "cards",
  Table = "table",
}

const membersTableLayoutLiterals = Object.values(MembersTableLayout).map((v) =>
  types.literal(v),
)

/******************************************************************
 *
 *
 * UiStore Model
 *
 *
 * ***************************************************************/
export const UiStoreModel = types
  .model("UiStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    isDrawerOpen: types.optional(types.boolean, true),
    membersTableLayout: types.optional(
      types.union(...membersTableLayoutLiterals),
      MembersTableLayout.Cards,
    ),
  })
  .actions((self) => ({
    toggleDrawer() {
      self.isDrawerOpen = !self.isDrawerOpen
    },
    setMembersTableLayout(value: MembersTableLayout) {
      self.membersTableLayout = value
    },
  }))
  .actions((self) => ({
    reset() {
      self.isDrawerOpen = true
      self.membersTableLayout = MembersTableLayout.Cards
    },
  }))
  .views((self) => ({
    get isCardLayout(): boolean {
      return self.membersTableLayout === MembersTableLayout.Cards
    },
  }))

export interface UiStore extends Instance<typeof UiStoreModel> {}
export interface UiStoreSnapshot extends SnapshotOut<typeof UiStoreModel> {}
