import * as React from "react"

const domainRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
const emailRegex = /(([a-zA-Z0-9._-]+)@([\w.-]+))/gi

const isWithoutHttp = (part: string): boolean => {
  const lowerCasedPart = part.toLocaleLowerCase()
  return (
    lowerCasedPart.indexOf("https://") === -1 &&
    lowerCasedPart.indexOf("http://") === -1
  )
}

type Props = {
  className?: string
  email?: boolean
  noopener?: boolean
  noreferrer?: boolean
  text: string
  target: "_self" | "_blank" | "_parent" | "_top"
}

const Parse: React.FC<Props> = ({
  className,
  email,
  noopener,
  noreferrer,
  text,
  target,
}) => {
  const rel = `${noopener ? "noopener" : ""}${noreferrer ? " noreferrer" : ""}`
  const textList = text.split(" ")

  const getUrlTag = (urlPart: string) => {
    const withoutHTTP = isWithoutHttp(urlPart)
    const safeURL = withoutHTTP ? `http://${urlPart}` : urlPart

    return (
      <a
        href={safeURL}
        className={className}
        rel={rel}
        key={`parse-${urlPart}`}
        target={target}
        onClick={() => {
          return window.confirm("You are about to leave Fresh Tri. Continue?")
        }}>
        {urlPart}
      </a>
    )
  }

  const contents = textList.reduce(
    (list: Array<string | JSX.Element>, part: string) => {
      const domainPart = part.match(domainRegex)
      const emailPart = email && part.match(emailRegex)
      let item: string | JSX.Element = part
      if (part.length === 0) {
        item = " "
      } else if (!!domainPart) {
        //FIX: https://freshtriteam.teamwork.com/#/tasks/33265960
        //check if the url contains new lines and other content expect the link
        //if so, need to split the new lines and the other content, and create a different tag for each split
        const isNewLineUrl = part.includes("\n")
        if (isNewLineUrl) {
          const urlParts = part.split("\n")
          const arrayTagParts = urlParts.map((p, index) => {
            const isLastElement = index !== urlParts.length - 1
            //for each next part add a new line if it's not the last element
            const nextPart = `${p}${isLastElement ? "\n" : ""}`

            //if the next part is an url, return an url tag
            if (p.match(domainRegex)) {
              return <>{getUrlTag(nextPart)}</>
            }
            //else just the  next string part
            return <>{nextPart}</>
          })

          //create a new element joining the splitted tags
          item = (
            <>
              {arrayTagParts.map((p: React.ReactElement) =>
                React.cloneElement(p),
              )}
            </>
          )
        } else {
          item = getUrlTag(part)
        }
      } else if (!!emailPart) {
        item = (
          <a
            href={`mailto:${part}`}
            key={`parse-${part}`}
            className={className}
            rel={rel}>
            {part}
          </a>
        )
      }

      const lastChild = list[list.length - 1]
      if (list.length === 0) {
        list.push(item)
      } else if (typeof lastChild === "string") {
        if (typeof item === "string") {
          list[list.length - 1] = lastChild + " " + item
        } else {
          list[list.length - 1] = lastChild + " "
          list.push(item)
        }
      } else {
        if (typeof item === "string") {
          list.push(" " + item)
        } else {
          list.push(" ")
          list.push(item)
        }
      }

      return list
    },
    [],
  )

  return <>{contents}</>
}

export default React.memo(Parse)
