import React from "react"

import { Grid, Typography, Stack } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { typography } from "../../services/theme/typography"
import { Person } from "../../models/person"
import { capitalizeFirstStringsLetter } from "../../utils/capitalize-first-stings-letter"
import { heightConverter } from "../../utils/height-convertes"

const useStyles = makeStyles((theme) =>
  createStyles({
    gridCellTitle: {
      fontSize: theme.spacing(1.75),
      color: "#1871A6",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
    gridCellValue: {
      fontSize: theme.spacing(2),
      color: "#485359",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
    sectionLabel: {
      fontSize: theme.spacing(2.25),
      color: "#2D353B",
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      ...typography.circularXXMedium,
    },
  }),
)
const GridCell = ({ detail }: { detail: DetailsGrid }) => {
  const classes = useStyles()

  const { title, value } = detail

  return (
    <Grid item md={2} mb={2} mr={1}>
      <Typography className={classes.gridCellTitle}>{title}</Typography>
      <Typography className={classes.gridCellValue}>
        {value || "N/A"}
      </Typography>
    </Grid>
  )
}

interface DetailsGrid {
  title: string
  value?: string
}

interface MemberDetailsProps {
  member: Person
}

export const MemberDetails = ({ member }: MemberDetailsProps) => {
  const classes = useStyles()

  const { timezone, lastBiometricUpdate } = member
  const { age, biologicalSex, height, weight } = lastBiometricUpdate || {}

  const details: DetailsGrid[] = [
    { title: "Age", value: age?.toString?.() },
    {
      title: "Sex",
      value: capitalizeFirstStringsLetter(biologicalSex?.toString?.()),
    },
    { title: "Height", value: heightConverter(height) },
    { title: "Timezone", value: timezone?.split("/")[1].replace(/_/g, " ") },
    {
      title: "Weight",
      value: Boolean(weight) ? `${weight?.toString?.()} lbs` : null,
    },
  ]

  return (
    <Stack>
      <Typography className={classes.sectionLabel}>Details</Typography>
      <Grid container columns={{ md: 7 }}>
        {details.map((detail) => (
          <GridCell detail={detail} />
        ))}
      </Grid>
    </Stack>
  )
}
