import React, { FC } from "react"
import { Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import { typography } from "../../services/theme/typography"

interface NotificationBadgeProps {
  unReadCount: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      height: 20,
      padding: `0 ${theme.spacing(1)}`,
      borderRadius: "50%",
      backgroundColor: "#F23D3C",
      color: "#FFFFFF",
      fontSize: theme.typography.pxToRem(14),
      ...typography.circularXXBold,
    },
  }),
)

export const NotificationBadge: FC<NotificationBadgeProps> = ({
  unReadCount,
}) => {
  const classes = useStyles()

  return <div className={clsx(classes.badge)}>{unReadCount}</div>
}
