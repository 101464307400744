import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const ChatMessageTrainModel = types.model("ChatMessageTrain").props({
  categoryName: types.maybeNull(types.string),
  coachName: types.maybeNull(types.string),
  duration: types.maybeNull(types.union(types.string, types.number)),
  episodeName: types.maybeNull(types.string),
  episodeURL: types.maybeNull(types.string),
  posterImageURL: types.maybeNull(types.string),
})

export interface ChatMessageTrain
  extends Instance<typeof ChatMessageTrainModel> {}
export interface ChatMessageTrainSnapshot
  extends SnapshotOut<typeof ChatMessageTrainModel> {}
