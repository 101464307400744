import React, { useEffect } from "react"
import { Box, List, Paper } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { useParams, useRouteMatch, useHistory } from "react-router-dom"
import { RouteParams } from "../../AppRoutes"
import { Cohort, InboxItemType } from "../../models/cohort"
import { ChatInboxItem } from "../chat-inbox-item"
import { EmptyInboxAside } from "./empty-inbox-aside"

const useStyles = makeStyles((theme) =>
  createStyles({
    searchField: {
      "&.MuiFormControl-root": {
        margin: 0,
      },
    },
    cohortChatAccordionListWrapper: {
      display: "flex",
      flex: "1 1 1px",
      overflow: "hidden",
      position: "relative",
    },
    paperRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRight: "1px solid #C7CFD4",
      borderRadius: 0,
      boxShadow: "none",
    },
    listRoot: {
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: 0,
    },
  }),
)

export const CohortChatAside = observer(() => {
  const params = useParams<RouteParams>()
  const { cohortStore, loginStore } = useStores()
  const { cohorts } = cohortStore

  const selectedCohort: Cohort = cohorts.find(
    (cohort) => cohort?.id === params.cohortId,
  )
  const { id: selectedCohortId, directChatsInboxList } = selectedCohort || {}

  const classes = useStyles()
  const { push } = useHistory()

  const matchChatConversationRoute = useRouteMatch<RouteParams>(
    "/site/:siteId/cohorts/:cohortId/chat/:roomId",
  )

  const matchChatRoute = useRouteMatch<RouteParams>(
    "/site/:siteId/cohorts/:cohortId/chat",
  )

  /**
   * TODO(Harris): figure out why theres' a weird race condition with
   * flattedd chats.
   * maybe it's time to refactor it and move this stuff up so that we can implement
   * proper loaders.
   */
  /**
   * This effect exists so that we always redirect
   * to a chat when the user lands on /chat.
   */
  useEffect(() => {
    if (
      selectedCohortId &&
      matchChatRoute.isExact &&
      directChatsInboxList?.length
    ) {
      const participant = directChatsInboxList?.[0].participant
      const firstRoomId = directChatsInboxList?.[0].participant?.personID
      //focus only if valid chat with valid participant
      if (firstRoomId && participant) {
        push(
          `/site/${params.siteId}/cohorts/${selectedCohortId}/chat/${firstRoomId}`,
        )
      }
    }
  }, [
    matchChatRoute,
    params.siteId,
    directChatsInboxList,
    selectedCohortId,
    push,
  ])

  if (!directChatsInboxList || directChatsInboxList?.length === 0) {
    return <EmptyInboxAside />
  }

  return (
    <>
      <Paper className={classes.paperRoot}>
        <Box className={classes.cohortChatAccordionListWrapper}>
          <List className={classes.listRoot}>
            {directChatsInboxList.map((chat: InboxItemType) => {
              const { newMessages, participant, latestMessage } = chat

              const isCurrentUser = loginStore.person.id === chat.participant.id
              const isCurrentRoom =
                matchChatConversationRoute?.params.roomId ===
                chat.participant.personID

              const isCohortActive =
                matchChatConversationRoute?.isExact && isCurrentRoom

              const { message, createdAt, id: messageId, createdAtFromNow } =
                latestMessage || {}

              return (
                <ChatInboxItem
                  key={participant.id}
                  linkTo={`/site/${params.siteId}/cohorts/${params.cohortId}/chat/${participant.personID}`}
                  isCurrentUser={isCurrentUser}
                  isChatFocused={isCohortActive}
                  newMessages={newMessages}
                  participant={participant}
                  messageId={messageId}
                  message={message}
                  createdAt={createdAt}
                  createdAtFromNow={createdAtFromNow}
                />
              )
            })}
          </List>
        </Box>
      </Paper>
    </>
  )
})
