import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
  Theme,
  IconButton,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  Button,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import AccountCircle from "@material-ui/icons/AccountCircle"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { FullPersonDetails } from "../../models/admin-user-search/full-person-details"
import { EnrolledPrograms } from "../../models/admin-user-search/enrolled-programs"
import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    triSectionContainer: {
      padding: theme.spacing(2),
      border: "1px solid #C7CFD4",
      borderRadius: theme.spacing(0.5),
      background: "#FFFFFF",
      position: "relative",
    },

    triAvatarButton: {
      padding: theme.spacing(1),
    },
    triSelectorButton: {
      padding: 0,
      borderRadius: 1,
      justifyContent: "flex-start",
    },
    triSelectorButtonText: {
      fontSize: 20,
      lineHeight: "30px",
      color: "#2D353B",
      "letter-spacing": "-0.32px",
      fontWeight: "bold",
    },
    triProgramNameText: {
      fontSize: 20,
      lineHeight: "25px",
      color: "#485359",
      "font-weight": "500",
    },
    triSelectorDropDown: {
      border: "1px solid #B0BABF",
      "box-shadow": "none",
    },
    triSelectorDropDownList: {
      padding: 0,
    },
    triSelectorDropDownItem: {
      fontSize: 20,
      lineHeight: "23px",
      color: "#B0BABF",
      padding: theme.spacing(1),
    },
    dropdownIcon: {
      color: "#2D353B",
    },
    showInvitations: {
      flexDirection: "column",
      alignItems: "flex-end",
      color: "#007CA6",
      fontSize: 22,
      lineHeight: "26px",
      "font-weight": "600",
      "text-transform": "none",
      "&:hover": {
        color: "#056383",
        backgroundColor: "transparent",
      },
      "&:active": {
        color: "#054960",
        backgroundColor: "transparent",
      },
    },
    showInvitationsEndIcon: {
      height: 18,
    },
    showInvitationsIcon: {
      fontSize: `64px !important`,
      marginTop: -15,
    },
  }),
)

const PersonTriSelector = observer(
  ({
    selectedPerson,
    programsList,
    openProgramInvitations,
  }: {
    selectedPerson: FullPersonDetails
    programsList: EnrolledPrograms[]
    openProgramInvitations: () => void
  }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()
    const { adminToolsStore } = useStores()
    const { changeSelectedPersonProgram } = adminToolsStore

    const isMenuOpen = Boolean(anchorEl)
    const menuId = "tri-selector-menu"

    const handleProfileMenuOpen = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
      setAnchorEl(null)
    }

    const handleProgramChange = (programId: string) => {
      try {
        changeSelectedPersonProgram({ programId })
        handleMenuClose()
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const { enrolledProgramsLabel } = selectedPerson?.person?.profile

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        classes={{
          paper: classes.triSelectorDropDown,
          list: classes.triSelectorDropDownList,
        }}>
        <Stack
          divider={<Divider orientation="horizontal" style={{ margin: 0 }} />}>
          {programsList.map((program) => (
            <MenuItem
              className={classes.triSelectorDropDownItem}
              onClick={() => handleProgramChange(program.id)}
              key={program.id}>
              {program.name}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    )

    return (
      <Stack spacing={1} className={classes.triSectionContainer}>
        <Stack direction="row" spacing={3}>
          <IconButton className={classes.triAvatarButton}>
            <Avatar
              src={
                "https://cdn01.freshtri.com/freshtri/v2/images/coach-avatars/CoachAvatar_SweetPotato.png"
              }>
              <AccountCircle />
            </Avatar>
          </IconButton>

          <Stack spacing={2}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
              className={classes.triSelectorButton}
              disableTouchRipple>
              <Typography className={classes.triSelectorButtonText}>
                Program
              </Typography>

              <ArrowDropDownIcon
                fontSize="large"
                className={classes.dropdownIcon}
              />
            </IconButton>

            <Typography className={classes.triProgramNameText}>
              {enrolledProgramsLabel}
            </Typography>
          </Stack>

          {renderMenu}
        </Stack>

        <Button
          endIcon={
            <ArrowRightAltIcon
              classes={{ root: classes.showInvitationsIcon }}
            />
          }
          classes={{
            root: classes.showInvitations,
            endIcon: classes.showInvitationsEndIcon,
          }}
          disableTouchRipple
          onClick={openProgramInvitations}>
          Show Invitations
        </Button>
      </Stack>
    )
  },
)

export default PersonTriSelector
