import React from "react"
import {
  Grid,
  Paper,
  Typography,
  Modal,
  Button,
  Theme,
  Stack,
  useTheme,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { ReactComponent as FileIcon } from "../../assets/images/file_icon.svg"
import { typography } from "../../services/theme/typography"

interface StyleProps {
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  formRoot: {
    padding: theme.spacing(4.5),
    paddingBottom: 0,
  },
  formHeader: {
    borderBottom: "1px solid #2D353B",
    paddingBottom: theme.spacing(3),
  },
  formButtons: {
    alignItems: "center",
    background: "#F5F6F7",
    padding: theme.spacing(3.5),
    filter: "drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.12))",
  },
  formActionButton: {
    minWidth: 114,
    minHeight: theme.spacing(5),
    padding: 0,
  },
  formTitle: {
    fontSize: theme.spacing(3),
    letterSpacing: "2px",
    color: "#0071BC",
    textAlign: "center",
    ...typography.circularXXMedium,
  },
  formSubTitle: {
    fontSize: theme.spacing(2),
    color: "#677278",
    textAlign: "center",
    ...typography.circularXXBook,
  },
  sessionContent: {
    padding: theme.spacing(3, 0, 4, 0),
  },
  sessionDescription: {
    padding: theme.spacing(0, 10),
    fontSize: theme.spacing(2.25),
    color: "#677278",
    textAlign: "center",
    ...typography.circularXXBookItalic,
  },
  documentName: {
    fontSize: theme.spacing(2.25),
    color: "#0071BC",
    textAlign: "center",
    ...typography.circularXXMedium,
  },
}))

const ViewSession = observer(() => {
  const history = useHistory()
  const { cohortStore, uiStore } = useStores()
  const { isDrawerOpen } = uiStore
  const { currentCohort } = cohortStore
  const { currentSession } = currentCohort

  const classes = useStyles({ drawerWidth, isDrawerOpen })

  const goBack = () => history.goBack()

  const handleClose = () => {
    goBack()
  }

  const onCancelPress = () => {
    handleClose()
    return
  }

  const theme = useTheme()

  const body = (
    <Paper
      sx={{
        position: "absolute",
        outline: "none",
        width: "100%",
        height: "100%",
        maxHeight: "80vh",
        filter: "drop-shadow(0px 7px 10px rgba(45, 53, 59, 0.15))",
        [theme.breakpoints.up("sm")]: {
          width: "50vw",
          height: "auto",
          left: "50%",
          top: "50%",
          transform: `translateX(-50%) translateY(-50%)`,
        },
      }}>
      <Grid container className={classes.formRoot}>
        <Grid item xs={12}>
          <Stack spacing={2} alignItems="center" className={classes.formHeader}>
            <Typography className={classes.formTitle}>
              {currentSession?.name}
            </Typography>

            <Typography className={classes.formSubTitle}>
              {moment(currentSession?.date).format("MMMM Do YYYY - hh:mm A")}
            </Typography>
          </Stack>

          <Stack className={classes.sessionContent} spacing={3}>
            <Typography className={classes.sessionDescription}>
              {currentSession.description}
            </Typography>

            <Stack spacing={1.5}>
              {currentSession.documents.map((doc) => (
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={1.5}
                  key={doc.url}>
                  <FileIcon />

                  <a href={doc.url} target="blank">
                    <Typography className={classes.documentName}>
                      {doc.label}
                    </Typography>
                  </a>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Stack
        className={classes.formButtons}
        direction="row"
        justifyContent="center"
        spacing={5}>
        <Button
          onClick={onCancelPress}
          variant="contained"
          size="large"
          className={classes.formActionButton}>
          Close
        </Button>
      </Stack>
    </Paper>
  )

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
})

export { ViewSession }
