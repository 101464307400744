import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { isEmpty } from "ramda"
import {
  FormControl,
  Grid,
  Stack,
  Paper,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Chip,
  Theme,
} from "@material-ui/core"
import FaceIcon from "@material-ui/icons/Face"
import ErrorIcon from "@material-ui/icons/Error"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { Group } from "../../models/group"
import { typography } from "../../services/theme/typography"
import { validateEmail } from "../../utils/validate"
import { FormField, FormInput } from "../admin-msl-events-list/form-input"
import { FormSelect } from "../admin-msl-events-list/form-select"

interface CreateInvitationModalProps {
  open: boolean
  closeModal: () => void
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modalRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContainer: {
      minWidth: 450,
      display: "flex",
      flexDirection: "column",
    },
    formRoot: {
      padding: theme.spacing(3, 5),
      outline: "none",
      width: "100%",
      height: "100%",
      maxWidth: 600,
      maxHeight: "90vh",
      overflow: "auto",
    },
    formTitle: {
      fontSize: theme.spacing(3.25),
      color: "#485359",
      ...typography.circularXXBold,
      marginBottom: theme.spacing(3),
    },
    formControl: {
      width: "100%",
    },
    formButtons: {
      padding: theme.spacing(2, 5),
      background: "#EBEEF0",
    },
    chipColorSecondary: {},
    chipColorSecondaryError: {
      backgroundColor: theme.palette.error.main,
    },
  }),
)

const CreateInvitationModal = observer(
  ({ open, closeModal }: CreateInvitationModalProps) => {
    const classes = useStyles()

    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    const { adminMslEventStoreModel } = useStores()
    const {
      newInvitation,
      nonDppGroups,
      resetNewInvitation,
      apiAdminSendInvitations,
    } = adminMslEventStoreModel
    const {
      emails,
      setEmails,
      group,
      setGroup,
      membershipDuration,
      setMembershipDuration,
      createInvitationErrors: errors,
    } = newInvitation

    const onCancel = () => {
      resetNewInvitation()
      closeModal()
    }

    const handleModalClose = (event, reason) => {
      if (reason === "backdropClick") {
        onCancel()
      }
    }

    const handleEmailsChange = (
      event: React.SyntheticEvent<Element, Event>,
      value: string[],
    ) => {
      event.preventDefault()
      setEmails(value)
    }

    const onSubmit = async () => {
      setSubmitted(true)
      if (!isEmpty(errors)) return
      setSubmitted(false)
      try {
        setLoading(true)
        await apiAdminSendInvitations()
        setSnackbarSeverity("success")
        setSnackbarText("Successfully created invitation !")
        setShowSnackbar(true)
        closeModal()
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      } finally {
        setLoading(false)
      }
    }

    return (
      <Modal
        open={open}
        onClose={handleModalClose}
        classes={{
          root: classes.modalRoot,
        }}>
        <Paper className={classes.modalContainer}>
          <Grid container className={classes.formRoot}>
            <Grid item xs={12}>
              <Typography className={classes.formTitle}>
                Create new invitations
              </Typography>
            </Grid>

            <Stack width="100%" spacing={2}>
              <FormField
                label="Email(s)"
                required
                isFieldCompleted={!errors.emails}>
                <FormSelect
                  multiple
                  options={[]}
                  value={emails.slice()}
                  onChange={handleEmailsChange}
                  filterSelectedOptions
                  renderTags={(value, getTagProps) =>
                    value.map((option: any, index) => {
                      const isEmail = validateEmail(option)
                      return (
                        <Chip
                          icon={isEmail ? <FaceIcon /> : <ErrorIcon />}
                          disabled={!isEmail}
                          label={<>{option}</>}
                          color="secondary"
                          classes={{
                            colorSecondary: isEmail
                              ? classes.chipColorSecondary
                              : classes.chipColorSecondaryError,
                          }}
                          {...getTagProps({ index })}
                        />
                      )
                    })
                  }
                  Input={{
                    placeholder: "Press 'enter' to separate multiple emails.",
                    error: submitted && Boolean(errors.emails),
                    helperText: submitted && errors.emails,
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                      if (e.target.value) {
                        setEmails([...emails, e.target.value])
                      }
                    },
                  }}
                />
              </FormField>

              <FormField
                label="Duration (Nr. of days)"
                required
                isFieldCompleted={!errors.membershipDuration}>
                <FormInput
                  id="duration"
                  placeholder="Enter invitiation duration"
                  value={membershipDuration}
                  error={submitted && Boolean(errors.membershipDuration)}
                  helperText={submitted && errors.membershipDuration}
                  onChange={(evt) => setMembershipDuration(evt.target.value)}
                />
              </FormField>

              <FormField
                label="Non DPP Group"
                required
                isFieldCompleted={!errors.group}>
                <FormSelect<Group>
                  options={nonDppGroups}
                  getOptionLabel={(option) => option.name}
                  value={group}
                  onChange={(evt, value: Group) => setGroup(value)}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  Input={{
                    placeholder: "Select a group",
                    error: submitted && Boolean(errors.group),
                    helperText: submitted && errors.group,
                  }}
                />
              </FormField>
            </Stack>
          </Grid>

          <Grid item xs={12} className={classes.formButtons}>
            <FormControl className={classes.formControl}>
              <Stack
                direction="row"
                justifyContent={"center"}
                flex={1}
                spacing={4}>
                <Button onClick={onCancel} variant="outlined" size="large">
                  Cancel
                </Button>

                <Button
                  endIcon={
                    loading && <CircularProgress color="secondary" size={24} />
                  }
                  onClick={onSubmit}
                  disabled={loading}
                  variant="contained"
                  size="large">
                  Send Invitations
                </Button>
              </Stack>
            </FormControl>
          </Grid>
        </Paper>
      </Modal>
    )
  },
)
export { CreateInvitationModal }
