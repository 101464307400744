import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { ChatMessageModel } from "../chat-message"
import { DirectChatMessagesMetaModel } from "../direct-chat-messages/direct-chat-messages-meta"

export const UserChatModel = types.model("UserChat").props({
  chats: types.optional(types.array(ChatMessageModel), []),
  limit: types.maybeNull(types.number),
  meta: types.maybeNull(DirectChatMessagesMetaModel),
  next: types.maybeNull(types.number),
  previous: types.maybeNull(types.number),
})

export interface UserChat extends Instance<typeof UserChatModel> {}
export interface UserChatSnapshot extends SnapshotOut<typeof UserChatModel> {}
