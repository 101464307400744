import React, { FC, useState } from "react"
import { useParams } from "react-router-dom"

import { Stack, Typography, Theme, Grid, Modal } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { Person } from "../../models/person"
import { useStores } from "../../models/root-store"
import { RouteParams } from "../../AppRoutes"
import { ChatButton } from "./chat-button"
import { typography } from "../../services/theme/typography"
import { ParticipantNotes } from "../participant-notes/participant-notes"
import { heightConverter } from "../../utils/height-convertes"
import { DetailTitle } from "./member-detail-title-card"
import { MemberDetail } from "./member-details-card"
import { Condition } from "./member-condition-cell"
import useMoreDetailsButton from "./useMoreDetailsButton"
import { ReactComponent as WalmartIcon } from "../../assets/images/walmart_icon.svg"
import { ShortText } from "../admin-msl-events-list/short-text"

interface MemberCardProps {
  member: Person
  newMessages?: number
  isCohortRoute?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      width: "100%",
      height: "100%",
      minHeight: 450,
      maxWidth: 440,
      padding: `${theme.spacing(3.75)} ${theme.spacing(4)}`,
      background: theme.palette.common.white,
      border: "0px solid #EBEEF0",
      boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.08)",
      borderRadius: theme.spacing(3.75),
      justifyContent: "space-between",
    },
    memberName: {
      fontSize: theme.spacing(2),
      color: theme.palette.text.secondary,
      textAlign: "center",
      ...typography.circularXXMedium,
    },
    memberEmail: {
      fontSize: theme.spacing(1.75),
      margin: theme.spacing(1.25, 0),
      lineHeight: theme.spacing(1.75),
      color: "#888C96",
      wordBreak: "break-all",
      ...typography.circularXXBook,
    },
    avatar: {
      height: 60,
      width: 58,
      marginRight: theme.spacing(1.25),
    },
    lastSeenContainer: {
      border: "1px solid #00CC7E",
      borderRadius: theme.spacing(6),
      padding: theme.spacing(1.1, 3.25),
      height: theme.spacing(3.125),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lastSeenText: {
      fontSize: theme.spacing(1.5),
      color: "#00CC7E",
    },
    companyLogo: {
      width: 70,
      height: 17,
    },
    detailTitle: {
      fontWeight: 700,
      fontSize: theme.spacing(1.75),
      ...typography.circularXXBook,
    },

    note: {
      fontSize: 16,
      fontWeight: 400,
      color: "#485359",
      wordWrap: "break-word",
      overflow: "hidden",
      height: theme.spacing(8.75),
      ...typography.circularXXBook,
    },
    readMore: {
      fontSize: 16,
      color: "#1771A6",
      fontWeight: 400,
      textDecoration: "underline",
      cursor: "pointer",
      ...typography.circularXXBook,
    },
    date: {
      color: "#485359",
      fontSize: 18,
      fontWeight: 400,
      marginTop: theme.spacing(1),
      ...typography.circularXXBook,
    },
    button: {
      border: "1px #1771A6 solid",
      width: 170,
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
      color: "#1771A6",
      ...typography.circularXXBook,
    },
    memberDetail: {
      fontSize: theme.spacing(2),
      ...typography.circularXXBook,
    },
    modalWidth: {
      width: "831px",
    },
    emptyState: {
      fontSize: 16,
      marginTop: theme.spacing(1),
      ...typography.circularXXBook,
    },
  }),
)

export const MemberCard: FC<MemberCardProps> = (props) => {
  const { member, newMessages, isCohortRoute } = props
  const classes = useStyles()
  const params = useParams<RouteParams>()
  const { personStore } = useStores()
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false)
  const { MoreDetailsButton } = useMoreDetailsButton(member)

  const { setCurrentPerson, apiGetParticipantNotes } = personStore

  const {
    fullName,
    email,
    avatarURL,
    lastBiometricUpdate,
    timezone,
    coachingStartDate,
    coachingEndDate,
    formattedConditionsList,
    employer,
    latestNote,
    coachingStatus,
  } = member
  const { age, personGenderLabel, height, weight } = lastBiometricUpdate || {}

  const detailCategories = [
    { title: "Age", value: age?.toString?.() },

    { title: "Height", value: heightConverter(height) },
    {
      title: "Weight",
      value: Boolean(weight) ? `${weight?.toString?.()} lbs` : null,
    },
    {
      title: "Sex",
      value: personGenderLabel,
    },
    { title: "Time Zone", value: timezone?.split("/")[1].replace(/_/g, " ") },
    { title: "App start date", value: null },
    { title: "Coaching", value: coachingStatus },
  ]

  const handleNoteModalStatus = async (status: boolean) => {
    if (status) {
      setCurrentPerson(member)
      await apiGetParticipantNotes(member.personID)
    }
    setIsNoteModalOpen(status)
  }

  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick") {
    }
  }

  return (
    <Stack flex={1} className={classes.cardContainer}>
      <Grid container flexDirection="row">
        <Grid item xs="auto" display="flex" flexDirection="row" width="100%">
          <img src={avatarURL} alt="avatar" className={classes.avatar} />
          <Stack alignItems="flex-start">
            <Typography className={classes.memberName}>
              {Boolean(fullName) ? fullName : "No Name"}
            </Typography>

            <Typography className={classes.memberEmail}>{email}</Typography>

            {employer === "Walmart" ? (
              <WalmartIcon className={classes.companyLogo} />
            ) : (
              <div className={classes.companyLogo} />
            )}
          </Stack>
        </Grid>

        {/* <Grid item xs="auto">
          <div className={classes.lastSeenContainer}>
            <Typography className={classes.lastSeenText}>1 hour ago</Typography>
          </div>
        </Grid> */}
      </Grid>

      <Grid container mt={3}>
        {detailCategories.map(({ title, value }, index) => (
          <MemberDetail key={index} title={title} value={value} index={index} />
        ))}
      </Grid>

      <Stack>
        <DetailTitle title="Conditions" />
        <Grid container flexDirection="row" justifyContent="space-between">
          {formattedConditionsList.map((condition) => (
            <Condition key={condition.label} condition={condition.label} />
          ))}
          {formattedConditionsList.length === 0 && (
            <Typography className={classes.emptyState}>N/A</Typography>
          )}
        </Grid>
      </Stack>

      <Stack mt={3}>
        <DetailTitle title="Latest Note" />
        <Typography mt={1} className={classes.note}>
          <ShortText value={latestNote?.noteText || "N/A"} textLimit={140} />
        </Typography>
        {!isCohortRoute && (
          <Typography
            mt={1}
            className={classes.readMore}
            onClick={() => handleNoteModalStatus(true)}>
            Read More
          </Typography>
        )}
      </Stack>

      <Stack flexDirection="row" mt={3}>
        <Stack mr={4}>
          <DetailTitle title="Coaching Start Date" />
          <Typography className={classes.date}>
            {coachingStartDate
              ? moment(coachingStartDate).format("MM/DD/YYYY")
              : "N/A"}
          </Typography>
        </Stack>

        <Stack>
          <DetailTitle title="Coaching End Date" />
          <Typography className={classes.date}>
            {coachingEndDate
              ? moment(coachingEndDate).format("MM/DD/YYYY")
              : "N/A"}
          </Typography>
        </Stack>
      </Stack>

      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        spacing={2}
        mt={2}>
        <Grid item xs="auto">
          <MoreDetailsButton className={classes.button} />
        </Grid>

        <Grid item xs="auto">
          <ChatButton
            personID={member.personID}
            cohortId={params.cohortId}
            siteId={params.siteId}
            newMessages={newMessages}
          />
        </Grid>
      </Grid>

      {isNoteModalOpen && (
        <Modal
          open={isNoteModalOpen}
          onClose={handleModalClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <ParticipantNotes
            closeModal={() => handleNoteModalStatus(false)}
            showHeader={true}
            extraStyle={classes.modalWidth}
            disableCreate={true}
          />
        </Modal>
      )}
    </Stack>
  )
}
