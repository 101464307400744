import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"
import { DidYouKnowTile } from "../../models/did-you-know-tile"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useDYKTilesForm } from "./useDYKTilesForm"

export enum ModalMode {
  ADD_NEW_TILE,
  EDIT_TILE,
}

const modalModeLabels = {
  [ModalMode.ADD_NEW_TILE]: {
    headerLabel: "Create new Did You Know Tile",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT_TILE]: {
    headerLabel: "Edit Did You Know Tile",
    buttonLabel: "Save",
  },
}

interface CreateTileModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateDidYouKnowTileModal = observer(
  ({ mode, open, closeModal }: CreateTileModalProps) => {
    const {
      loading,
      submitted,
      values,
      errors,
      onCancel,
      onSubmit,
      formConfig,
    } = useDYKTilesForm({ mode, closeModal, isModalOpen: open })

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<DidYouKnowTile>
            submitted={submitted}
            values={values}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT_TILE}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateDidYouKnowTileModal }
