import React, { useState } from "react"
import {
  Box,
  Grid,
  Paper,
  Typography,
  Modal,
  Theme,
  FormControl,
  TextField,
  Avatar,
  Input,
  InputAdornment,
  Button,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/core/Autocomplete"
import clsx from "clsx"
import { trim } from "ramda"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { useSnackbars } from "../../components/use-snackbar"
import { Person } from "../../models/person"
import { RouteParams } from "../../AppRoutes"
import { CohortSwitcher } from "../../components/cohort-switcher"
import { noop } from "../../utils"

interface StyleProps {
  invitees: Array<string>
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    position: "absolute",
    padding: theme.spacing(6, 8, 1, 8),
    outline: "none",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 544,
      height: "auto",
      left: "50%",
      top: "50%",
      transform: (props) =>
        `translateX(calc(-50% + ${
          props.isDrawerOpen ? props.drawerWidth / 2 : 0
        }px)) translateY(-50%)`,
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  cohortSwitcherWrapper: {
    display: "flex",
    alignItems: "center",
  },
}))

const CreateChat = observer(() => {
  const history = useHistory()

  const { cohortStore, uiStore, siteStore } = useStores()
  const { isDrawerOpen } = uiStore

  const { currentCohort } = cohortStore
  const { newSite } = siteStore
  const { invitees } = newSite

  const [message, setMessage] = useState<string>("")
  const [selectedParticipantId, setSelectedParticipantId] = useState<
    string | null
  >(null)

  const params = useParams<RouteParams>()

  const trimmedMessage = trim(message)

  const classes = useStyles({ invitees, drawerWidth, isDrawerOpen })

  const matchChatRoute = useRouteMatch<RouteParams>(
    "/site/:siteId/cohorts/:cohortId/chat/:roomId",
  )

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const goBack = () => history.goBack()

  const handleClose = () => {
    goBack()
  }

  const sendMessage = async () => {
    try {
      if (!trimmedMessage.length) return
      if (!params.cohortId) throw new Error("need cohort id")
      if (!selectedParticipantId) throw new Error("No participant selected.")

      const isIndividualChat = matchChatRoute?.isExact

      await cohortStore.apiCreateCohortChat(
        message,
        params.cohortId,
        isIndividualChat,
        selectedParticipantId,
      )
      setSnackbarSeverity("success")
      setSnackbarText("Message sent")
      setShowSnackbar(true)
      goBack()
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setMessage("")
    }

    return
  }

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setMessage(event.target.value)
  }

  const handleMessageKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!event.shiftKey && event.key === "Enter") {
      sendMessage()
    }
  }

  const handleParticipantChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Person,
    reason: AutocompleteChangeReason,
  ) => {
    event.preventDefault()
    setSelectedParticipantId(value?.personID)
  }

  const body = (
    <Paper className={classes.paper}>
      <Grid container className={classes.formRoot}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="h2">Send Message</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box pb={2} className={classes.cohortSwitcherWrapper}>
            <Typography variant="button">COHORT:</Typography>
            <CohortSwitcher />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              autoSelect={true}
              options={currentCohort?.registeredParticipants}
              classes={{}}
              onChange={handleParticipantChange}
              filterSelectedOptions
              getOptionLabel={(option) => option.email}
              renderOption={(liProps, option) => {
                return (
                  <li {...liProps}>
                    <Avatar
                      alt={option.firstName}
                      src={option.avatarURL}
                      style={{ marginRight: 10 }}>
                      {option.initials}
                    </Avatar>

                    {option.email}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Participant"
                  placeholder="Choose a participant."
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth className={clsx(classes.formControl)}>
            <Input
              id="standard-adornment-password"
              type="text"
              placeholder="Type message here"
              autoFocus
              value={message}
              multiline={true}
              minRows={2}
              maxRows={3}
              onChange={handleMessageChange}
              onKeyPress={handleMessageKeyPress}
              className={classes.messageInput}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="text"
                    color="primary"
                    onClick={sendMessage}
                    disabled={!trimmedMessage.length || !selectedParticipantId}>
                    SEND
                  </Button>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  )

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
})

export default CreateChat
