import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"
import { useStores } from "../../models/root-store"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useWhatVideosForm } from "./useWhatVideosForm"
import { WhatVideo } from "../../models/what-videos"

export enum ModalMode {
  ADD_NEW_WHAT_VIDEO,
  EDIT_WHAT_VIDEO,
}

interface CreateWhatVideoModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateWhatVideosModal = observer(
  ({ open, closeModal, mode }: CreateWhatVideoModalProps) => {
    const {
      loading,
      submitted,
      onCancel,
      onSubmit,
      formConfig,
    } = useWhatVideosForm({ mode, closeModal, isModalOpen: open })
    const { adminMslEventStoreModel } = useStores()
    const { newWhatVideo } = adminMslEventStoreModel

    const modalTitle =
      mode === ModalMode.ADD_NEW_WHAT_VIDEO
        ? "Create New What Video"
        : "Edit What Video"

    const { createWhatVideoErrors: errors } = newWhatVideo

    return (
      <FormModal
        open={open}
        modalTitle={modalTitle}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={
          mode === ModalMode.ADD_NEW_WHAT_VIDEO ? "Create What Video" : "Save"
        }
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<WhatVideo>
            submitted={submitted}
            values={newWhatVideo}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT_WHAT_VIDEO}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateWhatVideosModal }
