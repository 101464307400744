import React from "react"
import { observer } from "mobx-react-lite"
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Stack,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { useStores } from "../../models/root-store"
import { MotivationBanner } from "../motivation-banner"
import { AdminViewSwitcher } from "../admin-view-switcher"
import { LogoutButton, ProfileButton } from "../logout-button"
import { typography } from "../../services/theme/typography"

interface AppBarProps {}

export const appBarHeight = 96

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(3),
      height: appBarHeight,
      justifyContent: "center",
    },
    adminAppBar: {
      background: "#1771A6",
    },
    logo: {
      width: 207,
    },
    logoDivider: {
      height: "100%",
      marginRight: theme.spacing(5.5),
      marginLeft: 26,
      borderLeft: "1px solid #fff",
    },
    personNameText: {
      fontSize: theme.spacing(3),
      lineHeight: "22px",
      color: theme.palette.common.white,
      letterSpacing: theme.spacing(0.5),
      ...typography.circularXXBold,
    },
    toolBar: {
      padding: 0,
    },
    siteSwitcher: {
      display: "flex",
      marginLeft: theme.spacing(4),
      alignItems: "center",
    },
  }),
)

export const AppBar = observer((props: AppBarProps) => {
  const classes = useStyles()
  const { loginStore, cohortStore } = useStores()
  const { authentication, person } = loginStore
  const { currentCohort } = cohortStore

  return (
    <>
      <MaterialAppBar
        className={clsx(classes.appBar, {
          [classes.adminAppBar]:
            authentication.isSiteAdmin || authentication.isFtAdmin,
        })}
        position="fixed">
        <Toolbar className={classes.toolBar}>
          <Link to="/">
            <img
              alt="logo"
              src="/FreshTriProLogo_White.png"
              className={classes.logo}
            />
          </Link>

          <div className={classes.logoDivider} />

          <Stack spacing={1} flex={1}>
            <Typography className={classes.personNameText}>
              {person.firstName} {person.lastName}
            </Typography>

            {(authentication.isCoach || authentication.isSiteAdmin) &&
              //Temporarily hide the Motivation Banner component if the current cohort is not existing
              //because the motivation quote is cohort related feature
              currentCohort?.id !== "-1" && <MotivationBanner />}
          </Stack>

          <Stack direction="row" alignItems="center" spacing={4}>
            {authentication.isFtAdmin && <AdminViewSwitcher />}

            <Stack direction="row" alignItems="center" spacing={5}>
              <ProfileButton />

              <LogoutButton />
            </Stack>
          </Stack>
        </Toolbar>
      </MaterialAppBar>
    </>
  )
})
