import moment from "moment"

export enum TimeOption {
  HOUR,
  MINUTE,
}

export const TimeFormat = {
  AM: "AM",
  PM: "PM",
}

const MIN_HOUR = 0
const MAX_HOUR = 12
const MIN_MINUTE = 0
const MAX_MINUTE = 59

/**
 * Method for returning a formatted value with double digits for a number
 */
const formattedTimeValue = (timeValue: number) =>
  timeValue < 10 ? `0${timeValue}` : timeValue.toString()

/**
 * Method for returning an incremented value based on time option
 * Check if the value is below max value and increment it
 * If value is equal to max, set it to min value
 */
export const incrementTime = (
  timeOption: TimeOption,
  currentValue: string,
): string => {
  const selectedValue = parseInt(currentValue || "0")

  if (timeOption === TimeOption.HOUR) {
    const newHourValue = selectedValue < MAX_HOUR ? selectedValue + 1 : MIN_HOUR
    return formattedTimeValue(newHourValue)
  }

  if (timeOption === TimeOption.MINUTE) {
    const newMinuteValue =
      selectedValue < MAX_MINUTE ? selectedValue + 1 : MIN_MINUTE
    return formattedTimeValue(newMinuteValue)
  }
}

/**
 * Method for returning an decremented value based on time option
 * Check if the value is above min value and decrement it
 * If value is equal to min, set it to max value
 */
export const decrementTime = (
  timeOption: TimeOption,
  currentValue: string,
): string => {
  const selectedValue = parseInt(currentValue || "0")

  if (timeOption === TimeOption.HOUR) {
    const newHourValue = selectedValue > MIN_HOUR ? selectedValue - 1 : MAX_HOUR
    return formattedTimeValue(newHourValue)
  }

  if (timeOption === TimeOption.MINUTE) {
    const newMinuteValue =
      selectedValue > MIN_MINUTE ? selectedValue - 1 : MAX_MINUTE
    return formattedTimeValue(newMinuteValue)
  }
}

/**
 * Method for checking if the next time value is not valid
 * 1.Check if the next value is not a number
 * 2.Check if the next value is has more than 3 chars
 * 3.Check if the next value is above maximum
 * 4.Check if the next value is below minimum
 * If any of the above condition is true, than the next time value is not valid
 */
export const isNotValidTime = (
  timeOption: TimeOption,
  nextValue: string,
): boolean => {
  const selectedValue = parseInt(nextValue)

  if (timeOption === TimeOption.HOUR) {
    return (
      isNaN(nextValue as any) ||
      nextValue.length > 2 ||
      selectedValue > MAX_HOUR ||
      selectedValue < MIN_HOUR
    )
  }

  if (timeOption === TimeOption.MINUTE) {
    return (
      isNaN(nextValue as any) ||
      nextValue.length > 2 ||
      selectedValue > MAX_MINUTE ||
      selectedValue < MIN_MINUTE
    )
  }
}

/**
 * Method to set the fixed values from the time picker on the given date
 */
export const updateTimeValue = (
  currentValue: Date,
  hourValue: string,
  minuteValue: string,
  timeFormat: string,
): moment.Moment => {
  //get the number value of the minutes string
  const finalMinuteValue = parseInt(minuteValue || "0")

  //get the number value of the hour string
  let finalHourValue = parseInt(hourValue || "0")

  //if the final hour value is 12, set it to 0 becuase the moment object manipulates the time value to 24 format and we need to format it in the next step
  if (finalHourValue === MAX_HOUR) {
    finalHourValue = MIN_HOUR
  }

  //if the picker is on PM format, add 12 hours the final hour value, because moment object manipulates the time value to 24 format
  if (timeFormat === TimeFormat.PM) {
    finalHourValue = finalHourValue + MAX_HOUR
  }

  //set fixed values to moment object
  const newValue = Boolean(currentValue) ? moment(currentValue) : moment()
  newValue.set({ hour: finalHourValue, minute: finalMinuteValue })

  return newValue
}

/**
 * Method which gets the date values (day, month, year) from the local calendar picker, and sets them to the given current value
 */
export const updateDateValue = (
  localValue: Date,
  currentValue: Date,
): moment.Moment => {
  const localValueMoment = moment(localValue)
  const dayValue = localValueMoment.date()
  const monthValue = localValueMoment.month()
  const yearValue = localValueMoment.year()

  const currentValueMoment = Boolean(currentValue)
    ? moment(currentValue)
    : moment()
  currentValueMoment.set({ year: yearValue, month: monthValue, D: dayValue })

  return currentValueMoment
}
