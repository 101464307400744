import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme, CircularProgress } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { AdminTableHeader } from "../admin-table-header"
import { ShortText } from "../admin-msl-events-list/short-text"
import { CreateMslTrainerModal } from "./create-msl-trainer-modal"
import { TrainerActions } from "./trainer-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
    circleImage: {
      display: "block",
      margin: "0 auto",
      width: "100%",
      maxWidth: 150,
    },
    bannerImage: {
      display: "block",
      margin: "0 auto",
      width: "100%",
    },
    imageUrl: {
      display: "inline-block",
      wordBreak: "break-word",
      marginTop: theme.spacing(1),
    },
  }),
)

const AdminMslTrainersList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateTrainerModalOpen, toggleCreateTrainerModalOpen] = useState(
    false,
  )

  const { adminMslEventStoreModel } = useStores()
  const {
    mindsetTrainers,
    apiAdminGetMindsetTrainers,
  } = adminMslEventStoreModel

  const openCreateTrainerModal = () => {
    toggleCreateTrainerModalOpen(true)
  }

  const closeCreateTrainerModal = () => {
    toggleCreateTrainerModalOpen(false)
  }

  useEffect(() => {
    const initialFetch = async () => {
      await apiAdminGetMindsetTrainers()
      toggleFirstLoading(false)
    }
    initialFetch()
  }, [apiAdminGetMindsetTrainers])

  //Sort trainers list alphabetically
  const sortedList = (mindsetTrainers.slice() || []).sort((a, b) =>
    a.fullName.localeCompare(b.fullName),
  )

  return (
    <div className={classes.root}>
      <LogsTable
        tableLabel="Trainers"
        tableAriaLabel="trainers-table"
        tablePaginationProps={{
          count: !!sortedList ? sortedList.length : 0,
        }}
        defaultRowsPerPage={25}
        CustomTableHeader={({ tableLabel }) => (
          <AdminTableHeader
            tableLabel={tableLabel}
            addNewButtonLabel="New trainer"
            addNewButtonOnClick={openCreateTrainerModal}
          />
        )}>
        {({ page, rowsPerPage }) => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "10%" }} isStartEndPoint>
                    Full Name
                  </HeaderCell>

                  <HeaderCell style={{ width: "25%" }}>Circle Image</HeaderCell>

                  <HeaderCell style={{ width: "25%" }}>Banner Image</HeaderCell>

                  <HeaderCell style={{ width: "10%" }}>Person ID</HeaderCell>

                  <HeaderCell style={{ width: "15%" }}>Bio</HeaderCell>

                  <HeaderCell style={{ width: "15%" }}>
                    Professional Title
                  </HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(sortedList.slice().length) ? (
                  sortedList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((trainer) => (
                      <TableRow key={trainer.personID}>
                        <BodyCell isStartEndPoint>{trainer.fullName}</BodyCell>

                        <BodyCell>
                          {Boolean(trainer.v2CircleImageURL) && (
                            <img
                              src={trainer.v2CircleImageURL}
                              alt="circle"
                              className={classes.circleImage}
                            />
                          )}

                          <div className={classes.imageUrl}>
                            <ShortText
                              value={trainer.v2CircleImageURL || "N/A"}
                              textLimit={50}
                            />
                          </div>
                        </BodyCell>

                        <BodyCell>
                          {Boolean(trainer.v2BannerImageURL) && (
                            <img
                              src={trainer.v2BannerImageURL}
                              alt="banner"
                              className={classes.bannerImage}
                            />
                          )}
                          <div className={classes.imageUrl}>
                            <ShortText
                              value={trainer.v2BannerImageURL || "N/A"}
                              textLimit={50}
                            />
                          </div>
                        </BodyCell>

                        <BodyCell>
                          <ShortText
                            value={trainer.personID || "N/A"}
                            textLimit={50}
                          />
                        </BodyCell>

                        <BodyCell>
                          <ShortText
                            value={trainer.bio || "N/A"}
                            textLimit={50}
                          />
                        </BodyCell>

                        <BodyCell>
                          <ShortText
                            value={trainer.professionalTitle || "N/A"}
                            textLimit={50}
                          />

                          <TrainerActions
                            trainerItem={trainer}
                            openCreateTrainerModal={openCreateTrainerModal}
                          />
                        </BodyCell>
                      </TableRow>
                    ))
                ) : (
                  <Box pl={3} pt={3}>
                    {firstLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EmptyState title="When you create some trainers, they will appear here" />
                    )}
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>

      <CreateMslTrainerModal
        open={isCreateTrainerModalOpen}
        closeModal={closeCreateTrainerModal}
      />
    </div>
  )
})

export { AdminMslTrainersList }
