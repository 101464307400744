import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const ModuleModel = types.model("Module").props({
  id: types.identifier,
  name: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
})

export interface Module extends Instance<typeof ModuleModel> {}
export interface ModuleSnapshot extends SnapshotOut<typeof ModuleModel> {}
