import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { MslEvent, MslEventModel } from "../msl-event"

export const EPISODES_LIMIT = 20

/**
 * Represents a pagination model.
 */
export const TrainPaginationModel = types
  .model("TrainPagination")
  .props({
    limit: types.optional(types.number, EPISODES_LIMIT),
    next: types.maybeNull(types.number),
    episodes: types.optional(types.array(MslEventModel), []),
  })
  .actions((self) => ({
    setEpisodes(value: Array<MslEvent>) {
      self.episodes.replace(value)
    },
    setNext(value: number) {
      self.next = value
    },
    extendLimit() {
      self.limit = self.limit + EPISODES_LIMIT
    },
    addEpisodes(value: MslEvent[]) {
      self.episodes.replace([...self.episodes, ...value])
    },
    appendEpisode(value: MslEvent) {
      self.episodes.replace([...self.episodes, value])
    },
    unshiftEpisode(value: MslEvent) {
      self.episodes.replace([value, ...self.episodes])
    },
    removeEpisode(messageId: string) {
      self.episodes.replace(
        [...self.episodes].filter((message) => message.id !== messageId),
      )
    },
    resetEpisodes() {
      if (isAlive(self.episodes)) {
        self.episodes.clear()
      }
    },
  }))
  .actions((self) => ({
    reset() {
      self.resetEpisodes()
      self.limit = EPISODES_LIMIT
      self.next = null
    },
  }))
  .views((self) => ({
    get isEndReached() {
      return !!self.next
    },
  }))

export interface TrainPagination
  extends Instance<typeof TrainPaginationModel> {}
export interface TrainPaginationSnapshot
  extends SnapshotOut<typeof TrainPaginationModel> {}
