import React, { useState } from "react"
import {
  Stack,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"

import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { Series } from "../../models/series"
import { CustomTooltip } from "../admin-msl-events-list/short-text"
import { ReactComponent as DeleteSessionIcon } from "../../assets/images/delete_session_icon.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unpublishedButton: {
      backgroundColor: `${theme.palette.text.disabled} !important`,
    },
  }),
)

interface SeriesActionsProps {
  seriesItem: Series
  openCreateSeriesModal: () => void
}

const SeriesActions = ({
  seriesItem,
  openCreateSeriesModal,
}: SeriesActionsProps) => {
  const [loadingPublish, setLoadingPublish] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false)

  const classes = useStyles()
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()
  const { adminMslEventStoreModel } = useStores()
  const {
    setNewSeries,
    apiAdminPublishSeries,
    apiAdminUnPublishSeries,
    apiAdminDeleteSeries,
  } = adminMslEventStoreModel

  const { id, name, isSeriesInProgress, isCurrentlyPublished } = seriesItem

  const onEdit = () => {
    openCreateSeriesModal()
    setNewSeries(seriesItem)
  }

  const togglePublish = async () => {
    try {
      setLoadingPublish(true)
      let successMessage = ""
      if (isCurrentlyPublished) {
        await apiAdminUnPublishSeries(id)
        successMessage = "Successfully unpublished series !"
      } else {
        await apiAdminPublishSeries(id)
        successMessage = "Successfully published series !"
      }
      setSnackbarSeverity("success")
      setSnackbarText(successMessage)
      setShowSnackbar(true)
      setLoadingPublish(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      setLoadingPublish(false)
    }
  }

  const handleDeleteSeries = async () => {
    try {
      toggleDeleteDialog(false)
      setLoadingDelete(true)
      await apiAdminDeleteSeries(id)
      setSnackbarSeverity("success")
      setSnackbarText("Successfully deleted series !")
      setShowSnackbar(true)
      setLoadingDelete(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      setLoadingDelete(false)
    }
  }

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      spacing={1}>
      <CustomTooltip
        title={!isSeriesInProgress ? "This series is not in progress" : ""}>
        <span>
          <Button
            variant="contained"
            onClick={togglePublish}
            disabled={!isSeriesInProgress || loadingPublish}
            endIcon={
              loadingPublish && <CircularProgress color="secondary" size={24} />
            }
            className={clsx({
              [classes.unpublishedButton]: isCurrentlyPublished,
            })}>
            {isCurrentlyPublished ? "Unpublish" : "Publish"}
          </Button>
        </span>
      </CustomTooltip>

      <Button variant="contained" onClick={onEdit}>
        Edit
      </Button>

      <Dialog
        open={isDeleteDialogOpen}
        aria-labelledby="alert-delete-series-title">
        <DialogTitle id="alert-delete-series-title">
          Are you sure you want to delete the series {name}?
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => toggleDeleteDialog(false)}>Cancel</Button>

          <Button autoFocus onClick={handleDeleteSeries}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        disabled={loadingDelete}
        onClick={() => toggleDeleteDialog(true)}>
        {loadingDelete ? (
          <CircularProgress color="secondary" size={24} />
        ) : (
          <DeleteSessionIcon width={18} height={18} />
        )}
      </IconButton>
    </Stack>
  )
}

export { SeriesActions }
