import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import { validate, ValidationRules } from "../../utils/validate"
import * as customTypes from "../types"

export const DocumentModel = types.model("Document").props({
  label: types.string,
  url: types.string,
})

export const SessionModel = types
  .model("Session")
  .props({
    id: types.identifier,
    date: types.maybeNull(
      types.optional(customTypes.iso8601, moment().toDate()),
    ),
    name: types.maybeNull(types.string),
    moduleID: types.maybeNull(types.string),
    attendanceTaken: types.maybeNull(types.optional(types.boolean, false)),
    description: types.optional(types.maybeNull(types.string), null),
    documents: types.optional(types.array(DocumentModel), []),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setDate(value: Date) {
      self.date = value
    },
    setAttendanceTaken(value: boolean) {
      self.attendanceTaken = value
    },
    reset() {
      self.name = ""
      self.date = null
      self.attendanceTaken = null
    },
  }))
  .views((self) => ({
    get localizedDate() {
      return moment(self.date).local()
    },
  }))
  .views((self) => ({
    get createSessionsErrors() {
      return validate(CREATE_SESSION_VALIDATION_RULES, self)
    },
  }))

const CREATE_SESSION_VALIDATION_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  date: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface Session extends Instance<typeof SessionModel> {}
export interface SessionSnapshot extends SnapshotOut<typeof SessionModel> {}
