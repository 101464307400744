import React from "react"
import type { FC, ReactNode } from "react"
import { Redirect } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { DashboardLayout, DefaultLayout, PilotLayout } from "../layouts"

interface ProfileGuardProps {
  children?: ReactNode
}

/**
 * Guard to redirect to login screen if the person is not authenticated
 * Also to choose a dynamic layout based on authed person role
 */
export const ProfileGuard: FC<ProfileGuardProps> = observer(({ children }) => {
  const { loginStore } = useStores()
  const { isAuthenticated, authentication } = loginStore

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }

  //dynamic layout based on authed person role
  const getPageLayout = () => {
    if (authentication.isMindsetTrainer) {
      return DefaultLayout
    }
    if (authentication.isPilotTrainer) {
      return PilotLayout
    }

    return DashboardLayout
  }

  //dynamic layout based on authed person role
  const Layout = getPageLayout()

  return <Layout>{children}</Layout>
})
