import React, { Fragment, lazy, Suspense, useEffect } from "react"
import { switcher } from "rambdax"
import { Route, Switch, useLocation, useParams } from "react-router-dom"
import {
  DashboardLayout,
  AdminLayout,
  SiteDashboardLayout,
  PilotLayout,
  DefaultLayout,
} from "./components/layouts"
import LoadingScreen from "./components/loading-screen/loading-screen"
import CreateChat from "./pages/create-chat"
import AddCohortParticipants from "./pages/add-cohort-participants"
import AddCohortCoaches from "./pages/add-cohort-coaches"
import AddSiteAdmins from "./pages/add-site-admins/add-site-admins"
import CreateSite from "./pages/create-site/create-site"
import CreateSession from "./pages/create-session/create-session"
import DeleteSession from "./pages/delete-session/delete-session"
import { ViewSession } from "./components/view-session"
import { useStores } from "./models/root-store"
import {
  AuthGuard,
  NotAuthGuard,
  CohortsGuard,
  FtAdminGuard,
  SiteAdminGuard,
  MindsetTrainerGuard,
  PilotTrainerGuard,
  ProfileGuard,
} from "./components/routes-guard"
import { useSnackbars } from "./components/use-snackbar"
import { ErrorType, noop, recordError } from "./utils"
import SetAttendance from "./pages/set-attendance"
import ChatPage from "./pages/chat"
import AdminFeed from "./pages/admin-feed"
import AdminPeopleSearch from "./pages/admin-people-search"
import AdminMslEvents from "./pages/admin-msl-events"
import AdminMslTrainers from "./pages/admin-msl-trainers"
import AdminMslTopics from "./pages/admin-msl-topics"
import AdminMslSeries from "./pages/admin-msl-series"
import AdminMslCategories from "./pages/admin-msl-categories"
import AdminMslThemes from "./pages/admin-msl-themes"
import AdminSendInvitations from "./pages/admin-send-invitations"
import AdminGroups from "./pages/admin-groups/admin-groups"
import AdminTags from "./pages/admin-tags/admin-tags"
import AdminNudges from "./pages/admin-nudges/admin-nudges"
import AdminDidYouKnowTiles from "./pages/admin-did-you-know-tiles/admin-did-you-know-tiles"
import AdminWhatsNextTiles from "./pages/admin-whats-next-tiles/admin-whats-next-tiles"
import AdminRakCards from "./pages/admin-rak-cards/admin-rak-cards"
import AdminVerificationCodes from "./pages/admin-verification-codes/admin-verification-codes"
import AdminRecipes from "./pages/admin-recipes/admin-recipes"
import AdminWhatVideos from "./pages/admin-what-videos/admin-what-videos"
import AdminTriPrograms from "./pages/admin-tri-programs"
import AdminTriModules from "./pages/admin-tri-modules"
import AdminTriWhats from "./pages/admin-tri-whats"
import AdminTriHows from "./pages/admin-tri-hows"
import AdminTriProducts from "./pages/admin-tri-products"

export enum ModalRoutes {
  createSite = "create-site",
  createSession = "create-session",
  viewSession = "view-session",
  editSession = "edit-session",
  deleteSession = "delete-session",
  addCohortParticipants = "add-cohort-participants",
  addCohortCoaches = "add-cohort-coaches",
  addSiteAdmins = "add-site-admins",
  createChat = "create-chat",
  setAttendance = "set-attendance",
}

interface LocationState {
  background: any
  modal: string
}

interface Props {
  routes: Routes
}

export interface RouteParams {
  cohortId: string
  siteId: string
  roomId?: string
  personId?: string
}

export const RenderRoutes = (props: Props): JSX.Element => {
  const { routes = [] } = props
  const location = useLocation<LocationState>()
  const params = useParams<RouteParams>()
  const { siteStore, cohortStore } = useStores()
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.
  const background = location?.state?.background
  const activeModal = location?.state?.modal

  /**
   * If the siteID changes, let's refresh some data
   * to reflect the new siteID.
   */
  useEffect(() => {
    const refreshCohortsAndSiteId = async () => {
      console.log("changing siteId...", params.siteId)
      cohortStore.resetCohorts()
      siteStore.setCurrentSiteId(params.siteId)
    }

    if (Boolean(params.siteId)) {
      if (siteStore.currentSiteId !== params.siteId) {
        try {
          refreshCohortsAndSiteId()
        } catch (error) {
          recordError(ErrorType.HANDLED, error)
          setSnackbarSeverity("error")
          setSnackbarText(error.message)
          setShowSnackbar(true)
        }
      }
    }
  }, [
    params.siteId,
    params.cohortId,
    cohortStore,
    siteStore,
    setSnackbarSeverity,
    setSnackbarText,
    setShowSnackbar,
  ])

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch location={background || location}>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes routes={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
      {/* Show the modal when a background page is set */}
      {background && <ModalRoute modal={activeModal} />}
    </Suspense>
  )
}

const ModalRoute = (props: any) => {
  return switcher<any>(props.modal)
    .is(
      ModalRoutes.createSite,
      <Route path={`/${props.modal}`} children={<CreateSite />} />,
    )
    .is(
      ModalRoutes.createSession,
      <Route path={`/${props.modal}`} children={<CreateSession />} />,
    )
    .is(
      ModalRoutes.editSession,
      <Route
        path={`/${props.modal}/:sessionId`}
        children={<CreateSession editMode />}
      />,
    )
    .is(
      ModalRoutes.deleteSession,
      <Route
        path={`/${props.modal}/:sessionId`}
        children={<DeleteSession />}
      />,
    )
    .is(
      ModalRoutes.addCohortParticipants,
      <Route path={`/${props.modal}`} children={<AddCohortParticipants />} />,
    )
    .is(
      ModalRoutes.addCohortCoaches,
      <Route path={`/${props.modal}`} children={<AddCohortCoaches />} />,
    )
    .is(
      ModalRoutes.addSiteAdmins,
      <Route path={`/${props.modal}`} children={<AddSiteAdmins />} />,
    )
    .is(
      ModalRoutes.createChat,
      <Route
        path={`/${props.modal}/:siteId/:cohortId`}
        children={<CreateChat />}
      />,
    )
    .is(
      ModalRoutes.setAttendance,
      <Route
        path={`/${props.modal}/:sessionId`}
        children={<SetAttendance />}
      />,
    )
    .is(
      ModalRoutes.viewSession,
      <Route path={`/${props.modal}/:sessionId`} children={<ViewSession />} />,
    )
    .default(null)
}

type Routes = {
  exact?: boolean
  path?: string | string[]
  guard?: any
  layout?: any
  component?: any
  routes?: Routes
}[]

export const routes: Routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("./pages/initial-screen")),
    guard: AuthGuard,
  },
  {
    exact: true,
    path: "/sites",
    component: lazy(() => import("./pages/sites")),
    layout: DashboardLayout,
    guard: CohortsGuard,
  },
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("./pages/login")),
    guard: NotAuthGuard,
  },
  {
    exact: true,
    path: "/forgot",
    component: lazy(() => import("./pages/forgot")),
    guard: NotAuthGuard,
  },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() => import("./pages/reset-password")),
    guard: NotAuthGuard,
  },
  {
    exact: true,
    path: "/signup",
    component: lazy(() => import("./pages/signup")),
    guard: NotAuthGuard,
  },
  {
    exact: true,
    path: "/verification",
    component: lazy(() => import("./pages/verification")),
    guard: NotAuthGuard,
  },
  {
    exact: true,
    path: "/create-cohort",
    component: lazy(() => import("./pages/create-cohort")),
    layout: DashboardLayout,
    guard: SiteAdminGuard,
  },
  {
    exact: true,
    path: "/profile-page",
    component: lazy(() => import("./pages/profile-page")),
    guard: ProfileGuard,
  },
  {
    exact: true,
    path: "/mindset-trainer",
    component: lazy(() => import("./pages/mindset-trainer")),
    layout: DefaultLayout,
    guard: MindsetTrainerGuard,
  },
  {
    exact: true,
    path: "/site/:siteId",
    layout: SiteDashboardLayout,
    component: lazy(() => import("./pages/site")),
    guard: CohortsGuard,
  },
  {
    path: "/site/:siteId/cohorts",
    layout: DashboardLayout,
    guard: CohortsGuard,
    component: lazy(() => import("./pages/home")),
    routes: [
      {
        exact: true,
        path: "/site/:siteId/cohorts",
        component: lazy(() => import("./pages/home")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/person/:personId",
        component: lazy(() => import("./pages/person")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/sessions",
        component: lazy(() => import("./pages/cohort-sessions")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/members",
        component: lazy(() => import("./pages/cohort-members")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/members/:personId",
        component: lazy(() => import("./pages/person-details")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/settings",
        component: lazy(() => import("./pages/cohort-settings")),
        guard: SiteAdminGuard,
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/chat",
        component: ChatPage,
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/group-chat",
        component: lazy(() => import("./pages/chat-group")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/video-chat",
        component: lazy(() => import("./pages/video-chat")),
      },
      {
        exact: true,
        path: "/site/:siteId/cohorts/:cohortId/chat/:roomId",
        component: ChatPage,
      },
    ],
  },

  {
    path: "/pilot-trainer",
    guard: PilotTrainerGuard,
    layout: PilotLayout,
    routes: [
      {
        exact: true,
        path: "/pilot-trainer",
        component: lazy(() => import("./pages/sites")),
      },
      {
        exact: true,
        path: "/pilot-trainer/:coachId/group/:groupId/members",
        component: lazy(() => import("./pages/pilot-members")),
      },
      {
        exact: true,
        path: "/pilot-trainer/:coachId/group/:groupId/members/:personId",
        component: lazy(() => import("./pages/person-details")),
      },
      {
        exact: true,
        path: "/pilot-trainer/:coachId/group/:groupId/chat",
        component: lazy(() => import("./pages/pilot-chat")),
      },
      {
        exact: true,
        path: "/pilot-trainer/:coachId/group/:groupId/chat/:roomId",
        component: lazy(() => import("./pages/pilot-chat")),
      },
    ],
  },
  {
    path: "/ftadmin",
    guard: FtAdminGuard,
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: "/ftadmin/tri-content/programs",
        component: AdminTriPrograms,
      },
      {
        exact: true,
        path: "/ftadmin/tri-content/modules",
        component: AdminTriModules,
      },
      {
        exact: true,
        path: "/ftadmin/tri-content/whats",
        component: AdminTriWhats,
      },
      {
        exact: true,
        path: "/ftadmin/tri-content/hows",
        component: AdminTriHows,
      },
      {
        exact: true,
        path: "/ftadmin/tri-content/how-tos/products",
        component: AdminTriProducts,
      },
      {
        exact: true,
        path: "/ftadmin/posts",
        component: AdminFeed,
      },
      {
        exact: true,
        path: "/ftadmin/users/search",
        component: AdminPeopleSearch,
      },
      {
        exact: true,
        path: "/ftadmin/train",
        component: () => <div />,
      },
      {
        exact: true,
        path: "/ftadmin/train/categories",
        component: AdminMslCategories,
      },
      {
        exact: true,
        path: "/ftadmin/train/episodes",
        component: AdminMslEvents,
      },
      {
        exact: true,
        path: "/ftadmin/train/series",
        component: AdminMslSeries,
      },
      {
        exact: true,
        path: "/ftadmin/train/themes",
        component: AdminMslThemes,
      },
      {
        exact: true,
        path: "/ftadmin/train/trainers",
        component: AdminMslTrainers,
      },
      {
        exact: true,
        path: "/ftadmin/train/topics",
        component: AdminMslTopics,
      },
      {
        exact: true,
        path: "/ftadmin/groups/invitations",
        component: AdminSendInvitations,
      },
      {
        exact: true,
        path: "/ftadmin/groups/manage",
        component: AdminGroups,
      },
      {
        exact: true,
        path: "/ftadmin/nudges",
        component: AdminNudges,
      },
      {
        exact: true,
        path: "/ftadmin/tags",
        component: AdminTags,
      },
      {
        exact: true,
        path: "/ftadmin/rotating/dyk-tiles",
        component: AdminDidYouKnowTiles,
      },
      {
        exact: true,
        path: "/ftadmin/rotating/wn-tiles",
        component: AdminWhatsNextTiles,
      },
      {
        exact: true,
        path: "/ftadmin/rotating/rak-cards",
        component: AdminRakCards,
      },
      {
        exact: true,
        path: "/ftadmin/users/verification",
        component: AdminVerificationCodes,
      },
      {
        exact: true,
        path: "/ftadmin/tri-content/how-tos/recipes",
        component: AdminRecipes,
      },
      {
        exact: true,
        path: "/ftadmin/what-videos",
        component: AdminWhatVideos,
      },
    ],
  },
]
