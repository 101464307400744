import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { Button, colors } from "@material-ui/core"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { ModalRoutes } from "../../AppRoutes"
import { useStores } from "../../models/root-store"
import { useConfirmation } from "../use-confirmation"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { appBarHeight } from "../app-bar/app-bar"
import { AppDrawerCollabslableListItem } from "./app-drawer-collapsable-list-item"
import { SupportPopup, contactSupportSectionHeight } from "../support-popup"
import { SiteSwitcher } from "../site-switcher"
import { typography } from "../../services/theme/typography"

export const drawerWidth = 260
const drawerContentHeight = `100vh - ${contactSupportSectionHeight}px - ${appBarHeight}px`

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: 0,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      position: "fixed",
      top: appBarHeight,
      borderRight: "1px solid #485359",
    },
    drawerContent: {
      height: `calc(${drawerContentHeight})`,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    listContainer: {
      padding: 0,
      paddingTop: theme.spacing(2.5),
      borderTop: "1px solid #EBEEF0",
    },
    nestedListItem: {
      paddingLeft: theme.spacing(2),
    },
    addCohortListItem: {
      padding: theme.spacing(3, 4.5),
    },
    addCohortCta: {
      background: "#EBEEF0",
      borderRadius: theme.spacing(1),
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
      padding: theme.spacing(1, 2),
      fontSize: theme.spacing(2),
      "line-height": "22px",
      color: "#485359",
      ...typography.circularXXBold,
      "&:hover": {
        background: "#EBEEF0",
        boxShadow: "none",
      },
    },
    deleteSiteCta: {
      background: colors.red["600"],
      "&:hover": {
        background: colors.red["900"],
      },
    },
    cohortsLabelListItem: {
      height: theme.spacing(8),
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(2.5),
      ...typography.circularXXBold,
      justifyContent: "center",
      alignItems: "center",
      borderTop: "1px solid #EBEEF0",
    },
  }),
)

interface AppDrawerProps {}

export const AppDrawer = observer((props: AppDrawerProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { cohortStore, loginStore, siteStore } = useStores()
  const { apiGetCohorts, resetCohorts, cohorts } = cohortStore
  const { authentication } = loginStore
  const { currentSite, apiDeletSite } = siteStore

  const { push } = useHistory()
  const location = useLocation()

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const confirm = useConfirmation()

  const handleDeleteSite = async () => {
    try {
      await confirm({
        variant: "danger",
        catchOnCancel: true,
        title: "Confirm",
        description: `Are you sure you want to delete ${currentSite.name}`,
      })

      await apiDeletSite(currentSite.id)
      push(`/`)
    } catch (error) {}
  }

  useEffect(() => {
    const refreshCohorts = async () => {
      resetCohorts()
      try {
        await apiGetCohorts()
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    if (Boolean(currentSite?.id)) {
      refreshCohorts()
    }
  }, [
    currentSite?.id,
    apiGetCohorts,
    resetCohorts,
    setSnackbarSeverity,
    setSnackbarText,
    setShowSnackbar,
  ])

  const drawer = (
    <>
      {(authentication.isSiteAdmin || authentication.isFtAdmin) && (
        <SiteSwitcher />
      )}

      <ListItem className={classes.cohortsLabelListItem}>
        Clinical Programs
      </ListItem>

      <List className={classes.listContainer}>
        {cohorts.map((c) => {
          return <AppDrawerCollabslableListItem key={c.id} cohort={c} />
        })}
      </List>

      {authentication.canCreateOrDeleteSite && (
        <>
          <Divider />
          <ListItem>
            <Button
              onClick={handleDeleteSite}
              variant="contained"
              color="primary"
              startIcon={<DeleteForeverIcon />}
              className={classes.deleteSiteCta}
              fullWidth>
              Delete Site
            </Button>
          </ListItem>
        </>
      )}

      {authentication.canCreateOrDeleteSite && (
        <ListItem
          component={Link}
          to={{
            pathname: `/${ModalRoutes.createSite}`,
            state: {
              background: location,
              modal: ModalRoutes.createSite,
            },
          }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleIcon />}
            className={classes.addCohortCta}
            fullWidth>
            New Site
          </Button>
        </ListItem>
      )}

      {authentication.canCreateOrDeleteCohort && (
        <>
          <Divider style={{ flex: 1 }} />

          <ListItem
            component={Link}
            to={{
              pathname: `/create-cohort`,
            }}
            className={classes.addCohortListItem}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.addCohortCta}
              fullWidth>
              Add New Program
            </Button>
          </ListItem>
        </>
      )}
    </>
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant="persistent"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerContent}>{drawer}</div>

        <SupportPopup />
      </Drawer>
    </nav>
  )
})
