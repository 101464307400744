import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"

import * as customTypes from "../types"

export const EntitlementModel = types.model("Entitlement").props({
  id: types.identifier,
  name: types.string,
  description: types.optional(types.maybeNull(types.string), ""),
  label: types.optional(types.maybeNull(types.string), ""),
  createdAt: types.optional(customTypes.iso8601, moment().toDate()),
  updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
})

export interface Entitlement extends Instance<typeof EntitlementModel> {}
export interface EntitlementSnapshot
  extends SnapshotOut<typeof EntitlementModel> {}
