import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { InputBase, Stack, Button, CircularProgress } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import EditIcon from "@material-ui/icons/Edit"
import { typography } from "../../services/theme/typography"

/**
 * @todo(Harris) Use Ref to compare previous motivation to current
 * in order to not trigger an unnecessary update if they
 * are identical.
 */

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    marginRight: theme.spacing(4),
  },
  fullName: {
    marginBottom: theme.spacing(2),
    "font-weight": "bold",
    "font-size": 30,
    "line-height": "22px",
    color: "#1A3932",
  },
  motivationLabel: {
    color: "#1A3932",
    "font-weight": "600",
    "line-height": "17px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    zIndex: 5,
    position: "relative",
    justifyContent: "center",
  },
  motivationContainer: {
    display: "flex",
    alignItems: "center",
  },
  motivationCurrentValue: {
    fontSize: 20,
    lineHeight: theme.spacing(3),
    color: "#DAEBE9",
    ...typography.circularXXBookItalic,
    padding: 0,
    marginRight: 10,
  },
  placeholderMotivation: {
    opacity: 0.5,
  },
  motivationInput: {
    minWidth: "50%",
    height: theme.spacing(5),
    fontSize: theme.spacing(2.5),
    padding: theme.spacing(1, 1, 1, 2),
    color: "#677278",
    background: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),

    //focused mode styles
    '&.MuiInput-root[class*="MuiInputBase-root"]:after': {
      "border-bottom": "0px",
    },
    '&.MuiInput-root[class*="MuiInputBase-root"]:hover:not(.Mui-disabled):before': {
      "border-bottom": "0px solid rgba(0, 0, 0, 0.42)",
    },
    '&.MuiInput-root[class*="MuiInputBase-root"]:before': {
      "border-bottom": "0px solid rgba(0, 0, 0, 0.42)",
    },
    ...typography.circularXXBookItalic,
  },
  actionButton: {
    width: 156,
    height: theme.spacing(5),
    borderRadius: theme.spacing(0.5),
    ...typography.circularXXMedium,
  },
  editIcon: {
    color: theme.palette.common.white,
    cursor: "pointer",
    fontSize: 20,
  },
}))

interface MotivationBannerProps {}

export const MotivationBanner = observer((props: MotivationBannerProps) => {
  const classes = useStyles()
  const { loginStore, cohortStore } = useStores()
  const { person, apiUpdateMotivation } = loginStore
  const { currentCohort } = cohortStore

  const [motiv, setMotive] = useState(person?.motivation || "")
  const [isEditMode, toggleEditMode] = useState(false)
  const [isUpdatingMotivation, setIsUpdatingMotivation] = useState(false)

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const openEditMode = () => {
    toggleEditMode(true)
  }

  const closeEditMode = () => {
    toggleEditMode(false)
  }

  const saveMotivation = async () => {
    try {
      setIsUpdatingMotivation(true)
      await apiUpdateMotivation(currentCohort.id, motiv)
      setSnackbarSeverity("success")
      setSnackbarText("Successfully updated motivation")
      setShowSnackbar(true)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setIsUpdatingMotivation(false)
      closeEditMode()
    }
  }

  const handleMotivationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setMotive(event.target.value)
  }

  //Set the initial input value to person.motivation on edit mode open
  useEffect(() => {
    setMotive(person?.motivation)
  }, [person, isEditMode])

  const isEmptyMotiv =
    !Boolean(person?.motivation) || person?.motivation?.length === 0

  return (
    <>
      {isEditMode ? (
        <Stack direction="row" alignItems="center" spacing={4}>
          <InputBase
            defaultValue=""
            placeholder="Edit your motivation here"
            inputProps={{ "aria-label": "naked" }}
            value={motiv}
            className={classes.motivationInput}
            onChange={handleMotivationChange}
            autoFocus
          />

          <Button
            variant="outlined"
            className={classes.actionButton}
            onClick={closeEditMode}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={saveMotivation}
            endIcon={
              isUpdatingMotivation && (
                <CircularProgress color="secondary" size={24} />
              )
            }
            disabled={isUpdatingMotivation}>
            Save
          </Button>
        </Stack>
      ) : (
        <div className={classes.motivationContainer}>
          <div
            className={clsx(classes.motivationCurrentValue, {
              [classes.placeholderMotivation]: isEmptyMotiv,
            })}>
            {isEmptyMotiv ? "My motivation" : person?.motivation}
          </div>

          <EditIcon className={classes.editIcon} onClick={openEditMode} />
        </div>
      )}
    </>
  )
})
