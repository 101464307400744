import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"

import { validate, ValidationRules } from "../../utils/validate"
import { Group, GroupModel } from "../group"

export const NewInvitationModel = types
  .model("NewInvitation")
  .props({
    emails: types.optional(types.array(types.string), []),
    group: types.optional(types.maybeNull(GroupModel), null),
    membershipDuration: types.optional(types.string, "60"),
  })
  .actions((self) => ({
    setEmails(value: Array<string>) {
      self.emails.replace(value)
    },
    setGroup(value: Group) {
      self.group = clone(value)
    },
    setMembershipDuration(value: string) {
      self.membershipDuration = value
    },

    reset() {
      self.emails.clear()
      self.group = undefined
      self.membershipDuration = "60"
    },
  }))
  .views((self) => ({
    get createInvitationErrors() {
      return validate(CREATE_NEW_INVITATION_RULES, self)
    },
    get groupId() {
      return self.group?.id
    },
    get membershipDurationValue() {
      return parseInt(self.membershipDuration)
    },
  }))

const CREATE_NEW_INVITATION_RULES: ValidationRules = {
  emails: {
    presence: { allowEmpty: false, message: "required" },
    validateEmails: {
      message: "*emails are not valid",
    },
  },
  group: {
    presence: { allowEmpty: false, message: "required" },
  },
  membershipDuration: {
    presence: { allowEmpty: false, message: "*" },
    format: {
      pattern: "[1-9]+[0-9]*",
      flags: "i",
      message: "*please enter a valid number of days",
    },
  },
}

export interface NewInvitation extends Instance<typeof NewInvitationModel> {}
export interface NewInvitationSnapshot
  extends SnapshotOut<typeof NewInvitationModel> {}
