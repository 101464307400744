import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { CircularProgress, Theme, Typography, Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { switcher } from "rambdax"
import { Button } from "@material-ui/core"
import { ChatMessage, ChatType } from "../../models/chat-message"
import { ReactComponent as DietScale } from "../../assets/images/diet_scale.svg"
import { ReactComponent as Dumbbell } from "../../assets/images/fitness_dumbbell.svg"
import { ReactComponent as Hearts } from "../../assets/images/hearts.svg"
import { ReactComponent as LightBulb } from "../../assets/images/lightbulb.svg"
import { VerifyImageModal } from "../verify-image-modal"
import { useStores } from "../../models/root-store"
import { CHAT_REFRESH_RATE } from "../chat-room"
import { typography } from "../../services/theme/typography"
import { lightPalette } from "../../services/theme/palette"

interface StyleProps {
  type: ChatType
}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    bubble: {
      padding: theme.spacing(2),
      backgroundColor: (props) => {
        return switcher<any>(props.type)
          .is(ChatType.Weight, "#E9FBF4")
          .is(ChatType.Exercise, "#7163C8")
          .is(ChatType.Gratitude, "#F58258")
          .is(ChatType.Idea, "#039CAD")
          .default("#5C9F19")
      },
      color: "#1A3932",
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
      maxWidth: "480px",
      borderRadius: theme.spacing(1),
    },
    bubbleWithImage: {
      paddingBottom: theme.spacing(4),
    },
    infoWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    info: {
      width: "100%",
    },
    personName: {
      marginBottom: theme.spacing(0.5),
      fontSize: theme.spacing(2),
      color: (props) => {
        return switcher<any>(props.type)
          .is(ChatType.Weight, theme.palette.text)
          .default(theme.palette.common.white)
      },
      ...typography.circularXXBold,
    },
    caption: {
      fontSize: theme.spacing(2.25),
      color: (props) => {
        return switcher<any>(props.type)
          .is(ChatType.Weight, theme.palette.text)
          .default(theme.palette.common.white)
      },
      ...typography.circularXXBold,
    },
    message: {
      fontSize: theme.spacing(2.25),
      color: (props) => {
        return switcher<any>(props.type)
          .is(ChatType.Weight, theme.palette.text)
          .default(theme.palette.common.white)
      },
      ...typography.circularXXBook,
    },
    note: {
      fontSize: theme.spacing(2.25),
      color: (props) => {
        return switcher<any>(props.type)
          .is(ChatType.Weight, theme.palette.text)
          .default(theme.palette.common.white)
      },
      ...typography.circularXXBook,
      textTransform: "lowercase",
    },
    iconWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "space-between",
      paddingBottom: theme.spacing(1),
    },
    authorName: {
      color: (props) => {
        return switcher<any>(props.type)
          .is(ChatType.Weight, theme.palette.text)
          .default(theme.palette.common.white)
      },
    },
    imageWrapper: {
      paddingTop: theme.spacing(3),
    },
    backgroundImage: {
      position: "relative",
      height: 200,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      borderRadius: theme.shape.borderRadius,
    },
    backgroundImageOverlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.6)",
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    viewImageButton: {
      backgroundColor: "white",
    },
    image: {
      width: "100%",
    },
    modalImage: {
      height: "500px",
    },
  }),
)

interface ChatBubbleLogProps {
  message: ChatMessage
  centerContent?: boolean
  showAuthorName?: boolean
}

export const ChatBubbleLog = observer(
  ({ message, centerContent, showAuthorName }: ChatBubbleLogProps) => {
    const { personStore } = useStores()
    const classes = useStyles({ type: message.type })
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isVerifying, setIsVerifying] = useState<boolean>(false)

    const closeModal = () => {
      setIsModalOpen(false)
    }

    const onVerifyClick = async () => {
      if (!message?.log?.id) throw new Error("no log id provided")
      if (message.log.verified) throw new Error("log already verified")
      try {
        setIsVerifying(true)
        await personStore.apiVerifyPersonLogs({
          logId: message.log.id,
        })
        message.log.setVerified(true)
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsVerifying(false)
        }, CHAT_REFRESH_RATE)
      }
    }

    const onRemoveVerifyClick = async () => {
      if (!message?.log?.id) throw new Error("no log id provided")
      if (!message.log.verified) throw new Error("log is not verified")
      try {
        setIsVerifying(true)
        await personStore.apiDeleteVerifyPersonLogs({
          logId: message.log.id,
        })
        message.log.setVerified(false)
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsVerifying(false)
        }, CHAT_REFRESH_RATE)
      }
    }

    const renderActionButtons = () => {
      if (
        !message.log?.type ||
        message?.log?.type !== ChatType.Weight ||
        !!!message.log.imageURL
      ) {
        return null
      }
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={5}
          sx={{
            marginTop: 4,
          }}>
          <Button variant="outlined" onClick={onViewImageClick}>
            View Image
          </Button>

          {message.log.verified ? (
            <Button
              disabled={isVerifying}
              variant="contained"
              color="primary"
              startIcon={
                isVerifying && <CircularProgress color="secondary" size={20} />
              }
              onClick={onRemoveVerifyClick}>
              Remove Verification
            </Button>
          ) : (
            <Button
              disabled={isVerifying}
              variant="contained"
              color="primary"
              startIcon={
                isVerifying && <CircularProgress color="secondary" size={20} />
              }
              onClick={onVerifyClick}>
              Verify Photo
            </Button>
          )}
        </Stack>
      )
    }

    const onViewImageClick = () => {
      setIsModalOpen(true)
    }

    return (
      <>
        <div
          className={clsx(classes.bubble, {
            [classes.bubbleWithImage]: Boolean(message?.log?.imageURL),
          })}>
          {showAuthorName && (
            <Typography className={classes.personName}>
              {message?.author?.firstNameWithLastInitial}
            </Typography>
          )}

          <div className={classes.infoWrapper}>
            <Stack className={classes.info} spacing={2.5}>
              <Stack
                direction="row"
                justifyContent={centerContent ? "center" : "flex-start"}
                alignItems="center"
                spacing={2}>
                <Typography className={classes.caption}>
                  {switcher<any>(message.type)
                    .is(ChatType.Weight, "Weight Entry")
                    .is(ChatType.Exercise, "Activty Entry")
                    .is(ChatType.Gratitude, "I am grateful for")
                    .is(ChatType.Idea, "HOW-TO")
                    .default(null)}
                </Typography>

                {switcher<any>(message.type)
                  .is(
                    ChatType.Weight,
                    <DietScale fill={lightPalette.text.primary} />,
                  )
                  .is(ChatType.Exercise, <Dumbbell />)
                  .is(ChatType.Gratitude, <Hearts />)
                  .is(ChatType.Idea, <LightBulb />)
                  .default(null)}
              </Stack>

              <Stack alignItems={centerContent ? "center" : "flex-start"}>
                {Boolean(message.log) && (
                  <Typography variant="h2" className={classes.message}>
                    {message.log.value} {message.log.unit}{" "}
                    <span className={classes.note}>{message.log.note}</span>
                  </Typography>
                )}

                {Boolean(message.message) && (
                  <Typography variant="body1" className={classes.message}>
                    {message.message}
                  </Typography>
                )}

                {Boolean(message?.idea?.title) && (
                  <Typography variant="body1" className={classes.message}>
                    {message?.idea?.title}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </div>

          {Boolean(message?.log?.imageURL) && (
            <div className={classes.imageWrapper}>
              <div
                className={classes.backgroundImage}
                style={{
                  backgroundImage: `url(${message.log.imageURL})`,
                }}></div>
            </div>
          )}

          {renderActionButtons()}
        </div>

        <VerifyImageModal
          open={isModalOpen}
          closeModal={closeModal}
          modalLog={message?.log}
          isVerifyingLoading={isVerifying}
          verifyImage={onVerifyClick}
          onRemoveVerify={onRemoveVerifyClick}
        />
      </>
    )
  },
)
