import React from "react"
import { Link } from "react-router-dom"
import { ListItem, Stack, Typography, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { TitleRounded } from "@material-ui/icons"
import FlagIcon from "@mui/icons-material/Flag"
import { Person } from "../../models/person"
import { getMessageTimeLabel } from "../../utils"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "block",
      "&:hover": {
        backgroundColor: `#EBEEF040`,
      },
    },
    activeLink: {
      backgroundColor: `#D5E5FF`,
      "&:hover": {
        backgroundColor: `#D5E5FF`,
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
      cursor: "pointer",
      padding: theme.spacing(2, 2, 2, 0),
    },
    notificationContainer: {
      width: theme.spacing(3.5),
      paddingLeft: theme.spacing(1.5),
    },
    notificationDot: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      borderRadius: "50%",
      background: "#F23D3C",
    },
    userAvatar: {
      width: theme.spacing(5.5),
      height: theme.spacing(5.5),
      marginRight: theme.spacing(1.5),
    },
    primaryText: {
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(2.25),
      color: "#485359",
      wordBreak: "break-all",
      ...typography.circularXXBold,
    },
    secondaryText: {
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(2.25),
      color: "#485359",
      wordBreak: "break-all",
      ...typography.circularXXBook,
    },
    dateText: {
      fontSize: 12,
      color: "#485359",
      marginLeft: theme.spacing(2),
    },
    flag: {
      color: "#F23D3C",
    },
  }),
)

interface ChatInboxItemProps {
  linkTo: string
  isCurrentUser: boolean
  isChatFocused: boolean
  isFlagged?: boolean
  message?: string
  createdAt?: Date
  createdAtFromNow?: string
  onClick?: () => void
  messageId?: string
  newMessages?: number
  participant: Person
}

export const ChatInboxItem = React.memo(
  (props: ChatInboxItemProps) => {
    const {
      linkTo,
      isCurrentUser,
      isChatFocused,
      isFlagged,
      onClick,
      newMessages,
      participant,
      messageId,
      message = "",
      createdAt,
      createdAtFromNow,
    } = props
    const classes = useStyles()

    return (
      <Link
        key={messageId}
        className={clsx(classes.link, {
          [classes.activeLink]: isChatFocused,
        })}
        to={linkTo}>
        <ListItem
          className={clsx(classes.accordion)}
          onClick={onClick}
          aria-controls={`${participant.fullName}-panel-content`}
          id={`${TitleRounded}-panel-header`}>
          <div className={classes.notificationContainer}>
            {Boolean(newMessages) && (
              <div className={classes.notificationDot} />
            )}
          </div>

          <Stack spacing={1} flex={1}>
            <Stack direction="row" spacing={2}>
              <Typography className={classes.primaryText}>
                {participant.firstName || participant.lastName}
              </Typography>

              {isFlagged && <FlagIcon className={classes.flag} />}
            </Stack>

            {Boolean(message) && (
              <Typography className={classes.secondaryText}>
                {isCurrentUser ? `you:` : ""} {message.slice(0, 100)}
                {message.length > 100 && "..."}
              </Typography>
            )}

            {Boolean(createdAt) && (
              <Typography className={classes.dateText}>
                {getMessageTimeLabel(createdAt, createdAtFromNow)}
              </Typography>
            )}
          </Stack>
        </ListItem>
      </Link>
    )
  },
  (prevProps, nextProps) => {
    const areEqual =
      prevProps.linkTo === nextProps.linkTo &&
      prevProps.isCurrentUser === nextProps.isCurrentUser &&
      prevProps.isChatFocused === nextProps.isChatFocused &&
      prevProps?.isFlagged === nextProps?.isFlagged &&
      prevProps?.newMessages === nextProps?.newMessages &&
      prevProps.participant.id === nextProps.participant.id &&
      prevProps.messageId === nextProps.messageId
    return areEqual
  },
)
