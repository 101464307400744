import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Theme, Button, Stack, Drawer, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { RouteParams } from "../../AppRoutes"
import { useStores } from "../../models/root-store"
import { ChatMessage, ChatType } from "../../models/chat-message"
import { ChatMessageLog } from "../../models/chat-message/chat-message-log"
import { ChatBubbleLog } from "../chat-bubble/chat-bubble-log"
import { appBarHeight } from "../app-bar"
import { dashboardHeaderHeight } from "../dashboard-header-bar"
import { ParticipantPreview } from "../participant-preview"
import { typography } from "../../services/theme/typography"

const drawerTop = appBarHeight + dashboardHeaderHeight

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    personName: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.text.primary,
      lineHeight: theme.spacing(3),
      ...typography.circularXXMedium,
    },
    cohortName: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.text.primary,
      lineHeight: theme.spacing(3),
      ...typography.circularXXRegular,
    },
    showMemberCardButton: {
      fontSize: theme.spacing(2.25),
      color: "#3B85CC",
      alignItems: "center",
      lineHeight: theme.spacing(3),
      ...typography.circularXXMedium,
    },
    showMemberCardStartIcon: {
      marginRight: theme.spacing(1.5),
    },
    showMemberCardIcon: {
      fontSize: `${theme.spacing(7)} !important`,
    },
    drawerRoot: {},
    drawerPaper: {
      maxWidth: 400,
      top: drawerTop,
      height: `calc(100vh - ${drawerTop}px)`,
      boxShadow: "none",
      filter: "drop-shadow(0px 4px 12px rgba(45, 53, 59, 0.24))",
      border: "1px solid #C7CFD4",
      background: "#EBEEF0",
      padding: theme.spacing(4, 6),
    },
    logTitle: {
      fontSize: theme.spacing(2.25),
      color: theme.palette.text.secondary,
      textAlign: "center",
      ...typography.circularXXMedium,
    },
    logDate: {
      fontSize: theme.spacing(2),
      color: "#485359",
      textAlign: "center",
      ...typography.circularXXBook,
    },
  }),
)

const MemberDetailsDrawer = observer(() => {
  const [showMemberCardDrawer, toggleMemberCardDrawer] = useState(false)

  const classes = useStyles()
  const params = useParams<RouteParams>()

  const { cohortStore, personStore } = useStores()
  const { setCurrentPerson, apiGetPersonLogs, currentPerson } = personStore
  const { currentCohort } = cohortStore
  const selectedParticipant = currentCohort.registeredParticipants.find(
    (p) => p.personID === params.roomId,
  )

  useEffect(() => {
    if (!selectedParticipant) {
      return
    }
    const getParticipantLogs = async () => {
      await apiGetPersonLogs(params.cohortId, selectedParticipant.personID)
    }
    setCurrentPerson(selectedParticipant)
    getParticipantLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.roomId])

  if (!selectedParticipant) {
    return <div />
  }

  const toggleParticipantDetails = () => {
    toggleMemberCardDrawer(!showMemberCardDrawer)
  }

  const renderMemberLog = (logType: ChatType, logDetails: ChatMessageLog) => {
    if (!Boolean(logDetails)) {
      return <div />
    }
    return (
      <Stack spacing={2}>
        <Typography className={classes.logTitle}>
          Latest {logType === ChatType.Weight ? "Weight" : "Activity"} Entry
        </Typography>

        <Typography className={classes.logDate}>
          {moment(logDetails.createdAt).format("dddd, MMMM Do, hh:mm A")}
        </Typography>

        <ChatBubbleLog
          message={
            {
              type: logType,
              log: logDetails,
            } as ChatMessage
          }
          centerContent
        />
      </Stack>
    )
  }

  return (
    <>
      <Stack flex={1} direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography className={classes.personName}>
            {currentPerson?.fullName || ""}
          </Typography>

          <Typography className={classes.cohortName}>
            {currentCohort?.name || ""}
          </Typography>
        </Stack>

        <Button
          className={classes.showMemberCardButton}
          classes={{
            startIcon: classes.showMemberCardStartIcon,
          }}
          startIcon={<MoreHorizIcon className={classes.showMemberCardIcon} />}
          onClick={toggleParticipantDetails}>
          {showMemberCardDrawer ? "Hide" : "Show"} Member Card
        </Button>
      </Stack>

      <Drawer
        anchor="right"
        open={showMemberCardDrawer}
        onClose={() => toggleMemberCardDrawer(false)}
        classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}>
        <Stack spacing={4}>
          <ParticipantPreview
            member={currentPerson}
            onClose={toggleParticipantDetails}
          />

          {renderMemberLog(ChatType.Weight, currentPerson?.lastWeightEntry)}

          {renderMemberLog(ChatType.Exercise, currentPerson?.lastExerciseEntry)}
        </Stack>
      </Drawer>
    </>
  )
})

export { MemberDetailsDrawer }
