import { types, Instance, SnapshotOut } from "mobx-state-tree"
import nProgress from "nprogress"
import { ChatMessage, ChatMessageModel } from "../chat-message"
import {
  DirectChatMessagesModel,
  DirectChatMessages,
} from "../direct-chat-messages"
import { CohortModel, Cohort } from "../cohort/cohort"
import { withEnvironment, withRootStore } from "../extensions"
import { PaginationModel, MESSAGES_LIMIT } from "../pagination"
import { Program, ProgramModel } from "../program"
import { Person } from "../person"
import { VideoChatEvent } from "../video-chat-event"
import { VideoChatToken } from "../video-chat-token"
import { Attendance } from "../attendance"
import { ErrorType, recordError } from "../../utils"
import { LogsShape } from "../../services/api/api.types"
import { prop, uniqBy } from "ramda"

const VERSION = 1

enum ChatsLimit {
  Direct = MESSAGES_LIMIT,
  Group = MESSAGES_LIMIT,
}

/******************************************************************
 *
 *
 * CohortStore Model
 *
 *
 * ***************************************************************/
export const CohortStoreModel = types
  .model("CohortStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    newCohort: types.optional(CohortModel, () =>
      CohortModel.create({ id: "-1", oneOnOneVideoChatEnabled: false }),
    ),
    currentCohort: types.optional(CohortModel, () =>
      CohortModel.create({ id: "-1", oneOnOneVideoChatEnabled: false }),
    ),
    loadingCurrentCohort: types.optional(types.boolean, false),
    cohorts: types.optional(types.array(CohortModel), []),
    programs: types.optional(types.array(ProgramModel), []),
    directChatsPagination: types.optional(PaginationModel, () =>
      PaginationModel.create({ limit: ChatsLimit.Direct }),
    ),
    currentRoom: types.maybeNull(types.string),
    isGroupChatFocused: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    setPrograms(value: Program[]) {
      self.programs.replace(value)
    },
    setCohorts(value: Cohort[]) {
      self.cohorts.replace(value)
    },
    appendCohorts(value: Cohort[]) {
      self.cohorts.replace(uniqBy(prop("id"), [...self.cohorts, ...value]))
    },
    setCurrentCohort(value: Cohort) {
      self.currentCohort = value
    },
    setCurrentRoom(value: string | null) {
      self.currentRoom = value
    },
    toggleGroupChatFocused(value: boolean) {
      self.isGroupChatFocused = value
    },
    setLoadingCurrentCohort(value: boolean) {
      self.loadingCurrentCohort = value
    },
    resetCohorts() {
      self.cohorts.clear()
    },
    resetNewCohort() {
      self.newCohort = CohortModel.create({ id: "-1" })
    },
    reset() {
      self.newCohort = CohortModel.create({ id: "-1" })
      self.currentCohort = CohortModel.create({ id: "-1" })
      self.programs.clear()
      self.cohorts.clear()
      self.currentRoom = null
      self.isGroupChatFocused = false
      self.directChatsPagination = PaginationModel.create({
        limit: ChatsLimit.Direct,
        next: null,
      })
    },
  }))
  .actions((self) => ({
    appendDirectChatToCohort(
      { author, ...data }: ChatMessage,
      cohortId: string,
      roomId: string,
    ) {
      const { cohorts } = self

      if (!cohortId || !roomId) throw new Error("need roomId & cohortId")

      const cohort = cohorts.find((c) => c.id === cohortId)
      const directChat = cohort.directChats.get(roomId)
      const chatMessage = ChatMessageModel.create(data)
      chatMessage.setAuthor(author)

      if (directChat && directChat?.appendChat) {
        directChat?.appendChat(chatMessage)
      } else {
        /**
         * TODO(Harris): remove this hack
         * once we figure out why new messages don't get added properly.
         */
        window.location.reload()
      }
    },

    appendGroupChatToCohort(data: ChatMessage, cohortId: string) {
      const { cohorts } = self

      if (!cohortId) throw new Error("need cohortId")

      const cohort = cohorts.find((c) => c.id === cohortId)
      cohort.appendGroupChat(data)
    },
    setVideoChatEvents(value: VideoChatEvent[]) {
      self.currentCohort.videoChatEvents.replace(value)
    },
  }))
  .actions((self) => ({
    async apiGetPrograms(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getPrograms()
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.setPrograms(data as Program[])
    },
    async apiGetCohorts(): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.getCohorts()
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const cohorts: Cohort[] = data
      /**
       * When refreshing cohorts, let's set the first cohort as teh currentCohort
       * to resolve https://freshtriteam.teamwork.com/#/tasks/29525607
       */
      const isCurrentCohortNotSet = self.currentCohort.id === "-1"
      if (isCurrentCohortNotSet && cohorts.length) {
        self.setCurrentCohort(data[0] as Cohort)
      }

      self.appendCohorts(data as Cohort[])
    },
    async apiRefreshCohorts(): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.getCohorts()
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const cohorts: Cohort[] = data
      self.setCohorts(cohorts)
    },
    async apiGetCohort(cohortId: string): Promise<void> {
      const { api } = self.environment
      if (self.loadingCurrentCohort) {
        return
      }
      self.setLoadingCurrentCohort(true)
      try {
        const { data, kind } = await api.getCohort(cohortId)
        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }
        self.setCurrentCohort(data as Cohort)
        self.setLoadingCurrentCohort(false)
      } catch (error) {
        self.setLoadingCurrentCohort(false)
        throw error
      }
    },
    async apiGetCohortLogs(cohortId: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getCohortLogs({ cohortId })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const logsData = data as {
        exerciseLogs: LogsShape
        weightLogs: LogsShape
      }

      try {
        /**
         * Now that we have the cohort logs,
         * let's append them to each participant
         */
        self.currentCohort.participants.forEach((participant) => {
          const exerciseEntry = logsData.exerciseLogs[participant.personID]
          const weightEntry = logsData.weightLogs[participant.personID]

          if (exerciseEntry) {
            const exerciseLogs = exerciseEntry.logs
            const exerciseMeta = exerciseEntry.meta

            participant.appendLogs(exerciseLogs)
            participant.setTotalWeightLossValue(exerciseMeta.weight.totalLoss)
            participant.setTotalWeightLossUnit(exerciseMeta.weight.unit)

            participant.setTotalExerciseMinutesValue(
              exerciseMeta.exercise.totalActivity,
            )
            participant.setTotalExerciseMinutesUnit(exerciseMeta.exercise.unit)
          }

          if (weightEntry) {
            const weightLogs = weightEntry.logs
            const weightmeta = weightEntry.meta

            participant.appendLogs(weightLogs)
            participant.setTotalWeightLossValue(weightmeta.weight.totalLoss)
            participant.setTotalWeightLossUnit(weightmeta.weight.unit)

            participant.setTotalExerciseMinutesValue(
              weightmeta.exercise.totalActivity,
            )
            participant.setTotalExerciseMinutesUnit(weightmeta.exercise.unit)
          }
        })
      } catch (error) {
        recordError(ErrorType.HANDLED, error)
      }
    },
    async apiCreateCohort(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.createCohort({
        name: self.newCohort.cohortName,
        startDate: self.newCohort.startDate,
        programID: self.newCohort.programID,
        moduleID: self.newCohort.moduleID,
        coachEmails: self.newCohort.coachEmails,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.appendCohorts([data as Cohort])
      self.resetNewCohort()
    },

    async apiUpdateMotivation(
      id: string | undefined,
      motivation: string | null | undefined,
    ): Promise<void> {
      const { api } = self.environment
      if (!motivation || !id) return

      nProgress.start()

      const { kind, data } = await api.patchCohort(id, { motivation })
      self.currentCohort.setMotivation(motivation)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason ?? kind)
      }

      nProgress.done()
      return Promise.resolve()
    },

    async apiGetChatsLists(cohortId: string): Promise<void> {
      const { environment, directChatsPagination, cohorts } = self
      const { api } = environment
      const { data, kind } = await api.getCohortChatsLists({
        cohortId,
        limit: directChatsPagination.limit,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const cohort = cohorts.find((c) => c.id === cohortId)

      // if there's no cohort, we want to early return
      if (!!!cohort) return

      if (!!data?.group?.chats) {
        cohort.setGroupChat(data.group.chats)
        cohort.setGroupChatMeta(data.group.meta)
        //workaround for keeping unread count to 0 if group chat is focused
        //trying to avoid a rare case where the above api is called some miliseconds before focusing to group chat
        //which breaks the notification flag flow
        const newTotalUnreadGroupMessages = self?.isGroupChatFocused
          ? 0
          : parseInt(data?.group?.new_messages || "0")
        cohort.setTotalUnreadGroupMessages(newTotalUnreadGroupMessages)
      }

      /**
       * We need to do this to properly wait
       * for the loop to complete so we don't have
       * race condition problems in the UI.
       */
      const appendDirectChats = () => {
        return new Promise((resolve, reject) => {
          const newMessages: { [key: string]: DirectChatMessages } = {}
          Object.keys(data.direct).forEach((roomIdAsKey, index, array) => {
            const { chats, meta, new_messages } = data.direct[roomIdAsKey]

            //workaround for keeping unread count to 0 if direct chat is focused
            //trying to avoid a rare case where the above api is called some miliseconds before focusing to a direct chat
            //which breaks the notification flag flow
            const newMessagesCount =
              self?.currentRoom === roomIdAsKey ? 0 : new_messages

            const chatMessagesMap = DirectChatMessagesModel.create({
              messages: chats,
              meta,
              newMessages: newMessagesCount,
            })
            newMessages[roomIdAsKey] = chatMessagesMap
            if (index === array.length - 1) resolve("complete")
          })
          cohort?.replaceDirectChats(newMessages)
        })
      }

      await appendDirectChats()

      return Promise.resolve()
    },

    async apiGetDirectChats(cohortId: string, roomId: string): Promise<void> {
      const { environment, cohorts } = self
      const { api } = environment
      const cohort = cohorts.find((c) => c.id === cohortId)
      if (!cohort) {
        throw new Error("")
      }
      const { currentRoomPagination } = cohort

      const { data, kind } = await api.getCohortDirectChats({
        cohortId,
        roomId,
        limit: currentRoomPagination.limit,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      //@ts-ignore
      const messagesList = data.chats as ChatMessage[]
      currentRoomPagination.setChats(messagesList.reverse())
      const isEndReached = messagesList.length < currentRoomPagination?.limit
      currentRoomPagination.setIsEndReached(isEndReached)
    },

    async apiGetGroupChats(cohortId: string): Promise<void> {
      const { environment, cohorts } = self
      const { api } = environment
      const cohort = cohorts.find((c) => c.id === cohortId)
      if (!cohort) {
        throw new Error("")
      }
      const { currentRoomPagination } = cohort
      const { data, kind } = await api.getCohortGroupChats({
        cohortId,
        limit: currentRoomPagination.limit,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      //@ts-ignore
      const messagesList = data.chats as ChatMessage[]
      currentRoomPagination.setChats(messagesList.reverse())
      const isEndReached = messagesList.length < currentRoomPagination?.limit
      currentRoomPagination.setIsEndReached(isEndReached)
    },

    async apiCreateCohortChat(
      message: string,
      cohortId: string,
      isIndividualChat: boolean,
      roomId?: string,
    ): Promise<void> {
      const { environment, cohorts } = self
      const { api } = environment
      const { data, kind } = await api.createCohortChat({
        cohortId,
        message: message,
        roomId: roomId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      if (isIndividualChat) {
        self.appendDirectChatToCohort(data as ChatMessage, cohortId, roomId)
      } else {
        self.appendGroupChatToCohort(data as ChatMessage, cohortId)
      }

      const cohort = cohorts.find((c) => c.id === cohortId)

      cohort.currentRoomPagination.appendChat(data as ChatMessage)

      return Promise.resolve()
    },

    async apiGetVideoChatEvents(
      cohortId: string,
      siteId: string,
    ): Promise<void> {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const resp = await api.getVideoChatEvents({
        cohortId,
        siteId,
      })
      const { data, kind } = resp

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.setVideoChatEvents(data.events)
      return Promise.resolve()
    },

    async apiGetVideoChatToken(
      cohortId: string,
      siteId: string,
      eventId: string,
    ): Promise<VideoChatToken> {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const resp = await api.getVideoChatToken({
        cohortId,
        siteId,
        eventId,
      })
      const { data, kind } = resp

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      //@ts-ignore
      return Promise.resolve(data)
    },

    async apiInviteParticipant(participantID: string) {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const { data, kind } = await api.resendParticipantInvite({
        cohortId: currentCohort.id,
        participantID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },

    async apiAddParticipantsToCohort() {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const { data, kind } = await api.inviteParticipantsToCohort({
        cohortId: currentCohort.id,
        participants: currentCohort.participantEmails,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      currentCohort.appendParticipants(data as Array<Person>)

      return Promise.resolve()
    },

    async apiInviteCoach(coachID: string) {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const { data, kind } = await api.resendCoachInvite({
        cohortId: currentCohort.id,
        coachID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },

    async apiAddCoachesToCohort() {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const { data, kind } = await api.inviteCoachesToCohort({
        cohortId: currentCohort.id,
        coachEmails: currentCohort.coachEmails,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      currentCohort.appendCoaches(data as Array<Person>)

      return Promise.resolve()
    },

    async apiDeleteCoachFromCohort(coachId: string) {
      const { environment, currentCohort } = self
      const { api } = environment

      if (currentCohort.id === "-1") throw new Error("no cohort selected")

      const { data, kind } = await api.deleteCoachFromCohort({
        cohortId: currentCohort.id,
        coachId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      currentCohort.removeCoach(coachId)

      return Promise.resolve()
    },

    async getCohortSessionAttendance(
      sessionId: string,
    ): Promise<Array<Attendance>> {
      const { environment, currentCohort, rootStore } = self
      const { siteStore } = rootStore
      const { api } = environment
      const { data, kind } = await api.getCohortSessionsAttendance({
        siteId: siteStore.currentSiteId,
        sessionId,
        cohortId: currentCohort.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentCohort.setAttendanceHistory(data as Attendance[])

      return data as Array<Attendance>
    },
  }))
  .views((self) => ({
    get selectedProgram() {
      if (!!!self.newCohort.programID) {
        return undefined
      }

      return self.programs.find((program) => {
        return program.id === self.newCohort.programID
      })
    },
  }))

export interface CohortStore extends Instance<typeof CohortStoreModel> {}
export interface CohortStoreSnapshot
  extends SnapshotOut<typeof CohortStoreModel> {}
