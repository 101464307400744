import React, { useState } from "react"

import { Stack, Typography, List } from "@material-ui/core"
import TablePagination from "@mui/material/TablePagination"
import makeStyles from "@material-ui/styles/makeStyles"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg"
import { SortDialog } from "../sort"
import { useNotesSort, SORT_KEYS } from "./useNotesSort"
import { NoteItem } from "./note-item"

const useStyles = makeStyles((theme) => ({
  closeIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: theme.spacing(3.75),
    width: theme.spacing(3.75),
    borderRadius: theme.spacing(1.88),
    padding: theme.spacing(1),
    backgroundColor: "#F6F7F7",
  },
  closeIcon: {
    height: "17px",
    width: "17px",
    color: "#173430",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    height: 55,
    width: 53,
    marginRight: theme.spacing(1.44),
  },
  memberName: {
    fontSize: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: "center",
    ...typography.circularXXMedium,
  },
  memberEmail: {
    fontSize: theme.spacing(1.75),
    margin: theme.spacing(1.25, 0),
    lineHeight: theme.spacing(1.75),
    color: "#888C96",
    textAlign: "center",
    ...typography.circularXXBook,
  },
  iconContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  sortContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(4),
    cursor: "pointer",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  sortFilterLabel: {
    color: "#1771A6",
    fontSize: 14,
    fontWeight: "700",
    marginRight: theme.spacing(1),
    letterSpacing: 0.2,
    wordWrap: "break-word",
    ...typography.circularXXBook,
  },
  title: {
    fontSize: theme.spacing(2.5),
    color: "#252733",
    ...typography.circularXXBold,
  },
  emptyState: {
    fontSize: 16,
    fontWeight: 500,
    width: "100%",
    textAlign: "center",
    margin: theme.spacing(5, 0),
    ...typography.circularXXBold,
  },
  listContainer: {
    height: "260px",
    overflow: "auto",
  },
}))

interface ReadContentProps {
  closeModal: () => void
  showHeader: boolean
  disableCreate?: boolean
  toggleModalOpen: (value: boolean) => void
}

const ReadContent = observer(
  ({
    closeModal,
    showHeader,
    disableCreate,
    toggleModalOpen,
  }: ReadContentProps) => {
    const classes = useStyles()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const { formattedList, activeSortKey, setActiveSortKey } = useNotesSort()
    const { personStore } = useStores()

    const { currentPerson } = personStore
    const { avatarURL, fullName, email, resetNotes, setNewNote } = currentPerson

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const paginatedNotesList = formattedList.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )

    return (
      <Stack justifyContent="space-between">
        {showHeader && (
          <div className={classes.iconContainer}>
            <div
              className={classes.closeIconContainer}
              onClick={() => {
                resetNotes()
                closeModal()
              }}>
              <CloseRoundedIcon className={classes.closeIcon} />
            </div>
          </div>
        )}

        <Stack className={classes.headerContainer}>
          {showHeader ? (
            <Stack flexDirection="row">
              <img src={avatarURL} alt="avatar" className={classes.avatar} />
              <Stack alignItems="flex-start">
                <Typography className={classes.memberName}>
                  {Boolean(fullName) ? fullName : "No Name"}
                </Typography>

                <Typography className={classes.memberEmail}>{email}</Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography className={classes.title}>Notes</Typography>
          )}

          <Stack flexDirection="row">
            <div className={classes.sortContainer}>
              <SortDialog
                activeSortKey={activeSortKey}
                setActiveSortKey={setActiveSortKey}
                sortOptions={[
                  {
                    key: SORT_KEYS.LATEST,
                    label: "Latest First",
                  },
                  {
                    key: SORT_KEYS.OLDEST,
                    label: "Oldest First",
                  },
                ]}
              />
            </div>

            {!disableCreate && (
              <div
                className={classes.filterContainer}
                onClick={() => toggleModalOpen(true)}>
                <FilterIcon />
                <Typography className={classes.sortFilterLabel}>
                  Add new note
                </Typography>
              </div>
            )}
          </Stack>
        </Stack>

        <List className={classes.listContainer}>
          {paginatedNotesList.slice().map((note) => (
            <NoteItem
              note={note}
              disableCreate={disableCreate}
              editNote={(selectedNote) => {
                setNewNote(selectedNote)
                toggleModalOpen(true)
              }}
            />
          ))}

          {formattedList.length === 0 && (
            <Typography className={classes.emptyState}>
              No available notes
            </Typography>
          )}
        </List>

        {formattedList.length > 10 && (
          <TablePagination
            component="div"
            count={formattedList.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Stack>
    )
  },
)

export { ReadContent }
