import React from "react"
import { Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { CustomSwitch } from "../custom-switch"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { DidYouKnowTile } from "../../models/did-you-know-tile"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switcherContainer: {
      display: "flex",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)
const TileStatusAction = ({ tile }: { tile: DidYouKnowTile }) => {
  const classes = useStyles()

  const { adminMslEventStoreModel } = useStores()

  const { apiAdminEditDidYouKnowTileStatus } = adminMslEventStoreModel

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const toggleChangeStatus = async () => {
    try {
      await apiAdminEditDidYouKnowTileStatus({ ...tile, active: !tile.active })
      setSnackbarSeverity("success")
      const publishStatus = tile.active ? "published" : "unpublished"
      setSnackbarText(`Successfully ${publishStatus} ${tile.fact} !`)
      setShowSnackbar(true)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  return (
    <div className={classes.switcherContainer}>
      <CustomSwitch
        checked={Boolean(tile?.active)}
        onChange={toggleChangeStatus}
        activeLabel=""
        inActiveLabel=""
        labelSwitchSpacing={1}
        activeViewColor={"#00CC7E"}
        inActiveViewColor={"#808080"}
      />
      <Typography ml={1} className={classes.label}>
        {Boolean(tile?.active) ? "On" : "Off"}
      </Typography>
    </div>
  )
}

export { TileStatusAction }
