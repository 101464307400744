import React from "react"
import Calendar, { CalendarProps } from "react-calendar"
import moment from "moment"
import { Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { ReactComponent as RightAngleIcon } from "../../assets/images/right_angle.svg"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    calendar: {
      "&.react-calendar": {
        width: 378,
      },
      "& button": {
        border: "0px solid #fff !important",
        cursor: "pointer",
      },
      "&& .react-calendar__navigation": {
        marginBottom: theme.spacing(1),
        height: "auto",
        display: "flex",
        alignItems: "center",
      },
      "&& .react-calendar__navigation__prev-button": {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "50%",
        background: theme.palette.common.white,
        padding: 0,
        minWidth: "auto",
      },
      "&& .react-calendar__navigation__next-button": {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "50%",
        background: theme.palette.common.white,
        padding: 0,
        minWidth: "auto",
      },
      "&& .react-calendar__navigation__label": {
        "pointer-events": "none",
        "&:hover": {
          background: "transparent",
        },
      },
      "&& .react-calendar__month-view__weekdays": {
        color: `${theme.palette.text.secondary} !important`,
        textTransform: "none !important",
        fontSize: 18,
        fontWeight: "400",
        marginBottom: theme.spacing(1.5),
      },
      "&& .react-calendar__month-view__weekdays__weekday": {
        width: 54,
        height: 44,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "&& abbr": {
        "text-decoration": "none !important",
      },
      "&& .react-calendar__tile": {
        padding: 2,
        "&:hover": {
          background: "transparent",
        },
      },
      "&& .react-calendar__tile abbr": {
        display: "none",
      },
      "&& .react-calendar__tile--active": {
        background: "transparent",
      },
      "&& .react-calendar__tile--active div": {
        background: "#1771A6",
        color: "#fff",
      },
      "&& .react-calendar__tile--now": {
        background: "transparent",
      },
      "&& .react-calendar__month-view__days__day--weekend": {
        color: theme.palette.text.secondary,
      },
      "&& .react-calendar__month-view__days__day--neighboringMonth div": {
        background: "transparent",
        boxShadow: "none",
        color: "rgba(0, 23, 84, 0.15)",
      },
    },
    labelBox: {
      padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
      background: theme.palette.common.white,
      boxShadow: "0px 1px 1px rgba(0, 14, 51, 0.05)",
      borderRadius: theme.spacing(0.75),
      fontSize: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXBold,
    },
    prevIcon: {
      transform: "rotate(180deg)",
    },
    tile: {
      width: 50,
      height: 44,
      margin: "0 2px",
      background: "#FFFFFF",
      boxShadow: "0px 1px 1px rgba(0, 14, 51, 0.05)",
      borderRadius: 6,
      fontSize: 18,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
)

const CustomCalendar = ({ value, onChange }: CalendarProps) => {
  const classes = useStyles()

  return (
    <Calendar
      onChange={onChange}
      value={value}
      prev2Label={null}
      next2Label={null}
      className={classes.calendar}
      navigationLabel={({ date }) => (
        <Stack direction="row" justifyContent="center" spacing={0.25}>
          <div className={classes.labelBox}>{moment(date).format("MMMM")}</div>

          <div className={classes.labelBox}>{moment(date).format("YYYY")}</div>
        </Stack>
      )}
      prevLabel={<RightAngleIcon className={classes.prevIcon} />}
      nextLabel={<RightAngleIcon />}
      tileContent={({ date }) => (
        <div className={classes.tile}>{moment(date).format("D")}</div>
      )}
    />
  )
}

export { CustomCalendar }
