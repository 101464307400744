import React, { useEffect, useState } from "react"
import {
  Box,
  Grid,
  Paper,
  Typography,
  Modal,
  Button,
  Theme,
  CircularProgress,
  Stack,
  useTheme,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { useHistory, useParams } from "react-router-dom"
import moment from "moment"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { useSnackbars } from "../../components/use-snackbar"
import { SetAttendanceRow } from "./set-attendance-row"
import { typography } from "../../services/theme/typography"

interface StyleProps {
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  formRoot: {
    padding: theme.spacing(4.5),
    paddingBottom: 0,
  },
  formHeader: {
    borderBottom: "1px solid #2D353B",
    paddingBottom: theme.spacing(3),
  },
  formButtons: {
    alignItems: "center",
    background: "#F5F6F7",
    padding: theme.spacing(3.5),
    filter: "drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.12))",
  },
  formActionButton: {
    minWidth: 114,
    minHeight: theme.spacing(5),
    padding: 0,
  },
  formTitle: {
    fontSize: theme.spacing(3),
    letterSpacing: "2px",
    color: "#22878D",
    textAlign: "center",
    ...typography.circularXXMedium,
  },
  formSubTitle: {
    fontSize: theme.spacing(2),
    color: "#677278",
    textAlign: "center",
    ...typography.circularXXBook,
  },
  participantsListContainer: {
    overflowY: "scroll",
    maxHeight: "50vh",
    margin: theme.spacing(2, 4.5),
  },
}))

interface SetAttendanceRouteParams {
  sessionId: string
}

const SetAttendance = observer(() => {
  const history = useHistory()
  const { cohortStore, siteStore, uiStore } = useStores()
  const { isDrawerOpen } = uiStore
  const { currentCohort, getCohortSessionAttendance } = cohortStore
  const {
    registeredParticipants,
    clearAttendances,
    currentSession,
    apiSetAttendance,
  } = currentCohort

  const [isLoadingAttendanceData, setIsLoadingAttendanceData] = useState(true)

  const classes = useStyles({ drawerWidth, isDrawerOpen })
  const params = useParams<SetAttendanceRouteParams>()

  const { setAndShowSnackbar } = useSnackbars()

  const goBack = () => history.goBack()

  useEffect(() => {
    setIsLoadingAttendanceData(true)
    setTimeout(() => {
      const getAttendancesForSession = async () => {
        await getCohortSessionAttendance(params.sessionId)
        setIsLoadingAttendanceData(false)
      }
      getAttendancesForSession()
    }, 500)
  }, [params.sessionId, getCohortSessionAttendance])

  const handleClose = () => {
    goBack()
  }

  const onCancelPress = () => {
    handleClose()
    return
  }

  const onSavePress = async () => {
    try {
      await apiSetAttendance({
        siteId: siteStore.currentSiteId,
        sessionId: params.sessionId,
      })
      setAndShowSnackbar({
        text: "Successfully set attendances",
      })
      clearAttendances()
      goBack()
    } catch (error) {
      setAndShowSnackbar({
        severity: "error",
        text: "Something went wrong",
      })
    }
  }

  const theme = useTheme()

  const body = (
    <Paper
      sx={{
        position: "absolute",
        outline: "none",
        width: "100%",
        height: "100%",
        maxHeight: "80vh",
        filter: "drop-shadow(0px 7px 10px rgba(45, 53, 59, 0.15))",
        [theme.breakpoints.up("sm")]: {
          width: "50vw",
          height: "auto",
          left: "50%",
          top: "50%",
          transform: `translateX(-50%) translateY(-50%)`,
        },
      }}>
      <Grid container className={classes.formRoot}>
        {isLoadingAttendanceData ? (
          <CircularProgress
            style={{
              display: "block",
              margin: "0px auto 50px auto",
            }}
            color="secondary"
            size={48}
          />
        ) : (
          <Grid item xs={12}>
            <Stack
              spacing={2}
              alignItems="center"
              className={classes.formHeader}>
              <Typography className={classes.formTitle}>
                {currentSession?.name}
              </Typography>

              <Typography className={classes.formSubTitle}>
                {moment(currentSession?.date).format("MMMM Do YYYY - hh:mm A")}
              </Typography>
            </Stack>

            <Box className={classes.participantsListContainer}>
              {registeredParticipants.map((participant) => {
                return <SetAttendanceRow participant={participant} />
              })}
            </Box>
          </Grid>
        )}
      </Grid>

      <Stack
        className={classes.formButtons}
        direction="row"
        justifyContent="center"
        spacing={5}>
        <Button
          onClick={onCancelPress}
          variant="outlined"
          size="large"
          className={classes.formActionButton}>
          Cancel
        </Button>

        <Button
          onClick={onSavePress}
          variant="contained"
          size="large"
          className={classes.formActionButton}
          disabled={isLoadingAttendanceData}>
          Save
        </Button>
      </Stack>
    </Paper>
  )

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
})

export default SetAttendance
