import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import React, { FC, ReactNode } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { AppBar, appBarHeight } from "../app-bar"

interface DefaultLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme) =>
  createStyles({
    routes: {
      minHeight: "100vh",
      display: "flex",
      flex: 1,
      paddingTop: appBarHeight,
      background: theme.palette.common.white,
    },
    content: {
      flexGrow: 1,
      flexDirection: "column",
    },
  }),
)

export const DefaultLayout: FC<DefaultLayoutProps> = observer(
  ({ children }) => {
    const classes = useStyles()

    return (
      <>
        <main className={clsx(classes.content)}>
          <AppBar />
          <div className={clsx(classes.routes)}>{children}</div>
        </main>
      </>
    )
  },
)
