import React, { Fragment } from "react"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ListItemText from "@material-ui/core/ListItemText"
import { useRouteMatch } from "react-router-dom"
import { PilotChatGroup } from "../../models/pilot-coach-group"
import { NotificationBadge } from "../../components/notification-badge"
import { ReactComponent as MembersIcon } from "../../assets/images/members_icon.svg"
import { ReactComponent as DirectChatIcon } from "../../assets/images/nav_direct_chat.svg"
import { typography } from "../../services/theme/typography"
import { GroupOption } from "./group-option"

const useStyles = makeStyles((theme) =>
  createStyles({
    groupName: {
      padding: theme.spacing(2, 1, 0, 3),
      ...typography.circularXXMedium,
      fontSize: theme.spacing(2),
      lineHeight: "22px",
      color: "#485359",
      textTransform: "none",
      wordBreak: "break-all",
    },
    nestedBadgeContainer: {
      display: "flex",
      marginLeft: -8,
    },
  }),
)

interface RouteMatchParams {
  coachId: string
  groupId: string
}

interface CoachGroupsSubItemProps {
  coachID: string
  group: PilotChatGroup
}

const CoachGroupsSubItem = ({ coachID, group }: CoachGroupsSubItemProps) => {
  const classes = useStyles()

  const matchMembersRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/members",
  )
  const matchMemberDetailsRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/members/:personId",
  )
  const matchChatRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/chat",
  )
  const matchChatRoomRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/chat/:roomId",
  )

  const { params } =
    matchMembersRoute ||
    matchChatRoute ||
    matchChatRoomRoute ||
    matchMemberDetailsRoute ||
    {}

  const isMembersActive = matchMembersRoute?.isExact
  const isMatchMemberDetailsRoute = matchMemberDetailsRoute?.isExact
  const isChatActive = matchChatRoute?.isExact
  const isChatConversationActive = matchChatRoomRoute?.isExact

  const isCurrentGroupSelected =
    params?.coachId === coachID && params?.groupId === group.id

  return (
    <Fragment>
      <ListItemText
        primary={group.name}
        style={{ margin: 0 }}
        classes={{ primary: classes.groupName }}
      />

      <GroupOption
        selected={
          (isMembersActive || isMatchMemberDetailsRoute) &&
          isCurrentGroupSelected
        }
        to={`/pilot-trainer/${coachID}/group/${group.id}/members`}
        icon={<MembersIcon width={40} height={23} />}
        label="Members"
      />

      <GroupOption
        selected={
          (isChatActive || isChatConversationActive) && isCurrentGroupSelected
        }
        to={`/pilot-trainer/${coachID}/group/${group.id}/chat`}
        icon={<DirectChatIcon width={40} height={42} />}
        label="1:1 Chat">
        <div className={classes.nestedBadgeContainer}>
          {Boolean(group.totalUnreadMessages) && (
            <NotificationBadge unReadCount={group.totalUnreadMessages} />
          )}
        </div>
      </GroupOption>
    </Fragment>
  )
}

export { CoachGroupsSubItem }
