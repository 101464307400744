import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { Stack, Button, Popover } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import MenuIcon from "@mui/icons-material/Menu"
import PersonIcon from "@mui/icons-material/Person"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew"
import { useStores } from "../../models/root-store"
import { useLogout } from "../../utils/hooks/useLogout"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    profileButton: {
      background: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.common.white,
      },
      padding: theme.spacing(1.25, 2.5, 1.25, 4),
      borderRadius: theme.spacing(4),
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "50%",
      marginLeft: theme.spacing(1.5),
    },
    menuIcon: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      fill: "#1771A6",
    },
    profileMenuContainer: {
      width: 260,
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
    divider: {
      width: "100%",
      height: 2,
      background: "#24645C1A",
    },
    editProfileButton: {
      width: "100%",
      padding: theme.spacing(3, 2),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#1771A6",
      fontSize: theme.spacing(2.25),
      ...typography.circularXXMedium,
    },
    logoutButton: {
      width: "100%",
      padding: theme.spacing(3, 2),
      display: "flex",
      justifyContent: "space-between",
      color: "#F36D4F",
      fontSize: theme.spacing(2.25),
      ...typography.circularXXMedium,
    },
    logoutIcon: {
      height: theme.spacing(3),
      background: "#F36D4F",
      color: theme.palette.common.white,
      borderRadius: "50%",
    },
  }),
)

const ProfileMenu = observer(() => {
  const classes = useStyles()
  const { loginStore } = useStores()
  const { person } = loginStore

  const { handleLogOut } = useLogout()

  const [profileMenuEl, setProfileMenuEl] = useState<HTMLButtonElement | null>(
    null,
  )

  const openProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenuEl(event.currentTarget)
  }

  const closeProfileMenu = () => {
    setProfileMenuEl(null)
  }

  return (
    <div>
      <Button className={classes.profileButton} onClick={openProfileMenu}>
        <MenuIcon className={classes.menuIcon} />

        <img
          src={person.profile.avatarImageURL}
          alt="Avatar"
          className={classes.avatar}
        />
      </Button>

      <Popover
        id={Boolean(profileMenuEl) ? "profile-menu" : undefined}
        open={Boolean(profileMenuEl)}
        anchorEl={profileMenuEl}
        onClose={closeProfileMenu}
        classes={{
          paper: classes.profileMenuContainer,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Link to="/profile-page">
          <Button className={classes.editProfileButton}>
            <Stack spacing={1.5} direction="row" alignItems="center" flex={1}>
              <PersonIcon />

              <span>Edit Profile</span>
            </Stack>

            <NavigateNextIcon />
          </Button>
        </Link>

        <div className={classes.divider} />

        <Button className={classes.logoutButton} onClick={handleLogOut}>
          <Stack spacing={1.5} direction="row" alignItems="center" flex={1}>
            <span className={classes.logoutIcon}>
              <PowerSettingsNewIcon />
            </span>

            <span>Sign out</span>
          </Stack>
        </Button>
      </Popover>
    </div>
  )
})

export { ProfileMenu }
