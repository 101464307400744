import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { useFormState } from "../admin-config-form/useFormState"

const useTopicForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newTopic,
    isNewTopicDuplicate,
    apiAdminCreateEventTopic,
  } = adminMslEventStoreModel

  const { createMslTopicErrors: errors } = newTopic

  if (isNewTopicDuplicate) {
    errors.name = "This topic already exists"
  }

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      await apiAdminCreateEventTopic()
      setAndShowSnackbar({
        text: "Successfully created topic !",
        severity: "success",
      })

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  return { loading, submitted, values: newTopic, errors, onCancel, onSubmit }
}

export { useTopicForm }
