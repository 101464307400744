import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useRakCardForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newRakCard,
    apiAdminRAKCardUploadContent,
    apiAdminEditRakCard,
    apiAdminCreateRakCard,

    resetNewRakCard,
  } = adminMslEventStoreModel

  const {
    uploadFiles,
    setUploadFile,
    setSequence,
    isEditMode,
    createRakCardErrors: errors,
  } = newRakCard

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewRakCard()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      await apiAdminRAKCardUploadContent()
      if (isEditMode) {
        await apiAdminEditRakCard()
        setAndShowSnackbar({ text: "Successfully edited rak card !" })
      } else {
        await apiAdminCreateRakCard()
        setAndShowSnackbar({ text: "Successfully created rak card !" })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "imageURL",
      onChangeMethod: "setImageURL",
      label: "Image Url",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter image URL or select locally",
        filePickerProps: {
          label: "Select image",
          fileType: "rak_card_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.imageURL,
        },
      },
    },
    {
      fieldName: "sequence",
      label: "Sequence",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter sequence here",
        type: "number",
        formInputProps: {
          onChange: (evt: { target: { value: string } }) => {
            const newSequence = parseInt(evt.target.value)
            setSequence(isNaN(newSequence) ? null : newSequence)
          },
        },
      },
    },
    {
      fieldName: "active",
      onChangeMethod: "setActive",
      label: "Active",
      fieldType: FieldTypes.Switch,
    },
  ]

  return {
    isEditMode,
    loading,
    submitted,
    errors,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useRakCardForm }
