import React, { useState } from "react"

import { Typography, CircularProgress, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { CustomSwitch } from "../custom-switch"
import { Series } from "../../models/series"

interface SeriesSwitcherProps {
  seriesItem: Series
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unpublishedButton: {
      backgroundColor: `${theme.palette.text.disabled} !important`,
    },
    switcherContainer: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)

const SeriesSwitcher = (props: SeriesSwitcherProps) => {
  const classes = useStyles()

  const { seriesItem } = props

  const { adminMslEventStoreModel } = useStores()

  const { apiAdminEditIsFeaturedSeries } = adminMslEventStoreModel

  const [loading, setLoading] = useState(false)
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const toggleChangeStatus = async () => {
    setLoading(true)

    try {
      await apiAdminEditIsFeaturedSeries(seriesItem.id, !seriesItem.isFeatured)
      setSnackbarSeverity("success")

      setSnackbarText(`Successfully set to ${!seriesItem.isFeatured}`)

      setShowSnackbar(true)
      setLoading(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      setLoading(false)
    }
  }
  const switcherValue = Boolean(seriesItem?.isFeatured)
  const switcherLabel = switcherValue ? "On" : "Off"

  return (
    <div className={classes.switcherContainer}>
      {loading ? (
        <CircularProgress />
      ) : (
        <CustomSwitch
          checked={switcherValue}
          onChange={toggleChangeStatus}
          activeLabel=""
          inActiveLabel=""
          labelSwitchSpacing={1}
          activeViewColor={"#00CC7E"}
          inActiveViewColor={"#808080"}
        />
      )}
      <Typography ml={1} className={classes.label}>
        {switcherLabel}
      </Typography>
    </div>
  )
}

export { SeriesSwitcher }
