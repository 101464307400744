import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useRouteMatch } from "react-router-dom"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import useToggle from "../use-toggle/use-toggle"
import { ReactComponent as ArrowDropDownIcon } from "../../assets/images/rounded_down_arrow.svg"
import { Site } from "../../models/site"
import { useStores } from "../../models/root-store"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { typography } from "../../services/theme/typography"

export interface MatchCohortParams {
  siteId: string
}

interface SiteSwitcherProps {}

const useStyles = makeStyles((theme) =>
  createStyles({
    mainListItem: {
      justifyContent: "space-between",
      padding: theme.spacing(2, 3),
      color: "#1771A6",
      fontSize: 20,
      ...typography.circularXXBold,
    },
    nestedListItem: {
      paddingLeft: theme.spacing(2),
      padding: `
      ${theme.spacing(1)} 
      ${theme.spacing(2)} 
      ${theme.spacing(1)}
      ${theme.spacing(2.5)}`,
      color: "#1771A6",
      fontSize: theme.spacing(2.5),
      borderTop: "0.5px solid #2D353B",
    },
    upArrow: {
      transform: "rotate(-180deg)",
      fontSize: 30,
      marginLeft: theme.spacing(2),
    },
    downArrow: {
      fontSize: 30,
      marginLeft: theme.spacing(2),
    },
  }),
)

export const SiteSwitcher = observer((props: SiteSwitcherProps) => {
  const { push } = useHistory()
  const { siteStore, loginStore } = useStores()
  const {
    sitesAsArray,
    currentSite,
    setCurrentSiteId,
    apiGetSites,
    clearSites,
  } = siteStore
  const { authentication } = loginStore
  const classes = useStyles()
  const [tabOpen, toggleTab] = useToggle(false)

  const matchSiteRoute = useRouteMatch<MatchCohortParams>("/site/:siteId")

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  useEffect(() => {
    const refresh = async () => {
      try {
        await apiGetSites()
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    refresh()
  }, [
    apiGetSites,
    clearSites,
    setSnackbarSeverity,
    setSnackbarText,
    setShowSnackbar,
  ])

  const onListItemClick = (site: Site) => {
    setCurrentSiteId(site.id)

    if (authentication.isFtAdmin) {
      push(`/site/${site.id}`)
      return
    }

    push(`/site/${site.id}/cohorts`)
  }

  return (
    <>
      <ListItem button onClick={toggleTab} className={classes.mainListItem}>
        {currentSite?.name}

        {tabOpen ? (
          <ArrowDropDownIcon className={classes.upArrow} />
        ) : (
          <ArrowDropDownIcon className={classes.downArrow} />
        )}
      </ListItem>

      <Collapse
        in={tabOpen}
        style={{ minHeight: "auto" }}
        timeout={{ enter: 0 }}>
        <List component="div" disablePadding>
          {sitesAsArray.map((site) => {
            const isActive = matchSiteRoute?.params.siteId === site.id
            return (
              <ListItem
                key={site.id}
                onClick={() => {
                  onListItemClick(site)
                  toggleTab()
                }}
                button
                selected={isActive}
                className={classes.nestedListItem}>
                <ListItemText primary={site.name} />
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    </>
  )
})
