export const heightConverter = (inchHeight: number) => {
  if (!inchHeight) {
    return null
  }

  const feet = Math.floor(inchHeight / 12)
  const remainingInches = inchHeight % 12

  return `${feet}’ ${remainingInches}”`
}
