import React, { useState } from "react"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { PilotCoachMeta } from "../../models/pilot-coach-meta"
import { ReactComponent as DropDownArrowIcon } from "../../assets/images/drop_down_arrow.svg"
import { typography } from "../../services/theme/typography"
import { CoachGroupsSubItem } from "./coach-groups-sub-item"

const useStyles = makeStyles((theme) =>
  createStyles({
    coachGroupsListItem: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 3),
      marginTop: theme.spacing(2),
      borderBottom: "1px solid #677278",
    },
    coachName: {
      ...typography.circularXXBold,
      fontSize: theme.spacing(2.25),
      lineHeight: "22px",
      color: "#485359",
      textTransform: "none",
      flex: 1,
      wordBreak: "break-all",
    },
    downArrow: {
      transform: "rotate(-180deg)",
    },
    listContainer: {
      padding: 0,
    },
  }),
)

interface PilotDrawerCollapsableCoachItemProps {
  defaultCollapseOpen?: boolean
  coachMeta: PilotCoachMeta
}

const PilotDrawerCollapsableCoachItem = ({
  defaultCollapseOpen = false,
  coachMeta,
}: PilotDrawerCollapsableCoachItemProps) => {
  const classes = useStyles()
  const [groupsTabOpen, setGroupsTabOpen] = useState(defaultCollapseOpen)

  const { coach, coachGroups } = coachMeta

  return (
    <>
      <ListItem
        button
        onClick={() => setGroupsTabOpen(!groupsTabOpen)}
        className={classes.coachGroupsListItem}>
        <ListItemText
          primary={`${coach.firstName} ${coach.lastName.charAt(0)}'s Groups`}
          style={{ margin: 0 }}
          classes={{ primary: classes.coachName }}
        />

        {groupsTabOpen ? (
          <DropDownArrowIcon className={classes.downArrow} stroke="#485359" />
        ) : (
          <DropDownArrowIcon stroke="#485359" />
        )}
      </ListItem>

      <Collapse in={groupsTabOpen} timeout="auto">
        <List className={classes.listContainer}>
          {coachGroups.map((group) => (
            <CoachGroupsSubItem
              key={group.id}
              coachID={coach.id}
              group={group}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}
export { PilotDrawerCollapsableCoachItem }
