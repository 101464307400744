import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Collapse, Stack, Typography, Button } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import MessageIcon from "@mui/icons-material/Message"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { RouteParams } from "../../AppRoutes"
import { Person } from "../../models/person"
import { MemberCard } from "../member-card/member-card"
import { ReactComponent as ChatShadowAvatar } from "../../assets/images/chat_shadow_avatar.svg"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    memberCard: {
      padding: theme.spacing(2, 3, 0, 3),
      background: theme.palette.common.white,
      marginBottom: theme.spacing(3),
      border: "0.3px solid #B9B9B9",
      borderRadius: theme.spacing(2),
    },
    memberCardExpanded: {
      paddingBottom: theme.spacing(5),
    },
    memberAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginTop: theme.spacing(0.5),
    },
    memberName: {
      marginBottom: theme.spacing(0.5),
      overflowWrap: "anywhere",
      fontSize: theme.spacing(2.25),
      lineHeight: theme.spacing(2.75),
      color: "#212121",
      ...typography.circularXXMedium,
    },
    memberEmail: {
      marginBottom: theme.spacing(1.25),
      overflowWrap: "anywhere",
      fontSize: theme.spacing(1.5),
      lineHeight: theme.spacing(1.75),
      color: "#757575",
      textDecorationLine: "underline",
      ...typography.circularXXBook,
    },
    actionButton: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      color: theme.palette.text.secondary,
      ...typography.circularXXBook,
    },
    chatIcon: {
      fill: "#3B85CC",
      fontSize: `${theme.spacing(3.5)} !important`,
    },
    visibilityIcon: {
      fill: "#3B85CC",
      fontSize: `${theme.spacing(3)} !important`,
    },
  }),
)

interface MemberAccordionProps {
  member: Person
}

const MemberAccordion = ({ member }: MemberAccordionProps) => {
  const classes = useStyles()
  const [cardExpanded, toggleCardExpanded] = useState(false)
  const params = useParams<RouteParams>()

  return (
    <Stack
      className={clsx(classes.memberCard, {
        [classes.memberCardExpanded]: cardExpanded,
      })}
      spacing={2}>
      <Stack direction="row" spacing={1.5}>
        <div>
          <ChatShadowAvatar className={classes.memberAvatar} />
        </div>

        <Stack>
          <Typography className={classes.memberName}>
            {member.fullName}
          </Typography>

          <Typography className={classes.memberEmail}>
            {member.email}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Link
              to={`/site/${params.siteId}/cohorts/${params.cohortId}/chat/${member.personID}`}>
              <Button className={classes.actionButton}>
                <MessageIcon className={classes.chatIcon} />
              </Button>
            </Link>

            <Button
              className={classes.actionButton}
              onClick={() => toggleCardExpanded(!cardExpanded)}>
              <MoreHorizIcon className={classes.visibilityIcon} />
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Collapse in={cardExpanded}>
        <Stack direction="row" justifyContent="center">
          <MemberCard member={member} />
        </Stack>
      </Collapse>
    </Stack>
  )
}

export { MemberAccordion }
