import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme, CircularProgress } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { AdminTableHeader } from "../admin-table-header"
import { ShortText } from "../admin-msl-events-list/short-text"
import { CreateThemeModal } from "./create-theme-modal"
import { ThemesActions } from "./themes-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableTrainerCircleImage: {
      width: "100%",
      maxWidth: 120,
    },
  }),
)

const AdminThemesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateThemeModalOpen, toggleCreateThemeModalOpen] = useState(false)

  const { adminMslEventStoreModel } = useStores()
  const {
    themes,
    apiAdminGetThemes,
    apiAdminGetMslEvents,
    apiAdminGetTags,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetThemes()
        await apiAdminGetMslEvents()
        await apiAdminGetTags()
      } catch (e) {
      } finally {
        toggleFirstLoading(false)
      }
    }
    initialFetch()
  }, [apiAdminGetThemes, apiAdminGetMslEvents, apiAdminGetTags])

  const openCreateThemeModal = () => {
    toggleCreateThemeModalOpen(true)
  }

  const closeCreateThemeModal = () => {
    toggleCreateThemeModalOpen(false)
  }

  const addNewTheme = () => {
    openCreateThemeModal()
  }

  return (
    <div className={classes.root}>
      <LogsTable
        tableLabel="Themes"
        tableAriaLabel="themes-table"
        tablePaginationProps={{
          count: !!themes ? themes.length : 0,
        }}
        defaultRowsPerPage={25}
        CustomTableHeader={({ tableLabel }) => (
          <AdminTableHeader
            tableLabel={tableLabel}
            addNewButtonLabel="New theme"
            addNewButtonOnClick={addNewTheme}
          />
        )}>
        {({ page, rowsPerPage }) => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "33%" }} isStartEndPoint>
                    Name
                  </HeaderCell>

                  <HeaderCell style={{ width: "33%" }}>Description</HeaderCell>

                  <HeaderCell style={{ width: "33%" }}>
                    Publish status
                  </HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(themes.slice().length) ? (
                  themes
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((themeItem) => (
                      <TableRow key={themeItem.id}>
                        <BodyCell isStartEndPoint>{themeItem.name}</BodyCell>

                        <BodyCell>
                          <ShortText
                            value={themeItem.description || "N/A"}
                            textLimit={50}
                          />
                        </BodyCell>

                        <BodyCell>
                          <ThemesActions
                            openCreateThemeModal={openCreateThemeModal}
                            themeItem={themeItem}
                          />
                        </BodyCell>
                      </TableRow>
                    ))
                ) : (
                  <Box pl={3} pt={3}>
                    {firstLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EmptyState title="When you create some themes, they will appear here" />
                    )}
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>

      <CreateThemeModal
        open={isCreateThemeModalOpen}
        closeModal={closeCreateThemeModal}
      />
    </div>
  )
})

export { AdminThemesList }
