import { Api } from "../services/api/api"
import { __DEV__ } from "../config/constants"
import { Reactotron } from "../config/reactotron"
/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor(api: Api) {
    // create each service
    if (__DEV__ && Reactotron) {
      // dev-only services
      //@ts-ignore
      this.reactotron = new Reactotron()
    }

    this.api = api
  }

  async setup() {
    if (__DEV__) {
      //@ts-ignore
      await this.reactotron.setup()
    }
  }

  /**
   * Our api.
   */
  api: Api

  /**
   * Reactotron is only available in dev.
   */
  //@ts-ignore
  reactotron: typeof Reactotron
}
