import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { Nudges } from "../../models/nudges"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useNudgesForm } from "./useNudgesForm"

export enum ModalMode {
  ADD_NEW_NUDGE,
  EDIT_NUDGE,
}

const modalModeLabels = {
  [ModalMode.ADD_NEW_NUDGE]: {
    headerLabel: "Create new Nudge",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT_NUDGE]: { headerLabel: "Edit Nudge", buttonLabel: "Save" },
}

interface CreateNudgeModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateNudgeModal = observer(
  ({ mode, open, closeModal }: CreateNudgeModalProps) => {
    const {
      loading,
      submitted,
      onCancel,
      onSubmit,
      formConfig,
    } = useNudgesForm({ mode, closeModal, isModalOpen: open })

    const { adminMslEventStoreModel } = useStores()
    const { newNudge } = adminMslEventStoreModel
    const { createNudgeErrors: errors } = newNudge

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Nudges>
            submitted={submitted}
            values={newNudge}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT_NUDGE}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateNudgeModal }
