import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme, CircularProgress } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { AdminTableHeader } from "../admin-table-header"
import { CreateMslTopicModal } from "./create-msl-topic-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
  }),
)

const AdminMslTopicsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateTopicModalOpen, toggleCreateTopicModalOpen] = useState(false)

  const { adminMslEventStoreModel } = useStores()
  const { eventTopics, apiAdminGetEventTopics } = adminMslEventStoreModel

  const openCreateTopicModal = () => {
    toggleCreateTopicModalOpen(true)
  }

  const closeCreateTopicModal = () => {
    toggleCreateTopicModalOpen(false)
  }

  useEffect(() => {
    const initialFetch = async () => {
      await apiAdminGetEventTopics()
      toggleFirstLoading(false)
    }
    initialFetch()
  }, [apiAdminGetEventTopics])

  //Sort topic list alphabetically
  const sortedList = (eventTopics.slice() || []).sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  return (
    <div className={classes.root}>
      <LogsTable
        tableLabel="Topics"
        tableAriaLabel="topics-table"
        defaultRowsPerPage={25}
        tablePaginationProps={{
          count: !!sortedList ? sortedList.length : 0,
        }}
        CustomTableHeader={({ tableLabel }) => (
          <AdminTableHeader
            tableLabel={tableLabel}
            addNewButtonLabel="New topic"
            addNewButtonOnClick={openCreateTopicModal}
          />
        )}>
        {() => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "50%" }} isStartEndPoint>
                    Name
                  </HeaderCell>

                  <HeaderCell style={{ width: "50%" }}>ID</HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(sortedList.length) ? (
                  sortedList.map((topic) => (
                    <TableRow key={topic.id}>
                      <BodyCell isStartEndPoint>{topic.name}</BodyCell>

                      <BodyCell>{topic.id}</BodyCell>
                    </TableRow>
                  ))
                ) : (
                  <Box pl={3} pt={3}>
                    {firstLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EmptyState title="When you create some topics, they will appear here" />
                    )}
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>

      <CreateMslTopicModal
        open={isCreateTopicModalOpen}
        closeModal={closeCreateTopicModal}
      />
    </div>
  )
})

export { AdminMslTopicsList }
