import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { InvitationModel, Invitation } from "./invitation"

export const LIMIT = 20
export const PAGE = 1

/**
 * Represents a pagination model.
 */
export const InvitationPaginationModel = types
  .model("InvitationPagination")
  .props({
    loading: types.optional(types.boolean, false),
    page: types.optional(types.number, PAGE),
    next: types.optional(types.number, 0),
    previous: types.optional(types.number, 0),
    limit: types.optional(types.number, LIMIT),
    isEndReached: types.optional(types.boolean, false),
    invitations: types.optional(types.array(InvitationModel), []),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },
    setNextPage(value: {
      next: number
      previous: number
      isEndReached: boolean
    }) {
      self.next = value.next
      self.previous = value.previous
      self.isEndReached = value.isEndReached
    },
    setPage(value: number) {
      self.page = value
    },
    setLimit(value: number) {
      self.limit = value
    },

    setInvitations(value: Invitation[]) {
      self.invitations.replace(
        uniqBy(prop("id"), [...self.invitations, ...value]),
      )
    },

    updateInvitation(value: Invitation) {
      const updatedInvitationsList = replaceObjectById(self.invitations, value)

      self.invitations.replace(updatedInvitationsList as Invitation[])
    },

    deleteInvitation(value: string) {
      const updatedInvitationsList = removeObjectById(self.invitations, value)
      self.invitations.replace(updatedInvitationsList as Invitation[])
    },

    resetInvitations() {
      if (isAlive(self.invitations)) {
        self.invitations.clear()
      }
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetInvitations()
      self.loading = false
      self.page = PAGE
      self.limit = LIMIT
      self.next = 0
      self.previous = 0
      self.isEndReached = false
    },
  }))

export interface InvitationPagination
  extends Instance<typeof InvitationPaginationModel> {}
export interface InvitationPaginationSnapshot
  extends SnapshotOut<typeof InvitationPaginationModel> {}
