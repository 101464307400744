import { FieldTypes } from "../admin-config-form"

export const recipeFormConfig = [
  {
    fieldName: "title",
    onChangeMethod: "setTitle",
    label: "Title",
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter title",
    },
  },
  {
    fieldName: "label",
    onChangeMethod: "setLabel",
    label: "Label",
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter label",
    },
  },
  {
    fieldName: "suggesticSearchKeyword",
    required: true,
    onChangeMethod: "setSuggesticSearchKeyword",
    label: "Suggestic Search Keyword",
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter suggestic search keyword",
    },
  },
  {
    fieldName: "suggesticRecipeId",
    required: true,
    onChangeMethod: "setSuggesticRecipeId",
    label: "Suggestic Recipe Id",
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter Suggestic Recipe Id here",
    },
  },
]
