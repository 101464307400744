import React from "react"
import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider as MUIThemeProvider,
  useTheme as useThemeMUI,
} from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import { typography } from "./typography"
import { lightPalette } from "./palette"

interface ThemeProviderProps {
  children?: React.ReactNode
}

export const ThemeProvider = ({
  children,
}: ThemeProviderProps): JSX.Element => {
  const theme = createTheme({
    palette: {
      ...lightPalette,
    },
    typography: {
      fontFamily: [
        "CircularXX-Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        "sans-serif",
      ].join(","),
      ...typography,
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            "text-transform": "none",
          },
          containedPrimary: {
            background: lightPalette.info.main,
            color: "#fff",
            fontSize: 16,
            ...typography.circularXXBook,
            boxShadow: "0px 4px 12px rgba(45, 53, 59, 0.25)",
            "&:hover": {
              background: "#094E7C",
            },
            "&:focus": {
              background: "#03304F",
            },
          },
          containedSecondary: {
            backgroundColor: lightPalette.secondary.main,
            color: "#1A3932",
            "&:hover": {
              backgroundColor: lightPalette.secondary.main,
            },
          },
          outlinedPrimary: {
            background: "#fff",
            borderColor: lightPalette.info.main,
            color: lightPalette.info.main,
            fontSize: 16,
            ...typography.circularXXBook,
            boxShadow: "0px 4px 12px rgba(45, 53, 59, 0.25)",
            "&:hover": {
              background: "#fff",
              color: "#094E7C",
              borderColor: "#094E7C",
            },
            "&:focus": {
              background: "#fff",
              color: "#03304F",
              borderColor: "#03304F",
            },
          },
        },
      },
    },
  })

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}

export const useTheme = (): Theme => {
  return useThemeMUI()
}

export { useMediaQuery } from "@material-ui/core"
export type { Theme } from "@material-ui/core"

export { StyledEngineProvider }
