import React from "react"
import { ListChildComponentProps } from "react-virtualized"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import Stack from "@material-ui/core/Stack"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import { observer } from "mobx-react-lite"

import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { AdminUserSearch } from "../../models/admin-user-search"

const useStyles = makeStyles((theme) =>
  createStyles({
    userListItem: {
      marginRight: theme.spacing(2),
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      background: "#F5F6F7",
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #C7CFD4",
    },
    userInfoContainer: {
      maxWidth: "70%",
    },
    userInfoText: {
      fontSize: 16,
      lineHeight: "20px",
      color: "#2D353B",
    },
    emailText: {
      fontSize: 16,
      lineHeight: "20px",
      color: "#677278",
    },
    alertText: {
      color: "#FF0000 !important",
    },
    managePersonActive: {
      color: "#0A8052 !important",
    },
    actionButton: {
      padding: 0,
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#485359",
      },
      "&:active": {
        color: "#2D353B",
      },
    },
    actionButtonText: {
      fontSize: 15,
      lineHeight: "18px",
      color: "#677278",
      fontWeight: "normal",
      "text-transform": "none",
    },
    actionButtonIcon: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  }),
)

type PeopleListItemProps = {}

export const PeopleListItem = observer(
  ({ index, style }: PeopleListItemProps & ListChildComponentProps) => {
    const classes = useStyles()

    const { adminToolsStore } = useStores()
    const {
      userSearchList,
      apiAdminBanUser,
      apiAdminUnBanUser,
      manuallyToggleUserBannedOnPosts,
      getPersonData,
      selectedPerson,
    } = adminToolsStore

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    const user: AdminUserSearch = userSearchList[index]

    const { personID, email, fname, isPersonBanned } = user
    const isManagePersonMode = selectedPerson?.person?.id === personID

    const toggleBan = async () => {
      try {
        //manually toggle user ban
        manuallyToggleUserBannedOnPosts({ personID })
        if (isPersonBanned) {
          await apiAdminUnBanUser({ userId: personID })
        } else {
          await apiAdminBanUser({ userId: personID })
        }
      } catch (error) {
        //revert toggle if api fails
        manuallyToggleUserBannedOnPosts({ personID })
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    return (
      <ListItem style={style} component="div" disablePadding>
        <ListItem className={clsx(classes.userListItem)}>
          <Stack spacing={1} className={classes.userInfoContainer}>
            <Stack
              direction="row"
              divider={
                <Divider orientation="vertical" flexItem sx={{ width: 2 }} />
              }
              spacing={2}>
              <ListItemText
                primary={fname}
                classes={{
                  primary: classes.userInfoText,
                }}
                style={{ margin: 0 }}
              />

              <ListItemText
                primary={personID}
                classes={{ primary: classes.userInfoText }}
              />
            </Stack>

            <ListItemText
              primary={email}
              classes={{ primary: classes.emailText }}
            />
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            flex={1}
            maxWidth={240}>
            <Button
              startIcon={<NotificationsOffOutlinedIcon />}
              classes={{
                root: classes.actionButton,
                textPrimary: clsx(classes.actionButtonText, {
                  [classes.alertText]: isPersonBanned,
                }),
                startIcon: classes.actionButtonIcon,
              }}
              onClick={toggleBan}>
              {isPersonBanned ? "Person Muted" : "Mute Person"}
            </Button>

            <Button
              startIcon={<PersonOutlineOutlinedIcon />}
              classes={{
                root: classes.actionButton,
                textPrimary: clsx(classes.actionButtonText, {
                  [classes.managePersonActive]: isManagePersonMode,
                }),
                startIcon: classes.actionButtonIcon,
              }}
              onClick={() => getPersonData({ personID })}>
              Manage Person
            </Button>
          </Stack>
        </ListItem>
      </ListItem>
    )
  },
)
