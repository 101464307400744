import { useRef } from "react"
import { debounce } from "rambdax"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"

const THROTTLE_DELAY = 2000

const useAdminSearchMslEvents = ({
  delay = THROTTLE_DELAY,
}: {
  delay?: number
}) => {
  const { adminMslEventStoreModel } = useStores()

  const {
    apiAdminSearchMslEvents,
    mslEventsPagination,
  } = adminMslEventStoreModel
  const {
    setLoading,
    resetPagination,
    setShowSearchResults,
  } = mslEventsPagination

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  /**
   * Debounced method for search api, to avoid api calls while admin is writing in the search field
   * The api call will be fired 2 seconds after the last debounced method
   */
  const debouncedAdminSearchMslEvents = useRef(
    debounce(async (query: string) => {
      try {
        //avoid firing api call with empty input
        if (query.trim().length) {
          setLoading(true)
          setShowSearchResults(true)
          // await new Promise((resolve) => setTimeout(resolve, 1000))
          resetPagination()
          await apiAdminSearchMslEvents({ query })
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }, delay),
  )

  const handleEventsSearch = async (value: string) => {
    setLoading(true)
    debouncedAdminSearchMslEvents.current(value)
    //call debounced method on every input change
  }

  return { handleEventsSearch }
}

export { useAdminSearchMslEvents }
