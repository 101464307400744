import React, { useState, useEffect } from "react"
import moment from "moment"
import { Stack } from "@material-ui/core"

import {
  TimeOption,
  TimeFormat,
  incrementTime,
  decrementTime,
  isNotValidTime,
  updateTimeValue,
} from "./utils"
import TimeInput from "./time-input"

/**
 * Custom Time Picker component wheel style
 * Contains three inputs with increment/decrement arrows for each input
 * On component first load set the default values of the inputs from the date value prop
 * On each input update create a clone date based on input values and use onChange method to update the value prop
 */

interface CustomTimePickerProps {
  value: Date
  onChange: (newValue: Date) => void
}

const CustomTimePicker = ({ value, onChange }: CustomTimePickerProps) => {
  const [hourValue, setHourValue] = useState<string>("")
  const [minuteValue, setMinuteValue] = useState<string>("")
  const [timeFormat, setTimeFormat] = useState<string>("")

  useEffect(() => {
    /**
     * Method to set the default values of the inputs
     */
    const initialLoad = () => {
      const formattedValue = Boolean(value) ? moment(value) : moment()
      setHourValue(formattedValue.format("hh"))
      setMinuteValue(formattedValue.format("mm"))
      setTimeFormat(formattedValue.format("A"))
    }

    initialLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const newValue = updateTimeValue(value, hourValue, minuteValue, timeFormat)
    onChange(newValue.toDate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourValue, minuteValue, timeFormat])

  const incrementHour = () => {
    const newHourValue = incrementTime(TimeOption.HOUR, hourValue)
    setHourValue(newHourValue)
  }

  const decrementHour = () => {
    const newHourValue = decrementTime(TimeOption.HOUR, hourValue)
    setHourValue(newHourValue)
  }

  const incrementMinute = () => {
    const newMinuteValue = incrementTime(TimeOption.MINUTE, minuteValue)
    setMinuteValue(newMinuteValue)
  }

  const decrementMinute = () => {
    const newMinuteValue = decrementTime(TimeOption.MINUTE, minuteValue)
    setMinuteValue(newMinuteValue)
  }

  const toggleTimeFormat = () => {
    setTimeFormat(timeFormat === TimeFormat.AM ? TimeFormat.PM : TimeFormat.AM)
  }

  const onHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value
    if (isNotValidTime(TimeOption.HOUR, nextValue)) {
      return
    }
    setHourValue(nextValue)
  }

  const onMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value
    if (isNotValidTime(TimeOption.MINUTE, nextValue)) {
      return
    }
    setMinuteValue(nextValue)
  }

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <TimeInput
        increment={incrementHour}
        decrement={decrementHour}
        value={hourValue}
        onChange={onHourChange}
        placeholder="12"
      />

      <TimeInput
        increment={incrementMinute}
        decrement={decrementMinute}
        value={minuteValue}
        onChange={onMinuteChange}
        placeholder="00"
      />

      <TimeInput
        increment={toggleTimeFormat}
        decrement={toggleTimeFormat}
        value={timeFormat}
        disabled
        disabledOption
      />
    </Stack>
  )
}

export { CustomTimePicker }
