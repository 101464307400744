import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { Recipe } from "../../models/recipes"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useRecipeForm } from "./useRecipeForm"
import { recipeFormConfig } from "./recipeFormConfig"

export enum ModalMode {
  ADD_NEW_RECIPE,
  EDIT_RECIPE,
}

interface CreateRecipeModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const modalModeLabels = {
  [ModalMode.ADD_NEW_RECIPE]: {
    headerLabel: "Create new recipe",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT_RECIPE]: { headerLabel: "Edit recipe", buttonLabel: "Save" },
}

const CreateRecipeModal = observer(
  ({ mode, open, closeModal }: CreateRecipeModalProps) => {
    const {
      values,
      errors,
      loading,
      submitted,
      onCancel,
      onSubmit,
    } = useRecipeForm({
      mode,
      closeModal,
      isModalOpen: open,
    })

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Recipe>
            submitted={submitted}
            values={values}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT_RECIPE}
            formConfig={recipeFormConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateRecipeModal }
