import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import { EnrolledProgramsModel } from "./enrolled-programs"

export const BasicProfileModel = types
  .model("BasicProfile")
  .props({
    id: types.identifier,
    smsAllowed: types.optional(types.boolean, false),
    timezone: types.maybeNull(types.string),
    intentionNotifyTime: types.maybeNull(types.string),
    intentionNotifyEnabled: types.optional(types.boolean, false),
    gratitudeNotifyTime: types.optional(customTypes.iso8601, moment().toDate()),
    gratitudeNotifyEnabled: types.optional(types.boolean, false),
    triggerNotifyTime: types.optional(customTypes.iso8601, moment().toDate()),
    triggerNotifyEnabled: types.optional(types.boolean, false),
    nudgeChannelPreference: types.maybeNull(types.string),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
    uniqueID: types.maybeNull(types.string),
    fcmDeviceToken: types.maybeNull(types.string),
    userPostAllowed: types.optional(types.boolean, false),
    emailUnsubscribed: types.optional(types.maybeNull(types.boolean), false),
    enrolledPrograms: types.optional(types.array(EnrolledProgramsModel), []),
  })
  .actions((self) => ({
    toggleUserBan() {
      self.userPostAllowed = !self.userPostAllowed
    },
    toggleEmailUnsubscribed() {
      self.emailUnsubscribed = !self.emailUnsubscribed
    },
  }))
  .views((self) => ({
    get isPersonBanned() {
      return !self.userPostAllowed
    },
    get enrolledProgramsLabel() {
      return self.enrolledPrograms.map((program) => program.name).join(", ")
    },
  }))

export interface BasicProfile extends Instance<typeof BasicProfileModel> {}
export interface BasicProfileSnapshot
  extends SnapshotOut<typeof BasicProfileModel> {}
