import React from "react"
import { Box, Grid, CircularProgress, Theme, Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Button, Modal } from "@material-ui/core"
import { ChatMessageLog } from "../../models/chat-message/chat-message-log"
import { ReactComponent as RoundedTickIcon } from "../../assets/images/rounded_tick.svg"

interface VerifyImageModalProps {
  open: boolean
  closeModal: () => void
  modalLog: ChatMessageLog
  isVerifyingLoading: boolean
  verifyImage: () => void
  onRemoveVerify: () => void
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modalRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContainer: {
      maxWidth: 800,
      maxHeight: 740,
      display: "flex",
      flexDirection: "column",
      background: "#C4C4C4",
    },
    imageBox: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: theme.spacing(6.5, 14, 5, 14),
    },
    modalImage: {
      width: "auto",
      maxHeight: "550px",
      borderRadius: theme.spacing(1),
      objectFit: "scale-down",
    },
    verifiedFlagIcon: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      position: "absolute",
      bottom: theme.spacing(6),
      right: theme.spacing(15),
    },
    buttonsRow: {
      boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
      background: "#EBEEF0",
      borderRadius: theme.spacing(0, 0, 1, 1),
      padding: theme.spacing(4),
    },
    actionButton: {
      minWidth: 154,
      height: 45,
      borderRadius: theme.spacing(0.5),
    },
    verifiedButton: {
      minWidth: 154,
      height: 45,
      background: "#0A8052",
      color: "#fff",
      "&.Mui-disabled": {
        background: "#0A8052",
        color: "#fff",
      },
    },
    verifiedButtonIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "50%",
      border: "2px solid #fff",
    },
  }),
)

const VerifyImageModal = ({
  open,
  closeModal,
  modalLog,
  isVerifyingLoading,
  verifyImage,
  onRemoveVerify,
}: VerifyImageModalProps) => {
  const classes = useStyles()

  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      closeModal()
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      classes={{
        root: classes.modalRoot,
      }}>
      <Grid className={classes.modalContainer} item md={8} lg={8}>
        <Box className={classes.imageBox}>
          <img
            alt="modal"
            className={classes.modalImage}
            src={modalLog?.imageURL}
          />

          {modalLog?.verified && (
            <RoundedTickIcon className={classes.verifiedFlagIcon} />
          )}
        </Box>

        <Stack
          direction="row"
          justifyContent="center"
          className={classes.buttonsRow}
          flex={1}
          spacing={4}>
          <Button
            onClick={closeModal}
            variant="outlined"
            size="large"
            className={classes.actionButton}>
            Done
          </Button>

          {modalLog?.verified ? (
            <>
              <Button
                disabled={isVerifyingLoading}
                variant="contained"
                size="large"
                className={classes.actionButton}
                startIcon={
                  isVerifyingLoading && (
                    <CircularProgress color="secondary" size={20} />
                  )
                }
                onClick={onRemoveVerify}>
                Remove Verification
              </Button>
            </>
          ) : (
            <Button
              disabled={isVerifyingLoading}
              variant="contained"
              size="large"
              className={classes.actionButton}
              startIcon={
                isVerifyingLoading && (
                  <CircularProgress color="secondary" size={20} />
                )
              }
              onClick={verifyImage}>
              Verify
            </Button>
          )}
        </Stack>
      </Grid>
    </Modal>
  )
}

export { VerifyImageModal }
