import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import { BasicBehaviorModel } from "./basic-behavior"

export const BasicTriModel = types
  .model("BasicTri")
  .props({
    id: types.identifier,
    behavior: types.maybeNull(BasicBehaviorModel),
    personID: types.maybeNull(types.string),
    startedAt: types.optional(customTypes.iso8601, moment().toDate()),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    what: types.maybeNull(types.string),
    how: types.maybeNull(types.string),
    why: types.maybeNull(types.string),
    totalCheckIns: types.maybeNull(types.number),
  })
  .views((self) => ({
    get startedAtFromNow() {
      return moment(self.startedAt).fromNow()
    },
    get formattedCreatedAt() {
      return !!self.createdAt ? moment(self.createdAt).format("lll") : null
    },
    get isTriValid() {
      return Boolean(self.what) || Boolean(self.how) || Boolean(self.why)
    },
    get formattedHow() {
      return self?.how || self?.behavior?.title || ""
    },
  }))

export interface BasicTri extends Instance<typeof BasicTriModel> {}
export interface BasicTriSnapshot extends SnapshotOut<typeof BasicTriModel> {}
