import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import moment from "moment"
import { uniqBy, prop } from "ramda"
import nProgress from "nprogress"
import { validate, ValidationRules } from "../../utils/validate"
import { Attendance, AttendanceModel } from "../attendance"
import {
  ChatMessage,
  ChatMessageModel,
  sortByTheLastestMessage,
} from "../chat-message"
import {
  DirectChatMessages,
  DirectChatMessagesModel,
} from "../direct-chat-messages"
import {
  DirectChatMessagesMeta,
  DirectChatMessagesMetaModel,
} from "../direct-chat-messages/direct-chat-messages-meta"
import { VideoChatEventModel } from "../video-chat-event"
import { withEnvironment } from "../extensions"
import { Person, PersonModel, MemberStatus, ActivityStatus } from "../person"
import { SessionModel, Session } from "../session"
import { PaginationModel, MESSAGES_LIMIT } from "../pagination"
import * as customTypes from "../types"

enum ChatsLimit {
  Direct = MESSAGES_LIMIT,
  Group = MESSAGES_LIMIT,
}

export interface InboxItemType {
  newMessages?: number
  participant: Person
  latestMessage?: ChatMessage
  isFlagged?: boolean
}

export const CohortModel = types
  .model("Cohort")
  .extend(withEnvironment)
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    startDate: types.optional(customTypes.iso8601, moment().toDate()),
    programID: types.maybeNull(types.string),
    moduleID: types.maybeNull(types.string),
    motivation: types.maybeNull(types.string),
    sessions: types.optional(types.array(SessionModel), []),
    newSession: types.optional(SessionModel, () =>
      SessionModel.create({ id: "-1" }),
    ),
    currentSession: types.optional(SessionModel, () =>
      SessionModel.create({ id: "-1" }),
    ),
    coaches: types.optional(types.array(PersonModel), []),
    coachEmails: types.optional(types.array(types.string), []),
    participants: types.optional(types.array(PersonModel), []),
    participantEmails: types.optional(types.array(types.string), []),
    directChats: types.map(DirectChatMessagesModel),
    groupChat: types.optional(types.array(ChatMessageModel), []),
    groupChatMeta: types.maybeNull(
      types.optional(DirectChatMessagesMetaModel, () =>
        DirectChatMessagesMetaModel.create({}),
      ),
    ),
    gratitudePrompt: types.optional(types.boolean, false),
    exerciseWeightPrompt: types.optional(types.boolean, false),
    attendances: types.optional(types.array(types.string), []),
    attendanceHistory: types.optional(types.array(AttendanceModel), []),
    totalUnreadGroupMessages: types.optional(types.number, 0),
    videoChatEvents: types.optional(types.array(VideoChatEventModel), []),
    oneOnOneVideoChatEnabled: types.optional(types.boolean, false),
    currentRoomPagination: types.optional(PaginationModel, () =>
      PaginationModel.create({
        limit: ChatsLimit.Direct,
        messages: [],
      }),
    ),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setStartDate(value: Date) {
      self.startDate = value
    },
    setProgramID(value: string | null) {
      self.programID = value
    },
    setModuleID(value: string | null) {
      self.moduleID = value
    },
    setMotivation(value: string | null) {
      self.motivation = value
    },
    setCoachEmails(value: Array<string>) {
      self.coachEmails.replace(value)
    },
    appendCoaches(value: Array<Person>) {
      self.coaches.replace(uniqBy(prop("id"), [...self.coaches, ...value]))
    },
    removeCoach(coachId: string) {
      self.coaches.remove(self.coaches.find((c) => c.id === coachId))
    },
    setParticipantEmails(value: Array<string>) {
      self.participantEmails.replace(value)
    },
    setGratitudePrompt(value: boolean) {
      self.gratitudePrompt = value
    },
    setExerciseWeightPrompt(value: boolean) {
      self.exerciseWeightPrompt = value
    },
    appendParticipants(value: Array<Person>) {
      self.participants.replace(
        uniqBy(prop("id"), [...self.participants, ...value]),
      )
    },
    appendAttendance(value: string) {
      self.attendances.replace([...self.attendances, value])
    },
    removeAttendance(value: string) {
      self.attendances.remove(value)
    },
    clearAttendances() {
      self.attendances.clear()
    },
    appendAttendanceHistory(value: Attendance) {
      self.attendanceHistory.replace([...self.attendanceHistory, value])
    },
    setAttendanceHistory(value: Attendance[]) {
      self.attendanceHistory.replace(value)
    },
    removeAttendanceHistory(value: Attendance) {
      self.attendanceHistory.remove(value)
    },
    appendDirectChats(id: string, value: DirectChatMessages) {
      self.directChats.set(id, value)
    },
    replaceDirectChats(value: { [key: string]: DirectChatMessages }) {
      self.directChats.replace(value)
    },
    setGroupChat(value: Array<ChatMessage>) {
      self.groupChat.replace(value)
    },
    setGroupChatMeta(value: DirectChatMessagesMeta) {
      self.groupChatMeta = value
    },
    setTotalUnreadGroupMessages(value: number) {
      self.totalUnreadGroupMessages = value
    },
    appendGroupChat(value: ChatMessage) {
      self.groupChat.replace([...self.groupChat, value])
    },
    removeGroupChatMessage(messageId: string) {
      self.groupChat.remove(self.groupChat.find((c) => c.id === messageId))
    },
    appendSession(value: Session) {
      self.sessions.replace(uniqBy(prop("id"), [...self.sessions, value]))
    },
    removeSession(sessionId: string) {
      self.sessions.remove(self.sessions.find((s) => s.id === sessionId))
    },
    setCurrentSession(value: Session) {
      self.currentSession = clone(value)
    },
    resetGroupChat() {
      self.groupChat.clear()
    },
    resetNewSession() {
      self.newSession.reset()
    },
  }))
  .actions((self) => ({
    async apiUpdateCohortSettings(siteId: string) {
      const { api } = self.environment

      await api.patchCohort(self.id, {
        siteId,
        gratitudePrompt: self.gratitudePrompt,
        exerciseWeightPrompt: self.exerciseWeightPrompt,
      })
    },
    async apiSetAttendance({ siteId, sessionId }) {
      const { api } = self.environment
      const attendanceMap = self.attendanceHistory.reduce((prev, curr) => {
        prev[curr.membershipID] = curr.attended
        return prev
      }, {})

      const { data, kind } = await api.setAttendance({
        cohortId: self.id,
        siteId,
        sessionId,
        attendance: attendanceMap,
      })

      const selectedSession = self.sessions.find((s) => s.id === sessionId)
      if (Boolean(selectedSession)) {
        selectedSession.setAttendanceTaken(true)
      }

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
    async apiUpdateName(): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.patchCohort(self.id, {
        name: self.name,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
  }))
  .actions((self) => ({
    async apiCreateSession() {
      const { api } = self.environment
      const { data, kind } = await api.createSession({
        name: self.newSession.name,
        date: self.newSession.date,
        cohortId: self.id,
        moduleID: self.moduleID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const session = data as Session
      self.appendSession(session)
      self.resetNewSession()
    },
    async apiEditCurrentSession(): Promise<void> {
      const { api } = self.environment
      nProgress.start()

      const { kind, data } = await api.updateCohortSession({
        name: self.currentSession.name,
        date: self.currentSession.date,
        sessionId: self.currentSession.id,
        cohortId: self.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason ?? kind)
      }

      //update the current session on sessions list manually
      const session = self.sessions.find((s) => s.id === self.currentSession.id)
      session.setName(self.currentSession.name)
      session.setDate(self.currentSession.date)

      nProgress.done()
      return Promise.resolve()
    },
    async apiDeleteSession() {
      const { api } = self.environment
      const { data, kind } = await api.deleteSession({
        sessionId: self.currentSession.id,
        cohortId: self.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.removeSession(self.currentSession.id)
    },
    async apiUpdateCoachMotivation(motivation: string) {
      const { api } = self.environment
      const { kind } = await api.updateCoachMotivation(self.id, {
        motivation,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      //const selectedCoach = self.coaches.find((c) => c.personID === coachId)
      //selectedCoach?.setMyMotivation?.(myMotivation)
    },
    async apiDeleteChatMessage({
      messageId,
      isDirectChat,
      roomId,
    }: {
      messageId: string
      isDirectChat: boolean
      roomId?: string
    }) {
      const { api } = self.environment
      const { data, kind } = await api.deleteChatMessage({
        messageId,
      })

      if (kind !== "ok") {
        throw new Error(data?.reason)
      }

      if (isDirectChat) {
        self?.directChats.get(roomId)?.removeDirectChatMessage?.(messageId)
      } else {
        self.removeGroupChatMessage(messageId)
      }
    },
  }))
  .views((self) => ({
    get createCohortErrors() {
      return validate(CREATE_COHORT_VALIDATION_RULES, self)
    },
    get preventAccidentalModalClose() {
      // if there are more than 2 valid values,
      // then it is likely that the form has been modified
      // so we can prevent the user from accidently closing the modal.
      return Object.values(self).filter(Boolean).length > 2
    },
    get cohortName() {
      return moment(self.startDate).format("dddd h:mm A")
    },
    get formattedCohortStartDate() {
      return moment(self.startDate).format("ll")
    },
    get registeredParticipants() {
      return self.participants
        .filter((member) => member.memberStatus === MemberStatus.Registered)
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    },
    get invitedParticipants() {
      return self.participants
        .filter((member) => member.memberStatus === MemberStatus.Invited)
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    },
    get inactiveParticipants() {
      return self.participants.filter(
        (member) => member.activityStatus === ActivityStatus.Inactive,
      )
    },
    get inactiveMembersCount() {
      return this.inactiveParticipants.length
    },
    get inactiveCoachesCount() {
      return self.coaches.filter(
        (member) => member.memberStatus === MemberStatus.Invited,
      ).length
    },
    get directChatValuesAsArray() {
      return Array.from(self.directChats.values()).sort((chatA, chatB) => {
        const lastMessageA = chatA.messages[chatA.messages.length - 1]
        const lastMessageB = chatB.messages[chatB.messages.length - 1]

        if (!lastMessageA || !lastMessageB) return 0

        //@ts-expect-error
        return lastMessageB.createdAt - lastMessageA.createdAt
      })
    },
    get participantsUnreadMessages() {
      return Array.from(self.directChats.keys()).reduce((acc, nextKey) => {
        const nextDirectChat = self.directChats.get(nextKey)
        const { messages, getParticipantByRoomID, newMessages } = nextDirectChat
        const latestMessage = messages[messages.length - 1]

        const { roomID } = latestMessage
        const participant = getParticipantByRoomID(roomID)
        if (!participant) return acc
        acc[participant.id] = newMessages
        return acc
      }, {})
    },
    get sessionsOrderedByDateAsc() {
      return self.sessions.slice().sort((sessionA, sessionB) => {
        //@ts-expect-error
        return moment.unix(sessionA.date) - moment.unix(sessionB.date)
      })
    },
    getDirectChatByRoomId(roomId: string) {
      return self.directChats.get(roomId)
    },
    get titleForChat(): string {
      return [self.name, "Group Chat"].filter(Boolean).join(" ")
    },
    get totalUnreadDirectMessages() {
      return Array.from(self.directChats.values()).reduce((acc, nextChat) => {
        const { messages, getParticipantByRoomID, newMessages } = nextChat
        if (messages.length === 0) {
          return acc
        }
        const latestMessage = messages[messages.length - 1]

        const { roomID } = latestMessage
        const participant = getParticipantByRoomID(roomID)

        // Ignore the chats without messages or chats which have no participant
        // because these chats are also ignored on the direct chat list
        if (!Boolean(messages.length) || !Boolean(participant)) {
          return acc
        }
        return acc + newMessages
      }, 0)
    },
    //derived value for generating inbox list
    //map all registered participants, and for each of them get the chat from direct chat list
    //sort the list by the latest message in the end
    get directChatsInboxList(): InboxItemType[] {
      return this.registeredParticipants
        .map((participant: Person) => {
          const selectedChat = this.getDirectChatByRoomId(participant.personID)
          const { messages = [], newMessages } = selectedChat || {}
          const latestMessage = messages[messages.length - 1]

          return {
            newMessages,
            participant,
            latestMessage,
          }
        })
        .sort(sortByTheLastestMessage)
    },
  }))

const CREATE_COHORT_VALIDATION_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  startDate: {
    presence: { allowEmpty: false, message: "required" },
  },
  programID: {
    presence: { allowEmpty: false, message: "required" },
  },
  moduleID: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface Cohort extends Instance<typeof CohortModel> {}
export interface CohortSnapshot extends SnapshotOut<typeof CohortModel> {}
