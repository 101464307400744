import React, { useState } from "react"
import { Link, useRouteMatch } from "react-router-dom"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import { RouteParams } from "../../AppRoutes"
import { typography } from "../../services/theme/typography"
import { AdminDrawerListItem } from "./admin-drawer-list-item"

const useStyles = makeStyles((theme) =>
  createStyles({
    selectedListItem: {
      fontWeight: "bold",
      color: "#2D353B",
    },
    subCategoriesList: {
      marginBottom: theme.spacing(2.5),
    },
    subCategoryLabel: {
      fontSize: theme.spacing(2),
      color: "#677278",
      margin: 0,
      textAlign: "center",
      ...typography.circularXXRegular,
    },
    nestedListItem: {
      paddingLeft: theme.spacing(10),
    },
  }),
)

interface SubCategoryItemProps {
  to: string
  label: string
  categoryRoute?: string
  isNestedSubcategory?: boolean
  nestedSubCategories?: SubCategoryItemProps[]
}

const SubCategoryItem = ({
  label,
  to,
  nestedSubCategories,
  categoryRoute,
  isNestedSubcategory,
}: SubCategoryItemProps) => {
  const classes = useStyles()

  const routeConfig = useRouteMatch<RouteParams>(to)
  const isSelected = routeConfig?.isExact
  const [open, setOpen] = useState(isSelected)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem
        button
        component={Link}
        to={to}
        selected={isSelected}
        classes={{
          selected: classes.selectedListItem,
        }}
        className={isNestedSubcategory ? classes.nestedListItem : undefined}
        onClick={handleClick}>
        <ListItemText
          classes={{ primary: classes.subCategoryLabel }}
          primary={label}
        />
      </ListItem>
      {nestedSubCategories && (
        <Collapse in={open} timeout="auto">
          <List component="div" disablePadding>
            {nestedSubCategories.map((subcategory) => {
              return (
                <SubCategoryItem
                  key={subcategory.to}
                  {...subcategory}
                  to={`${categoryRoute}${subcategory.to}`}
                  isNestedSubcategory={true}
                />
              )
            })}
          </List>
        </Collapse>
      )}
    </>
  )
}

interface CategorySectionDropdownProps {
  categoryLabel: string
  categoryRoute: string
  defaultSectionRoute: string
  sectionRoutes: SubCategoryItemProps[]
}

export const CategorySectionDropdown = ({
  categoryLabel,
  categoryRoute,
  defaultSectionRoute,
  sectionRoutes,
}: CategorySectionDropdownProps) => {
  const classes = useStyles()

  const categorySectionTab = useRouteMatch<RouteParams>(
    `${categoryRoute}/:subcategory/:nestedSubcategory?`,
  )

  const isCategorySectionTabActive = categorySectionTab?.isExact

  return (
    <>
      <AdminDrawerListItem
        selected={isCategorySectionTabActive}
        label={categoryLabel}
        to={`${categoryRoute}${defaultSectionRoute}`}
      />

      <Collapse in={isCategorySectionTabActive} timeout="auto">
        <List
          component="div"
          disablePadding
          className={classes.subCategoriesList}>
          {sectionRoutes.map(({ to, label, nestedSubCategories }) => (
            <SubCategoryItem
              key={`${to}_${label}`}
              label={label}
              to={`${categoryRoute}${to}`}
              nestedSubCategories={nestedSubCategories}
              categoryRoute={categoryRoute}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}
