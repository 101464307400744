import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { PilotChatMeta, PilotChatMetaModel } from "../pilot-chat-meta"
import { Person } from "../person/person"
import { PaginationModel, MESSAGES_LIMIT } from "../pagination"
import {
  ChatMessage,
  ChatMessageModel,
  sortByTheLastestMessage,
} from "../chat-message"
import { InboxItemType } from "../cohort"
import { DirectChatMessagesMeta } from "../direct-chat-messages/direct-chat-messages-meta"
import { PilotCoachMetaModel, PilotCoachMeta } from "../pilot-coach-meta"
import { PilotCoachAddNewMessageParams } from "../../services/api/api.types"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { cloneObject } from "../../utils"
import { TrainPaginationModel } from "./train-pagination"
import { transformChatMeta } from "./utils"

const VERSION = 1

export const PilotTrainerStoreModel = types
  .model("PilotTrainerStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    coachGroupsMetaList: types.optional(types.array(PilotCoachMetaModel), []),
    currentGroupChatMeta: types.optional(PilotChatMetaModel, {}),
    loadingCurrentGroupChatMeta: types.optional(types.boolean, false),
    currentRoomPagination: types.optional(PaginationModel, () =>
      PaginationModel.create({
        limit: MESSAGES_LIMIT,
        messages: [],
      }),
    ),
    savedChats: types.optional(types.array(ChatMessageModel), []),
    trainEpisodesPagination: types.optional(TrainPaginationModel, () =>
      TrainPaginationModel.create(),
    ),
  })
  .actions((self) => ({
    setCoachGroupsMetaList(value: PilotCoachMeta[]) {
      self.coachGroupsMetaList.replace(value)
    },
    setCurrentGroupChatMeta(value: PilotChatMeta) {
      self.currentGroupChatMeta = value
    },
    setSavedChats(value: ChatMessage[]) {
      self.savedChats.replace(value)
    },
    setLoadingCurrentGroupChatMeta(value: boolean) {
      self.loadingCurrentGroupChatMeta = value
    },

    reset() {
      self.currentGroupChatMeta.reset()
    },
  }))
  .actions((self) => ({
    async apiGetPilotCoachGroupsMeta(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getPilotCoachGroupsMeta()
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setCoachGroupsMetaList(data)
    },
    async apiGetCurrentGroupMeta(groupID: string): Promise<void> {
      const { api } = self.environment
      if (self.loadingCurrentGroupChatMeta) {
        return
      }
      try {
        self.setLoadingCurrentGroupChatMeta(true)
        const { data, kind } = await api.getCurrentGroupMeta({ groupID })
        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        const transformedData = transformChatMeta(data[0])
        self.setCurrentGroupChatMeta(transformedData as PilotChatMeta)
        self.setLoadingCurrentGroupChatMeta(false)
      } catch (error) {
        self.setLoadingCurrentGroupChatMeta(false)
        throw error
      }
    },
    async apiGetPilotChatConversation({
      roomId,
      coachID,
    }: {
      roomId: string
      coachID: string
    }): Promise<void> {
      const { environment, currentRoomPagination } = self
      const { api } = environment

      const { data, kind } = await api.getPilotChatConversation({
        roomId,
        coachID,
        limit: currentRoomPagination.limit,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const messagesList = data.chats as ChatMessage[]
      currentRoomPagination.setChats(messagesList.reverse())
      const isEndReached = messagesList.length < currentRoomPagination?.limit
      currentRoomPagination.setIsEndReached(isEndReached)
    },
    async apiPilotCoachAddNewMessage(
      params: PilotCoachAddNewMessageParams,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachAddNewMessage(params)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentRoomPagination.unshiftChat(data)
      self.currentGroupChatMeta.replaceLastMessage(params.personID, data)
    },
    async apiPilotCoachDeleteMessage(messageId: string): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachDeleteMessage({
        messageId,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.currentRoomPagination.removeChat(messageId)
    },
    async apiGetPilotCoachSavedChats(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getPilotCoachSavedChats()
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setSavedChats(data.chats)
    },

    async apiPilotCoachSaveChat(chatMessage: ChatMessage): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachSaveChat({
        chatID: chatMessage.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setSavedChats([...self.savedChats, cloneObject(chatMessage)])
    },
    async apiPilotCoachDeleteSavedChat(
      chatMessage: ChatMessage,
    ): Promise<void> {
      const { environment } = self
      const { api } = environment
      const { data, kind } = await api.pilotCoachDeleteChat({
        chatID: chatMessage.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setSavedChats(
        removeObjectById<ChatMessage>(self.savedChats, chatMessage.id),
      )
    },
    async apiPilotCoachSearchTrainContent({
      query,
      next,
    }: {
      query: string
      next?: number
    }): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.pilotCoachSearchTrainContent({
        query,
        next,
        limit: self.trainEpisodesPagination.limit,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      //if paginating (next param is available), append episodes to the list
      if (next) {
        self.trainEpisodesPagination.addEpisodes(data.events)
      } else {
        self.trainEpisodesPagination.setEpisodes(data.events)
      }
      //if the result list is empty, it means the search is complete
      self.trainEpisodesPagination.setNext(
        data.events.length === 0 ? null : data.next,
      )
    },
  }))
  .views((self) => ({
    get chatsParticipantsList() {
      return Object.keys(
        self.currentGroupChatMeta?.participantDetails?.toJSON() || {},
      ).map((id) => {
        return self.currentGroupChatMeta?.participantDetails?.get(id) as Person
      })
    },
    get totalUnreadMessages() {
      return Object.values(
        self.currentGroupChatMeta.newMessages.toJSON(),
      ).reduce((acc, nextRoomCount) => acc + nextRoomCount, 0)
    },
    get flaggedParticipantConversations() {
      return self.savedChats.reduce((acc, nextChat) => {
        return {
          ...acc,
          [nextChat.author.id]: true,
        }
      }, {})
    },
  }))
  .views((self) => ({
    get chatRoomMeta() {
      return {
        participants: self.chatsParticipantsList,
      } as DirectChatMessagesMeta
    },
    get coachesMap(): { [id: string]: Person } {
      return self.coachGroupsMetaList.reduce((acc, { coach }) => {
        return {
          ...acc,
          [coach.personID]: coach,
        }
      }, {})
    },
    get pilotChatInboxList(): InboxItemType[] {
      return Object.keys(
        self?.currentGroupChatMeta?.lastMessage?.toJSON() || {},
      )
        .map((participantID) => {
          return {
            newMessages: self.currentGroupChatMeta.newMessages.get(
              participantID,
            ),
            participant: self.currentGroupChatMeta.participantDetails.get(
              participantID,
            ),
            latestMessage: self.currentGroupChatMeta.lastMessage.get(
              participantID,
            ),
            isFlagged: self.flaggedParticipantConversations[participantID],
          }
        })
        .sort(sortByTheLastestMessage)
    },
    get chatRoomMessagesList(): ChatMessage[] {
      return self.currentRoomPagination.messages.map((message) => ({
        ...message,
        isFlagged: self.savedChats.some((chat) => chat.id === message.id),
      }))
    },
    get currentGroupUnreadMessages(): { [id: string]: number } {
      return self.currentGroupChatMeta?.newMessages?.toJSON?.() || {}
    },
  }))

export interface PilotTrainerStore
  extends Instance<typeof PilotTrainerStoreModel> {}
export interface PilotTrainerStoreSnapshot
  extends SnapshotOut<typeof PilotTrainerStoreModel> {}
