import React, { useState } from "react"
import {
  Stack,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
  Typography,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { CustomSwitch } from "../custom-switch"

import { useStores } from "../../models/root-store"
import { MslEvent } from "../../models/msl-event"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { ReactComponent as DeleteSessionIcon } from "../../assets/images/delete_session_icon.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
    unpublishedButton: {
      backgroundColor: `${theme.palette.text.disabled} !important`,
    },

    switcherContainer: {
      display: "flex",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)

interface EventActionsProps {
  eventID: string
  openEditEventModal?: (event: MslEvent) => void
}

const EventActions = ({ eventID, openEditEventModal }: EventActionsProps) => {
  const [loadingDeleteEvent, toggleLoadingDeleteEvent] = useState(false)
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false)

  const classes = useStyles()

  const { adminMslEventStoreModel } = useStores()
  const {
    mslEventsPagination,
    apiAdminDeleteMslEvent,
  } = adminMslEventStoreModel
  const { mslEvents } = mslEventsPagination

  const event = mslEvents.find((event) => event.id === eventID)

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const openDeleteDialog = () => {
    toggleDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    toggleDeleteDialog(false)
  }

  const deleteEvent = async () => {
    try {
      closeDeleteDialog()
      toggleLoadingDeleteEvent(true)
      await apiAdminDeleteMslEvent(event.id)
      setSnackbarSeverity("success")
      setSnackbarText(`Successfully deleted ${event.name} event !`)
      setShowSnackbar(true)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      toggleLoadingDeleteEvent(false)
    }
  }

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      spacing={1}>
      <Button
        variant="contained"
        onClick={() => openEditEventModal(event)}
        className={classes.actionButton}>
        Edit
      </Button>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-delete-event-title">
        <DialogTitle id="alert-delete-event-title">
          Are you sure you want to delete {event?.name || ""} event ?
        </DialogTitle>

        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>

          <Button onClick={deleteEvent} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        onClick={openDeleteDialog}
        disabled={loadingDeleteEvent}
        className={classes.actionButton}>
        {loadingDeleteEvent ? (
          <CircularProgress color="secondary" size={24} />
        ) : (
          <DeleteSessionIcon width={18} height={18} />
        )}
      </IconButton>
    </Stack>
  )
}

const EventStatusAction = ({ eventID }: { eventID: string }) => {
  const classes = useStyles()

  const { adminMslEventStoreModel } = useStores()

  const {
    mslEventsPagination,
    apiAdminPublishMslEvent,
    apiAdminUnPublishMslEvent,
  } = adminMslEventStoreModel
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()
  const { mslEvents } = mslEventsPagination

  const event = mslEvents.find((event) => event.id === eventID)

  const togglePublishEvent = async () => {
    try {
      if (event?.isPublished) {
        await apiAdminUnPublishMslEvent(event.id)
      } else {
        await apiAdminPublishMslEvent(event.id)
      }
      setSnackbarSeverity("success")
      const publishStatus = event?.isPublished ? "published" : "unpublished"
      setSnackbarText(`Successfully ${publishStatus} ${event.name} event !`)
      setShowSnackbar(true)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  return (
    <div className={classes.switcherContainer}>
      <CustomSwitch
        checked={!!event?.isPublished}
        onChange={togglePublishEvent}
        activeLabel=""
        inActiveLabel=""
        labelSwitchSpacing={1}
        activeViewColor={"#00CC7E"}
        inActiveViewColor={"#808080"}
      />
      <Typography ml={1} className={classes.label}>
        {event?.isPublished ? "On" : "Off"}
      </Typography>
    </div>
  )
}

export { EventActions, EventStatusAction }
