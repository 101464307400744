import { UploadFile } from "../models/upload-file"
import {
  AdminUploadContentParams,
  AdminUploadContentParamsResult,
} from "../services/api/api.types"

export const adminUploadFile = async (
  apiAdminUploadContent: (
    params: AdminUploadContentParams,
    setProgress: (progressEvent: ProgressEvent<EventTarget>) => void,
  ) => Promise<AdminUploadContentParamsResult>,
  content: UploadFile,
) => {
  const { data, kind } = await apiAdminUploadContent(
    { file: content.file },
    ({ loaded, total }: { loaded: number; total: number }) => {
      const uploadPercentage = Math.round((loaded * 100) / total)

      if (uploadPercentage) {
        content.setUploadFileProgress(uploadPercentage)
      }
    },
  )

  if (kind !== "ok") {
    //@ts-ignore
    content.setUploadFileProgress(0)
    content.reset()

    throw new Error(data?.reason)
  }

  content.reset()
  return data.url
}
