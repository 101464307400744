import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { validate, ValidationRules } from "../../utils/validate"
import { PublishedVideo } from "../published-videos"
import { WhatVideoCategories } from "../what-videos-categories"

export const WhatVideosModel = types
  .model("WhatVideo")
  .props({
    id: types.identifier,
    categoryID: types.optional(types.maybeNull(types.string), ""),
    categoryName: types.optional(types.maybeNull(types.string), ""),
    videoID: types.optional(types.maybeNull(types.string), ""),
    videoName: types.optional(types.maybeNull(types.string), ""),
    sequenceNumber: types.optional(types.maybeNull(types.number), null),
    active: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setCategoryID(value: WhatVideoCategories) {
      self.categoryID = value?.id || null
    },
    setCategoryName(value: string) {
      self.categoryName = value
    },
    setVideoID(value: PublishedVideo) {
      self.videoID = value?.id || null
    },
    setVideoName(value: string) {
      self.videoName = value
    },
    setSequenceNumber(value: number) {
      self.sequenceNumber = Number(value)
    },
    setActive(value: boolean) {
      self.active = value
    },
    reset() {
      self.categoryID = ""
      self.categoryName = ""
      self.videoID = ""
      self.videoName = ""
      self.sequenceNumber = null
      self.active = false
    },
  }))
  .views((self) => ({
    get createWhatVideoErrors() {
      return validate(CREATE_NEW_VIDEOS_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_VIDEOS_RULES: ValidationRules = {
  categoryID: {
    presence: { allowEmpty: false, message: "required" },
  },
  videoID: {
    presence: { allowEmpty: false, message: "required" },
  },
  sequenceNumber: {
    presence: { allowEmpty: false, message: "required" },
  },
  active: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface WhatVideo extends Instance<typeof WhatVideosModel> {}
export interface WhatVideoSnapshot
  extends SnapshotOut<typeof WhatVideosModel> {}
