import React from "react"
import { observer } from "mobx-react-lite"
import { isEmpty } from "ramda"
import {
  FormControl,
  Grid,
  Stack,
  Paper,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Theme,
  FormHelperText,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { ValidationErrors } from "../../utils"
import { typography } from "../../services/theme/typography"

export enum ModalMode {
  ADD_NEW_EVENT,
  EDIT_EVENT,
}

interface FormModalProps {
  open: boolean
  modalTitle: string
  closeModal: () => void
  loading?: boolean
  submitted?: boolean
  saveButtonLabel?: string
  onSubmit?: () => void
  errors: ValidationErrors
  children?: React.ReactNode
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modalRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContainer: {
      width: 500,
      display: "flex",
      flexDirection: "column",
    },

    formRoot: {
      padding: theme.spacing(3, 5),
      outline: "none",
      width: "100%",
      height: "100%",
      maxHeight: "80vh",
      maxWidth: 500,
      overflow: "auto",
    },
    formTitle: {
      fontSize: theme.spacing(3.25),
      color: "#485359",
      ...typography.circularXXBold,
      marginBottom: theme.spacing(3),
    },
    formControl: {
      width: "100%",
    },
    formButtons: {
      padding: theme.spacing(2, 5),
      background: "#EBEEF0",
    },
    formError: {
      color: theme.palette.error.main,
    },
  }),
)

const FormModal = observer(
  ({
    open,
    closeModal,
    modalTitle,
    loading,
    submitted,
    errors,
    saveButtonLabel,
    onSubmit,
    children,
  }: FormModalProps) => {
    const classes = useStyles()

    const onCancel = () => {
      closeModal()
    }

    const handleModalClose = (event, reason) => {
      if (reason === "backdropClick") {
        onCancel()
      }
    }

    return (
      <Modal
        open={open}
        onClose={handleModalClose}
        classes={{
          root: classes.modalRoot,
        }}>
        <Paper className={classes.modalContainer}>
          <Grid container className={classes.formRoot}>
            <Grid item xs={12}>
              <Typography className={classes.formTitle}>
                {modalTitle}
              </Typography>

              {children}
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.formButtons}>
            <FormControl className={classes.formControl}>
              <Stack
                direction="row"
                justifyContent={"center"}
                flex={1}
                spacing={4}>
                <Button onClick={onCancel} variant="outlined" size="large">
                  Cancel
                </Button>

                <Button
                  endIcon={
                    loading && <CircularProgress color="secondary" size={24} />
                  }
                  onClick={onSubmit}
                  disabled={loading}
                  variant="contained"
                  size="large">
                  {saveButtonLabel || "Save"}
                </Button>
              </Stack>

              {submitted && !isEmpty(errors) && (
                <FormHelperText className={classes.formError}>
                  *Required fields are missing
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Paper>
      </Modal>
    )
  },
)
export { FormModal }
