import React from "react"
import { useHistory } from "react-router-dom"
import Stack from "@mui/material/Stack"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { useStores } from "../../models/root-store"
import { CustomSwitch, defaultTransitionDuration } from "../custom-switch"

const programViewColor = "#22878D"
const adminViewColor = "#1771A6"

const useStyles = makeStyles((theme) =>
  createStyles({
    switchContainer: {
      background: "#fff",
      padding: `${theme.spacing(2)}  ${theme.spacing(4)}`,
      borderRadius: theme.spacing(0.5),
    },
    switch: {
      height: theme.spacing(3),
    },
    disabledLabel: {
      fontSize: 16,
      lineHeight: "19px",
      color: "#B0BABF",
      letterSpacing: "0.02em",
    },
    programViewSelectedLabel: {
      color: programViewColor,
      fontWeight: "bold",
    },
    adminViewSelectedLabel: {
      color: adminViewColor,
      fontWeight: "bold",
    },
  }),
)

export const AdminViewSwitcher = () => {
  const classes = useStyles()
  const { push, location } = useHistory()
  const { loginStore, siteStore } = useStores()
  const { authentication } = loginStore

  const [isAdminView, toggleAdminView] = React.useState(
    location.pathname.includes("/ftadmin"),
  )
  const switchPage = () => {
    toggleAdminView(!isAdminView)
    //wait for the switch animation to finish before handling redirection
    setTimeout(() => {
      if (!isAdminView) {
        push(`/ftadmin/posts`)
      } else if (authentication.isFtAdmin) {
        push(`/site/${siteStore.currentSiteId}`)
      } else {
        push(`/site/${siteStore.currentSiteId}/cohorts`)
      }
    }, defaultTransitionDuration)
  }

  return (
    <>
      <Stack className={classes.switchContainer}>
        <CustomSwitch
          checked={isAdminView}
          onChange={switchPage}
          activeLabel="ADMIN VIEW"
          inActiveLabel="PROGRAM VIEW"
          activeViewColor={adminViewColor}
          inActiveViewColor={programViewColor}
          labelSwitchSpacing={1}
          switchCustomClass={classes.switch}
          labelCustomClass={classes.disabledLabel}
          inActiveLabelCustomClass={classes.programViewSelectedLabel}
          activeLabelCustomClass={classes.adminViewSelectedLabel}
        />
      </Stack>
    </>
  )
}
