import React, {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert, { AlertColor } from "@material-ui/core/Alert"

export const SnackBarContext = createContext({})
const AUTO_DISMISS = 5000

interface SnackbarProviderProps {
  children: React.ReactNode
}

interface SetAndShowSnackbarParams {
  text: string
  severity?: AlertColor
  show?: boolean
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarText, setSnackbarText] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>(
    "success",
  )

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return
    }

    setShowSnackbar(false)
  }

  const setAndShowSnackbar = ({
    text,
    severity = "success",
    show = true,
  }: SetAndShowSnackbarParams) => {
    setSnackbarText(text)
    setSnackbarSeverity(severity as AlertColor)
    setShowSnackbar(show)
  }

  return (
    <SnackBarContext.Provider
      value={{
        showSnackbar,
        setShowSnackbar,
        snackbarText,
        setSnackbarText,
        snackbarSeverity,
        setSnackbarSeverity,
        setAndShowSnackbar,
      }}>
      {children}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={AUTO_DISMISS}
        onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarText}
        </MuiAlert>
      </Snackbar>
    </SnackBarContext.Provider>
  )
}

interface Snackbars {
  showSnackbar?: boolean
  setShowSnackbar?: Dispatch<SetStateAction<boolean>>
  snackbarText?: string
  setSnackbarText?: Dispatch<SetStateAction<string>>
  snackbarSeverity?: AlertColor
  setSnackbarSeverity?: Dispatch<SetStateAction<AlertColor>>
  setAndShowSnackbar?: (params: SetAndShowSnackbarParams) => void
}

export function useSnackbars(): Snackbars {
  return useContext(SnackBarContext)
}
