import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as Sentry from "@sentry/react"
import { CohortStoreModel } from "../cohort-store"
import { LoginStoreModel } from "../login-store"
import { PersonStoreModel } from "../person-store"
import { SignupStoreModel } from "../signup-store"
import { SiteStoreModel } from "../site-store"
import { UiStoreModel } from "../ui-store"
import { MindsetTrainerEventsStoreModel } from "../mindset-trainer-events-store"
import { PilotTrainerStoreModel } from "../pilot-trainer-store"
import { AdminToolsStoreModel } from "../admin-tools-store"
import { AdminMslEventStoreModel } from "../admin-msl-events-store"

const VERSION = 1

/******************************************************************
 *
 *
 * RootStore Model
 *
 *
 * ***************************************************************/

export const RootStoreModel = types
  .model("RootStore")
  .props({
    version: VERSION,
    siteStore: types.optional(SiteStoreModel, () => SiteStoreModel.create()),
    loginStore: types.optional(LoginStoreModel, () => LoginStoreModel.create()),
    signupStore: types.optional(SignupStoreModel, () =>
      SignupStoreModel.create(),
    ),
    uiStore: types.optional(UiStoreModel, () => UiStoreModel.create()),
    cohortStore: types.optional(CohortStoreModel, () =>
      CohortStoreModel.create(),
    ),
    personStore: types.optional(PersonStoreModel, () =>
      PersonStoreModel.create(),
    ),
    mindsetTrainerEventsStore: types.optional(
      MindsetTrainerEventsStoreModel,
      () => MindsetTrainerEventsStoreModel.create(),
    ),
    pilotTrainerStore: types.optional(PilotTrainerStoreModel, () =>
      PilotTrainerStoreModel.create(),
    ),
    adminToolsStore: types.optional(AdminToolsStoreModel, () =>
      AdminToolsStoreModel.create(),
    ),
    adminMslEventStoreModel: types.optional(AdminMslEventStoreModel, () =>
      AdminMslEventStoreModel.create(),
    ),
  })
  .actions((self) => ({
    reset() {
      self.loginStore.reset()
      self.signupStore.reset()
      self.uiStore.reset()
      self.siteStore.reset()
      self.cohortStore.reset()
      self.personStore.reset()
      self.mindsetTrainerEventsStore.reset()
      self.pilotTrainerStore.reset()
      self.adminToolsStore.reset()
      self.adminMslEventStoreModel.reset()
      // clear the user form sentry
      Sentry.configureScope((scope) => scope.setUser(null))
    },
  }))

export interface RootStore extends Instance<typeof RootStoreModel> {}
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
