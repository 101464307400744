import React, { useState } from "react"
import {
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
  Modal,
  Chip,
  TextField,
  Button,
  Theme,
  CircularProgress,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import FaceIcon from "@material-ui/icons/Face"
import ErrorIcon from "@material-ui/icons/Error"
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
import { red } from "@material-ui/core/colors"
import { useHistory } from "react-router-dom"
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/core/Autocomplete"
import clsx from "clsx"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { validateEmail } from "../../utils/validate"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { ReactComponent as TickIcon } from "../../assets/images/tick.svg"
import { typography } from "../../services/theme/typography"

interface StyleProps {
  emails: Array<string>
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    position: "absolute",
    boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: 453,
      height: "auto",
      left: "50%",
      top: "50%",
      transform: (props) =>
        `translateX(calc(-50% + ${
          props.isDrawerOpen ? props.drawerWidth / 2 : 0
        }px)) translateY(-50%)`,
    },
  },
  iconContainer: {
    width: 81,
    height: 81,
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1),
    position: "absolute",
    top: 0,
    right: 0,
    background: theme.palette.secondary.main,
    borderRadius: `0px 10px 0px 1000px`,
  },
  addPersonIcon: {
    fontSize: theme.spacing(6),
    color: "#485359",
  },
  autoCompleteInputRoot: {
    alignItems: "flex-start",
    maxWidth: 345,
    '&.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: (props) =>
        props.emails?.length > 0 ? theme.spacing(3) : null,
      paddingBottom: (props) =>
        props.emails?.length > 0 ? theme.spacing(1) : null,
    },
  },
  formRoot: {
    padding: theme.spacing(3, 5, 3, 5.5),
    outline: "none",
    width: "100%",
    height: "100%",
  },
  formTitle: {
    maxWidth: 220,
    fontSize: theme.spacing(3.25),
    lineHeight: theme.spacing(4),
    color: "#485359",
    ...typography.circularXXMedium,
    marginBottom: theme.spacing(3),
  },
  formControl: {
    width: "100%",
  },
  autoCompleteLabel: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(2),
  },
  autoCompleteRoot: {
    minWidth: 345,
    padding: 0,
  },
  autoCompleteInput: {
    padding: theme.spacing(1),
    fontSize: theme.spacing(2.25),
    minWidth: "0px !important",
  },
  fieldInputNotchedOutline: {
    border: `1px solid ${theme.palette.common.black} !important`,
    borderRadius: 0,
  },
  activeTick: {
    fill: theme.palette.success.main,
  },
  formButtons: {
    padding: theme.spacing(2.5, 7),
    background: "#EBEEF0",
  },
  actionButton: {
    flex: 1,
  },
  chipColorSecondary: {},
  chipColorSecondaryError: {
    backgroundColor: red[400],
  },
}))

const AddCohortParticipants = observer(() => {
  const history = useHistory()
  const { cohortStore, uiStore } = useStores()
  const { isDrawerOpen } = uiStore
  const { currentCohort, apiAddParticipantsToCohort } = cohortStore
  const { participantEmails, setParticipantEmails } = currentCohort

  const [loading, setLoading] = useState(false)

  const classes = useStyles({
    drawerWidth,
    isDrawerOpen,
    emails: participantEmails,
  })

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const goBack = () => history.goBack()

  const handleClose = () => {
    //reset the participant list on modal close
    setParticipantEmails([])
    goBack()
  }

  const handleEmailsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    event.preventDefault()
    setParticipantEmails(value)
  }

  const onSaveCohort = async () => {
    try {
      setLoading(true)
      await apiAddParticipantsToCohort()
      // clear participants emails from form once added
      setParticipantEmails([])
      goBack()
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  const emailsListValue = currentCohort?.participantEmails?.slice?.() || []

  const body = (
    <Paper className={classes.paper}>
      <div className={classes.iconContainer}>
        <PersonAddAlt1Icon className={classes.addPersonIcon} />
      </div>

      <Grid container className={classes.formRoot}>
        <Grid item xs={12}>
          <Typography className={classes.formTitle}>
            Invite participants to your cohort
          </Typography>
        </Grid>

        <Stack spacing={2}>
          <Typography className={classes.autoCompleteLabel}>
            Press return to enter multiple addresses
          </Typography>

          <Stack direction="row" spacing={1}>
            <FormControl>
              <Autocomplete
                //@ts-ignore
                multiple={true}
                //@ts-ignore
                freeSolo={true}
                autoSelect={true}
                options={[]}
                value={emailsListValue}
                classes={{
                  inputRoot: classes.autoCompleteInputRoot,
                }}
                onChange={handleEmailsChange}
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option: any, index) => {
                    const isEmail = validateEmail(option)
                    return (
                      <Chip
                        icon={isEmail ? <FaceIcon /> : <ErrorIcon />}
                        disabled={!isEmail}
                        label={<>{option}</>}
                        color="secondary"
                        classes={{
                          colorSecondary: isEmail
                            ? classes.chipColorSecondary
                            : classes.chipColorSecondaryError,
                        }}
                        {...getTagProps({ index })}
                      />
                    )
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label=""
                    placeholder="Participant Email(s)"
                    multiline
                    rows={4}
                    maxRows={6}
                    InputProps={{
                      //@ts-ignore
                      ...params.InputProps,
                      classes: {
                        root: classes.autoCompleteRoot,
                        input: classes.autoCompleteInput,
                        notchedOutline: classes.fieldInputNotchedOutline,
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <TickIcon
              className={clsx({
                [classes.activeTick]: Boolean(emailsListValue.length),
              })}
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} className={classes.formButtons}>
        <FormControl className={classes.formControl}>
          <Stack direction="row" flex={1} spacing={4}>
            <Button
              onClick={handleClose}
              variant="outlined"
              size="large"
              className={classes.actionButton}>
              Cancel
            </Button>

            <Button
              endIcon={
                loading && <CircularProgress color="secondary" size={24} />
              }
              onClick={onSaveCohort}
              disabled={loading}
              variant="contained"
              size="large"
              className={classes.actionButton}>
              Send Invite(s)
            </Button>
          </Stack>
        </FormControl>
      </Grid>
    </Paper>
  )

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
})

export default AddCohortParticipants
