import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { isEmpty } from "ramda"
import {
  FormControl,
  Grid,
  Stack,
  Paper,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Chip,
  Theme,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { Entitlement } from "../../models/entitlement"
import { Coach } from "../../models/coach"
import { Program } from "../../models/program"
import { typography } from "../../services/theme/typography"
import { FormField, FormInput } from "../admin-msl-events-list/form-input"
import { FormSelect } from "../admin-msl-events-list/form-select"

interface CreateGroupModalProps {
  open: boolean
  closeModal: () => void
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modalRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContainer: {
      minWidth: 450,
      display: "flex",
      flexDirection: "column",
    },
    formRoot: {
      padding: theme.spacing(3, 5),
      outline: "none",
      width: "100%",
      height: "100%",
      maxWidth: 500,
      maxHeight: "90vh",
      overflow: "auto",
    },
    formTitle: {
      fontSize: theme.spacing(3.25),
      color: "#485359",
      ...typography.circularXXBold,
      marginBottom: theme.spacing(3),
    },
    formControl: {
      width: "100%",
    },
    formButtons: {
      padding: theme.spacing(2, 5),
      background: "#EBEEF0",
    },
  }),
)

const CreateGroupModal = observer(
  ({ open, closeModal }: CreateGroupModalProps) => {
    const classes = useStyles()

    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    const { adminMslEventStoreModel } = useStores()
    const {
      newNonDppGroup,
      coaches,
      programs: programsOptions,
      entitlements: entitlementsOptions,
      resetNewNonDppGroup,
      apiAdminCreateGroup,
    } = adminMslEventStoreModel
    const {
      name,
      setName,
      membershipDurationDays,
      setMembershipDurationDays,
      program,
      setProgram,
      coach,
      setCoach,
      entitlements = [],
      setEntitlements,
      createGroupErrors: errors,
    } = newNonDppGroup

    const handleModalClose = (event, reason) => {
      if (reason === "backdropClick") {
        resetNewNonDppGroup()
        closeModal()
      }
    }

    const handleEntitlementsChange = (
      event: React.SyntheticEvent<Element, Event>,
      value: Entitlement[],
    ) => {
      event.preventDefault()
      const areValidEntitlements = value.every((e) => e?.id)
      if (areValidEntitlements) {
        setEntitlements(value.slice())
      }
    }

    const onCancel = () => {
      resetNewNonDppGroup()
      closeModal()
    }

    const onSubmit = async () => {
      setSubmitted(true)
      if (!isEmpty(errors)) return
      setSubmitted(false)
      try {
        setLoading(true)
        await apiAdminCreateGroup()
        setSnackbarSeverity("success")
        setSnackbarText("Successfully created group !")
        setShowSnackbar(true)
        closeModal()
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      } finally {
        setLoading(false)
      }
    }

    return (
      <Modal
        open={open}
        onClose={handleModalClose}
        classes={{
          root: classes.modalRoot,
        }}>
        <Paper className={classes.modalContainer}>
          <Grid container className={classes.formRoot}>
            <Grid item xs={12}>
              <Typography className={classes.formTitle}>
                Create new group
              </Typography>
            </Grid>

            <Stack width="100%" spacing={2}>
              <FormField label="Name" required isFieldCompleted={!errors.name}>
                <FormInput
                  id="name"
                  placeholder="Enter group name"
                  value={name}
                  error={submitted && Boolean(errors.name)}
                  helperText={submitted && errors.name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </FormField>

              <FormField
                label="Membership Duration Days"
                required={false}
                isFieldCompleted>
                <FormInput
                  id="membershipDurationDays"
                  placeholder="Enter duration days number"
                  value={membershipDurationDays}
                  error={submitted && Boolean(errors.membershipDurationDays)}
                  helperText={submitted && errors.membershipDurationDays}
                  onChange={(evt) =>
                    setMembershipDurationDays(evt.target.value)
                  }
                />
              </FormField>

              <FormField label="Coach" required={false} isFieldCompleted>
                <FormSelect
                  options={coaches}
                  value={coach}
                  onChange={(evt, value: Coach) => setCoach(value)}
                  getOptionLabel={(option: Coach) => option.fullName}
                  Input={{ placeholder: "Select Coach" }}
                />
              </FormField>

              <FormField label="Program" required={false} isFieldCompleted>
                <FormSelect
                  options={programsOptions}
                  value={program}
                  onChange={(evt, value: Program) => setProgram(value)}
                  getOptionLabel={(option: Program) => option.name}
                  Input={{ placeholder: "Select Program" }}
                />
              </FormField>

              <FormField label="Entitlements" required={false} isFieldCompleted>
                <FormSelect
                  multiple
                  forbidDuplicates
                  options={entitlementsOptions.slice()}
                  value={entitlements.slice()}
                  onChange={handleEntitlementsChange}
                  getOptionLabel={(option: Entitlement) => option.name}
                  Input={{ placeholder: "Select Entitlements" }}
                  renderTags={(value, getTagProps) =>
                    value.map((option: Entitlement, index) => (
                      <Chip
                        label={option.name}
                        color="secondary"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </FormField>
            </Stack>
          </Grid>

          <Grid item xs={12} className={classes.formButtons}>
            <FormControl className={classes.formControl}>
              <Stack
                direction="row"
                justifyContent={"center"}
                flex={1}
                spacing={4}>
                <Button onClick={onCancel} variant="outlined" size="large">
                  Cancel
                </Button>

                <Button
                  endIcon={
                    loading && <CircularProgress color="secondary" size={24} />
                  }
                  onClick={onSubmit}
                  disabled={loading}
                  variant="contained"
                  size="large">
                  Create Group
                </Button>
              </Stack>
            </FormControl>
          </Grid>
        </Paper>
      </Modal>
    )
  },
)
export { CreateGroupModal }
