import React from "react"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { ProgramInvitation } from "../../models/program-invitation"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invitationCard: {
      background: "#F4FBFF",
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    invitationLabel: {
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "30px",
      color: "#2D353B",
    },
    invitationValue: {
      fontSize: 20,
      lineHeight: "30px",
      color: "#000",
    },
    revokeInvitationsButton: {
      fontSize: 20,
      lineHeight: "30px",
      color: "#B0BABF",
      padding: 0,
      "text-transform": "none",
    },
  }),
)

const InvitationCard = ({
  invitation,
  onRevokeInvitations,
}: {
  invitation: ProgramInvitation
  onRevokeInvitations: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const classes = useStyles()

  const getStatusColor = () => {
    switch (invitation.status) {
      case "accepted":
        return "#0A8052"
      case "pending":
        return "#039CAD"
      default:
        return "#000"
    }
  }

  return (
    <Stack spacing={1.5} className={classes.invitationCard}>
      <Stack spacing={0.1}>
        <Typography className={classes.invitationLabel}>
          Program Name
        </Typography>

        <Typography className={classes.invitationValue}>
          {invitation.program}
        </Typography>
      </Stack>

      <Stack spacing={0.1}>
        <Typography className={classes.invitationLabel}>
          Invitation Link
        </Typography>

        <Typography className={classes.invitationValue}>
          {invitation.link}
        </Typography>
      </Stack>

      <Stack spacing={0.1}>
        <Typography className={classes.invitationLabel}>Status</Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography
            className={classes.invitationValue}
            style={{ color: getStatusColor() }}>
            {invitation.status}
          </Typography>

          <Button
            className={classes.revokeInvitationsButton}
            onClick={onRevokeInvitations}>
            Revoke All Invitations
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default InvitationCard
