import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { PersonModel } from "../person"

export const DirectChatMessagesMetaModel = types
  .model("DirectChatMessagesMeta")
  .props({
    participants: types.optional(types.array(PersonModel), []),
    coaches: types.optional(types.array(PersonModel), []),
    siteAdmins: types.optional(types.array(PersonModel), []),
  })

export interface DirectChatMessagesMeta
  extends Instance<typeof DirectChatMessagesMetaModel> {}
export interface DirectChatMessagesMetaSnapshot
  extends SnapshotOut<typeof DirectChatMessagesMetaModel> {}
