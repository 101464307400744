import { roundToTwoDecimal } from "./helpers"

export const calculateSRBAIAverage = (srbiaScore: number) => {
  return roundToTwoDecimal(srbiaScore / 4)
}

export const getPBCProgressPerformance = (
  progress: number,
  showProgress?: boolean,
): "Low" | "Medium" | "High" | "" => {
  if (!showProgress) {
    return ""
  }
  if (progress < 5) {
    return "Low"
  }
  if (progress < 8) {
    return "Medium"
  }
  return "High"
}

export const getSRBAIProgressPerformance = (
  progress: number,
  showProgress?: boolean,
): "Low" | "Medium" | "High" | "" => {
  if (!showProgress) {
    return ""
  }
  if (progress < 3) {
    return "Low"
  }
  if (progress < 4) {
    return "Medium"
  }
  return "High"
}
