import React, { useCallback } from "react"
import { Link, useParams } from "react-router-dom"

import { Button, ButtonProps } from "@material-ui/core"

import { Person } from "../../models/person"
import { useStores } from "../../models/root-store"
import { RouteParams } from "../../AppRoutes"
import { useSnackbars } from "../../components/use-snackbar"

interface PilotChatRoomParams extends RouteParams {
  coachId: string
  groupId: string
}

const useMoreDetailsButton = (member: Person) => {
  const { personStore, cohortStore, loginStore } = useStores()
  const params = useParams<PilotChatRoomParams>()
  const { setAndShowSnackbar } = useSnackbars()

  const { authentication } = loginStore
  const { apiInviteParticipant } = cohortStore
  const { setCurrentPerson } = personStore

  const isMemberRegistered = Boolean(member.personID)

  const handleResendInvite = async (particpantID: string) => {
    try {
      await apiInviteParticipant(particpantID)
      setAndShowSnackbar({
        text: "Successfully resent invite ✅",
      })
    } catch (error) {}
  }

  const MoreDetailsButton = useCallback(
    (props: ButtonProps) => {
      if (!authentication.hasFullAccess) {
        return <div />
      }
      if (authentication.isPilotTrainer) {
        return (
          <Link
            to={`/pilot-trainer/${params.coachId}/group/${params.groupId}/members/${member.personID}`}>
            <Button
              {...props}
              //set current person on button click to avoid modal reload
              onClick={() => setCurrentPerson(member)}>
              More Details
            </Button>
          </Link>
        )
      }
      if (isMemberRegistered) {
        return (
          <Link
            to={`/site/${params.siteId}/cohorts/${params.cohortId}/members/${member.personID}`}>
            <Button
              {...props}
              //set current person on button click to avoid modal reload
              onClick={() => setCurrentPerson(member)}>
              More Details
            </Button>
          </Link>
        )
      }

      return (
        <Button {...props} onClick={() => handleResendInvite(member.id)}>
          Resend Invite
        </Button>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authentication.hasFullAccess,
      authentication.isPilotTrainer,
      isMemberRegistered,
      member.id,
    ],
  )

  return { MoreDetailsButton }
}

export default useMoreDetailsButton
