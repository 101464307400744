import { types, Instance, SnapshotOut } from "mobx-state-tree"

import { validate, ValidationRules } from "../../utils/validate"

export const TopicModel = types
  .model("Topic")
  .props({
    id: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    reset() {
      self.name = ""
    },
  }))
  .views((self) => ({
    get createMslTopicErrors() {
      return validate(CREATE_MSL_TOPIC_VALIDATION_RULES, self)
    },
  }))

const CREATE_MSL_TOPIC_VALIDATION_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface Topic extends Instance<typeof TopicModel> {}
export interface TopicSnapshot extends SnapshotOut<typeof TopicModel> {}
