import * as React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  colors,
} from "@material-ui/core"

import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant: "danger" | "info"
  title: string
  description: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    confirmDanger: {
      background: colors.red["600"],
      "&:hover": {
        background: colors.red["900"],
      },
    },
  }),
)

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { open, title, variant, description, onSubmit, onClose } = props
  const classes = useStyles({ variant })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          className={variant === "danger" && classes.confirmDanger}
          autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
