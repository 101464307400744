import React, { useState, useEffect } from "react"
import {
  Theme,
  Stack,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { isEmpty } from "ramda"
import EditIcon from "@material-ui/icons/Edit"
import {
  FormFields,
  CustomInputComponent,
  generateFieldsValues,
  isEmptyString,
  Field,
  FieldsValues,
} from "."
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: theme.spacing(0.5),
    },
    editIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.info.main,
    },
    buttonsColumn: {
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      padding: theme.spacing(1.5, 2),
      lineHeight: theme.spacing(2),
      ...typography.circularXXMedium,
    },
    saveButton: {
      padding: theme.spacing(1.5, 3.25),
      lineHeight: theme.spacing(2),
      border: `1px solid ${theme.palette.info.main}`,
      ...typography.circularXXMedium,
    },
    loadingProgress: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
  }),
)

interface FormInputsGroupProps {
  fields: Field[]
  updateFieldsValues: (values: FieldsValues) => void
  customValidation?: (fieldsValues: FieldsValues) => FieldsValues
}

const FormInputsGroup = ({
  fields,
  customValidation,
  updateFieldsValues,
}: FormInputsGroupProps) => {
  const classes = useStyles()
  const [isEditMode, toggleEditMode] = useState(false)
  const [fieldsValues, setFieldsValues] = useState<FieldsValues>(
    generateFieldsValues(fields),
  )
  const [fieldsErrors, setFieldsErrors] = useState({})
  const [isLoading, toggleLoading] = useState(false)

  const openEditMode = () => {
    toggleEditMode(true)
  }

  const closeEditMode = () => {
    toggleEditMode(false)
  }

  const onInputChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: FormFields,
  ) => {
    setFieldsValues({ ...fieldsValues, [fieldName]: evt.target.value })

    //reset field error on input change
    if (Boolean(fieldsErrors[fieldName])) {
      setFieldsErrors({ [fieldName]: "" })
    }
  }

  //handle field reset to current field value on edit mode close
  useEffect(() => {
    if (!isEditMode) {
      setFieldsValues(generateFieldsValues(fields))
    }
  }, [fields, isEditMode])

  //handle error reset on edit mode close
  useEffect(() => {
    if (!isEditMode) {
      setFieldsErrors({})
    }
  }, [isEditMode])

  const handleValidation = () => {
    const requiredFields = fields.filter((field) => field.required)
    if (requiredFields.length === 0 && !customValidation) {
      return { areFieldsValid: true }
    }
    const requiredErrors = requiredFields.reduce((acc, field) => {
      if (!isEmptyString(fieldsValues[field.name])) {
        return acc
      }
      return {
        ...acc,
        [field.name]: "Field is required!",
      }
    }, {})

    const customValidationErrors = customValidation?.(fieldsValues) || {}

    const newFieldsErrors = {
      ...requiredErrors,
      ...customValidationErrors,
    }
    setFieldsErrors(newFieldsErrors)

    return { areFieldsValid: isEmpty(newFieldsErrors) }
  }

  const onFieldSave = async () => {
    const { areFieldsValid } = handleValidation()

    if (!areFieldsValid) {
      return
    }

    try {
      toggleLoading(true)
      await updateFieldsValues(fieldsValues)
      toggleLoading(false)
      closeEditMode()
    } catch (error) {
      toggleLoading(false)
      throw error
    }
  }

  if (!isEditMode) {
    const firstField = fields[0]

    return (
      <CustomInputComponent
        label={firstField.label}
        fieldName={firstField.name}
        contentDirection="row"
        textFieldProps={{
          value: firstField.value,
          disabled: true,
        }}>
        <IconButton className={classes.editButton} onClick={openEditMode}>
          <EditIcon className={classes.editIcon} />
        </IconButton>
      </CustomInputComponent>
    )
  }

  return (
    <Stack spacing={2}>
      {fields.map((field) => (
        <CustomInputComponent
          key={field.name}
          label={field.label}
          fieldName={field.name}
          inputError={fieldsErrors[field.name]}
          contentDirection="column"
          textFieldProps={{
            value: fieldsValues[field.name],
            onChange: (e) => onInputChange(e, field.name),
          }}
        />
      ))}

      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        className={classes.buttonsColumn}>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={closeEditMode}>
          Cancel
        </Button>

        <Button
          variant="contained"
          className={classes.saveButton}
          disabled={isLoading}
          onClick={onFieldSave}>
          Save
          {isLoading && (
            <CircularProgress size={20} className={classes.loadingProgress} />
          )}
        </Button>
      </Stack>
    </Stack>
  )
}

export { FormInputsGroup }
