import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"

import { Entitlement, EntitlementModel } from "../entitlement"
import { Program, ProgramModel } from "../program"
import { Coach, CoachModel } from "../coach"
import { validate, ValidationRules } from "../../utils/validate"

export const GroupModel = types
  .model("Group")
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    membershipDurationDays: types.optional(
      types.maybeNull(types.union(types.string, types.number)),
      "",
    ),
    coach: types.maybeNull(CoachModel),
    program: types.maybeNull(ProgramModel),
    entitlements: types.optional(
      types.maybeNull(types.array(EntitlementModel)),
      [],
    ),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setMembershipDurationDays(value: string | number) {
      self.membershipDurationDays = value
    },
    setCoach(value: Coach) {
      self.coach = clone(value)
    },
    setEntitlements(value: Entitlement[]) {
      self.entitlements.replace(value.map((e) => clone(e)))
    },
    setProgram(value: Program) {
      self.program = clone(value)
    },

    reset() {
      self.name = ""
      self.membershipDurationDays = ""
      self.coach = null
      self.program = null
      self.entitlements.replace([])
    },
  }))
  .views((self) => ({
    get createGroupErrors() {
      return validate(CREATE_NEW_GROUP_RULES, self)
    },
    get membershipDurationDaysValue() {
      return parseInt(self.membershipDurationDays as string)
    },
    get coachID() {
      return self.coach?.id
    },
    get programID() {
      return self.program?.id
    },
    get entitlementsIDs() {
      return self.entitlements?.map((e) => e.id) || []
    },
  }))

const CREATE_NEW_GROUP_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  membershipDurationDays: {
    presence: { allowEmpty: true },
    //accepts only positive numbers and empty string
    format: {
      pattern: "^$|[0-9]+[0-9]*",
      flags: "i",
      message: "*please enter a valid number of days",
    },
  },
}

export interface Group extends Instance<typeof GroupModel> {}
export interface GroupSnapshot extends SnapshotOut<typeof GroupModel> {}
