import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme, CircularProgress } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { AdminTableHeader } from "../admin-table-header"
import { CreateGroupModal } from "./create-group-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
  }),
)

const AdminGroupsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateGroupModalOpen, toggleCreateGroupModalOpen] = useState(false)

  const { adminMslEventStoreModel } = useStores()
  const {
    nonDppGroups,
    apiAdminGetNonDppGroups,
    apiAdminGetEntitlements,
    apiAdminGetPrograms,
    apiAdminGetCoaches,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetNonDppGroups()
        await apiAdminGetEntitlements()
        await apiAdminGetPrograms()
        await apiAdminGetCoaches()
      } catch (e) {}
      toggleFirstLoading(false)
    }
    initialFetch()
  }, [
    apiAdminGetNonDppGroups,
    apiAdminGetEntitlements,
    apiAdminGetPrograms,
    apiAdminGetCoaches,
  ])

  const openCreateGroupModal = () => {
    toggleCreateGroupModalOpen(true)
  }

  const closeCreateGroupModal = () => {
    toggleCreateGroupModalOpen(false)
  }

  return (
    <div className={classes.root}>
      <LogsTable
        tableLabel="Groups"
        tableAriaLabel="groups-table"
        tablePaginationProps={{
          count: !!nonDppGroups ? nonDppGroups.length : 0,
        }}
        defaultRowsPerPage={25}
        CustomTableHeader={({ tableLabel }) => (
          <AdminTableHeader
            tableLabel={tableLabel}
            addNewButtonLabel="New group"
            addNewButtonOnClick={openCreateGroupModal}
          />
        )}>
        {({ page, rowsPerPage }) => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "16%" }} isStartEndPoint>
                    ID
                  </HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Name</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>
                    Membership Duration Days
                  </HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Coach</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Program</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Entitlements</HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(nonDppGroups.slice().length) ? (
                  nonDppGroups
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((group) => (
                      <TableRow key={group.id}>
                        <BodyCell isStartEndPoint>{group.id}</BodyCell>

                        <BodyCell>{group.name}</BodyCell>

                        <BodyCell>
                          {group.membershipDurationDays ||
                          group.membershipDurationDays === 0
                            ? group.membershipDurationDays
                            : "N/A"}
                        </BodyCell>

                        <BodyCell>{group.coach?.fullName || "N/A"}</BodyCell>

                        <BodyCell>{group.program?.name || "N/A"}</BodyCell>

                        <BodyCell>
                          {group.entitlements
                            ?.map((entitlement) => entitlement.name)
                            ?.join(", ") || "N/A"}
                        </BodyCell>
                      </TableRow>
                    ))
                ) : (
                  <Box pl={3} pt={3}>
                    {firstLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EmptyState title="When you create some groups, they will appear here" />
                    )}
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>

      <CreateGroupModal
        open={isCreateGroupModalOpen}
        closeModal={closeCreateGroupModal}
      />
    </div>
  )
})

export { AdminGroupsList }
