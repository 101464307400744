const ENV_BASE_URL = process.env.REACT_APP_REST_BASE_URL

export interface ApiConfig {
  url: string
  timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: ENV_BASE_URL || "https://devapi.freshtri.com/api/v2/pro",
  timeout: 60000,
}
