import React from "react"

import { Typography, Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { Note } from "../../models/note"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    gridCellTitle: {
      fontSize: theme.spacing(1.75),
      color: "#1871A6",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
    gridCellValue: {
      fontSize: theme.spacing(2),
      color: "#485359",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
    sectionLabel: {
      fontSize: theme.spacing(2.25),
      color: "#2D353B",
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      ...typography.circularXXMedium,
    },
  }),
)

interface NotesCellProps {
  title: Date
  value: string
}

const NotesCell = ({ title, value }: NotesCellProps) => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.gridCellTitle} mt={2}>
        {moment(title).format("DD.MM.YYYY")}
      </Typography>
      <Typography className={classes.gridCellValue}>{value}</Typography>
    </>
  )
}

interface NotesProps {
  notes: Note[]
}

export const Notes = ({ notes }: NotesProps) => {
  const classes = useStyles()
  const emptyNotes = notes.length === 0

  return (
    <Stack>
      <Typography className={classes.sectionLabel}>Notes</Typography>

      <Stack>
        {notes.map(({ noteText, updatedAt }) => (
          <NotesCell title={updatedAt} value={noteText} />
        ))}

        {emptyNotes && (
          <Typography className={classes.gridCellValue}>N/A</Typography>
        )}
      </Stack>
    </Stack>
  )
}
