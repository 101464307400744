import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const ChatMessageIdeaModel = types.model("ChatMessageIdea").props({
  id: types.identifier,
  active: types.optional(types.boolean, false),
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
})

export interface ChatMessageIdea extends Instance<typeof ChatMessageIdeaModel> {}
export interface ChatMessageIdeaSnapshot extends SnapshotOut<typeof ChatMessageIdeaModel> {}
