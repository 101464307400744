import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import { isNil } from "ramda"
import * as customTypes from "../types"

export const AttendanceModel = types
  .model("Attendance")
  .props({
    sessionID: types.maybeNull(types.string),
    sessionDate: types.optional(customTypes.iso8601, moment().toDate()),
    sessionName: types.maybeNull(types.string),
    attended: types.maybeNull(types.boolean),
    membershipID: types.maybeNull(types.string),
  })
  .actions((self) => ({
    toggleAttended: () => {
      self.attended = !self.attended
    },
  }))
  .views((self) => ({
    get formattedSessionDate() {
      return !!self.sessionDate
        ? moment(self.sessionDate).format("MMMM Do, YYYY")
        : ""
    },
    get formattedSessionTime() {
      return !!self.sessionDate
        ? moment(self.sessionDate).format("hh:mm A")
        : ""
    },

    get attendanceStatus(): string {
      return isNil(self.attended)
        ? "N/A"
        : self.attended
        ? "ATTENDED"
        : "ABSENT"
    },
  }))

export interface Attendance extends Instance<typeof AttendanceModel> {}
export interface AttendanceSnapshot
  extends SnapshotOut<typeof AttendanceModel> {}
