import React, { useState, useRef } from "react"
import moment from "moment"
import {
  Grid,
  Stack,
  Paper,
  Modal,
  Button,
  Theme,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { useHistory } from "react-router-dom"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"
import { ReactComponent as SuccessIcon } from "../../assets/images/success_icon.svg"
import { typography } from "../../services/theme/typography"

interface StyleProps {
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4.5, 9),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: "auto",
      minHeight: 258,
      left: "40%",
      top: "50%",
      transform: (props) =>
        `translateX(calc(-50% + ${
          props.isDrawerOpen ? props.drawerWidth / 2 : 0
        }px)) translateY(-50%)`,
    },
  },
  formTitle: {
    fontSize: theme.spacing(3),
    color: "#485359",
    lineHeight: "29px",
    ...typography.circularXXBold,
  },
  sessionName: {
    fontSize: theme.spacing(3),
    letterSpacing: "2px",
    color: "#22878D",
    textAlign: "center",
    lineHeight: "29px",
    ...typography.circularXXMedium,
  },
  sessionDate: {
    fontSize: theme.spacing(2),
    color: "#677278",
    textAlign: "center",
    lineHeight: "21px",
    ...typography.circularXXBook,
  },
  actionButton: {
    minWidth: 156,
    height: theme.spacing(5),
  },
  successTitle: {
    fontSize: theme.spacing(4.5),
    lineHeight: theme.spacing(3),
    color: "#0A8052",
    ...typography.circularXXMedium,
  },
  successSubTitle: {
    fontSize: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
}))

const DeleteSession = observer(() => {
  const history = useHistory()
  const { cohortStore, uiStore } = useStores()
  const { isDrawerOpen } = uiStore
  const { currentCohort } = cohortStore

  const { currentSession, apiDeleteSession } = currentCohort

  const [loading, setLoading] = useState(false)
  const [showDeletedMessage, toggleDeletedMessage] = useState(false)

  const closeTimerRef = useRef<ReturnType<typeof setInterval>>(null)

  const classes = useStyles({ drawerWidth, isDrawerOpen })

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const goBack = () => history.goBack()

  const handleClose = () => {
    //make sure to clear the timeout in case the user closes the modal before the timer does
    if (Boolean(closeTimerRef?.current)) {
      clearTimeout(closeTimerRef?.current)
    }
    goBack()
  }

  const onCancelPress = () => {
    handleClose()
  }

  /**
   * Method to handle after the delete api fires successfully
   * Activate `showDeletedMessage` to show the success message component
   * Set a timeout to 2 sec to close the popover
   */
  const handleSuccessDeletion = () => {
    toggleDeletedMessage(true)
    closeTimerRef.current = setTimeout(() => {
      handleClose()
    }, 2000)
  }

  const onDelete = async () => {
    try {
      setLoading(true)
      await apiDeleteSession()
      setSnackbarSeverity("success")
      setSnackbarText("Successfully deleted session")
      setShowSnackbar(true)
      handleSuccessDeletion()
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  const renderDeleteMessage = () => {
    return (
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Stack spacing={3} direction="row" alignItems="center">
          <Typography className={classes.successTitle}>Success!</Typography>

          <SuccessIcon />
        </Stack>

        <Typography className={classes.successSubTitle}>
          The session has been deleted
        </Typography>
      </Stack>
    )
  }

  const renderModalContent = () => {
    if (showDeletedMessage) {
      return renderDeleteMessage()
    }
    return (
      <Grid item xs={12}>
        <Stack spacing={3} alignItems="center">
          <Typography className={classes.formTitle}>
            Are you sure you want to delete this session?
          </Typography>

          <Stack spacing={2} alignItems="center">
            <Typography className={classes.sessionName}>
              {currentSession?.name}
            </Typography>

            <Typography className={classes.sessionDate}>
              {moment(currentSession?.date).format("MMMM Do YYYY - hh:mm A")}
            </Typography>
          </Stack>

          <Stack direction="row" flex={1} spacing={4}>
            <Button
              onClick={onCancelPress}
              variant="outlined"
              size="large"
              className={classes.actionButton}>
              Cancel
            </Button>

            <Button
              endIcon={
                loading && <CircularProgress color="secondary" size={24} />
              }
              onClick={onDelete}
              disabled={loading}
              variant="contained"
              size="large"
              className={classes.actionButton}>
              Delete Session
            </Button>
          </Stack>
        </Stack>
      </Grid>
    )
  }

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Paper className={classes.paper}>{renderModalContent()}</Paper>
      </Modal>
    </div>
  )
})

export default DeleteSession
