import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const AdminUserSearchModel = types
  .model("AdminUserSearch")
  .props({
    email: types.maybeNull(types.string),
    fname: types.maybeNull(types.string),
    lname: types.maybeNull(types.string),
    personID: types.string,
    userPostAllowed: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleUserBan() {
      self.userPostAllowed = !self.userPostAllowed
    },
  }))
  .views((self) => ({
    get isPersonBanned() {
      return !self.userPostAllowed
    },
  }))

export interface AdminUserSearch
  extends Instance<typeof AdminUserSearchModel> {}
export interface AdminUserSearchSnapshot
  extends SnapshotOut<typeof AdminUserSearchModel> {}
