import React, { useState } from "react"
import makeStyles from "@material-ui/styles/makeStyles"
import {
  Stack,
  Paper,
  Collapse,
  Theme,
  Table,
  TableContainer,
  TablePagination,
} from "@material-ui/core"
import { typography } from "../../services/theme/typography"
import { LogsTableHeader } from "./logs-table-header"

interface PaginationValues {
  page: number
  rowsPerPage: number
}

interface CustomTableHeaderProps {
  tableLabel?: string
  isExpanded?: boolean
  toggleExpanded?: () => void
}

interface LogsTableProps {
  defaultShowTable?: boolean
  tableAriaLabel: string
  tableLabel: string
  children: (value: PaginationValues) => React.ReactNode
  CustomTableHeader?: ({
    tableLabel,
    isExpanded,
    toggleExpanded,
  }: CustomTableHeaderProps) => React.ReactElement
  defaultRowsPerPage?: number
  tablePaginationProps?: {
    count: number
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    border: "1px solid #B0BABF",
    borderRadius: theme.spacing(1),
  },
  table: {
    minWidth: 650,
    border: 0,
    boxShadow: "none",
  },
  tableHeaderCell: {
    fontSize: theme.spacing(2.5),
    lineHeight: theme.spacing(3),
    color: theme.palette.text.secondary,
    padding: theme.spacing(2, 0),
    borderBottom: "2px solid #B0BABF",
    textAlign: "left",
    ...typography.circularXXBook,
  },
  tableRowCell: {
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(3),
    color: "#677278",
    textAlign: "left",
    borderBottom: "1px solid #EBEEF0",
    paddingLeft: 0,
    alignItems: "center",
    ...typography.circularXXBook,
  },
  tableStartCell: {
    padding: theme.spacing(2, 8),
  },
  paginationText: {
    color: "#485359",
    letterSpacing: "0.3px",
    fontSize: 14,
    lineHeight: "20px",
    ...typography.circularXXRegular,
  },
  paginationSelectText: {
    color: theme.palette.info.main,
  },
}))

const LogsTable = ({
  tableLabel,
  tableAriaLabel,
  tablePaginationProps,
  defaultShowTable = true,
  defaultRowsPerPage = 5,
  CustomTableHeader,
  children,
}: LogsTableProps) => {
  const classes = useStyles()
  const [showTable, toggleTable] = useState(defaultShowTable)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const toggleExpanded = () => toggleTable(!showTable)

  const renderTableHeader = () => {
    if (Boolean(CustomTableHeader)) {
      return (
        <CustomTableHeader
          tableLabel={tableLabel}
          isExpanded={showTable}
          toggleExpanded={toggleExpanded}
        />
      )
    }

    return (
      <LogsTableHeader
        tableLabel={tableLabel}
        isExpanded={showTable}
        toggleExpanded={toggleExpanded}
      />
    )
  }

  return (
    <Stack spacing={3} className={classes.container}>
      {renderTableHeader()}

      <Collapse in={showTable}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} aria-label={tableAriaLabel}>
              {children({ page, rowsPerPage })}
            </Table>
          </TableContainer>

          <TablePagination
            count={0}
            {...tablePaginationProps}
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              selectLabel: classes.paginationText,
              displayedRows: classes.paginationText,
              select: classes.paginationSelectText,
            }}
          />
        </Paper>
      </Collapse>
    </Stack>
  )
}

export { LogsTable }
