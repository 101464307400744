import React from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { ReactComponent as TriangleIcon } from "../../assets/images/triangle.svg"

const useStyles = makeStyles((theme) =>
  createStyles({
    nestedListItem: {
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      borderBottom: "1px solid #B0BABF",
      "&.Mui-selected": {
        background: "transparent",
      },
    },
    nestedListNameRoot: {
      flex: "none",
      margin: 0,
      marginRight: theme.spacing(1),
    },
    nestedListNamePrimary: {
      fontSize: 24,
      lineHeight: "28px",
      color: "#B0BABF",
      "font-weight": "500",
      margin: 0,
    },
    selectedListItem: {
      fontWeight: "bold",
      color: "#2D353B",
    },
  }),
)

export const AdminDrawerListItem = ({ selected, label, to }) => {
  const classes = useStyles()

  return (
    <ListItem
      selected={selected}
      to={to}
      component={Link}
      button
      className={classes.nestedListItem}>
      <ListItemText
        classes={{
          root: classes.nestedListNameRoot,
          primary: clsx(classes.nestedListNamePrimary, {
            [classes.selectedListItem]: selected,
          }),
        }}
        primary={label}
      />

      {selected && (
        <ListItemIcon>
          <TriangleIcon />
        </ListItemIcon>
      )}
    </ListItem>
  )
}
