import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"

export const ScoreModel = types.model("Score").props({
  score: types.maybeNull(types.number),
  createdAt: types.maybeNull(customTypes.iso8601),
})

export interface Score extends Instance<typeof ScoreModel> {}
export interface ScoreSnapshot extends SnapshotOut<typeof ScoreModel> {}
