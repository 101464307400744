import React from "react"

import moment from "moment"
import { Stack, Typography, Grid } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionLabel: {
      fontSize: theme.spacing(2.25),
      color: "#2D353B",
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      ...typography.circularXXMedium,
    },
    gridCellTitle: {
      fontSize: theme.spacing(1.75),
      color: "#1871A6",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
    gridCellValue: {
      fontSize: theme.spacing(2),
      color: "#485359",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
  }),
)

interface DatesProps {
  startDate: Date
  endDate: Date
}

export const Dates = ({ startDate, endDate }: DatesProps) => {
  const classes = useStyles()

  const gridItems = [
    {
      title: "Coaching Start Date",
      value: startDate ? moment(startDate).format("MM/DD/YYYY") : "N/A",
    },
    {
      title: "Coaching End Date",
      value: endDate ? moment(endDate).format("MM/DD/YYYY") : "N/A",
    },
  ]

  return (
    <Stack>
      <Typography className={classes.sectionLabel}>Dates</Typography>

      <Grid container spacing={2}>
        {gridItems.map(({ title, value }) => (
          <Grid item xs={12} sm={6}>
            <Typography className={classes.gridCellTitle}>{title}</Typography>
            <Typography className={classes.gridCellValue}>{value}</Typography>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
