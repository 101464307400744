import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

export const TagModel = types
  .model("Tag")
  .props({
    id: types.identifier,
    label: types.optional(types.maybeNull(types.string), ""),
    displayName: types.optional(types.maybeNull(types.string), ""),
    type: types.optional(types.maybeNull(types.string), ""),
    description: types.optional(types.maybeNull(types.string), ""),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
    isUserFacing: types.optional(types.maybeNull(types.boolean), false),
  })
  .actions((self) => ({
    setDescription(value: string) {
      self.description = value
    },
    setLabel(value: string) {
      self.label = value
    },
    setDisplayName(value: string) {
      self.displayName = value
    },
    setType(value: string) {
      self.type = value
    },
    setIsUserFacing(value: boolean) {
      self.isUserFacing = value
    },

    reset() {
      self.description = ""
      self.label = ""
      self.displayName = ""
      self.type = ""
      self.createdAt = null
      self.updatedAt = null
      self.isUserFacing = false
    },
  }))
  .views((self) => ({
    get createTagErrors() {
      return validate(CREATE_NEW_TAG_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_TAG_RULES: ValidationRules = {
  label: {
    presence: { allowEmpty: false, message: "required" },
  },
  displayName: {
    presence: { allowEmpty: false, message: "required" },
  },
  type: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface Tag extends Instance<typeof TagModel> {}
export interface TagSnapshot extends SnapshotOut<typeof TagModel> {}
