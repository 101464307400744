import React from "react"

import { Grid, Typography, Stack } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    conditionContainer: {
      background: "white",
      padding: theme.spacing(0.5, 2),
      borderRadius: theme.spacing(2),
      display: "flex",
      justifyContainer: "center",
      alignItems: "center",
    },
    conditionTitle: {
      fontSize: theme.spacing(2),
      color: "#2D353B",
      fontWeight: 400,
      ...typography.circularXXMedium,
    },
    sectionLabel: {
      fontSize: theme.spacing(2.25),
      color: "#2D353B",
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      ...typography.circularXXMedium,
    },
  }),
)

const Condition = ({ value }: { value: string }) => {
  const classes = useStyles()

  return (
    <Grid item xs="auto" className={classes.conditionContainer}>
      <Typography className={classes.conditionTitle}>{value}</Typography>
    </Grid>
  )
}

interface ConditionsProps {
  conditions: {
    id: string
    label: string
  }[]
}

export const Conditions = ({ conditions }: ConditionsProps) => {
  const classes = useStyles()

  return (
    <Stack>
      <Typography className={classes.sectionLabel}>Conditions</Typography>
      {conditions[0] ? (
        <Grid container columnGap={1} rowGap={1}>
          {conditions.map(({ label }) => (
            <Condition value={label} />
          ))}
        </Grid>
      ) : (
        <Typography className={classes.conditionTitle}>N/A</Typography>
      )}
    </Stack>
  )
}
