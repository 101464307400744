import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"

export const VideoChatEventModel = types
  .model("VideoChatEvent")
  .props({
    id: types.maybeNull(types.string),
    coachID: types.maybeNull(types.string),
    participantID: types.maybeNull(types.string),
    sessionDescription: types.maybeNull(types.string),
    scheduledStartTime: types.optional(customTypes.iso8601, moment().toDate()),
  })
  .views((self) => ({
    get formattedStartTime() {
      return !!self.scheduledStartTime
        ? moment(self.scheduledStartTime).format("MMMM Do, YYYY")
        : ""
    },
  }))

export interface VideoChatEvent extends Instance<typeof VideoChatEventModel> {}
export interface VideoChatEventSnapshot
  extends SnapshotOut<typeof VideoChatEventModel> {}
