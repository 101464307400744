import { types, Instance, SnapshotOut } from "mobx-state-tree"

export enum AuthRoles {
  SiteAdmin = "SiteAdmin",
  FtAdmin = "FTAdmin",
  Coach = "Coach",
  User = "User",
  MindsetTrainer = "MindsetTrainer",
  FTCoach = "FTCoach",
}

const AuthRolesTypeLiterals = Object.values(AuthRoles).map((v) =>
  types.literal(v),
)

export const AuthenticationModel = types
  .model("Authentication")
  .props({
    tokenType: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    expiresIn: types.maybeNull(types.number),
    roles: types.optional(types.array(types.union(...AuthRolesTypeLiterals)), [
      AuthRoles.SiteAdmin,
    ]),
  })
  .views((self) => ({
    get isSiteAdmin() {
      return self.roles.includes(AuthRoles.SiteAdmin)
    },
    get isFtAdmin() {
      return self.roles.includes(AuthRoles.FtAdmin)
    },
    get isCoach() {
      return self.roles.includes(AuthRoles.Coach)
    },
    get isMindsetTrainer() {
      return self.roles.includes(AuthRoles.MindsetTrainer)
    },
    get isPilotTrainer() {
      return self.roles.includes(AuthRoles.FTCoach)
    },
    get roleTitle() {
      if (this.isSiteAdmin) {
        return AuthRoles.SiteAdmin
      } else if (this.isFtAdmin) {
        return AuthRoles.FtAdmin
      } else if (this.isCoach) {
        return AuthRoles.Coach
      } else if (this.isMindsetTrainer) {
        return "Mindset Trainer"
      } else {
        return ""
      }
    },
    get canCreateOrDeleteCohort() {
      return this.isSiteAdmin
    },
    get canCreateOrDeleteSite() {
      return this.isFtAdmin
    },
    get hasFullAccess() {
      return !this.isFtAdmin
    },
  }))

export interface Authentication extends Instance<typeof AuthenticationModel> {}
export interface AuthenticationSnapshot
  extends SnapshotOut<typeof AuthenticationModel> {}
