import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const PilotChatGroupModel = types
  .model("PilotChatGroup")
  .props({
    id: types.identifier,
    name: types.string,
    newMessages: types.optional(types.map(types.number), {}),
  })
  .views((self) => ({
    get totalUnreadMessages() {
      return Object.values(self.newMessages.toJSON()).reduce(
        (acc, nextRoomCount) => acc + nextRoomCount,
        0,
      )
    },
  }))

export interface PilotChatGroup extends Instance<typeof PilotChatGroupModel> {}
export interface PilotChatGroupSnapshot
  extends SnapshotOut<typeof PilotChatGroupModel> {}
