import React from "react"
import { observer } from "mobx-react-lite"

import { ValidationErrors } from "../../utils"
import { FormField } from "../admin-form-components"
import { FormComponent, FormComponentProps } from "./form-component"

interface FormInputType
  extends Pick<
    FormComponentProps,
    "fieldName" | "fieldType" | "componentProps"
  > {
  label: string
  onChangeMethod?: string
  required?: boolean
  showOnlyOnEditMode?: boolean
}

interface AdminConfigFormProps<ValuesType> {
  formConfig: FormInputType[]
  values: ValuesType
  errors?: ValidationErrors
  submitted?: boolean
  isEditMode?: boolean
}

const AdminConfigForm = observer(
  <ValuesType extends {}>({
    formConfig,
    values,
    errors,
    submitted,
    isEditMode,
  }: AdminConfigFormProps<ValuesType>) => {
    return (
      <>
        {formConfig.map(
          ({
            label,
            required,
            fieldName,
            onChangeMethod,
            fieldType,
            showOnlyOnEditMode,
            componentProps,
          }) => {
            if (showOnlyOnEditMode && !isEditMode) {
              return <></>
            }
            return (
              <FormField
                label={label}
                required={required}
                isFieldCompleted={errors && !errors[fieldName]}
                key={fieldName}>
                <FormComponent
                  fieldType={fieldType}
                  fieldName={fieldName}
                  fieldValue={values[fieldName]}
                  error={errors ? errors[fieldName] : ""}
                  onChange={values[onChangeMethod]}
                  submitted={submitted}
                  componentProps={componentProps}
                />
              </FormField>
            )
          },
        )}
      </>
    )
  },
)

export { AdminConfigForm }
