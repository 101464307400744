import React from "react"
import { observer } from "mobx-react-lite"
import { Button, Theme, Typography } from "@material-ui/core"
import clsx from "clsx"

import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { ReactComponent as DropDownArrowIcon } from "../../assets/images/drop_down_arrow.svg"
import { typography } from "../../services/theme/typography"

interface LogsHeaderProps {
  tableLabel: string
  isExpanded: boolean
  toggleExpanded: () => void
  buttonContainerClass?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logsTableWrapper: {
      width: 248,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 0,
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    downArrow: {
      transform: "rotate(-180deg)",
    },
  }),
)

export const LogsTableHeader = observer(
  ({
    tableLabel,
    buttonContainerClass,
    isExpanded,
    toggleExpanded,
  }: LogsHeaderProps) => {
    const classes = useStyles()

    return (
      <Button
        className={clsx(classes.logsTableWrapper, buttonContainerClass)}
        onClick={toggleExpanded}>
        <Typography className={classes.tableLabel}>{tableLabel}</Typography>

        {isExpanded ? (
          <DropDownArrowIcon className={classes.downArrow} />
        ) : (
          <DropDownArrowIcon />
        )}
      </Button>
    )
  },
)
