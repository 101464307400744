import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { GridColDef, GridCellProps } from "@mui/x-data-grid"
import { Theme, Stack, Button, Typography } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"

import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"
import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CreateWhatVideosModal, ModalMode } from "./create-what-videos-modal"
import { CellComponent } from "./cell-component"
import { WhatVideo } from "../../models/what-videos"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    columnHeader: {
      flex: 1,
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
    tableCell: {
      width: "30%",
      maxWidth: "30%",
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },
  }),
)

const AdminWhatVideosList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateWhatVideoModalOpen, toggleCreateWhatVideoModalOpen] = useState(
    false,
  )
  const [whatVideoMode, setWhatVideoModalMode] = useState<ModalMode>(
    ModalMode.ADD_NEW_WHAT_VIDEO,
  )

  const { adminMslEventStoreModel } = useStores()
  const {
    whatVideos,
    setNewWhatVideo,
    resetNewWhatVideo,
    apiAdminGetWhatVideos,
    apiAdminGetWhatVideoCategories,
    apiAdminGetPublishedVideos,
  } = adminMslEventStoreModel

  const openCreateWhatVideoModal = () => {
    resetNewWhatVideo()
    setWhatVideoModalMode(ModalMode.ADD_NEW_WHAT_VIDEO)
    toggleCreateWhatVideoModalOpen(true)
  }

  const closeCreateWhatVideoModal = () => {
    toggleCreateWhatVideoModalOpen(false)
  }

  const openEditWhatVideoModal = useCallback((event: WhatVideo) => {
    setNewWhatVideo(event)
    setWhatVideoModalMode(ModalMode.EDIT_WHAT_VIDEO)
    toggleCreateWhatVideoModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const initialFetch = async () => {
      await apiAdminGetWhatVideos()
      await apiAdminGetWhatVideoCategories()
      await apiAdminGetPublishedVideos()
      toggleFirstLoading(false)
    }
    initialFetch()
  }, [
    apiAdminGetWhatVideos,
    apiAdminGetWhatVideoCategories,
    apiAdminGetPublishedVideos,
  ])

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditWhatVideoModal={openEditWhatVideoModal}
          videoID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
    },
    {
      field: "categoryName",
      headerName: "Category Name",
      sortable: false,
    },
    {
      field: "videoName",
      headerName: "Video Name",
    },
    {
      field: "sequenceNumber",
      headerName: "Sequence Number",
    },
    {
      field: "active",
      headerName: "Is Active ?",
    },
    {
      field: "edit",
      headerName: "Edit",
    },
  ]

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>What Videos</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateWhatVideoModal}>
          Add Video
        </Button>
      </Stack>

      <CustomGridTable
        rows={whatVideos.slice()}
        columns={columns}
        aria-label="what-videos-table"
        cell={MemoTableCell}
        emptyStateLabel="When you create some What Videos, they will appear here"
        loading={firstLoading}
        classes={{
          columnHeader: classes.columnHeader,
        }}
      />

      <CreateWhatVideosModal
        open={isCreateWhatVideoModalOpen}
        closeModal={closeCreateWhatVideoModal}
        mode={whatVideoMode}
      />
    </div>
  )
})

export { AdminWhatVideosList }
