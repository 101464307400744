import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const PublishedVideosModel = types.model("PublishedVideo").props({
  id: types.identifier,
  name: types.optional(types.maybeNull(types.string), ""),
})

export interface PublishedVideo extends Instance<typeof PublishedVideosModel> {}
export interface PublishedVideoSnapshot
  extends SnapshotOut<typeof PublishedVideosModel> {}
