import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const ConditionOptionModel = types.model("ConditionOption").props({
  id: types.identifier,
  optionText: types.maybeNull(types.string),
  optionType: types.maybeNull(types.string),
})

export interface ConditionOption
  extends Instance<typeof ConditionOptionModel> {}
export interface ConditionOptionSnapshot
  extends SnapshotOut<typeof ConditionOptionModel> {}
