import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const CoachModel = types
  .model("Coach")
  .props({
    id: types.identifier,
    firstName: types.optional(types.maybeNull(types.string), ""),
    lastName: types.optional(types.maybeNull(types.string), ""),
    avatarURL: types.optional(types.maybeNull(types.string), ""),
    description: types.optional(types.maybeNull(types.string), ""),
    intro: types.optional(types.maybeNull(types.string), ""),
    strengths: types.optional(types.maybeNull(types.array(types.string)), []),
    credentials: types.optional(types.maybeNull(types.array(types.string)), []),
  })
  .views((self) => ({
    get fullName() {
      return `${self.firstName || ""} ${self.lastName || ""}`
    },
  }))

export interface Coach extends Instance<typeof CoachModel> {}
export interface CoachSnapshot extends SnapshotOut<typeof CoachModel> {}
