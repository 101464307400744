import React from "react"
import ReactDOM from "react-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import { init as sentryInit } from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import "./index.css"
import "nprogress/nprogress.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "react-perfect-scrollbar/dist/css/styles.css"

sentryInit({
  dsn:
    "https://f0c084cb4e324b13b2a63101dcf8c00f@o475345.ingest.sentry.io/5513313",
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
