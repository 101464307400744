import React from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Typography } from "@material-ui/core"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import {
  FormInput,
  FormSwitch,
  FormInputsGroup,
  FormFields,
  isEmptyString,
  FieldsValues,
} from "../profile-components"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: "#fff",
      marginTop: theme.spacing(3),
      flex: 1,
    },
    labelContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: `${theme.spacing(3)} 0`,
    },
    label: {
      fontWeight: "bold",
      fontSize: 24,
      color: "#039CAD",
    },
    listContent: {
      width: "100%",
      padding: `0 ${theme.spacing(3)}`,
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      position: "relative",
      alignSelf: "center",
    },
    loadingFlag: {
      alignSelf: "center",
    },
  }),
)

export const AdminUpdatePersonDetails = observer(() => {
  const classes = useStyles()
  const { adminToolsStore } = useStores()

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const {
    selectedPerson,
    apiAdminUpdatePerson,
    apiAdminUpdatePersonProfile,
  } = adminToolsStore
  const { person } = selectedPerson

  const updatePersonDetails = async (values: FieldsValues) => {
    try {
      await apiAdminUpdatePerson(values)
      setSnackbarSeverity("success")
      setSnackbarText("Successfully updated person details")
      setShowSnackbar(true)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  const updateSmsAllowedAttribute = async (newFieldValue: boolean) => {
    try {
      await apiAdminUpdatePersonProfile({
        smsAllowed: newFieldValue,
      })
      setSnackbarSeverity("success")
      setSnackbarText("Successfully updated person details")
      setShowSnackbar(true)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  return (
    <Stack className={classes.container}>
      <Stack className={classes.labelContainer}>
        <Typography className={classes.label}>Edit Person Details</Typography>
      </Stack>

      <Stack spacing={3} className={classes.listContent}>
        <FormInput
          label="First Name"
          fieldName={FormFields.FirstName}
          fieldValue={person.firstName}
          updateFieldValue={(fieldName, newValue) =>
            updatePersonDetails({ [fieldName]: newValue })
          }
          required
          showSubmitButtonsColumn
          textFieldProps={{
            placeholder: "Enter your first name here",
          }}
        />

        <FormInput
          label="Last Name"
          fieldName={FormFields.LastName}
          fieldValue={person.lastName}
          updateFieldValue={(fieldName, newValue) =>
            updatePersonDetails({ [fieldName]: newValue })
          }
          required
          showSubmitButtonsColumn
          textFieldProps={{
            placeholder: "Enter your last name here",
          }}
        />

        <FormInput
          label="Email"
          fieldName={FormFields.Email}
          fieldValue={person.email}
          updateFieldValue={(fieldName, newValue) =>
            updatePersonDetails({ [fieldName]: newValue })
          }
          required
          showSubmitButtonsColumn
          textFieldProps={{
            placeholder: "Enter your email here",
          }}
        />

        <FormInput
          label="Zip code"
          fieldName={FormFields.ZipCode}
          fieldValue={person.zipCode}
          updateFieldValue={(fieldName, newValue) =>
            updatePersonDetails({ [fieldName]: newValue })
          }
          showSubmitButtonsColumn
          textFieldProps={{
            placeholder: "Enter your zip code here",
          }}
        />

        <FormInputsGroup
          fields={[
            {
              name: FormFields.MobilePhone,
              value: person.mobilePhone,
              label: "Phone number",
            },
            {
              name: FormFields.CountryCode,
              value: person.countryCode,
              label: "Country code",
            },
          ]}
          updateFieldsValues={updatePersonDetails}
          customValidation={(values) => {
            if (
              !isEmptyString(values[FormFields.MobilePhone]) &&
              isEmptyString(values[FormFields.CountryCode])
            ) {
              return {
                [FormFields.CountryCode]: "Country code is required!",
              }
            }

            if (
              isEmptyString(values[FormFields.MobilePhone]) &&
              !isEmptyString(values[FormFields.CountryCode])
            ) {
              return {
                [FormFields.MobilePhone]: "Phone number is required!",
              }
            }
            return {}
          }}
        />

        <FormSwitch
          label="Are sms allowed ?"
          fieldName={FormFields.SmsAllowed}
          fieldValue={person.profile.smsAllowed}
          updateFieldValue={updateSmsAllowedAttribute}
          showSubmitButtonsColumn
        />

        <div />
      </Stack>
    </Stack>
  )
})
