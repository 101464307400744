import React from "react"
import { Stack } from "@material-ui/core"
import moment from "moment"

import { CustomSwitch, CustomSwitchProps } from "../custom-switch"
import { FilePicker, FilePickerProps } from "../file-picker"
import {
  FormInput,
  FormInputProps,
  FormSelect,
  FormSelectProps,
  DateTimeInput,
  DateTimeInputProps,
} from "../admin-form-components"

export enum FieldTypes {
  TextInput,
  Switch,
  Select,
  DateTimePicker,
  FilePicker,
}

type FieldValueType =
  | FormInputProps["value"]
  | CustomSwitchProps["checked"]
  | FormSelectProps["value"]
  | DateTimeInputProps["value"]

interface FilePickerComponentProps extends FormInputProps {
  filePickerProps: FilePickerProps
}

export interface FormComponentProps {
  fieldName: string
  fieldType: FieldTypes
  fieldValue: FieldValueType
  error?: string | {} | null
  onChange: (newValue: FieldValueType) => void
  submitted: boolean
  componentProps?:
    | FormInputProps
    | CustomSwitchProps
    | FormSelectProps
    | DateTimeInputProps
    | FilePickerComponentProps
}

const FormComponent = ({
  fieldType,
  fieldName,
  fieldValue,
  error,
  onChange,
  submitted,
  componentProps,
}: FormComponentProps) => {
  switch (fieldType) {
    case FieldTypes.TextInput:
      return (
        <FormInput
          id={fieldName}
          value={fieldValue}
          error={submitted && Boolean(error)}
          helperText={submitted && error}
          onChange={(evt) =>
            onChange(evt.target.value as FormInputProps["value"])
          }
          {...(componentProps as FormInputProps)}
        />
      )
    case FieldTypes.Switch:
      return (
        <CustomSwitch
          checked={fieldValue as CustomSwitchProps["checked"]}
          onChange={(e?: React.ChangeEvent) => {
            onChange(!fieldValue as CustomSwitchProps["checked"])
          }}
          activeLabel=""
          inActiveLabel=""
          labelSwitchSpacing={0.01}
          activeViewColor="#0A8052"
          inActiveViewColor="#677278"
          {...(componentProps as CustomSwitchProps)}
        />
      )
    case FieldTypes.Select:
      return (
        <FormSelect
          value={fieldValue as FormSelectProps["value"]}
          id={fieldName}
          forbidDuplicates
          disableClearable={false}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: FormSelectProps["value"],
          ) => {
            event?.preventDefault?.()
            onChange && onChange(value)
          }}
          {...(componentProps as FormSelectProps)}
          Input={{
            error: submitted && Boolean(error),
            helperText: submitted && error,
            ...(componentProps as FormSelectProps)?.Input,
          }}
        />
      )

    case FieldTypes.DateTimePicker:
      return (
        <DateTimeInput
          value={fieldValue as DateTimeInputProps["value"]}
          onChange={onChange}
          {...(componentProps as DateTimeInputProps)}
          InputProps={{
            placeholder: "Choose Date & Time",
            value: fieldValue
              ? moment(fieldValue).format("MM/DD/YYYY hh:mm A")
              : "",
            error: submitted && Boolean(error),
            helperText: submitted && error,
            ...(componentProps as DateTimeInputProps)?.InputProps,
          }}
        />
      )
    case FieldTypes.FilePicker:
      return (
        <Stack width={"100%"}>
          <FormInput
            id={fieldName}
            value={fieldValue}
            error={submitted && Boolean(error)}
            helperText={submitted && error}
            onChange={(evt) =>
              onChange(evt.target.value as FilePickerComponentProps["value"])
            }
            {...(componentProps as FilePickerComponentProps)}
          />

          <FilePicker
            inputId={fieldName}
            fileUrl={fieldValue}
            {...(componentProps as FilePickerComponentProps).filePickerProps}
          />
        </Stack>
      )

    default:
      return <div />
  }
}

export { FormComponent }
