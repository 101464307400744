import React from "react"
import { Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { CustomSwitch } from "../custom-switch"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switcherContainer: {
      display: "flex",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)

const ActiveSlider = ({ rakCardID }: { rakCardID: string }) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()

  const { rakCards, apiAdminToggleActiveRakCard } = adminMslEventStoreModel
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const currentRakCard = rakCards.find((rakCard) => rakCard.id === rakCardID)
  const { active, setActive } = currentRakCard

  const toggleActiveRakCard = async () => {
    try {
      setActive(!active)
      if (active) {
        await apiAdminToggleActiveRakCard({
          ...currentRakCard,
          active: false,
        })
      } else {
        await apiAdminToggleActiveRakCard({
          ...currentRakCard,
          active: true,
        })
      }
      setSnackbarSeverity("success")
      const activeStatus = !active ? "activated" : "deactivated"
      setSnackbarText(`Successfully ${activeStatus} rak card !`)
      setShowSnackbar(true)
    } catch (error) {
      setActive(!active)
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  return (
    <div className={classes.switcherContainer}>
      <CustomSwitch
        checked={active}
        onChange={toggleActiveRakCard}
        activeLabel=""
        inActiveLabel=""
        labelSwitchSpacing={1}
        activeViewColor={"#00CC7E"}
        inActiveViewColor={"#808080"}
      />
      <Typography ml={1} className={classes.label}>
        {active ? "On" : "Off"}
      </Typography>
    </div>
  )
}

export { ActiveSlider }
