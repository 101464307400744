export const ASCENDING = "ascending"
export const DESCENDING = "descending"
export type SORT_TYPE = typeof ASCENDING | typeof DESCENDING

export const EMPTY_FILTER = ""
export const GRATITUDE = "gratitude"
export const INTENTION = "intention"
export type POST_TYPE_FILTER =
  | typeof EMPTY_FILTER
  | typeof GRATITUDE
  | typeof INTENTION

export const FUTURE = "future"
export const PAST = "past"
export const TODAY = "today"
export const YESTERDAY = "yesterday"
export const LAST_7_DAYS = "last_7_days"
export const LAST_30_DAYS = "last_30_days"
export const CUSTOM_RANGE = "custom_range"
export type DATE_RANGE_FILTER =
  | typeof EMPTY_FILTER
  | typeof FUTURE
  | typeof PAST
  | typeof TODAY
  | typeof YESTERDAY
  | typeof LAST_7_DAYS
  | typeof LAST_30_DAYS
  | typeof CUSTOM_RANGE

export const SHOW_SEEDED = "show_seeded"
export const HIDE_SEEDED = "hide_seeded"
export type SEEDED_POST_FILTER =
  | typeof EMPTY_FILTER
  | typeof SHOW_SEEDED
  | typeof HIDE_SEEDED
