import React from "react"
import { Link } from "react-router-dom"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    nestedListItem: {
      minHeight: theme.spacing(6),
      padding: theme.spacing(2, 0, 1, 4.5),
      paddingRight: 0,
      marginTop: theme.spacing(1),
    },
    nestedListName: {
      fontSize: theme.spacing(2),
      color: "#677278",
      margin: 0,
      ...typography.circularXXRegular,
    },
    nestedListIconContainer: {
      minWidth: 0,
      width: theme.spacing(8.5),
    },
    selectedListItem: {
      backgroundColor: "#F1F1F1 !important",
    },
    expandedListItem: {
      marginRight: 16,
      flex: "initial",
    },
  }),
)

interface GroupOptionProps {
  selected?: boolean
  to: string
  icon: React.ReactNode
  label: string
  children?: React.ReactNode
}

const GroupOption = ({
  selected,
  to,
  icon,
  label,
  children,
}: GroupOptionProps) => {
  const classes = useStyles()

  return (
    <ListItem
      selected={selected}
      to={to}
      component={Link}
      button
      className={classes.nestedListItem}
      classes={{
        selected: classes.selectedListItem,
      }}>
      <ListItemIcon className={classes.nestedListIconContainer}>
        {icon}
      </ListItemIcon>

      <ListItemText
        classes={{
          root: classes.expandedListItem,
          primary: classes.nestedListName,
        }}
        primary={label}
      />

      {children}
    </ListItem>
  )
}

export { GroupOption }
