import { useHistory } from "react-router-dom"

import { useStores } from "../../models/root-store"
import { clear } from "../../utils"

export const useLogout = () => {
  const { push } = useHistory()
  const { loginStore } = useStores()
  const { rootStore } = loginStore

  const handleLogOut = () => {
    push("/")
    rootStore.reset()
    clear()
  }

  return { handleLogOut }
}
