import { MslEvent } from "../models/msl-event"

/**
 * Method to handle incremental sequence of the last event of the list
 */

export const handleEventsIncrementalSequence = (events: MslEvent[]) => {
  return events.map((event, index) => {
    if (index === events.length - 1) {
      event.setSequence(events.length)
    }
    return event
  })
}
