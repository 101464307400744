import React from "react"
import { IconButton, Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import CloseIcon from "@mui/icons-material/Close"
import { transformToTimeValue } from "../../utils"
import {
  DateTimePickerDialog,
  DateTimePickerDialogProps,
} from "../custom-date-time-picker"
import { FormInput, FormInputProps } from "./form-input"

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    dateInput: {
      flex: 1,
    },
  }),
)

export interface DateTimeInputProps
  extends Omit<DateTimePickerDialogProps, "onChange"> {
  InputProps: FormInputProps
  onChange: (value: Date | null) => void
}

const DateTimeInput = ({
  InputProps,
  onChange,
  ...props
}: DateTimeInputProps) => {
  const classes = useStyles()

  const clearInput = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onChange(null)
  }

  return (
    <DateTimePickerDialog
      onChange={(date) => onChange(transformToTimeValue(date))}
      toggleButtonClassName={classes.dateInput}
      {...props}>
      <FormInput
        {...InputProps}
        disabled
        InputProps={{
          endAdornment: (
            <IconButton onClick={clearInput}>
              <CloseIcon />
            </IconButton>
          ),
          ...InputProps?.InputProps,
        }}
      />
    </DateTimePickerDialog>
  )
}

export { DateTimeInput }
