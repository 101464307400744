import React, { useState, useEffect } from "react"
import clsx from "clsx"
import {
  Theme,
  Stack,
  Typography,
  TextField,
  StandardTextFieldProps,
  OutlinedTextFieldProps,
  FilledTextFieldProps,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import EditIcon from "@material-ui/icons/Edit"
import { FormFields } from "./utils"
import { typography } from "../../services/theme/typography"

interface CustomInputComponentProps {
  inputContainerStyle?: string
  label: string
  fieldName: FormFields
  required?: boolean
  inputError?: string
  secureTextEntry?: boolean
  contentDirection?: "row" | "column"
  textFieldProps:
    | StandardTextFieldProps
    | FilledTextFieldProps
    | OutlinedTextFieldProps
  children?: React.ReactNode
}

interface FormInputProps {
  label: string
  fieldName: FormFields
  fieldValue: string
  updateFieldValue: (fieldName: FormFields, newValue: string) => void
  openCustomEditMode?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  required?: boolean
  showSubmitButtonsColumn?: boolean
  textFieldProps:
    | StandardTextFieldProps
    | FilledTextFieldProps
    | OutlinedTextFieldProps
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
      color: theme.palette.common.black,
    },
    requiredStar: {
      color: theme.palette.error.main,
    },
    formControlRoot: {
      maxWidth: 345,
    },
    fieldRoot: {
      padding: 0,
      color: theme.palette.text.secondary,
      "&.Mui-disabled": {
        color: theme.palette.text.secondary,
      },
    },
    fieldInput: {
      padding: theme.spacing(1.25, 1.5),
      color: theme.palette.text.secondary,
      "&.Mui-disabled": {
        "-webkit-text-fill-color": theme.palette.text.secondary,
      },
    },
    fieldInputNotchedOutline: {
      border: `1px solid ${theme.palette.common.black} !important`,
      borderRadius: 0,
    },
    fieldInputErrorOutline: {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
    editButton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: theme.spacing(0.5),
    },
    editIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.info.main,
    },
    buttonsRow: {
      marginLeft: theme.spacing(4),
    },
    buttonsColumn: {
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      padding: theme.spacing(1.5, 2),
      lineHeight: theme.spacing(2),
      ...typography.circularXXMedium,
    },
    saveButton: {
      padding: theme.spacing(1.5, 3.25),
      lineHeight: theme.spacing(2),
      border: `1px solid ${theme.palette.info.main}`,
      ...typography.circularXXMedium,
    },
    loadingProgress: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
  }),
)

const CustomInputComponent = ({
  inputContainerStyle,
  label,
  fieldName,
  required,
  inputError,
  secureTextEntry,
  contentDirection = "row",
  textFieldProps,
  children,
}: CustomInputComponentProps) => {
  const classes = useStyles()
  const [showPass, setShowPass] = useState(false)

  const { variant, ...ExtraTextFieldProps } = textFieldProps

  return (
    <Stack spacing={0.5} className={inputContainerStyle}>
      <Typography className={classes.label}>
        {label}{" "}
        {Boolean(required) && <span className={classes.requiredStar}>*</span>}
      </Typography>

      <Stack direction={contentDirection}>
        <TextField
          variant="outlined"
          id={fieldName}
          name={fieldName}
          required
          fullWidth
          label=""
          autoFocus
          type={secureTextEntry && !showPass ? "password" : "text"}
          {...ExtraTextFieldProps}
          InputProps={{
            ...ExtraTextFieldProps.InputProps,
            endAdornment: secureTextEntry && (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPass(!showPass)}>
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              root: classes.fieldRoot,
              input: classes.fieldInput,
              notchedOutline: clsx(classes.fieldInputNotchedOutline, {
                [classes.fieldInputErrorOutline]: Boolean(inputError),
              }),
            },
          }}
          className={classes.formControlRoot}
        />

        {children}
      </Stack>

      {Boolean(inputError) && (
        <Typography className={classes.requiredStar}>{inputError}</Typography>
      )}
    </Stack>
  )
}

const FormInput = ({
  label,
  fieldName,
  fieldValue,
  updateFieldValue,
  openCustomEditMode,
  required,
  textFieldProps,
  showSubmitButtonsColumn,
}: FormInputProps) => {
  const classes = useStyles()
  const [isEditMode, toggleEditMode] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [isLoading, toggleLoading] = useState(false)
  const [inputError, setInputError] = useState("")

  const openEditMode = () => {
    toggleEditMode(true)
  }

  const closeEditMode = () => {
    toggleEditMode(false)
  }

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value)

    //reset field error on input change
    if (Boolean(inputError)) {
      setInputError("")
    }
  }

  //handle field reset to current field value on edit mode close
  useEffect(() => {
    if (!isEditMode) {
      setInputValue(fieldValue || "")
    }
  }, [fieldValue, isEditMode])

  //handle error reset on edit mode close
  useEffect(() => {
    if (!isEditMode && Boolean(inputError)) {
      setInputError("")
    }
  }, [inputError, isEditMode])

  const onFieldSave = async () => {
    //quick empty validation for requried fields
    //TODO: Proper validation
    if (Boolean(required) && inputValue?.trim?.()?.length === 0) {
      setInputError("Please fill the required field !")
      return
    }

    try {
      toggleLoading(true)
      await updateFieldValue(fieldName, inputValue)
      toggleLoading(false)
      closeEditMode()
    } catch (error) {
      toggleLoading(false)
      throw error
    }
  }

  return (
    <CustomInputComponent
      label={label}
      fieldName={fieldName}
      required={required}
      inputError={inputError}
      //change the input content direction to "column" on edit mode, when `showSubmitButtonsColumn` is present
      contentDirection={
        isEditMode && showSubmitButtonsColumn ? "column" : "row"
      }
      textFieldProps={{
        ...textFieldProps,
        value: inputValue,
        onChange: onInputChange,
        disabled: !isEditMode,
      }}>
      {isEditMode ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          className={clsx({
            [classes.buttonsRow]: !showSubmitButtonsColumn,
            [classes.buttonsColumn]: showSubmitButtonsColumn,
          })}>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={closeEditMode}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className={classes.saveButton}
            disabled={isLoading}
            onClick={onFieldSave}>
            Save
            {isLoading && (
              <CircularProgress size={20} className={classes.loadingProgress} />
            )}
          </Button>
        </Stack>
      ) : (
        <IconButton
          className={classes.editButton}
          onClick={
            Boolean(openCustomEditMode) ? openCustomEditMode : openEditMode
          }>
          <EditIcon className={classes.editIcon} />
        </IconButton>
      )}
    </CustomInputComponent>
  )
}

export { CustomInputComponent, FormInput }
