import React, { useState } from "react"
import {
  Theme,
  Popover,
  PopoverProps,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "25%",
      height: "40%",
      padding: `0 ${theme.spacing(5)}`,
      boxShadow: "none",
      border: "1px solid #B0BABF",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogText: {
      fontSize: 18,
      color: "#000",
    },
    cancelButton: {
      flex: 1,
      border: `1px solid #1C84C6 !important`,
      borderRadius: theme.spacing(1),
      color: "#1C84C6",
    },
    submitButton: {
      flex: 1,
      background: "#007CA6 !important",
      borderRadius: theme.spacing(1),
      color: "#fff",
    },
    successText: {
      fontSize: 36,
      color: "#000",
    },
    successIcon: {
      width: "125px",
      height: "125px",
      color: "#00CC7E",
    },
    loadingProgress: {
      color: "#fff",
    },
  }),
)

interface DeleteDialogProps extends PopoverProps {
  handleClose: () => void
  onDelete: () => void
  personName: string
}

const DeleteDialog = ({
  id,
  open,
  anchorEl,
  handleClose,
  onDelete,
  personName,
}: DeleteDialogProps) => {
  const classes = useStyles()
  const [loadingDelete, toggleLoadingDelete] = useState(false)
  const [showDeletedMessage, toggleDeletedMessage] = useState(false)

  /**
   * Method to handle after the delete api fires successfully
   * Activate `showDeletedMessage` to show the success message component
   * Set a timeout to 2 sec to close the popover
   */
  const handleSuccessDeletion = () => {
    toggleDeletedMessage(true)
    setTimeout(() => {
      handleClose()
    }, 2000)
  }

  /**
   * Method to fire the delete api call and to toggle loading flag during the api call
   */
  const onSubmit = async () => {
    try {
      toggleLoadingDelete(true)
      await onDelete()
      toggleLoadingDelete(false)
      handleSuccessDeletion()
    } catch (error) {
      toggleLoadingDelete(false)
    }
  }

  const renderDialog = () => {
    return (
      <Stack spacing={4}>
        <Stack spacing={5} alignItems="center">
          <Typography className={classes.dialogText}>
            You are about to delete {personName}’s account.
          </Typography>

          <Typography className={classes.dialogText}>
            Would you like to proceed?
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={handleClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className={classes.submitButton}
            disabled={loadingDelete}
            onClick={onSubmit}>
            {loadingDelete ? (
              <CircularProgress size={20} className={classes.loadingProgress} />
            ) : (
              "Proceed"
            )}
          </Button>
        </Stack>
      </Stack>
    )
  }

  const renderMessage = () => {
    return (
      <Stack spacing={6} alignItems="center">
        <Typography className={classes.successText}>Account Deleted</Typography>

        <CheckCircleIcon className={classes.successIcon} fontSize={"large"} />
      </Stack>
    )
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      classes={{
        paper: classes.root,
      }}>
      {showDeletedMessage ? renderMessage() : renderDialog()}
    </Popover>
  )
}

export default DeleteDialog
