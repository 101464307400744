export const dotify = (obj: any) => {
  var res = {}

  function recurse(obj: any, current: any) {
    for (var key in obj) {
      var value = obj[key]
      var newKey = current ? current + "." + key : key

      if (value && typeof value === "object") {
        recurse(value, newKey) // it's a nested object, so do it again
      } else {
        //@ts-ignore
        res[newKey] = value // it's not an object, so set the property
      }
    }
  }

  recurse(obj, null)
  return res
}

export const cloneObject = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}
