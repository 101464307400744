import { FieldTypes } from "../admin-config-form"

const formConfig = [
  {
    fieldName: "name",
    required: true,
    onChangeMethod: "setName",
    label: "Topic Name",
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter topic name here",
    },
  },
]

export { formConfig }
