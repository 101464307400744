import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridColDef, GridCellProps } from "@mui/x-data-grid"

import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { CreateNudgeModal, ModalMode } from "./create-nudge-modal"
import { Nudges } from "../../models/nudges"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      tableCell: {
        width: "11%",
        maxWidth: "11%",
        display: "flex",
        borderBottom: "unset",
        wordBreak: "break-word",
      },
    },
  }),
)

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "title",
    headerName: "Title",
  },
  {
    field: "type",
    headerName: "Type",
  },
  {
    field: "body",
    headerName: "Body",
  },
  {
    field: "route",
    headerName: "Route",
  },
  {
    field: "routeParam",
    headerName: "Route Param",
  },
  {
    field: "defaultSendAt",
    headerName: "Default Send Time",
  },
  {
    field: "active",
    headerName: "Is Active",
  },
  {
    field: "edit",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]

const AdminNudgesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateNudgeModalOpen, toggleCreateNudgeModalOpen] = useState(false)
  const [nudgeModalMode, setNudgeModalMode] = useState<ModalMode>(
    ModalMode.ADD_NEW_NUDGE,
  )

  const { adminMslEventStoreModel } = useStores()
  const {
    nudges,
    setNewNudge,
    apiAdminGetNudges,
    apiAdminGetNudgeTypes,
    resetNewNudge,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetNudges()
        await apiAdminGetNudgeTypes()
        toggleFirstLoading(false)
      } catch (e) {
        toggleFirstLoading(false)
      }
    }
    initialFetch()
  }, [apiAdminGetNudges, apiAdminGetNudgeTypes])

  const openCreateNudgesModal = () => {
    resetNewNudge()
    setNudgeModalMode(ModalMode.ADD_NEW_NUDGE)
    toggleCreateNudgeModalOpen(true)
  }

  const openEditNudgeModal = useCallback((event: Nudges) => {
    setNewNudge(event)
    setNudgeModalMode(ModalMode.EDIT_NUDGE)
    toggleCreateNudgeModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateNudgeModal = () => {
    toggleCreateNudgeModalOpen(false)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props}>
        <CellComponent
          {...props}
          openEditNudgeModal={openEditNudgeModal}
          nudgeID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Nudges</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateNudgesModal}>
          Add nudge
        </Button>
      </Stack>

      <CustomGridTable
        rows={nudges.slice()}
        columns={columns}
        aria-label="nudges-table"
        cell={MemoTableCell}
        emptyStateLabel="When you create some nudges, they will appear here"
        loading={firstLoading}
      />

      <CreateNudgeModal
        open={isCreateNudgeModalOpen}
        closeModal={closeCreateNudgeModal}
        mode={nudgeModalMode}
      />
    </div>
  )
})

export { AdminNudgesList }
