import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { RecipeModel, Recipe } from "../recipes"
import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"

export const PAGE = 1
export const PAGE_SIZE = 100

/**
 * Represents a pagination model.
 */
export const RecipePaginationModel = types
  .model("RecipePagination")
  .props({
    loading: types.optional(types.boolean, false),
    page: types.optional(types.number, PAGE),
    pageSize: types.optional(types.number, PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
    recipes: types.optional(types.array(RecipeModel), []),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },

    setPagination(value: {
      page: number
      pageSize: number
      totalCount: number
    }) {
      self.page = value.page
      self.pageSize = value.pageSize
      self.totalCount = value.totalCount
    },

    setRecipes(value: Recipe[]) {
      self.recipes.replace(uniqBy(prop("id"), [...self.recipes, ...value]))
    },

    updateRecipe(value: Recipe) {
      const updatedRecipesList = replaceObjectById(self.recipes, value)

      self.recipes.replace(updatedRecipesList as Recipe[])
    },

    deleteRecipe(value: string) {
      const updatedRecipesList = removeObjectById(self.recipes, value)
      self.recipes.replace(updatedRecipesList as Recipe[])
      self.totalCount = self.totalCount - 1
    },

    resetRecipes() {
      if (isAlive(self.recipes)) {
        self.recipes.clear()
      }
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetRecipes()
      self.page = PAGE
      self.pageSize = PAGE_SIZE
    },
  }))

export interface RecipePagination
  extends Instance<typeof RecipePaginationModel> {}
export interface RecipePaginationSnapshot
  extends SnapshotOut<typeof RecipePaginationModel> {}
