import { colors } from "@material-ui/core"

export const lightPalette = {
  action: {
    active: colors.blueGrey[600],
  },
  primary: {
    main: "#22878D",
  },
  secondary: {
    main: "#D5E5FF",
  },
  text: {
    primary: "#1A3932",
    secondary: "#2D353B",
  },
  error: {
    main: "#FF011C",
  },
  info: {
    main: "#0071BC",
  },
}
