import React, { useState } from "react"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import FlagIcon from "@mui/icons-material/Flag"
import { Theme, CircularProgress } from "@material-ui/core"

import clsx from "clsx"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { ChatMessage } from "../../models/chat-message"

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    flag: {
      marginRight: theme.spacing(2),
    },
    selectedFlag: {
      color: "#F23D3Ced",
    },
    loadingIcon: {
      width: `${theme.spacing(2)} !important`,
      height: `${theme.spacing(2)} !important`,
      marginRight: theme.spacing(2),
      color: "#F23D3Ced",
    },
  }),
)

interface FlagProps {
  message: ChatMessage
}

export const Flag = ({ message }: FlagProps) => {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)

  const { isFlagged } = message

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()
  const { loginStore, pilotTrainerStore } = useStores()
  const { authentication } = loginStore
  const {
    apiPilotCoachSaveChat,
    apiPilotCoachDeleteSavedChat,
  } = pilotTrainerStore

  const toggleFlagMessage = async () => {
    setIsLoading(true)
    try {
      let successMessage = ""
      if (isFlagged) {
        await apiPilotCoachDeleteSavedChat(message)
        successMessage = "Message is unflagged successfully!"
      } else {
        await apiPilotCoachSaveChat(message)
        successMessage = "Message is flagged successfully!"
      }
      setIsLoading(false)
      setSnackbarSeverity("success")
      setSnackbarText(successMessage)
      setShowSnackbar(true)
    } catch (error) {
      setIsLoading(false)
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  if (!authentication.isPilotTrainer) {
    return <></>
  }
  if (isLoading) {
    return <CircularProgress className={classes.loadingIcon} />
  }
  if (isFlagged) {
    return (
      <FlagIcon
        className={clsx(classes.flag, classes.selectedFlag)}
        onClick={toggleFlagMessage}
      />
    )
  }
  return (
    <FlagOutlinedIcon className={classes.flag} onClick={toggleFlagMessage} />
  )
}
