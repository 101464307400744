import React from "react"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Tooltip, tooltipClasses } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgb(97, 97, 97)",
        fontSize: 12,
      },
    },
  }),
)

interface ShortTextProps {
  value?: string | null
  textLimit?: number
}

export const CustomTooltip = ({ children, title }) => {
  const classes = useStyles()

  return (
    <Tooltip
      title={title}
      placement="top-start"
      arrow
      classes={{ popper: classes.tooltip }}>
      {children}
    </Tooltip>
  )
}

const ShortText = ({ value, textLimit = 50 }: ShortTextProps) => {
  if (!value) {
    return <div>N/A</div>
  }

  return (
    <CustomTooltip title={value}>
      <div>
        {`${value}`.slice(0, textLimit)}
        {`${value}`.length > textLimit && "..."}
      </div>
    </CustomTooltip>
  )
}

export { ShortText }
