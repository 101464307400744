import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-next-tile-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useWNTilesForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newTile,
    tileTypes,
    apiAdminCreateTile,
    apiAdminEditTile,
    apiAdminWNTilesUploadContent,
    resetNewTile,
  } = adminMslEventStoreModel

  const { type, uploadFiles, setUploadFile, createTileErrors: errors } = newTile

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewTile()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      await apiAdminWNTilesUploadContent()
      if (mode === ModalMode.ADD_NEW_TILE) {
        await apiAdminCreateTile()
        setAndShowSnackbar({
          text: "Successfully created tile !",
          severity: "success",
        })
      } else {
        await apiAdminEditTile()
        setAndShowSnackbar({
          text: "Successfully edited tile !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "id",
      label: "ID",
      showOnlyOnEditMode: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        disbled: true,
      },
    },
    {
      fieldName: "label",
      onChangeMethod: "setLabel",
      label: "Label",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter label",
      },
    },
    {
      fieldName: "friendlyName",
      onChangeMethod: "setFriendlyName",
      label: "Friendly name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter friendly name",
      },
    },
    {
      fieldName: "type",
      onChangeMethod: "setType",
      label: "Type",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        options: tileTypes.slice(),
        value: type,
        getOptionLabel: (option: { id: string; label: string }) => {
          return option
        },
        Input: {
          placeholder: "Select Type",
        },
      },
    },
    {
      fieldName: "actionURL",
      onChangeMethod: "setActionUrl",
      label: "Action URL",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter Action URL",
      },
    },
    {
      fieldName: "imageURL",
      onChangeMethod: "setImageUrl",
      label: "Image URL",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter image URL or select locally",
        filePickerProps: {
          label: "Select image",
          fileType: "whats_next_tile_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.imageURL,
        },
      },
    },
    {
      fieldName: "videoID",
      onChangeMethod: "setVideoId",
      label: "Video ID",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter video ID",
      },
    },
    {
      fieldName: "videoIDV2",
      onChangeMethod: "setVideoIdV2",
      label: "Video ID V2",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter video ID V2",
      },
    },
    {
      fieldName: "active",
      onChangeMethod: "setIsActive",
      label: "Is Active ?",
      fieldType: FieldTypes.Switch,
    },
  ]

  return { loading, submitted, errors, onCancel, onSubmit, formConfig }
}

export { useWNTilesForm }
