import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"
import { TopicModel, Topic } from "./topic"
import { TrainerModel, Trainer } from "./trainer"
import { TagModel, Tag } from "../tag"
import { UploadFileModel, UploadFile } from "../upload-file"

export const MslEventModel = types
  .model("MslEvent")
  .props({
    id: types.identifier,
    legacyID: types.maybeNull(types.number),
    trainerID: types.maybeNull(types.string),
    trainerIDV2: types.maybeNull(types.string),
    name: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    isLive: types.optional(types.boolean, false),
    posterImageURL: types.maybeNull(types.string),
    topicID: types.maybeNull(types.string),
    topic: types.maybeNull(TopicModel),
    trainer: types.maybeNull(TrainerModel),
    scheduledStartTime: types.optional(customTypes.iso8601, null),
    scheduledEndTime: types.optional(customTypes.iso8601, null),
    actualStartTime: types.maybeNull(customTypes.iso8601),
    actualEndTime: types.maybeNull(customTypes.iso8601),
    videoRoomSID: types.maybeNull(types.string),
    playerStreamerSID: types.maybeNull(types.string),
    mediaProcessorSID: types.maybeNull(types.string),
    isPublished: types.optional(types.boolean, false),
    provider: types.maybeNull(types.string),
    recordingAvailable: types.optional(types.boolean, false),
    recordingURL: types.maybeNull(types.string),
    audioOnlyImageURL: types.maybeNull(types.string),
    duration: types.optional(
      types.maybeNull(types.union(types.string, types.number)),
      "",
    ),
    isFeatured: types.optional(types.maybeNull(types.boolean), false),
    audioOnly: types.optional(types.maybeNull(types.boolean), false),
    publishStartTime: types.optional(customTypes.iso8601, null),
    publishEndTime: types.optional(customTypes.iso8601, null),
    tags: types.optional(types.array(TagModel), []),
    sequence: types.optional(types.maybeNull(types.number), null),
    script: types.optional(types.maybeNull(types.string), ""),
    searchKeywords: types.optional(types.maybeNull(types.string), ""),
    previewStartTime: types.optional(customTypes.iso8601, null),
    hlsVideoURL: types.optional(types.maybeNull(types.string), ""),
    uploadFiles: types.optional(
      types.model({
        posterImageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "posterImageURL",
          }),
        ),
        audioOnlyImageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "audioOnlyImageURL",
          }),
        ),
        recordingURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "recordingURL",
          }),
        ),
      }),
      {},
    ),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setDescription(value: string) {
      self.description = value
    },
    setScheduledStartTime(value: Date) {
      self.scheduledStartTime = value
    },
    setScheduledEndTime(value: Date) {
      self.scheduledEndTime = value
    },
    setPosterImageURL(value: string) {
      self.posterImageURL = value
    },
    setIsPublished(value: boolean) {
      self.isPublished = value
    },
    setTrainerId(value: string | null) {
      self.trainerID = value
    },
    setTrainerIDV2(value: Trainer) {
      self.trainerIDV2 = value?.id || null
    },
    setTopicId(value: Topic) {
      self.topicID = value?.id || null
    },
    setRecordingAvailable(value: boolean) {
      self.recordingAvailable = value
    },
    setRecordingURL(value: string | null) {
      self.recordingURL = value
    },
    setAudioOnlyImageURL(value: string) {
      self.audioOnlyImageURL = value
    },
    setDuration(value: string | number | null) {
      self.duration = value
    },
    setIsFeatured(value: boolean) {
      self.isFeatured = value
    },
    setAudioOnly(value: boolean) {
      self.audioOnly = value
    },
    setPublishStartTime(value: Date) {
      self.publishStartTime = value
    },
    setPublishEndTime(value: Date) {
      self.publishEndTime = value
    },
    setPreviewStartTime(value: Date) {
      self.previewStartTime = value
    },
    setTags(value: Tag[]) {
      self.tags.replace(value.map((e) => clone(e)))
    },
    setSequence(value: number | null) {
      self.sequence = value
    },
    setScript(value: string) {
      self.script = value
    },
    setSearchKeywords(value: string) {
      self.searchKeywords = value
    },
    setHlsVideoURL(value: string) {
      self.hlsVideoURL = value
    },
    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },
    reset() {
      self.name = ""
      self.description = ""
      self.scheduledStartTime = null
      self.scheduledEndTime = null
      self.posterImageURL = ""
      self.topicID = ""
      self.trainerID = ""
      self.trainerIDV2 = ""
      self.recordingAvailable = false
      self.recordingURL = ""
      self.audioOnlyImageURL = ""
      self.duration = ""
      self.isFeatured = false
      self.audioOnly = false
      self.publishStartTime = null
      self.publishEndTime = null
      self.sequence = null
      self.script = ""
      self.searchKeywords = ""
      self.tags.replace([])
      self.uploadFiles.posterImageURL.reset()
    },
  }))
  .views((self) => ({
    get createMslEventErrors() {
      if (typeof self.duration === "number") {
        self.setDuration(`${self.duration}`)
      }
      return validate(CREATE_MSL_EVENT_VALIDATION_RULES, self)
    },
    get formattedScheduledStartDate() {
      if (Boolean(self.scheduledStartTime)) {
        return moment(self.scheduledStartTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get formattedScheduledEndDate() {
      if (Boolean(self.scheduledEndTime)) {
        return moment(self.scheduledEndTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get formattedPublishStartTime() {
      if (Boolean(self.publishStartTime)) {
        return moment(self.publishStartTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get formattedPublishEndTime() {
      if (Boolean(self.publishEndTime)) {
        return moment(self.publishEndTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get formattedPreviewStartTime() {
      if (Boolean(self.previewStartTime)) {
        return moment(self.previewStartTime).format("MM/DD/YYYY hh:mm A")
      }

      return ""
    },
    get durationValue() {
      return parseInt(self.duration as string)
    },
  }))

const CREATE_MSL_EVENT_VALIDATION_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  description: {
    presence: { allowEmpty: false, message: "required" },
  },
  trainerIDV2: {
    presence: { allowEmpty: false, message: "required" },
  },
  posterImageURL: {
    fileUpload: {},
  },
  scheduledStartTime: {
    presence: { allowEmpty: false, message: "required" },
    validateStartEndDates: {
      compare: "scheduledEndTime",
      message: "Scheduled Start Time should be before the Scheduled End Time",
    },
  },
  scheduledEndTime: {
    presence: { allowEmpty: false, message: "required" },
  },
  publishStartTime: {
    validateStartEndDates: {
      compare: "publishEndTime",
      message: "Publish Start Time should be before the Publish End Time",
    },
  },
  duration: {
    format: {
      pattern: "^$|[1-9]+[0-9]*",
      flags: "i",
      message: "*please enter number of seconds that is greater than zero",
    },
  },
  hlsVideoURL: {
    format: {
      pattern: "^$|(http.*?.m3u8+)",
      flags: "i",
      message: "*HLS videos should use a URL ending with .m3u8",
    },
  },
}

export interface MslEvent extends Instance<typeof MslEventModel> {}
export interface MslEventSnapshot extends SnapshotOut<typeof MslEventModel> {}
