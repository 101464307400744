import React from "react"
import { Stack } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"

import { ShortText } from "../admin-msl-events-list/short-text"
import { TagActions } from "./tag-actions"

const CellComponent = (props: GridCellProps) => {
  switch (props.field) {
    case "isUserFacing":
      return (
        <Stack direction="row" spacing={2}>
          <span>{!!props.value ? "Yes" : "No"}</span>

          <TagActions tagItemID={`${props?.rowId || ""}`} />
        </Stack>
      )
    default:
      return <ShortText value={props.value || "N/A"} />
  }
}

export { CellComponent }
