import React from "react"
import { Grid, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    conditionContainer: {
      fontSize: theme.spacing(2),
      fontWeight: 400,
      color: "#2D353B",
      padding: theme.spacing(0.62, 2),
      backgroundColor: "#F5F5F5",
      borderRadius: 131,
      ...typography.circularXXBook,
    },
  }),
)

export const Condition = ({ condition }: { condition: string }) => {
  const classes = useStyles()
  return (
    <Grid item xs="auto" mt={2}>
      <Typography className={classes.conditionContainer}>
        {condition}
      </Typography>
    </Grid>
  )
}
