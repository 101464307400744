import React, { useState, useEffect } from "react"
import clsx from "clsx"
import {
  Box,
  Theme,
  Popover,
  Typography,
  Stack,
  Button,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"
import { ReactComponent as TickIcon } from "../../assets/images/tick.svg"
import { ReactComponent as SuccessIcon } from "../../assets/images/success_icon.svg"
import { CustomInputComponent, FormInput } from "./form-input"
import { FormFields } from "./utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "inline-block",
    },
    pickerContainer: {
      minWidth: 453,
      minHeight: 350,
      boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
      borderRadius: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    formContent: {
      alignItems: "center",
      padding: theme.spacing(3, 5, 4, 5),
    },
    pickerTitle: {
      fontSize: theme.spacing(3.25),
      lineHeight: theme.spacing(4),
      color: "#485359",
      ...typography.circularXXBold,
    },
    inputContainer: {
      width: "100%",
    },
    inputDivider: {
      width: "100%",
      borderBottom: "1px solid #485359",
    },
    tick: {
      marginLeft: theme.spacing(2),
    },
    activeTick: {
      fill: theme.palette.success.main,
    },

    formButtons: {
      padding: theme.spacing(2),
      background: "#EBEEF0",
      flex: "initial",
    },
    actionButton: {
      width: 154,
      height: 45,
      ...typography.circularXXMedium,
    },
    successTitle: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.spacing(3),
      color: "#0A8052",
      ...typography.circularXXMedium,
    },
    successSubTitle: {
      fontSize: theme.spacing(3),
      color: theme.palette.text.secondary,
    },
  }),
)

const ChangePasswordDialog = () => {
  const classes = useStyles()
  const { loginStore } = useStores()
  const { apiChangePassword } = loginStore

  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [newPasswordError, setNewPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")

  const [pickerEl, setPickerEl] = React.useState<HTMLButtonElement | null>(null)
  const [isLoading, toggleLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [showSuccessContent, toggleSuccessContent] = useState(false)

  const openPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPickerEl(event.currentTarget)
  }

  const handlePickerClose = () => {
    setPickerEl(null)
    setNewPassword("")
    setConfirmPassword("")
    setNewPasswordError("")
    setConfirmPasswordError("")
  }

  const open = Boolean(pickerEl)
  const id = open ? "change-password-dialog" : undefined

  /**
   * Reset error messages on field change
   */
  useEffect(() => {
    setSubmitted(false)
    setConfirmPasswordError("")
    setNewPasswordError("")
  }, [newPassword, confirmPassword])

  const isNewPasswordFilled = Boolean(newPassword.trim())
  const isConfirmPasswordFilled = Boolean(confirmPassword.trim())

  /**
   * Method for validating input values and giving the right error message
   * @returns a boolean if both fields are valid
   */
  const validatePasswords = () => {
    if (!isNewPasswordFilled) {
      setNewPasswordError("Password should not be empty")
      return false
    }
    if (!isConfirmPasswordFilled) {
      setConfirmPasswordError("Password should not be empty")
      return false
    } else if (newPassword.trim().length < 4) {
      setConfirmPasswordError("Your password is too short")
      return false
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Your passwords don’t match")
      return false
    }

    return true
  }

  /**
   * Method to handle after the change password api fires successfully
   * Activate `showSuccessContent` to show the success message component
   * Set a timeout to 2 sec to close the popover
   */
  const handleSuccessUpdate = () => {
    toggleSuccessContent(true)
    setTimeout(() => {
      handlePickerClose()
      toggleSuccessContent(false)
    }, 2000)
  }

  const updatePassword = async () => {
    setSubmitted(true)
    const isValidPassword = validatePasswords()
    if (!isValidPassword) {
      return
    }
    try {
      toggleLoading(true)
      await apiChangePassword(newPassword)
      toggleLoading(false)
      handleSuccessUpdate()
    } catch (error) {
      toggleLoading(false)
      throw error
    }
  }

  const renderSucceessMessage = () => {
    return (
      <Stack flex={1} spacing={3} justifyContent="center" alignItems="center">
        <Stack spacing={3} direction="row" alignItems="center">
          <Typography className={classes.successTitle}>Success!</Typography>

          <SuccessIcon />
        </Stack>

        <Typography className={classes.successSubTitle}>
          Your password has been changed
        </Typography>
      </Stack>
    )
  }

  const renderDialogContent = () => {
    if (showSuccessContent) {
      return renderSucceessMessage()
    }
    return (
      <>
        <Stack
          spacing={3}
          flex={1}
          direction="column"
          className={classes.formContent}>
          <Typography className={classes.pickerTitle}>
            Change Password
          </Typography>

          <CustomInputComponent
            label="New Password"
            fieldName={FormFields.NewPassword}
            required
            secureTextEntry
            inputContainerStyle={classes.inputContainer}
            inputError={submitted && newPasswordError}
            textFieldProps={{
              value: newPassword,
              onChange: (e) => setNewPassword(e.target.value),
              placeholder: "*******************",
            }}>
            <TickIcon
              className={clsx(classes.tick, {
                [classes.activeTick]: isNewPasswordFilled,
              })}
            />
          </CustomInputComponent>

          <CustomInputComponent
            label="Confirm Password"
            fieldName={FormFields.ConfirmPassword}
            required
            secureTextEntry
            inputContainerStyle={classes.inputContainer}
            inputError={submitted && confirmPasswordError}
            textFieldProps={{
              value: confirmPassword,
              onChange: (e) => setConfirmPassword(e.target.value),
              placeholder: "*******************",
            }}>
            <TickIcon
              className={clsx(classes.tick, {
                [classes.activeTick]: isConfirmPasswordFilled,
              })}
            />
          </CustomInputComponent>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          flex={1}
          spacing={4}
          className={classes.formButtons}>
          <Button
            onClick={handlePickerClose}
            variant="outlined"
            size="large"
            className={classes.actionButton}>
            Cancel
          </Button>

          <Button
            endIcon={
              isLoading && <CircularProgress color="secondary" size={24} />
            }
            onClick={updatePassword}
            disabled={isLoading}
            variant="contained"
            size="large"
            className={classes.actionButton}>
            Change
          </Button>
        </Stack>
      </>
    )
  }

  return (
    <Box className={classes.container}>
      <FormInput
        label="Password"
        fieldName={FormFields.Password}
        fieldValue={"*******************"}
        updateFieldValue={() => {}}
        openCustomEditMode={openPicker}
        required
        textFieldProps={{
          placeholder: "Enter your email here",
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={pickerEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.pickerContainer,
        }}>
        {renderDialogContent()}
      </Popover>
    </Box>
  )
}

export { ChangePasswordDialog }
