import React, { useState } from "react"
import {
  Box,
  FormControl,
  Grid,
  Paper,
  Typography,
  Modal,
  Chip,
  TextField,
  Button,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import FaceIcon from "@material-ui/icons/Face"
import ErrorIcon from "@material-ui/icons/Error"
import { red } from "@material-ui/core/colors"
import { useHistory } from "react-router-dom"
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/core/Autocomplete"
import clsx from "clsx"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { validateEmail } from "../../utils/validate"
import { useSnackbars } from "../../components/use-snackbar"
import { isEmpty } from "ramda"
import { noop } from "../../utils"

interface StyleProps {
  invitees: Array<string>
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    position: "absolute",
    padding: theme.spacing(6, 8, 1, 8),
    outline: "none",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 544,
      height: "auto",
      left: "50%",
      top: "50%",
      transform: (props) =>
        `translateX(calc(-50% + ${
          props.isDrawerOpen ? props.drawerWidth / 2 : 0
        }px)) translateY(-50%)`,
    },
  },
  autoCompleteInputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: (props) =>
        props.invitees.length > 0 ? theme.spacing(3) : null,
      paddingBottom: (props) =>
        props.invitees.length > 0 ? theme.spacing(1) : null,
    },
  },
  formRoot: {},
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  formButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chipColorSecondary: {},
  chipColorSecondaryError: {
    backgroundColor: red[400],
  },
}))

const CreateSite = observer(() => {
  const history = useHistory()
  const { siteStore, uiStore } = useStores()
  const { isDrawerOpen } = uiStore
  const { newSite, apiCreateSite } = siteStore
  const {
    setName,
    setAddress,
    setSiteID,
    setInvitees,
    invitees,
    createSiteErrors: errors,
    name,
    address,
    siteID,
    preventAccidentalModalClose,
  } = newSite

  const [backDialogOpen, setIsBackDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const classes = useStyles({ invitees, drawerWidth, isDrawerOpen })

  const isNameInvalid = submitted && Boolean(errors.name)
  const isAddressInvalid = submitted && Boolean(errors.address)
  const isSiteIDInvalid = submitted && Boolean(errors.siteID)
  const isInviteesInvalid = submitted && Boolean(errors.invitees)

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const goBack = () => history.goBack()

  const handleClose = () => {
    if (preventAccidentalModalClose) {
      setIsBackDialogOpen(true)
      return
    }

    goBack()
  }

  const onCancelPress = () => {
    if (preventAccidentalModalClose) {
      setIsBackDialogOpen(true)
      return
    }
  }

  const handleBackDialogClose = () => {
    setIsBackDialogOpen(false)
  }

  const handleAdminsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    event.preventDefault()
    setInvitees(value)
  }

  const onSave = async () => {
    setSubmitted(true)
    if (!isEmpty(errors)) return
    try {
      setLoading(true)
      await apiCreateSite()
      setSnackbarSeverity("success")
      setSnackbarText("Successfully created site")
      setShowSnackbar(true)
      goBack()
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  const body = (
    <Paper className={classes.paper}>
      <Grid container className={classes.formRoot}>
        <Grid item xs={12}>
          <Box mb={4}>
            <Typography variant="h2">Create a Site</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" className={classes.formControl}>
            <TextField
              variant="filled"
              required
              fullWidth
              id="siteName"
              label="Site Name"
              name="siteName"
              value={name}
              autoFocus
              error={isNameInvalid}
              helperText={submitted && errors.name}
              onChange={(evt) => {
                setName(evt.target.value)
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" className={classes.formControl}>
            <TextField
              variant="filled"
              required
              fullWidth
              id="address"
              label="Site Address"
              value={address}
              name="address"
              error={isAddressInvalid}
              helperText={submitted && errors.address}
              onChange={(evt) => {
                setAddress(evt.target.value)
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" className={classes.formControl}>
            <TextField
              variant="filled"
              required
              fullWidth
              id="siteID"
              value={siteID}
              label="Site ID"
              name="siteID"
              error={isSiteIDInvalid}
              helperText={submitted && errors.siteID}
              onChange={(evt) => {
                setSiteID(evt.target.value)
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              //@ts-ignore
              multiple={true}
              //@ts-ignore
              freeSolo={true}
              autoSelect={true}
              options={[]}
              value={invitees.slice()}
              classes={{
                inputRoot: classes.autoCompleteInputRoot,
              }}
              onChange={handleAdminsChange}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option: any, index) => {
                  const isEmail = validateEmail(option)
                  return (
                    <Chip
                      icon={isEmail ? <FaceIcon /> : <ErrorIcon />}
                      disabled={!isEmail}
                      label={<>{option}</>}
                      color="secondary"
                      classes={{
                        colorSecondary: isEmail
                          ? classes.chipColorSecondary
                          : classes.chipColorSecondaryError,
                      }}
                      {...getTagProps({ index })}
                    />
                  )
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Site Administrator(s)"
                  error={isInviteesInvalid}
                  helperText={submitted && errors.invitees}
                  placeholder="Press 'enter' to separate multiple emails."
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            className={clsx(classes.formControl, classes.formButtons)}>
            <Button onClick={onCancelPress} variant="outlined" size="large">
              Cancel
            </Button>
            <Button
              endIcon={
                loading && <CircularProgress color="secondary" size={24} />
              }
              onClick={onSave}
              disabled={loading}
              variant="contained"
              size="large">
              Save Site
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  )

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>

      <Dialog
        open={backDialogOpen}
        onClose={handleBackDialogClose}
        aria-labelledby="confirm-go-back">
        <DialogTitle id="confirm-go-back">
          <Typography variant="h3">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may have some unsaved changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={goBack} color="inherit">
            Go back
          </Button>
          <Button onClick={handleBackDialogClose} color="inherit" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default CreateSite
