export enum FormFields {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Password = "password",
  CurrentPassword = "currentPassword",
  NewPassword = "newPassword",
  ConfirmPassword = "confirmPassword",
  MobilePhone = "mobilePhone",
  CountryCode = "countryCode",
  ZipCode = "zipCode",
  Motivation = "motivation",
  SmsAllowed = "sms_allowed",
}

export type Field = {
  name: FormFields
  value: string
  required?: boolean
  label: string
}

export type FieldsValues = Partial<Record<FormFields, string>>

export const generateFieldsValues = (fields: Field[]) => {
  return fields.reduce((acc, field) => {
    return { ...acc, [field.name]: field.value }
  }, {} as FieldsValues)
}

export const isEmptyString = (value: string) => value.trim() === ""
