import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

export const NudgesModel = types
  .model("Nudges")
  .props({
    id: types.identifier,
    active: types.optional(types.boolean, false),
    body: types.optional(types.maybeNull(types.string), ""),
    default: types.optional(types.boolean, false),
    defaultSendAt: types.optional(customTypes.iso8601, null),
    displayName: types.optional(types.maybeNull(types.string), ""),
    route: types.optional(types.maybeNull(types.string), ""),
    routeParam: types.optional(types.maybeNull(types.string), ""),
    title: types.optional(types.maybeNull(types.string), ""),
    type: types.optional(types.maybeNull(types.string), ""),
  })
  .actions((self) => ({
    setTitle(value: string) {
      self.title = value
    },
    setBody(value: string) {
      self.body = value
    },
    setType(value: string) {
      self.type = value
    },
    setRoute(value: string) {
      self.route = value
    },
    setRouteParam(value: string) {
      self.routeParam = value
    },
    setDefaultSendAt(value: Date) {
      self.defaultSendAt = value
    },
    setDisplayName(value: string) {
      self.displayName = value
    },
    setIsDefault(value: boolean) {
      self.default = value
    },
    setIsActive(value: boolean) {
      self.active = value
    },

    reset() {
      self.title = ""
      self.body = ""
      self.type = ""
      self.route = ""
      self.routeParam = ""
      self.defaultSendAt = null
      self.displayName = ""
      self.default = false
      self.active = false
    },
  }))
  .views((self) => ({
    get createNudgeErrors() {
      return validate(CREATE_NEW_NUDGE_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_NUDGE_RULES: ValidationRules = {
  title: {
    presence: { allowEmpty: false, message: "Required" },
  },
  body: {
    presence: { allowEmpty: false, message: "Required" },
  },
  displayName: {
    presence: { allowEmpty: false, message: "Required" },
  },
}

export interface Nudges extends Instance<typeof NudgesModel> {}
export interface NudgesSnapshot extends SnapshotOut<typeof NudgesModel> {}
