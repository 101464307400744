import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { MslEvent } from "../../models/msl-event"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useMslEventForm } from "./useMslEventForm"

export enum ModalMode {
  ADD_NEW_EVENT,
  EDIT_EVENT,
}

interface CreateMslEventModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateMslEventModal = observer(
  ({ open, closeModal, mode }: CreateMslEventModalProps) => {
    const {
      mslEventData,
      errors,
      loading,
      submitted,
      onCancel,
      onSubmit,
      formConfig,
    } = useMslEventForm({ mode, closeModal, isModalOpen: open })

    return (
      <FormModal
        open={open}
        modalTitle={
          mode === ModalMode.ADD_NEW_EVENT
            ? "Create New MSL event"
            : "Edit MSL event"
        }
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={
          mode === ModalMode.ADD_NEW_EVENT ? "Create Event" : "Save"
        }
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<MslEvent>
            submitted={submitted}
            values={mslEventData}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT_EVENT}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateMslEventModal }
