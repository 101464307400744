import React from "react"

import { Box } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

const useStyles = makeStyles((theme) =>
  createStyles({
    section: {
      backgroundColor: "#F8FAFA",
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2, 3),
      borderRadius: theme.spacing(2.5),
    },
  }),
)

export const SectionLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  return <Box className={classes.section}>{children}</Box>
}
