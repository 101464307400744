import React, { useState, useRef, useEffect } from "react"
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  OutlinedInput,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { debounce } from "rambdax"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"

const THROTTLE_DELAY = 2000

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionContainer: {
      background: "transparent",
      "box-shadow": "none",
      minHeight: `0px !important`,
    },
    accordionInputRoot: {
      minHeight: `0px !important`,
      justifyContent: "flex-start",
      padding: 0,
    },
    accordionInputContent: {
      flex: "initial",
      margin: `0px !important`,
    },
    accordionInputIcon: {
      color: "#2D353B",
    },
    accordionLabel: {
      fontSize: 22,
      lineHeight: "27px",
      color: "#2D353B",
      fontWeight: "bold",
    },
    accordionDetailsRoot: {
      padding: 0,
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(12),
    },
    searchInput: {
      width: "100%",
      background: "#FFFFFF",
      "box-shadow": "0px 2px 5px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      borderWidth: 0,
    },
    searchInputNotchedOutline: {
      border: "0px solid !important",
    },
  }),
)

const SearchPostDropdown = observer(() => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [expanded, setExpanded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  const { adminToolsStore } = useStores()
  const {
    apiAdminSearchPost,
    setShowSearchPostResults,
    resetSearchPostResults,
  } = adminToolsStore

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  /**
   * Debounced method for search api, to avoid api calls while user is writing in the search field
   * The api call will be fired 2 seconds after the last debounced method
   */
  const debouncedAdminSearchUser = useRef(
    debounce(async (query: string) => {
      try {
        //avoid firing api call with empty input
        if (query.trim().length) {
          await apiAdminSearchPost({ query })
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }, THROTTLE_DELAY),
  )

  /**
   * Local flag which checks if the search term is empty or not
   */
  const isSearchCompleted = Boolean(searchTerm.trim().length)

  /**
   * Control the ui logic of `showSearchPostResults` flag
   */
  useEffect(() => {
    //if search input visible and search input is completed activate `showSearchPostResults` flag
    if (isSearchCompleted && expanded) {
      setShowSearchPostResults(true)
    }

    //if search input is NOT visible OR search input is NOT completed disable `showSearchPostResults` flag
    if (!isSearchCompleted || !expanded) {
      setShowSearchPostResults(false)
      resetSearchPostResults()
    }

    //reset search term on accordion close
    if (!expanded) {
      setSearchTerm("")
    }
  }, [
    isSearchCompleted,
    expanded,
    setShowSearchPostResults,
    resetSearchPostResults,
  ])

  /**
   * Search Input `onChange` handler
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
    setLoading(true)

    //call debounced method on every input change
    debouncedAdminSearchUser.current(event.target.value)
  }

  /**
   * Accordion `onChange` handler. Save locally the expanded flag
   */
  const onChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion className={classes.accordionContainer} onChange={onChange}>
      <AccordionSummary
        expandIcon={
          <ArrowDropDownIcon
            fontSize="large"
            className={classes.accordionInputIcon}
          />
        }
        aria-controls="search-dropdown"
        id="search-dropdown"
        classes={{
          root: classes.accordionInputRoot,
          content: classes.accordionInputContent,
        }}>
        <Typography className={classes.accordionLabel}>
          Search for a post
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <OutlinedInput
            id="outlined-basic"
            label=""
            autoFocus
            classes={{
              root: classes.searchInput,
              notchedOutline: classes.searchInputNotchedOutline,
              focused: classes.searchInputNotchedOutline,
            }}
            value={searchTerm}
            onChange={onInputChange}
            endAdornment={
              /**
               * While api call is loading, show the progress component
               */
              isSearchCompleted && loading && <CircularProgress size={20} />
            }
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
})

export default SearchPostDropdown
