import React from "react"
import { GridCellProps } from "@mui/x-data-grid"
import { Button } from "@material-ui/core"
import { WhatVideo } from "../../models/what-videos"
import { ShortText } from "../admin-form-components"
import { useStores } from "../../models/root-store"
import { WhatVideoAction } from "./what-video-action"

interface CellComponentProps extends GridCellProps {
  openEditWhatVideoModal?: (event: WhatVideo) => void
  videoID?: string
}

const CellComponent = ({
  openEditWhatVideoModal,
  videoID,
  ...props
}: CellComponentProps) => {
  const { adminMslEventStoreModel } = useStores()
  const { whatVideos } = adminMslEventStoreModel

  const whatVideo = whatVideos.find((video: WhatVideo) => video.id === videoID)

  switch (props.field) {
    case "active":
      return <WhatVideoAction whatVideo={whatVideo} />
    case "edit":
      return (
        <Button
          variant="contained"
          onClick={() => openEditWhatVideoModal(whatVideo)}>
          Edit
        </Button>
      )
    default:
      return <ShortText value={props.value || "N/A"} textLimit={16} />
  }
}

export { CellComponent }
