import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const TriIdeaProductModel = types.model("TriIdeaProduct").props({
  id: types.optional(types.string, ""),
  displayName: types.optional(types.string, ""),
})

export interface TriIdeaProduct extends Instance<typeof TriIdeaProductModel> {}
export interface TriIdeaProductSnapshot
  extends SnapshotOut<typeof TriIdeaProductModel> {}
