import Tron from "reactotron-react-js"
import { onSnapshot } from "mobx-state-tree"
import tronsauce from "reactotron-apisauce"
import { mst } from "reactotron-mst"
import { ReactotronConfig, DEFAULT_REACTOTRON_CONFIG } from "./reactotron-config"
import { __DEV__ } from "../constants"

// Teach TypeScript about the bad things we want to do.
declare global {
  interface Console {
    /**
     * Hey, it's Reactotron if we're in dev, and no-ops if we're in prod.
     */
    //@ts-ignore
    tron: typeof Tron
  }
}

// in dev, we attach Reactotron, in prod we attach a interface-compatible mock.
if (__DEV__) {
  //@ts-ignore
  console.tron = Tron // attach reactotron to `console.tron`
}

/**
 * You'll probably never use the service like this since we hang the Reactotron
 * instance off of `console.tron`. This is only to be consistent with the other
 * services.
 */
export class Reactotron {
  config: ReactotronConfig
  rootStore: any

  /**
   * Create the Reactotron service.
   *
   * @param config the configuration
   */
  constructor(config: ReactotronConfig = DEFAULT_REACTOTRON_CONFIG) {
    // merge the passed in config with some defaults
    this.config = {
      host: "localhost",
      useAsyncStorage: true,
      ...config,
      state: {
        initial: false,
        snapshots: false,
        ...(config && config.state),
      },
    }
  }

  /**
   * Hook into the root store for doing awesome state-related things.
   *
   * @param rootStore The root store
   */
  setRootStore(rootStore: any, initialData: any) {
    if (__DEV__) {
      rootStore = rootStore as any // typescript hack
      this.rootStore = rootStore

      //@ts-ignore
      const { initial, snapshots } = this.config.state
      const name = "ROOT STORE"

      // logging features
      if (initial) {
        //@ts-ignore
        console.tron.display({
          name,
          value: initialData,
          preview: "Initial State",
        })
      }
      // log state changes?
      if (snapshots) {
        onSnapshot(rootStore, (snapshot) => {
          //@ts-ignore
          console.tron.display({ name, value: snapshot, preview: "New State" })
        })
      }

      // @ts-ignore
      console.tron.trackMstNode(rootStore)
    }
  }

  /**
   * Configure reactotron based on the the config settings passed in, then connect if we need to.
   */
  async setup() {
    // only run this in dev... metro bundler will ignore this block: 🎉
    if (__DEV__) {
      // configure reactotron
      Tron.configure({
        name: "Freshtrip Pro",
        host: this.config.host,
      })

      // ignore some chatty `mobx-state-tree` actions
      const RX = /postProcessSnapshot|@APPLY_SNAPSHOT/

      // hookup mobx-state-tree middleware
      Tron.use(
        mst({
          filter: (event) => RX.test(event.name) === false,
        }),
      )

      Tron.use(tronsauce())

      // connect to the app
      Tron.connect()

      Tron.onCustomCommand({
        command: "resetStore",
        handler: async () => {
          this.rootStore.reset()
        },
        title: "Reset Store",
        description: "Resets the store to its default state.",
      })

      // clear if we should
      if (this.config.clearOnLoad) {
        //@ts-ignore
        Tron.clear()
      }
    }
  }
}
