import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const WhatVideoCategoriesModel = types
  .model("WhatVideoCategories")
  .props({
    id: types.identifier,
    title: types.optional(types.maybeNull(types.string), ""),
  })

export interface WhatVideoCategories
  extends Instance<typeof WhatVideoCategoriesModel> {}
export interface WhatVideoCategoriesSnapshot
  extends SnapshotOut<typeof WhatVideoCategoriesModel> {}
