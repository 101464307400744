import React, { useState, useEffect } from "react"
import moment from "moment"
import clsx from "clsx"
import {
  FormControl,
  Grid,
  Stack,
  Paper,
  Typography,
  Modal,
  Button,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { useHistory } from "react-router-dom"
import { drawerWidth } from "../../components/app-drawer"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import { useSnackbars } from "../../components/use-snackbar"
import { isEmpty } from "ramda"
import { noop } from "../../utils"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { ReactComponent as CreateSessionIcon } from "../../assets/images/create_session_icon.svg"
import { ReactComponent as DropDownArrowIcon } from "../../assets/images/drop_down_arrow.svg"
import {
  DatePickerDialog,
  TimePickerDialog,
} from "../../components/custom-date-time-picker"
import { typography } from "../../services/theme/typography"
import { FormField, FormInput } from "./form-input"

interface StyleProps {
  drawerWidth: number
  isDrawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    position: "absolute",
    boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: 453,
      height: "auto",
      left: "50%",
      top: "50%",
      transform: (props) =>
        `translateX(calc(-50% + ${
          props.isDrawerOpen ? props.drawerWidth / 2 : 0
        }px)) translateY(-50%)`,
    },
  },
  iconContainer: {
    width: 92,
    height: 92,
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2.5, 2.25),
    position: "absolute",
    top: 0,
    right: 0,
    background: theme.palette.secondary.main,
    borderRadius: `0px 10px 0px 1000px`,
  },
  formRoot: {
    padding: theme.spacing(3, 5),
    outline: "none",
    width: "100%",
    height: "100%",
  },
  editLabelBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBEEF0",
    padding: theme.spacing(3, 5),

    fontSize: theme.spacing(3.25),
    color: "#22878D",
    textAlign: "center",
    ...typography.circularXXMedium,
  },
  formTitle: {
    fontSize: theme.spacing(3.25),
    color: "#485359",
    ...typography.circularXXBold,
    marginBottom: theme.spacing(3),
  },
  editFormTitle: {
    fontSize: theme.spacing(2.75),
  },
  formControl: {
    width: "100%",
  },
  formButtons: {
    padding: theme.spacing(2, 5),
    background: "#EBEEF0",
  },
  actionButton: {
    flex: 1,
  },
  requiredLabel: {
    fontSize: theme.spacing(2),
    color: theme.palette.error.main,
    ...typography.circularXXLight,
  },
  dateInput: {
    flex: 1,
  },
}))

const CreateSession = observer(({ editMode }: { editMode?: boolean }) => {
  const history = useHistory()
  const { cohortStore, uiStore } = useStores()
  const { isDrawerOpen } = uiStore
  const { currentCohort } = cohortStore

  const {
    newSession,
    currentSession,
    apiCreateSession,
    apiEditCurrentSession,
  } = currentCohort
  const sourceSession = editMode ? currentSession : newSession
  const {
    name,
    setName,
    date,
    setDate,
    createSessionsErrors: errors,
  } = sourceSession

  const [backDialogOpen, setIsBackDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [currentSessionLabelTitle, setCurrentSessionLabelTitle] = useState("")

  const classes = useStyles({ drawerWidth, isDrawerOpen })

  const isNameInvalid = submitted && Boolean(errors.name)
  const isDateInvalid = submitted && Boolean(errors.date)

  //save the current session name to use it on modal label
  //trigger only on currentSession.id update, to prevent trigger from input change
  useEffect(() => {
    if (currentSession?.name) {
      setCurrentSessionLabelTitle(currentSession?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession?.id])

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const goBack = () => history.goBack()

  const handleClose = () => {
    setIsBackDialogOpen(true)

    goBack()
  }

  const onCancelPress = () => {
    setIsBackDialogOpen(true)
  }

  const handleBackDialogClose = () => {
    setIsBackDialogOpen(false)
  }

  const handleDateChange = (date: moment.Moment) => {
    if (date) {
      setDate(date.toDate())
    }
  }

  const onSave = async () => {
    setSubmitted(true)
    if (!isEmpty(errors)) return
    setSubmitted(false)
    try {
      setLoading(true)
      if (editMode) {
        await apiEditCurrentSession()
      } else {
        await apiCreateSession()
      }

      setSnackbarSeverity("success")
      setSnackbarText("Successfully created session")
      setShowSnackbar(true)
      goBack()
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  const body = (
    <Paper className={classes.paper}>
      {editMode ? (
        <div className={classes.editLabelBox}>{currentSessionLabelTitle} </div>
      ) : (
        <div className={classes.iconContainer}>
          <CreateSessionIcon />
        </div>
      )}

      <Grid container className={classes.formRoot}>
        <Grid item xs={12}>
          <Typography
            className={clsx(classes.formTitle, {
              [classes.editFormTitle]: editMode,
            })}>
            {editMode ? "Edit Session" : "Create New Session"}
          </Typography>
        </Grid>

        <Stack width="100%" spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.requiredLabel}>Required*</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormField
              label="Session Name"
              required
              isFieldCompleted={!errors.name}>
              <FormInput
                id="sessionName"
                label=""
                name="sessionName"
                placeholder="Enter name here"
                value={name}
                autoFocus
                error={isNameInvalid}
                helperText={submitted && errors.name}
                onChange={(evt) => {
                  setName(evt.target.value)
                }}
              />
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <FormField
              label="Start Date"
              required
              isFieldCompleted={!errors.date}>
              <DatePickerDialog
                value={date}
                onChange={handleDateChange}
                toggleButtonClassName={classes.dateInput}>
                <FormInput
                  id="sessionDate"
                  label=""
                  name="sessionDate"
                  placeholder="Choose Date"
                  value={Boolean(date) ? moment(date).format("MM/DD/YYYY") : ""}
                  error={isDateInvalid}
                  disabled
                  helperText={submitted && errors.date}
                  InputProps={{
                    endAdornment: <CalendarTodayIcon />,
                  }}
                />
              </DatePickerDialog>
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <FormField label="Time" required isFieldCompleted={!errors.date}>
              <TimePickerDialog
                value={date}
                onChange={handleDateChange}
                toggleButtonClassName={classes.dateInput}>
                <FormInput
                  id="sessionTime"
                  label=""
                  name="sessionTime"
                  placeholder="Pick a time"
                  value={Boolean(date) ? moment(date).format("hh:mm A") : ""}
                  error={isDateInvalid}
                  disabled
                  helperText={submitted && errors.date}
                  InputProps={{
                    endAdornment: <DropDownArrowIcon />,
                  }}
                />
              </TimePickerDialog>
            </FormField>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} className={classes.formButtons}>
        <FormControl className={classes.formControl}>
          <Stack direction="row" flex={1} spacing={4}>
            <Button
              onClick={onCancelPress}
              variant="outlined"
              size="large"
              className={classes.actionButton}>
              Cancel
            </Button>

            <Button
              endIcon={
                loading && <CircularProgress color="secondary" size={24} />
              }
              onClick={onSave}
              disabled={loading}
              variant="contained"
              size="large"
              className={classes.actionButton}>
              {editMode ? "Save Changes" : "Create Session"}
            </Button>
          </Stack>
        </FormControl>
      </Grid>
    </Paper>
  )

  return (
    <div>
      <Modal
        open={true}
        disablePortal
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>

      <Dialog
        open={backDialogOpen}
        onClose={handleBackDialogClose}
        aria-labelledby="confirm-go-back">
        <DialogTitle id="confirm-go-back">
          <Typography variant="h3">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may have some unsaved changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={goBack} color="inherit">
            Go back
          </Button>
          <Button onClick={handleBackDialogClose} color="inherit" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default CreateSession
