import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Typography, CircularProgress } from "@material-ui/core"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { FullPersonDetails } from "../../models/admin-user-search/full-person-details"
import VerificationCodeCard from "./verification-code-card"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: "#fff",
      marginTop: theme.spacing(3),
      flex: 1,
    },
    labelContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: `${theme.spacing(3)} 0`,
    },
    label: {
      fontWeight: "bold",
      fontSize: 24,
      color: "#039CAD",
    },
    listContent: {
      width: "100%",
      padding: `0 ${theme.spacing(3)}`,
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      position: "relative",
      alignSelf: "center",
    },
    loadingFlag: {
      alignSelf: "center",
    },
  }),
)

export const PersonVerificationCodes = observer(() => {
  const classes = useStyles()
  const { adminToolsStore } = useStores()
  const [loading, setLoading] = useState(false)

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const { selectedPerson, apiAdminGetPersonVerificationCodes } = adminToolsStore
  const { verificationCodes } = selectedPerson as FullPersonDetails

  useEffect(() => {
    const initialFetch = async () => {
      setLoading(true)
      try {
        await apiAdminGetPersonVerificationCodes()
      } catch (error) {
        if (error.message) {
          setSnackbarSeverity("error")
          setSnackbarText(error.message)
          setShowSnackbar(true)
        }
      }
      setLoading(false)
    }

    initialFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderVerificationCodesList = () => {
    if (loading) {
      return <CircularProgress className={classes.loadingFlag} />
    }

    if (verificationCodes.length === 0) {
      return <Typography>No verification codes found !</Typography>
    }

    return (
      <>
        {verificationCodes.map((verificationCode) => (
          <VerificationCodeCard
            key={verificationCode.id}
            verificationCode={verificationCode}
          />
        ))}
      </>
    )
  }

  return (
    <Stack className={classes.container}>
      <Stack className={classes.labelContainer}>
        <Typography className={classes.label}>Verification Codes</Typography>
      </Stack>

      <Stack spacing={1} className={classes.listContent}>
        {renderVerificationCodesList()}

        <div />
      </Stack>
    </Stack>
  )
})
