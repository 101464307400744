import React from "react"
import clsx from "clsx"
import { Theme, Button, ButtonProps } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityButton: {
      minHeight: 76,
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      background: "#FFFFFF",
      border: "1px solid #C7CFD4",
      "box-shadow": "0px 4px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: 4,
      color: "#677278",
      "&:hover": {
        background: "#F5F6F7",
        border: "1px solid #B0BABF",
        color: "#2D353B",
      },
      "&:active": {
        background: "#EBEEF0",
        border: "1px solid #C7CFD4",
        color: "#2D353B",
      },
    },
    activityButtonText: {
      fontSize: 18,
      lineHeight: "21px",
      color: "#677278",
      fontWeight: "normal",
      "text-transform": "none",
    },
    activityButtonIcon: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
    activeText: {
      color: "#FF0000 !important",
    },
    seeAllPostsActive: {
      background: "#0A8052",
      color: "#fff",
      "&:hover": {
        background: "#0A8052",
        color: "#fff",
      },
      "&:active": {
        background: "#0A8052",
        color: "#fff",
      },
    },
  }),
)

interface ActivityButtonProps extends ButtonProps {
  isActive?: boolean
  label: string
  activeLabel?: string
  Icon?: React.FunctionComponent<{ fill?: string }>
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  rootActiveStyle?: string
  textActiveStyle?: string
}

const ActivityButton = ({
  isActive,
  label,
  activeLabel,
  onClick,
  Icon,
  rootActiveStyle,
  textActiveStyle,
  ...buttonProps
}: ActivityButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      startIcon={Icon && <Icon fill={isActive ? "#FF0000" : "#677278"} />}
      classes={{
        root: clsx(classes.activityButton, {
          [rootActiveStyle]: isActive && Boolean(rootActiveStyle),
        }),
        textPrimary: clsx(classes.activityButtonText, {
          [classes.activeText]: isActive,
          [textActiveStyle]: isActive && Boolean(textActiveStyle),
        }),
        startIcon: classes.activityButtonIcon,
      }}
      disableTouchRipple
      onClick={onClick}
      {...buttonProps}>
      {isActive ? activeLabel : label}
    </Button>
  )
}

export default ActivityButton
