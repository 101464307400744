import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Paper, Stack, Typography, Button } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ChatIcon from "@material-ui/icons/Chat"
import { ModalRoutes } from "../../AppRoutes"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    paperRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRight: "1px solid #C7CFD4",
      borderRadius: 0,
      boxShadow: "none",
      padding: theme.spacing(6, 10, 6, 8),
      background: "#EBEEF0",
    },
    chatIcon: {
      fontSize: theme.spacing(11),
      color: "#677278",
    },
    infoText: {
      fontSize: theme.spacing(2.5),
      color: "#1771A6",
      textAlign: "center",
      ...typography.circularXXMedium,
    },
    addNewMemberButton: {
      padding: theme.spacing(1, 2),
      ...typography.circularXXBold,
    },
  }),
)

const EmptyInboxAside = ({ isGroupInbox }: { isGroupInbox?: boolean }) => {
  const classes = useStyles()
  const location = useLocation()

  const message = isGroupInbox
    ? "There are no members to chat with. Add new members to this program to have conversations here."
    : "There are no members to chat with. Add new members to this program to have conversations here."

  return (
    <Paper className={classes.paperRoot}>
      <Stack spacing={5} flex={1} alignItems="center">
        <ChatIcon className={classes.chatIcon} />

        <Typography className={classes.infoText}>{message}</Typography>

        <Link
          to={{
            pathname: `/${ModalRoutes.addCohortParticipants}`,
            state: {
              background: location,
              modal: ModalRoutes.addCohortParticipants,
            },
          }}>
          <Button variant="contained" className={classes.addNewMemberButton}>
            ADD NEW MEMBER
          </Button>
        </Link>
      </Stack>
    </Paper>
  )
}

export { EmptyInboxAside }
