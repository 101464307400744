import React from "react"
import clsx from "clsx"
import { Theme, Stack, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { ReactComponent as CurrentTriImage } from "../../assets/images/current_tri.svg"
import { FullPersonDetails } from "../../models/admin-user-search/full-person-details"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactInfoCard: {
      padding: theme.spacing(2),
      border: "1px solid #C7CFD4",
      borderRadius: theme.spacing(0.5),
      background: "#FFFFFF",
      position: "relative",
    },
    contactInfoName: {
      fontSize: 22,
      lineHeight: "26px",
      color: "#2D353B",
      "font-weight": "600",
    },
    contactInfoDetails: {
      fontSize: 20,
      lineHeight: "30px",
      color: "#63646D",
      "letter-spacing": "-0.32px",
      "word-break": "break-all",
    },
    infoSection: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      border: "1px solid #C7CFD4",
      borderRadius: theme.spacing(0.5),
      background: "#FFFFFF",
      alignItems: "center",
    },
    infoSectionTitle: {
      fontSize: 16,
      lineHeight: "19px",
      color: "#039CAD",
      "font-weight": "500",
    },
    infoSectionValue: {
      fontSize: 16,
      lineHeight: "31px",
      color: "#63646D",
    },
    currentTriText: {
      color: "#039CAD",
    },
    currentTriImage: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }),
)

const ContactInfoSection = ({
  selectedPerson,
}: {
  selectedPerson: FullPersonDetails
}) => {
  const classes = useStyles()

  const {
    person,
    formattedLastCheckIn,
    totalUserPosts,
    lastPostDate,
    currentTri,
  } = selectedPerson

  return (
    <>
      <Stack spacing={1} className={classes.contactInfoCard}>
        <Typography className={classes.contactInfoName}>
          Contact Info
        </Typography>

        <Typography className={classes.contactInfoDetails}>
          {person.email}
          {Boolean(person.mobilePhone) && (
            <Typography className={classes.contactInfoDetails}>
              {person.countryCode} {person.mobilePhone}
            </Typography>
          )}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Stack className={classes.infoSection}>
          <Typography className={classes.infoSectionTitle}>
            Last Check-in
          </Typography>

          <Typography className={classes.infoSectionValue}>
            {formattedLastCheckIn}
          </Typography>
        </Stack>

        <Stack className={classes.infoSection}>
          <Typography className={classes.infoSectionTitle}>
            # of Posts
          </Typography>

          <Typography className={classes.infoSectionValue}>
            {totalUserPosts.length}
          </Typography>
        </Stack>

        <Stack className={classes.infoSection}>
          <Typography className={classes.infoSectionTitle}>
            Last Post
          </Typography>

          <Typography className={classes.infoSectionValue}>
            {lastPostDate}
          </Typography>
        </Stack>
      </Stack>

      {Boolean(currentTri) && (
        <Stack className={classes.contactInfoCard}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack spacing={1}>
              <Typography
                className={clsx(
                  classes.contactInfoName,
                  classes.currentTriText,
                )}>
                Current Tri
              </Typography>

              {Boolean(currentTri?.why) && (
                <Typography className={classes.contactInfoDetails}>
                  Why: {currentTri?.why}
                </Typography>
              )}

              {Boolean(currentTri?.formattedHow) && (
                <Typography className={classes.contactInfoDetails}>
                  How: {currentTri?.formattedHow}
                </Typography>
              )}
            </Stack>

            {Boolean(currentTri?.behavior?.iconFullURI) ? (
              <img
                src={currentTri?.behavior?.iconFullURI}
                className={classes.currentTriImage}
                alt="current_tri_image"
              />
            ) : (
              <CurrentTriImage className={classes.currentTriImage} />
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default ContactInfoSection
