import React from "react"
import clsx from "clsx"
import { Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userRole: {
      display: "flex",
      alignItems: "center",
      height: theme.spacing(3),
      padding: theme.spacing(0, 1.75),
      borderRadius: theme.spacing(0.5),
      color: theme.palette.common.white,
      fontSize: 13,
      lineHeight: theme.spacing(1),
      letterSpacing: "-0.02em",
      textTransform: "uppercase",
      ...typography.circularXXMedium,
    },
    coachRole: {
      background: "#0B8857",
    },
    adminRole: {
      background: theme.palette.info.main,
    },
  }),
)

const UserRole = () => {
  const classes = useStyles()

  const { loginStore } = useStores()
  const { authentication } = loginStore

  return (
    <Typography
      className={clsx(classes.userRole, {
        [classes.coachRole]: authentication.isCoach,
        [classes.adminRole]:
          authentication.isSiteAdmin || authentication.isFtAdmin,
      })}>
      {authentication.roleTitle}
    </Typography>
  )
}

export { UserRole }
