import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useTrainerForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newTrainer,
    apiAdminCreateTrainer,
    apiAdminEditTrainer,
    apiAdminTrainerUploadContent,
  } = adminMslEventStoreModel

  const {
    isEditMode,
    reset,
    uploadFiles,
    setUploadFile,
    createMslTrainerErrors: errors,
  } = newTrainer

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    reset()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)
      await apiAdminTrainerUploadContent()

      if (isEditMode) {
        await apiAdminEditTrainer()
        setAndShowSnackbar({
          text: "Successfully edited trainer !",
          severity: "success",
        })
      } else {
        await apiAdminCreateTrainer()
        setAndShowSnackbar({
          text: "Successfully created trainer !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "personID",
      onChangeMethod: "setPersonID",
      label: "Person ID",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter person id here",
      },
    },
    {
      fieldName: "fullName",
      required: true,
      onChangeMethod: "setFullName",
      label: "Full Name",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter full name here",
      },
    },
    {
      fieldName: "circleImageURL",
      required: true,
      onChangeMethod: "setCircleImageURL",
      label: "Circle Image Url",
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter circle image url or select locally",
        filePickerProps: {
          label: "Select circle image",
          fileType: "train_trainer_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.circleImageURL,
        },
      },
    },
    {
      fieldName: "bannerImageURL",
      required: true,
      onChangeMethod: "setBannerImageURL",
      label: "Banner Image Url",
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter banner image url or upload locally",
        filePickerProps: {
          label: "Select banner image",
          fileType: "train_trainer_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.bannerImageURL,
        },
      },
    },
    {
      fieldName: "v2CircleImageURL",
      onChangeMethod: "setV2CircleImageURL",
      label: "Circle Image Url V2",
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter circle image url v2 or upload locally",
        filePickerProps: {
          label: "Select banner image",
          fileType: "train_trainer_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.v2CircleImageURL,
        },
      },
    },
    {
      fieldName: "v2BannerImageURL",
      onChangeMethod: "setV2BannerImageURL",
      label: "Banner Image Url V2",
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter banner image url v2 here",
        filePickerProps: {
          label: "Select banner image",
          fileType: "train_trainer_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.v2BannerImageURL,
        },
      },
    },
    {
      fieldName: "bio",
      onChangeMethod: "setBio",
      label: "Bio",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter bio here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "professionalTitle",
      onChangeMethod: "setProfessionalTitle",
      label: "Professional title",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter professional title here",
      },
    },
  ]

  return {
    isEditMode,
    values: newTrainer,
    errors,
    loading,
    submitted,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useTrainerForm }
