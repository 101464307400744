import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  createContext,
  Fragment,
} from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Collapse, Box, Theme } from "@material-ui/core"
import { GridColDef, GridCellProps } from "@mui/x-data-grid"

import { useStores } from "../../models/root-store"
import { AdminTableHeader } from "../admin-table-header"
import { LogsTableHeader } from "../logs-table/logs-table-header"
import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { CreateTagModal } from "./create-tag-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableHeaderButton: {
      width: "initial",
    },
    tableCell: {
      width: "16% !important",
      maxWidth: "16%",
    },
  }),
)

export const ModalContext = createContext<{ openCreateTagModal: () => void }>({
  openCreateTagModal: () => {},
})

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "label",
    headerName: "Label",
  },
  {
    field: "displayName",
    headerName: "Display Name",
  },
  {
    field: "type",
    headerName: "Type",
  },
  {
    field: "description",
    headerName: "Description",
  },
  {
    field: "isUserFacing",
    headerName: "Is User Facing?",
    type: "boolean",
  },
]

const AdminTagsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [showTable, setShowTable] = useState<{ [key: string]: boolean }>({})
  const [isCreateTagModalOpen, toggleCreateTagModalOpen] = useState(false)

  const { adminMslEventStoreModel } = useStores()
  const {
    tags,
    tagsByType,
    apiAdminGetTags,
    apiAdminGetTagTypes,
  } = adminMslEventStoreModel

  const toggleTable = (tagType: string) => {
    setShowTable({ ...showTable, [tagType]: !showTable[tagType] })
  }

  const openCreateTagModal = useCallback(() => {
    toggleCreateTagModalOpen(true)
  }, [])

  const closeCreateTagModal = () => {
    toggleCreateTagModalOpen(false)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent {...props} />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const contextValue = useMemo(() => ({ openCreateTagModal }), [
    openCreateTagModal,
  ])

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetTags()
        await apiAdminGetTagTypes()
      } catch (e) {}
      toggleFirstLoading(false)
    }
    initialFetch()
  }, [apiAdminGetTags, apiAdminGetTagTypes])

  return (
    <div className={classes.root}>
      <Box>
        <AdminTableHeader
          tableLabel="Tags"
          addNewButtonLabel="New Tag"
          addNewButtonOnClick={openCreateTagModal}
        />
      </Box>

      <Box mt={3} />

      <ModalContext.Provider value={contextValue}>
        <LogsTableHeader
          tableLabel="All types"
          buttonContainerClass={classes.tableHeaderButton}
          isExpanded={showTable["index"]}
          toggleExpanded={() => toggleTable("index")}
        />
        <Collapse in={showTable["index"]}>
          {showTable["index"] && (
            <CustomGridTable
              rows={tags.slice()}
              columns={columns}
              aria-label="tags-table"
              tableCellClass={classes.tableCell}
              cell={MemoTableCell}
              emptyStateLabel="When you create some tags, they will appear here"
              loading={firstLoading}
            />
          )}
        </Collapse>

        {Object.keys(tagsByType).map((tagType) => {
          const isExpanded = showTable[tagType]

          return (
            <Fragment key={tagType}>
              <Box mt={3} />

              <LogsTableHeader
                tableLabel={`Type: ${tagType}`}
                buttonContainerClass={classes.tableHeaderButton}
                isExpanded={isExpanded}
                toggleExpanded={() => toggleTable(tagType)}
              />

              <Collapse in={isExpanded}>
                {isExpanded && (
                  <CustomGridTable
                    rows={tagsByType[tagType].slice()}
                    columns={columns}
                    aria-label={`${tagType}-tags-table`}
                    tableCellClass={classes.tableCell}
                    cell={MemoTableCell}
                    emptyStateLabel="When you create some tags, they will appear here"
                    loading={firstLoading}
                  />
                )}
              </Collapse>
            </Fragment>
          )
        })}
      </ModalContext.Provider>

      <CreateTagModal
        open={isCreateTagModalOpen}
        closeModal={closeCreateTagModal}
      />
    </div>
  )
})

export { AdminTagsList }
