import Logger from "js-logger"
import { __DEV__ } from "../config/constants"

// Set level to NONE for production, INFO for
// development and allow to override to other levels for debugging

Logger.setDefaults({
  defaultLevel: __DEV__ ? Logger.ERROR : Logger.OFF,
})

export const Level = {
  TRACE: __DEV__ ? Logger.TRACE : Logger.OFF,
  DEBUG: __DEV__ ? Logger.DEBUG : Logger.OFF,
  INFO: __DEV__ ? Logger.INFO : Logger.OFF,
  TIME: __DEV__ ? Logger.TIME : Logger.OFF,
  WARN: __DEV__ ? Logger.WARN : Logger.OFF,
  ERROR: __DEV__ ? Logger.ERROR : Logger.OFF,
  OFF: Logger.OFF,
}

/*************************************************************
 *
 * Configure Specific Loggers
 *
 *************************************************************/

Logger.get("core").setLevel(Level.INFO) // don't show up everything
Logger.get("snapshots").setLevel(Level.ERROR) // allow showing state diffs
Logger.get("api").setLevel(Level.ERROR) // allows logging for API

Logger.get("myloads").setLevel(Level.ERROR)
Logger.get("loadform").setLevel(Level.ERROR)

export const logger = Logger.get("core")

export const getLogger = (loggerName: string) => Logger.get(loggerName)

export default Logger
