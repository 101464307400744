import React, { useEffect } from "react"
import Collapse from "@material-ui/core/Collapse"
import VoiceChatIcon from "@material-ui/icons/VoiceChat"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import useToggle from "../use-toggle/use-toggle"
import { useParams, Link, useRouteMatch } from "react-router-dom"
import { RouteParams } from "../../AppRoutes"
import { FC } from "react"
import { Cohort } from "../../models/cohort"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { useInterval } from "../use-interval"
import { NotificationBadge } from "../../components/notification-badge"
import { ReactComponent as MembersIcon } from "../../assets/images/members_icon.svg"
import { ReactComponent as SessionsIcon } from "../../assets/images/nav_sessions_icon.svg"
import { ReactComponent as SettingsIcon } from "../../assets/images/settings_icon.svg"
import { ReactComponent as DirectChatIcon } from "../../assets/images/nav_direct_chat.svg"
import { ReactComponent as GroupChatIcon } from "../../assets/images/nav_group_chat.svg"
import { ReactComponent as DropDownArrowIcon } from "../../assets/images/drop_down_arrow.svg"
import { typography } from "../../services/theme/typography"

export const CHAT_LISTS_REFRESH_RATE = 30000

interface AppDrawerCollabslableListItemProps {
  cohort: Cohort
}

const useStyles = makeStyles((theme) =>
  createStyles({
    cohortList: {
      marginBottom: theme.spacing(2.5),
    },
    cohortListItem: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 3),
      marginBottom: theme.spacing(2),
    },
    cohortName: {
      ...typography.circularXXBold,
      fontSize: theme.spacing(2.25),
      lineHeight: "22px",
      color: "#485359",
      textTransform: "none",
      flex: 1,
    },
    cohortIconContainer: {
      minWidth: 47,
    },
    nestedListItem: {
      minHeight: theme.spacing(6),
      padding: theme.spacing(0, 0, 0, 5),
      paddingRight: 0,
    },
    nestedListName: {
      fontSize: theme.spacing(2),
      color: "#677278",
      margin: 0,
      ...typography.circularXXRegular,
    },
    nestedListIconContainer: {
      minWidth: 0,
      width: theme.spacing(8),
    },
    selectedListItem: {
      backgroundColor: "#F1F1F1 !important",
    },
    badgeContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    navIcon: {
      color: "#677278",
    },
    downArrow: {
      transform: "rotate(-180deg)",
    },
    nestedBadgeContainer: {
      width: theme.spacing(8),
      padding: theme.spacing(0, 1),
      display: "flex",
    },
    cohortDivider: {
      width: "100%",
      borderTop: "1px solid #EBEEF0",
    },
    activeDivider: {
      borderTop: "1px solid #B0BABF",
    },
  }),
)

export const AppDrawerCollabslableListItem: FC<AppDrawerCollabslableListItemProps> = observer(
  (props) => {
    const { cohort } = props
    const { loginStore, cohortStore, siteStore } = useStores()
    const { authentication } = loginStore
    const { currentSite } = siteStore
    const classes = useStyles()
    const params = useParams<RouteParams>()

    const matchCohortRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId",
    )
    const matchCohortSettingsRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/settings",
    )
    const matchCohortSessionsRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/sessions",
    )
    const matchCohortChatRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/chat",
    )
    const matchCohortGroupChatRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/group-chat",
    )
    const matchCohortChatRoomRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/chat/:roomId",
    )
    const matchCohortMembersRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/members",
    )
    const matchCohortPersonDetailsRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/members/:personId",
    )
    const matchPersonRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/person/:personId",
    )
    const matchCohortOneOnOneChatRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/video-chat",
    )

    const isCurrentCohortActive =
      matchCohortRoute?.params.cohortId === cohort.id

    const areWeInCohortsRoute = [
      matchCohortRoute?.isExact,
      matchCohortSettingsRoute?.isExact,
      matchPersonRoute?.isExact,
    ].includes(true)

    const isSessionsActive =
      matchCohortSessionsRoute?.isExact && isCurrentCohortActive

    const isGroupChatActive =
      matchCohortGroupChatRoute?.isExact && isCurrentCohortActive

    const isChatActive = matchCohortChatRoute?.isExact && isCurrentCohortActive

    const isChatConversationActive =
      matchCohortChatRoomRoute?.isExact && isCurrentCohortActive

    const isMembersActive =
      matchCohortMembersRoute?.isExact && isCurrentCohortActive

    const isPersonDetailsActive =
      matchCohortPersonDetailsRoute?.isExact && isCurrentCohortActive

    const isSettingsActive =
      matchCohortSettingsRoute?.isExact && isCurrentCohortActive

    const isVideoChatActive =
      matchCohortOneOnOneChatRoute?.isExact && isCurrentCohortActive

    const isActive =
      [
        isSessionsActive,
        isGroupChatActive,
        isChatActive,
        isChatConversationActive,
        isMembersActive,
        isPersonDetailsActive,
        isSettingsActive,
        isVideoChatActive,
      ].includes(true) && isCurrentCohortActive

    const [cohortsTabOpen, toggleCohortsTab] = useToggle(isActive ?? false)

    /**
     * make sure we keep the drawer open if state changes
     */
    useEffect(() => {
      toggleCohortsTab(isActive)
    }, [
      matchCohortRoute?.isExact,
      matchCohortSettingsRoute?.isExact,
      matchPersonRoute?.isExact,
      areWeInCohortsRoute,
      toggleCohortsTab,
      isActive,
    ])

    const refreshChatLists = async () => {
      if (cohort.id === "-1") return
      try {
        await cohortStore.apiGetChatsLists(cohort.id)
      } catch (error) {
        console.error(error)
      }
    }

    //block chat refresh interval if user doesn't have full access
    if (authentication.hasFullAccess) {
      /**
       * refresh chat lists api every 30 seconds, so we can show notification badges if there are new messages
       */
      useInterval(refreshChatLists, CHAT_LISTS_REFRESH_RATE, true)
    }

    const currentSiteId = params.siteId || currentSite.id

    return (
      <>
        <ListItem
          button
          onClick={toggleCohortsTab}
          className={classes.cohortListItem}>
          <ListItemText
            primary={cohort.name}
            style={{ margin: 0 }}
            classes={{ primary: classes.cohortName }}
          />

          {(Boolean(cohort.totalUnreadGroupMessages) ||
            Boolean(cohort.totalUnreadDirectMessages)) && (
            <div className={classes.badgeContainer}>
              <NotificationBadge
                unReadCount={
                  cohort.totalUnreadGroupMessages +
                  cohort.totalUnreadDirectMessages
                }
              />
            </div>
          )}

          {cohortsTabOpen ? (
            <DropDownArrowIcon className={classes.downArrow} stroke="#485359" />
          ) : (
            <DropDownArrowIcon stroke="#485359" />
          )}
        </ListItem>

        <Collapse in={cohortsTabOpen} timeout="auto">
          <List component="div" disablePadding className={classes.cohortList}>
            <ListItem
              selected={isMembersActive || isPersonDetailsActive}
              to={`/site/${currentSiteId}/cohorts/${cohort.id}/members`}
              component={Link}
              button
              className={classes.nestedListItem}
              classes={{
                selected: classes.selectedListItem,
              }}>
              <ListItemIcon className={classes.nestedListIconContainer}>
                <MembersIcon />
              </ListItemIcon>

              <ListItemText
                classes={{ primary: classes.nestedListName }}
                primary="Members"
              />
            </ListItem>

            <ListItem
              selected={isSessionsActive}
              to={`/site/${currentSiteId}/cohorts/${cohort.id}/sessions`}
              component={Link}
              button
              className={classes.nestedListItem}
              classes={{
                selected: classes.selectedListItem,
              }}>
              <ListItemIcon className={classes.nestedListIconContainer}>
                <SessionsIcon />
              </ListItemIcon>

              <ListItemText
                classes={{ primary: classes.nestedListName }}
                primary="Sessions"
              />
            </ListItem>

            {authentication.hasFullAccess && (
              <ListItem
                selected={isChatActive || isChatConversationActive}
                to={`/site/${currentSiteId}/cohorts/${cohort.id}/chat`}
                component={Link}
                button
                className={classes.nestedListItem}
                classes={{
                  selected: classes.selectedListItem,
                }}>
                <ListItemIcon className={classes.nestedListIconContainer}>
                  <DirectChatIcon />
                </ListItemIcon>

                <ListItemText
                  classes={{ primary: classes.nestedListName }}
                  primary="1:1 Chat"
                />

                <div className={classes.nestedBadgeContainer}>
                  {Boolean(cohort.totalUnreadDirectMessages) && (
                    <NotificationBadge
                      unReadCount={cohort.totalUnreadDirectMessages}
                    />
                  )}
                </div>
              </ListItem>
            )}

            {authentication.hasFullAccess && (
              <ListItem
                selected={isGroupChatActive}
                to={`/site/${currentSiteId}/cohorts/${cohort.id}/group-chat`}
                component={Link}
                button
                className={classes.nestedListItem}
                classes={{
                  selected: classes.selectedListItem,
                }}>
                <ListItemIcon className={classes.nestedListIconContainer}>
                  <GroupChatIcon />
                </ListItemIcon>

                <ListItemText
                  classes={{ primary: classes.nestedListName }}
                  primary="Group Chat"
                />

                <div className={classes.nestedBadgeContainer}>
                  {Boolean(cohort.totalUnreadGroupMessages) && (
                    <NotificationBadge
                      unReadCount={cohort.totalUnreadGroupMessages}
                    />
                  )}
                </div>
              </ListItem>
            )}

            {authentication.hasFullAccess && cohort.oneOnOneVideoChatEnabled && (
              <ListItem
                selected={isVideoChatActive}
                to={`/site/${currentSiteId}/cohorts/${cohort.id}/video-chat`}
                component={Link}
                button
                className={classes.nestedListItem}
                classes={{
                  selected: classes.selectedListItem,
                }}>
                <ListItemIcon className={classes.nestedListIconContainer}>
                  <VoiceChatIcon className={classes.navIcon} />
                </ListItemIcon>

                <ListItemText
                  classes={{ primary: classes.nestedListName }}
                  primary="Video Chat"
                />
              </ListItem>
            )}

            {authentication.isSiteAdmin && (
              <ListItem
                selected={isSettingsActive}
                to={`/site/${currentSiteId}/cohorts/${cohort.id}/settings`}
                component={Link}
                button
                className={classes.nestedListItem}
                classes={{
                  selected: classes.selectedListItem,
                }}>
                <ListItemIcon className={classes.nestedListIconContainer}>
                  <SettingsIcon />
                </ListItemIcon>

                <ListItemText
                  classes={{ primary: classes.nestedListName }}
                  primary="Settings"
                />
              </ListItem>
            )}
          </List>
        </Collapse>
      </>
    )
  },
)
