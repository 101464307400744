import React, { useState } from "react"
import clsx from "clsx"
import {
  Theme,
  Popover,
  PopoverProps,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import DoneIcon from "@mui/icons-material/Done"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "25%",
      height: "40%",
      padding: `0 ${theme.spacing(5)}`,
      boxShadow: "none",
      border: "1px solid #B0BABF",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogText: {
      fontSize: 18,
      color: "#000",
      textAlign: "center",
    },
    cancelButton: {
      flex: 1,
      border: `1px solid #1C84C6 !important`,
      borderRadius: theme.spacing(1),
      color: "#1C84C6",
      textTransform: "none",
    },
    submitButton: {
      flex: 1,
      background: "#007CA6 !important",
      borderRadius: theme.spacing(1),
      color: "#fff",
      textTransform: "none",
    },
    revokedSuccessButton: {
      background: "#0A8052 !important",
    },
    loadingProgress: {
      color: "#fff",
    },
  }),
)

interface RevokeInvitationsDialogProps extends PopoverProps {
  focusedEmail: string
  handleClose: () => void
  onRevoke: () => void
  resetInvitationsList: () => void
}

const RevokeInvitationsDialog = ({
  id,
  open,
  anchorEl,
  focusedEmail,
  handleClose,
  onRevoke,
  resetInvitationsList,
}: RevokeInvitationsDialogProps) => {
  const classes = useStyles()
  const [loadingRevoke, toggleLoadingRevoke] = useState(false)
  const [showRevokedMode, toggleRevokedMessage] = useState(false)

  /**
   * Method to handle after the revoke api fires successfully
   * Activate `showRevokedMode` to show the success mode component
   * Set a timeout to 2 sec to close the popover
   */
  const handleSuccessDeletion = () => {
    toggleRevokedMessage(true)
    setTimeout(() => {
      handleClose()
      resetInvitationsList()
    }, 2000)
  }

  /**
   * Method to fire the revoke api call and to toggle loading flag during the api call
   */
  const onSubmit = async () => {
    try {
      toggleLoadingRevoke(true)
      await onRevoke()
      toggleLoadingRevoke(false)
      handleSuccessDeletion()
    } catch (error) {
      toggleLoadingRevoke(false)
    }
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      classes={{
        paper: classes.root,
      }}>
      <Stack spacing={4}>
        <Stack spacing={5} alignItems="center">
          <Typography className={classes.dialogText}>
            You are about to revoke all program invitations of
            <Typography className={classes.dialogText}>
              {focusedEmail}
            </Typography>
          </Typography>

          <Typography className={classes.dialogText}>
            Would you like to proceed?
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={handleClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className={clsx(classes.submitButton, {
              [classes.revokedSuccessButton]: showRevokedMode,
            })}
            disabled={loadingRevoke}
            onClick={onSubmit}
            startIcon={showRevokedMode && <DoneIcon />}>
            {loadingRevoke ? (
              <CircularProgress size={20} className={classes.loadingProgress} />
            ) : showRevokedMode ? (
              "Invitations Revoked"
            ) : (
              "Revoke Invitations"
            )}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  )
}

export default RevokeInvitationsDialog
