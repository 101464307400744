import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import React, { FC, ReactNode } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { AppBar, appBarHeight } from "../app-bar"
import { AppDrawer, drawerWidth } from "../app-drawer"

interface SiteDashboardLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme) =>
  createStyles({
    routes: {
      flexGrow: 1,
      paddingLeft: drawerWidth + 40,
      paddingRight: theme.spacing(6),
      paddingTop: appBarHeight,
    },
    content: {
      flexGrow: 1,
      flexDirection: "column",
    },
    appContentWrapper: {
      display: "flex",
      flexDirection: "row",
    },
  }),
)

export const SiteDashboardLayout: FC<SiteDashboardLayoutProps> = observer(
  ({ children }) => {
    const classes = useStyles()

    return (
      <>
        <main className={clsx(classes.content)}>
          <AppBar />
          <div className={clsx(classes.appContentWrapper)}>
            <AppDrawer />
            <div className={clsx(classes.routes)}>{children}</div>
          </div>
        </main>
      </>
    )
  },
)
