import { FieldTypes } from "../admin-config-form"

const formConfig = [
  {
    fieldName: "personID",
    onChangeMethod: "setPersonID",
    label: "Person ID",
    required: true,
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter person ID",
    },
  },
  {
    fieldName: "message",
    onChangeMethod: "setMessage",
    label: "Message",
    required: true,
    fieldType: FieldTypes.TextInput,
    componentProps: {
      placeholder: "Enter message here",
      multiline: true,
      minRows: 1,
      maxRows: 6,
    },
  },
  {
    fieldName: "isAnonymous",
    onChangeMethod: "setIsAnonymous",
    label: "Is anonymous ?",
    fieldType: FieldTypes.Switch,
  },
  {
    fieldName: "postType",
    onChangeMethod: "setPostType",
    label: "PostType",
    required: true,
    fieldType: FieldTypes.Select,
    componentProps: {
      options: ["gratitude", "intention"],
      getOptionLabel: (option: "gratitude" | "intention") =>
        option === "gratitude" ? "Gratitude" : "Intention",
      Input: {
        placeholder: "Select a post type",
      },
    },
  },
  {
    fieldName: "createdAt",
    onChangeMethod: "setCreatedAt",
    label: "Created At",
    required: true,
    fieldType: FieldTypes.DateTimePicker,
  },
]

export { formConfig }
