import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import { BasicProfile, BasicProfileModel } from "./basic-profile"

export const BasicPersonModel = types
  .model("BasicPerson")
  .props({
    id: types.identifier,
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    deleted: types.optional(types.boolean, false),
    countryCode: types.optional(types.string, ""),
    mobilePhone: types.optional(types.string, ""),
    zipCode: types.optional(types.string, ""),
    profile: types.optional(BasicProfileModel, () =>
      BasicProfileModel.create({ id: "-1" }),
    ),
  })
  .actions((self) => ({
    toggleDeleted() {
      self.deleted = !self.deleted
    },
    setProfile(value: BasicProfile) {
      self.profile = value
    },
  }))
export interface BasicPerson extends Instance<typeof BasicPersonModel> {}
export interface BasicPersonSnapshot
  extends SnapshotOut<typeof BasicPersonModel> {}
