import React from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"

import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { Tag } from "../../models/tag"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useCategoryForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newCategory,
    tags: tagsOptions,
    resetNewCategory,
    apiAdminCreateCategory,
    apiAdminEditCategory,
    apiAdminAddTagToCategory,
    apiAdminRemoveTagToCategory,
    apiAdminCategoriesUploadContent,
  } = adminMslEventStoreModel
  const {
    tags,
    setTags,
    isEditMode,
    uploadFiles,
    setUploadFile,
    createCategoryErrors: errors,
  } = newCategory

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToCategory(newCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminRemoveTagToCategory(newCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const onCancel = () => {
    resetNewCategory()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }
    try {
      setLoading(true)
      await apiAdminCategoriesUploadContent()

      if (isEditMode) {
        await apiAdminEditCategory()
        setAndShowSnackbar({ text: "Successfully edited category !" })
      } else {
        await apiAdminCreateCategory()
        setAndShowSnackbar({ text: "Successfully created category !" })
      }
      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter category name",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Description",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter description here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "imageUrl",
      onChangeMethod: "setImageUrl",
      label: "Image",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter category image url or select image locally",
        filePickerProps: {
          label: "Select image",
          fileType: "train_category_image",
          inputId: "imageUrl",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.imageUrl,
        },
      },
    },
    {
      fieldName: "isFeatured",
      onChangeMethod: "setIsFeatured",
      label: "Is featured ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "isPublished",
      onChangeMethod: "setIsPublished",
      label: "Is published ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "publishStartTime",
      onChangeMethod: "setPublishStartTime",
      label: "Publish Start Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "publishEndTime",
      onChangeMethod: "setPublishEndTime",
      label: "Publish End Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    isEditMode,
    values: newCategory,
    errors,
    loading,
    submitted,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useCategoryForm }
