import React from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  List,
} from "@material-ui/core"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { useAdminSearchMslEvents } from "../../utils/hooks/useAdminSearchMslEvents"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"
import { Tag } from "../../models/tag"
import { handleEventsIncrementalSequence } from "../../utils/events-sequence"
import { MslEvent } from "../../models/msl-event"
import { EpisodeCard } from "../episode-card"

const useThemeForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newTheme,
    tags: tagsOptions,
    mslEventsPagination,
    resetNewTheme,
    apiAdminCreateTheme,
    apiAdminEditTheme,
    apiAdminAddTagToTheme,
    apiAdminRemoveTagToTheme,
    apiAdminUpdateThemeEvents,
  } = adminMslEventStoreModel

  const {
    tags,
    events,
    setEvents,
    setTags,
    isEditMode,
    createThemeErrors: errors,
  } = newTheme

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const { handleEventsSearch } = useAdminSearchMslEvents({})

  const onCancel = () => {
    resetNewTheme()
    closeModal()
  }

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToTheme(newTheme.id, option.id)

        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
          severity: "success",
        })
      }
      if (reason === "removeOption") {
        await apiAdminRemoveTagToTheme(newTheme.id, option.id)

        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
          severity: "success",
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleEventsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: MslEvent[],
  ) => {
    event?.preventDefault?.()
    const areValidEvents = value.every((e) => e?.id)
    if (areValidEvents) {
      setEvents(handleEventsIncrementalSequence(value.slice()))
    }
  }

  const handleRemoveEvent = (eventID: string) => {
    setEvents(events.slice().filter((e) => e.id !== eventID))
  }

  const handleSequenceChange = (episode: MslEvent, newValue: string) => {
    const newSequence = parseInt(newValue)
    episode.setSequence(isNaN(newSequence) ? null : newSequence)
    setEvents(events)
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (isEditMode) {
        await apiAdminUpdateThemeEvents()
        await apiAdminEditTheme()
        setAndShowSnackbar({
          text: "Successfully edited theme !",
          severity: "success",
        })
      } else {
        await apiAdminCreateTheme()
        setAndShowSnackbar({
          text: "Successfully created theme !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter theme name",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Description",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter theme description",
      },
    },
    {
      fieldName: "isFeatured",
      onChangeMethod: "setIsFeatured",
      label: "Is featured ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "isPublished",
      onChangeMethod: "setIsPublished",
      label: "Is published ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "publishStartTime",
      onChangeMethod: "setPublishStartTime",
      label: "Publish Start Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "publishEndTime",
      onChangeMethod: "setPublishEndTime",
      label: "Publish End Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      showOnlyOnEditMode: true,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
    },
    {
      fieldName: "events",
      onChangeMethod: "setEvents",
      label: "Episodes",
      fieldType: FieldTypes.Select,
      componentProps: {
        id: "events",
        multiple: true,
        forbidDuplicates: true,
        options: mslEventsPagination.mslEvents.slice(),
        value: events.slice(),
        loading: mslEventsPagination.loading,
        onChange: handleEventsChange,
        onInputChange: (_, value) => handleEventsSearch(value),
        renderOption: (props, option: MslEvent) => (
          <li {...props} key={`${option.id}_${props["aria-rowindex"]}`}>
            {option.name}
          </li>
        ),
        getOptionLabel: (option: MslEvent) => option.name,
        Input: {
          placeholder: "Select Episodes",
          error: submitted && Boolean(errors.events),
          helperText: submitted && errors.events,
        },
        hideTags: true,
        selectedOptionContent: (
          <List>
            {events.slice().map((episode: MslEvent, index) => (
              <EpisodeCard
                key={`${episode.id}_${index}`}
                episode={episode}
                handleRemoveEvent={handleRemoveEvent}
                handleSequenceChange={handleSequenceChange}
              />
            ))}
          </List>
        ),
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    isEditMode,
    values: newTheme,
    errors,
    loading,
    submitted,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useThemeForm }
