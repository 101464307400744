import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Typography, CircularProgress } from "@material-ui/core"
import { useStores } from "../../models/root-store"
import { FullPersonDetails } from "../../models/admin-user-search/full-person-details"
import InvitationCard from "./invitation-card"
import RevokeInvitationsDialog from "./revoke-invitations-dialog"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invitationsContainer: {
      background: "#fff",
      marginTop: theme.spacing(3),
      flex: 1,
    },
    labelContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: `${theme.spacing(3)} 0`,
    },
    label: {
      fontWeight: "bold",
      fontSize: 24,
      color: "#039CAD",
    },
    invitationsList: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      position: "relative",
      alignSelf: "center",
    },
  }),
)

export const PersonInvitations = observer(() => {
  const classes = useStyles()
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()
  const { adminToolsStore } = useStores()
  const { selectedPerson, apiAdminDeletePersonInvitations } = adminToolsStore
  const {
    programInvitations,
    loadingProgramInvitations,
    person,
    setProgramInvitations,
  } = selectedPerson as FullPersonDetails

  const [
    revokeInvitationsPopUpEl,
    setRevokeInvitationsPopUpEl,
  ] = useState<HTMLButtonElement | null>(null)

  const openRevokeInvitationsPopup = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setRevokeInvitationsPopUpEl(event.currentTarget)
  }

  const closeRevokeInvitationsPopup = () => {
    setRevokeInvitationsPopUpEl(null)
  }

  const isRevokePopupOpen = Boolean(revokeInvitationsPopUpEl)

  const revokeAllInvitations = async () => {
    try {
      //manually toggle delete attribute on a person
      await apiAdminDeletePersonInvitations({ email: person.email })
    } catch (error) {
      //revert toggle when api fails
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      throw error.message
    }
  }

  const resetInvitationsList = () => {
    setProgramInvitations([])
  }

  const renderInvitationsList = () => {
    if (loadingProgramInvitations) {
      return <CircularProgress />
    }

    if (programInvitations.length === 0) {
      return <Typography>No invitations found !</Typography>
    }

    return (
      <>
        {programInvitations.map((invitation) => (
          <InvitationCard
            key={invitation.id}
            invitation={invitation}
            onRevokeInvitations={openRevokeInvitationsPopup}
          />
        ))}
      </>
    )
  }

  return (
    <Stack className={classes.invitationsContainer}>
      <Stack className={classes.labelContainer}>
        <Typography className={classes.label}>Program Invitations</Typography>
      </Stack>

      <Stack spacing={1} className={classes.invitationsList}>
        {renderInvitationsList()}

        <div />
      </Stack>

      {isRevokePopupOpen && (
        <RevokeInvitationsDialog
          id={isRevokePopupOpen ? "person-popup-revoke" : undefined}
          open={isRevokePopupOpen}
          handleClose={closeRevokeInvitationsPopup}
          anchorEl={revokeInvitationsPopUpEl}
          onRevoke={revokeAllInvitations}
          resetInvitationsList={resetInvitationsList}
          focusedEmail={person.email}
        />
      )}
    </Stack>
  )
})
