import React, { useState, useEffect } from "react"
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  OutlinedInput,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { useAdminSearchMslEvents } from "../../utils/hooks/useAdminSearchMslEvents"

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionContainer: {
      background: "transparent",
      "box-shadow": "none",
      marginTop: theme.spacing(2),

      '&.MuiPaper-root[class*="MuiAccordion-root"]:before': {
        display: "none",
      },
    },
    accordionInputRoot: {
      minHeight: `0px !important`,
      justifyContent: "flex-start",
      padding: 0,
    },
    accordionInputContent: {
      flex: "initial",
      margin: `0px !important`,
    },
    accordionInputIcon: {
      color: "#2D353B",
    },
    accordionLabel: {
      fontSize: 22,
      lineHeight: "27px",
      color: "#2D353B",
      fontWeight: "bold",
    },
    accordionDetailsRoot: {
      padding: 0,
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(12),
    },
    searchInput: {
      width: "100%",
      background: "#FFFFFF",
      "box-shadow": "0px 2px 5px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      borderWidth: 0,
    },
    searchInputNotchedOutline: {
      border: "0px solid !important",
    },
  }),
)

const SearchMslEventDropdown = observer(() => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [expanded, setExpanded] = useState<boolean>(false)
  const classes = useStyles()

  const { adminMslEventStoreModel } = useStores()

  const { apiAdminGetMslEvents, mslEventsPagination } = adminMslEventStoreModel
  const {
    showSearchResults,
    resetPagination,
    setShowSearchResults,
  } = mslEventsPagination

  const { handleEventsSearch } = useAdminSearchMslEvents({})

  /**
   * Local flag which checks if the search term is empty or not
   */
  const isSearchCompleted = Boolean(searchTerm.trim().length)

  /**
   * Control the ui logic of `showSearchResults` flag
   */
  useEffect(() => {
    //if search input is NOT visible OR search input is NOT completed disable `showSearchPostResults` flag
    if (showSearchResults && (!expanded || !isSearchCompleted)) {
      setShowSearchResults(false)
      resetPagination()
      apiAdminGetMslEvents()
    }

    //reset search term on accordion close
    if (!expanded) {
      setSearchTerm("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchCompleted, expanded, showSearchResults])

  /**
   * Search Input `onChange` handler
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
    handleEventsSearch(event.target.value)
  }

  /**
   * Accordion `onChange` handler. Save locally the expanded flag
   */
  const onChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion className={classes.accordionContainer} onChange={onChange}>
      <AccordionSummary
        expandIcon={
          <ArrowDropDownIcon
            fontSize="large"
            className={classes.accordionInputIcon}
          />
        }
        aria-controls="search-dropdown"
        id="search-dropdown"
        classes={{
          root: classes.accordionInputRoot,
          content: classes.accordionInputContent,
        }}>
        <Typography className={classes.accordionLabel}>
          Search for an episode
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <OutlinedInput
            id="outlined-basic"
            label=""
            autoFocus
            classes={{
              root: classes.searchInput,
              notchedOutline: classes.searchInputNotchedOutline,
              focused: classes.searchInputNotchedOutline,
            }}
            value={searchTerm}
            onChange={onInputChange}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
})

export default SearchMslEventDropdown
