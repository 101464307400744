import { Button, Theme, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import React, { FC } from "react"
import AddCircleIcon from "@material-ui/icons/AddCircle"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingTop: theme.spacing(2),
      alignItems: "center",
      justifyContent: "center",
    },
    mainCta: {
      marginTop: theme.spacing(3),
    },
    title: {
      textAlign: "center",
    },
  }),
)

interface EmptyStateProsp {
  title: string
  cta?: string
}

export const EmptyState: FC<EmptyStateProsp> = ({ title, cta }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>

      {Boolean(cta) && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddCircleIcon />}
          className={classes.mainCta}>
          {cta}
        </Button>
      )}
    </div>
  )
}
