import React from "react"
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Avatar,
  IconButton,
  TextField,
} from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"
import MovieIcon from "@mui/icons-material/Movie"

import { MslEvent } from "../../models/msl-event"
import { ShortText } from "../admin-msl-events-list/short-text"

interface EpisodeCardProps {
  episode: MslEvent
  handleRemoveEvent: (eventID: string) => void
  handleSequenceChange: (episode: MslEvent, newValue: string) => void
}

const EpisodeCard = ({
  episode,
  handleRemoveEvent,
  handleSequenceChange,
}: EpisodeCardProps) => {
  const onSequenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSequenceChange(episode, e.target.value)
  }

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={episode.name} src={episode.posterImageURL}>
            <MovieIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={episode.name}
          secondary={
            <React.Fragment>
              <ShortText value={episode.description} />

              <TextField
                value={episode.sequence}
                variant="standard"
                type="number"
                onChange={onSequenceChange}
                label="Sequence number"
                sx={{ mt: 0.5 }}
              />
            </React.Fragment>
          }
        />

        <IconButton onClick={() => handleRemoveEvent(episode.id)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </>
  )
}

export { EpisodeCard }
