import React from "react"
import { Theme, Typography, Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { ChatMessage } from "../../models/chat-message"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    trainEpisodeContent: {
      maxWidth: 200,
      background: "#fff",
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    trainEpisodePosterImage: {
      position: "relative",
      maxWidth: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      borderRadius: theme.shape.borderRadius,
    },
    episodeTitle: {
      fontSize: theme.spacing(2),
      ...typography.circularXXMedium,
    },
    episodeAuthor: {
      fontSize: theme.spacing(1.75),
      ...typography.circularXXRegular,
    },
  }),
)

interface TrainContentProps {
  message: ChatMessage
}

const TrainContent = ({ message }: TrainContentProps) => {
  const classes = useStyles()

  if (!message.trainVideoDetails) {
    return <div />
  }

  return (
    <Stack spacing={1} className={classes.trainEpisodeContent}>
      <img
        src={message.trainVideoDetails.posterImageURL}
        className={classes.trainEpisodePosterImage}
        alt="poster"
      />

      <Typography className={classes.episodeTitle}>
        {message.trainVideoDetails.episodeName || ""}
      </Typography>

      <Typography className={classes.episodeAuthor || ""}>
        {message.trainVideoDetails.coachName}
      </Typography>
    </Stack>
  )
}

export { TrainContent }
