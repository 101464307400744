import { InboxItemType } from "../cohort"

export const sortByTheLastestMessage = (
  chatA: InboxItemType,
  chatB: InboxItemType,
) => {
  const lastMessageA = chatA.latestMessage
  const lastMessageB = chatB.latestMessage

  //if there is chat on a conversation, set the earliest date possible as a value to sort empty chats on the end of the list
  const lastMessageADate = lastMessageA
    ? new Date(lastMessageA.createdAt)
    : //earliest date possible
      new Date(-8640000000000000)
  const lastMessageBDate = lastMessageB
    ? new Date(lastMessageB.createdAt)
    : new Date(-8640000000000000)

  return (
    //@ts-expect-error
    lastMessageBDate - lastMessageADate
  )
}
