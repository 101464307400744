import React from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  List,
} from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { Tag } from "../../models/tag"
import { MslEvent } from "../../models/msl-event"

import { useSnackbars } from "../use-snackbar"
import { useAdminSearchMslEvents } from "../../utils/hooks/useAdminSearchMslEvents"
import { handleEventsIncrementalSequence } from "../../utils/events-sequence"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { EpisodeCard } from "../episode-card"

const useSeriesForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    mslEventsPagination,
    newSeries,
    tags: tagsOptions,
    resetNewSeries,
    apiAdminCreateSeries,
    apiAdminEditSeries,
    apiAdminUpdateSeriesEvents,
    apiAdminAddTagToSeries,
    apiAdminRemoveTagToSeries,
    apiAdminSeriesUploadContent,
  } = adminMslEventStoreModel
  const {
    events,
    setEvents,
    tags,
    setTags,
    isEditMode,
    uploadFiles,
    setUploadFile,
    createSeriesErrors: errors,
  } = newSeries

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })
  const { handleEventsSearch } = useAdminSearchMslEvents({})

  const onCancel = () => {
    resetNewSeries()
    closeModal()
  }

  const updateSeriesEvent = async () => {
    try {
      await apiAdminUpdateSeriesEvents()
      setAndShowSnackbar({
        text: "Episode event updated successfully !",
        severity: "success",
      })
    } catch (error) {
      setAndShowSnackbar({ text: "Episode update failed !", severity: "error" })
    }
  }

  const handleEventsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: MslEvent[],
  ) => {
    event?.preventDefault?.()
    const areValidEvents = value.every((e) => e?.id)
    if (areValidEvents) {
      setEvents(handleEventsIncrementalSequence(value.slice()))

      updateSeriesEvent()
    }
  }

  const handleRemoveEvent = (eventID: string) => {
    setEvents(events.slice().filter((e) => e.id !== eventID))

    updateSeriesEvent()
  }

  const handleSequenceChange = (episode: MslEvent, newValue: string) => {
    const newSequence = parseInt(newValue)
    episode.setSequence(isNaN(newSequence) ? null : newSequence)
    setEvents(events)
  }

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToSeries(newSeries.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminRemoveTagToSeries(newSeries.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }
    try {
      setLoading(true)
      await apiAdminSeriesUploadContent()

      if (isEditMode) {
        await apiAdminUpdateSeriesEvents()
        await apiAdminEditSeries()
        setAndShowSnackbar({ text: "Successfully edited series !" })
      } else {
        await apiAdminCreateSeries()
        setAndShowSnackbar({ text: "Successfully created series !" })
      }
      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter series name",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Description",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter series description",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "imageUrl",
      onChangeMethod: "setImageUrl",
      label: "Image",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter series image url or select locally",
        filePickerProps: {
          label: "Select image",
          fileType: "train_series_image",
          inputId: "imageUrl",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.imageUrl,
        },
      },
    },
    {
      fieldName: "isFeatured",
      onChangeMethod: "setIsFeatured",
      label: "Is featured ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "isPublished",
      onChangeMethod: "setIsPublished",
      label: "Is published ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "publishStartTime",
      onChangeMethod: "setPublishStartTime",
      label: "Publish Start Date",
      fieldType: FieldTypes.DateTimePicker,
      required: true,
    },
    {
      fieldName: "publishEndTime",
      onChangeMethod: "setPublishEndTime",
      label: "Publish End Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "searchKeywords",
      onChangeMethod: "setSearchKeywords",
      label: "Search Keywords",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter the search keywords",
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "events",
      onChangeMethod: "setEvents",
      label: "Episodes",
      fieldType: FieldTypes.Select,
      componentProps: {
        id: "events",
        multiple: true,
        forbidDuplicates: true,
        options: mslEventsPagination.mslEvents.slice(),
        value: events.slice(),
        loading: mslEventsPagination.loading,
        onChange: handleEventsChange,
        onInputChange: (_, value) => handleEventsSearch(value),
        renderOption: (props, option: MslEvent) => (
          <li {...props} key={`${option.id}_${props["aria-rowindex"]}`}>
            {option.name}
          </li>
        ),
        getOptionLabel: (option: MslEvent) => option.name,
        Input: {
          placeholder: "Select Episodes",
          error: submitted && Boolean(errors.events),
          helperText: submitted && errors.events,
        },
        hideTags: true,
        selectedOptionContent: (
          <List>
            {events.slice().map((episode: MslEvent, index) => (
              <EpisodeCard
                key={`${episode.id}_${index}`}
                episode={episode}
                handleRemoveEvent={handleRemoveEvent}
                handleSequenceChange={handleSequenceChange}
              />
            ))}
          </List>
        ),
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    isEditMode,
    values: newSeries,
    errors,
    loading,
    submitted,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useSeriesForm }
