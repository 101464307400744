import React from "react"
import { observer } from "mobx-react-lite"
import { Stack, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ChatIcon from "@material-ui/icons/Chat"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    chatIcon: {
      fontSize: theme.spacing(11),
      color: "#1771A6",
    },
    infoText: {
      maxWidth: 350,
      fontSize: theme.spacing(2.5),
      color: "#1771A6",
      textAlign: "center",
      ...typography.circularXXMedium,
    },
  }),
)

interface EmptyRoomProps {
  isIndividualChat?: boolean
  isChatUnavailable?: boolean
}

const EmptyRoom = observer(
  ({ isIndividualChat, isChatUnavailable }: EmptyRoomProps) => {
    const classes = useStyles()
    const { personStore } = useStores()
    const { currentPerson } = personStore

    const renderMessage = () => {
      if (isChatUnavailable) {
        return "There are no members to chat with. Add new members to this program to have conversations here."
      }
      if (isIndividualChat) {
        return `
        This is the first time you’re messaging ${currentPerson?.firstName}.  Be sure to introduce yourself and give them some context for reaching out.`
      }

      return "This is the first time you’re messaging to the group chat of this program. Be sure to introduce yourself and give them some context for reaching out."
    }

    return (
      <Stack flex={1} justifyContent="center" alignItems="center" spacing={5}>
        <ChatIcon className={classes.chatIcon} />

        <Typography className={classes.infoText}>{renderMessage()}</Typography>
      </Stack>
    )
  },
)

export { EmptyRoom }
