import React, { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

interface MutePostDialogProps {
  open: boolean
  isMuted: boolean
  handleClose: () => void
  postMessage: string
  muteReason: string | null
  editMuteReason: (reason: string) => void
  toggleMutePost: (reason: string) => void
}

const MutePostDialog = ({
  open,
  isMuted,
  handleClose,
  postMessage,
  muteReason,
  editMuteReason,
  toggleMutePost,
}: MutePostDialogProps) => {
  const [reason, setReason] = useState("")
  const [error, setError] = useState("")

  const isPostDeleted = isMuted && !Boolean(muteReason)

  const onChangeText = (e) => {
    Boolean(error) && setError("")
    if (e.target.value.length <= 64) {
      setReason(e.target.value)
    } else {
      setError("Maximum length is 64 characters !")
    }
  }

  const onClose = () => {
    handleClose()
    setReason("")
    setError("")
  }

  const dialogLabel = () => {
    if (isPostDeleted) {
      return 'Mute Reason - "User deleted"'
    } else if (isMuted) {
      return `Mute Reason -  "${muteReason}"`
    }
    return "To mute this post, please enter your reason."
  }

  const validate = () => {
    if (reason.trim().length === 0) {
      setError("Reason is required !")
      return { valid: false }
    } else if (reason.trim().length > 64) {
      setError("Maximum length is 64 characters !")
      return { valid: false }
    }
    return { valid: true }
  }

  const unMute = () => {
    toggleMutePost("")

    onClose()
  }

  const submitMutePost = () => {
    const { valid } = validate()
    if (!valid) {
      return
    }

    if (isMuted) {
      editMuteReason(reason)
    } else {
      toggleMutePost(reason)
    }

    onClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{postMessage}</DialogTitle>

        <DialogContent>
          <DialogContentText>{dialogLabel()}</DialogContentText>

          {!isPostDeleted && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              type="email"
              fullWidth
              variant="standard"
              required
              error={Boolean(error)}
              helperText={error}
              value={reason}
              onChange={onChangeText}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          {!isPostDeleted && (
            <>
              {isMuted && <Button onClick={unMute}>Unmute</Button>}

              <Button onClick={submitMutePost}>
                {isMuted ? "Edit Mute Reason" : "Mute Post"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { MutePostDialog }
