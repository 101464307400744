import React, { useState, useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import AdapterMoment from "@material-ui/lab/AdapterMoment"
import LocalizationProvider from "@material-ui/lab/LocalizationProvider"

import { ErrorBoundary } from "@sentry/react"
import {
  RootStore,
  RootStoreProvider,
  setupRootStore,
} from "./models/root-store"
import { ThemeProvider, Theme, StyledEngineProvider } from "./services/theme"
import { RenderRoutes, routes } from "./AppRoutes"
import LoadingScreen from "./components/loading-screen/loading-screen"
import { SnackbarProvider } from "./components/use-snackbar"
import { ConfirmationProvider } from "./components/use-confirmation"

declare module "@material-ui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)
  const [initializing, setInitializing] = useState(true)

  const setupStore = async () => {
    const rootStore = await setupRootStore()
    setRootStore(rootStore)
    setInitializing(false)
  }

  useEffect(() => {
    setupStore()
  }, [])

  if (!rootStore || initializing) {
    return <LoadingScreen />
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RootStoreProvider value={rootStore}>
            <ErrorBoundary fallback={"An error has occurred"}>
              <SnackbarProvider>
                <ConfirmationProvider>
                  <div className="App">
                    <BrowserRouter>
                      {<RenderRoutes routes={routes} />}
                    </BrowserRouter>
                  </div>
                </ConfirmationProvider>
              </SnackbarProvider>
            </ErrorBoundary>
          </RootStoreProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
