import React, { useState } from "react"
import { Stack, Button, Popover } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"

import { typography } from "../../services/theme/typography"
import { SortOption } from "./sort-option"
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg"

const useStyles = makeStyles((theme) =>
  createStyles({
    optionButton: {
      padding: 0,
      minWidth: 0,
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
      fontWeight: 700,
      color: "#0071BC",
      ...typography.circularXXBold,
      "& svg": {
        marginRight: theme.spacing(0.5),
        height: "24px",
        width: "24px",
      },
    },
    sortMenuContainer: {
      width: 205,
      borderRadius: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      padding: theme.spacing(2.5),
    },
    sortDivider: {
      border: "1px solid #F4F5F9",
      width: "100%",
    },
  }),
)

export interface Sort {
  key: number
  label: string
}

export interface SortDialogProps {
  sortOptions: Sort[]
  activeSortKey: Sort["key"]
  setActiveSortKey: (value: Sort["key"]) => void
  sortOptionButtonClass?: string
}

const SortDialog = (props: SortDialogProps) => {
  const {
    sortOptions,
    activeSortKey,
    setActiveSortKey,
    sortOptionButtonClass,
  } = props

  const classes = useStyles()
  const [sortMenuEl, setSortMenuEl] = useState<HTMLButtonElement | null>(null)

  const openSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortMenuEl(event.currentTarget)
  }

  const closeSortMenu = () => {
    setSortMenuEl(null)
  }

  return (
    <div>
      <Button
        className={clsx(classes.optionButton, sortOptionButtonClass)}
        onClick={openSortMenu}>
        <SortIcon />
        Sort
      </Button>

      <Popover
        id={Boolean(sortMenuEl) ? "sort-menu" : undefined}
        open={Boolean(sortMenuEl)}
        anchorEl={sortMenuEl}
        onClose={closeSortMenu}
        classes={{
          paper: classes.sortMenuContainer,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Stack spacing={1.75}>
          {sortOptions.map(({ key, label }, index) => (
            <React.Fragment key={key}>
              <SortOption
                label={label}
                selected={key === activeSortKey}
                onClick={() => setActiveSortKey(key)}
              />

              {index !== sortOptions.length - 1 && (
                <div className={classes.sortDivider} />
              )}
            </React.Fragment>
          ))}
        </Stack>
      </Popover>
    </div>
  )
}

export { SortDialog }
