import React from "react"
import { GridCellProps } from "@mui/x-data-grid"

import { ShortText } from "../admin-msl-events-list/short-text"

const CellComponent = ({ value, ...props }: GridCellProps) => {
  switch (props.field) {
    case "imageURL":
      return (
        <ShortText value={value === "verified" ? "Verified" : "Not Verified"} />
      )

    default:
      return <ShortText value={value} />
  }
}

export { CellComponent }
