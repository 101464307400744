import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { FieldTypes } from "../admin-config-form"
import { ModalMode } from "./create-what-videos-modal"
import { useFormState } from "../admin-config-form/useFormState"
import { WhatVideoCategories } from "../../models/what-videos-categories"
import { PublishedVideo } from "../../models/published-videos"

const useWhatVideosForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newWhatVideo,
    whatVideosCategories,
    publishedVideos,
    resetNewWhatVideo,
    apiAdminCreateWhatVideos,
    apiAdminEditWhatVideo,
  } = adminMslEventStoreModel

  const { categoryID, videoID, createWhatVideoErrors: errors } = newWhatVideo

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewWhatVideo()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }
    try {
      setLoading(true)

      if (mode === ModalMode.ADD_NEW_WHAT_VIDEO) {
        await apiAdminCreateWhatVideos()
        setAndShowSnackbar({ text: "Successfully created What Video !" })
      } else {
        await apiAdminEditWhatVideo()
        setAndShowSnackbar({ text: "Successfully edited What Video !" })
      }
      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "categoryID",
      onChangeMethod: "setCategoryID",
      label: "Category",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        options: whatVideosCategories.slice(),
        value: whatVideosCategories
          .slice()
          .find((category) => category.id === categoryID),
        getOptionLabel: (option: WhatVideoCategories) => option.title,
        Input: {
          placeholder: "Select category",
        },
      },
    },
    {
      fieldName: "videoID",
      onChangeMethod: "setVideoID",
      label: "Video",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        options: publishedVideos.slice(),
        value: publishedVideos.slice().find((video) => video.id === videoID),
        getOptionLabel: (option: PublishedVideo) => option.name,
        Input: {
          placeholder: "Select video",
        },
      },
    },
    {
      fieldName: "sequenceNumber",
      onChangeMethod: "setSequenceNumber",
      label: "Sequence Number",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter sequence number here",
        type: "number",
      },
    },
    {
      fieldName: "active",
      onChangeMethod: "setActive",
      label: "Is Active ?",
      fieldType: FieldTypes.Switch,
    },
  ]

  return { loading, submitted, onCancel, onSubmit, formConfig }
}

export { useWhatVideosForm }
