import React from "react"
import { Paper } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { AdminPeopleSearchList } from "../admin-people-search-list"
import SearchPeopleDropdown from "./search-people-dropdown"

const useStyles = makeStyles((theme) =>
  createStyles({
    paperRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      "box-shadow": "none",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(15),
    },
  }),
)

export const AdminPeopleSearchWrapper = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.paperRoot}>
      <SearchPeopleDropdown />

      <AdminPeopleSearchList />
    </Paper>
  )
}
