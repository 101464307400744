import React, { useEffect } from "react"
import { FC } from "react"
import NProgress from "nprogress"
import { Box, LinearProgress, Paper } from "@material-ui/core"

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  return (
    <Paper
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        minHeight: "100%",
        paddingBottom: 3,
      }}>
      <Box width={"100%"}>
        <LinearProgress />
      </Box>
    </Paper>
  )
}

export default LoadingScreen
