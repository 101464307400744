import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"

export const TriModel = types
  .model("Tri")
  .props({
    triID: types.identifier,
    personID: types.maybeNull(types.string),
    startedAt: types.optional(customTypes.iso8601, moment().toDate()),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    what: types.maybeNull(types.string),
    how: types.maybeNull(types.string),
    totalCheckIns: types.maybeNull(types.number),
    lastCheckIn: types.optional(customTypes.iso8601, moment().toDate()),
  })
  .views((self) => ({
    get startedAtFromNow() {
      return moment(self.startedAt).fromNow()
    },
    get formattedCreatedAt() {
      return !!self.createdAt ? moment(self.createdAt).format("lll") : null
    },
    get formattedLastCheckIn() {
      return !!self.lastCheckIn ? moment(self.lastCheckIn).format("lll") : null
    },
  }))

export interface Tri extends Instance<typeof TriModel> {}
export interface TriSnapshot extends SnapshotOut<typeof TriModel> {}
