import React from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import clsx from "clsx"
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Stack,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"
import { ProfileMenu } from "./profile-menu"

interface AppBarProps {}

export const appBarHeight = 96

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(3),
      height: appBarHeight,
      justifyContent: "center",
      background: "#0096A6",
    },
    adminAppBar: {
      background: "#1771A6",
    },
    logo: {
      width: 207,
    },
    personNameText: {
      fontSize: theme.spacing(3),
      lineHeight: "22px",
      color: theme.palette.common.white,
      letterSpacing: theme.spacing(0.5),
      ...typography.circularXXBold,
    },
    toolBar: {
      padding: 0,
      justifyContent: "space-between",
    },
  }),
)

export const PilotAppBar = observer((props: AppBarProps) => {
  const classes = useStyles()
  const { loginStore } = useStores()
  const { authentication, person } = loginStore

  return (
    <>
      <MaterialAppBar
        className={clsx(classes.appBar, {
          [classes.adminAppBar]:
            authentication.isSiteAdmin || authentication.isFtAdmin,
        })}
        position="fixed">
        <Toolbar className={classes.toolBar}>
          <Link to="/">
            <img
              alt="logo"
              src="/FreshTriProLogo_White.png"
              className={classes.logo}
            />
          </Link>

          <Stack direction="row" alignItems="center" spacing={4}>
            <Typography className={classes.personNameText}>
              {person.firstName} {person.lastName}
            </Typography>

            <ProfileMenu />
          </Stack>
        </Toolbar>
      </MaterialAppBar>
    </>
  )
})
