import React from "react"
import { Stack, Button } from "@material-ui/core"
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker"
import MuiDateRangePickerDay, {
  DateRangePickerDayProps,
} from "@mui/lab/DateRangePickerDay"
import AdapterDateMoment from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles((theme) =>
  createStyles({
    datePicker: {
      background: "#F5F6F7",
      border: "1px solid #B0BABF",
      "box-shadow": "0px 1px 2px rgba(0, 14, 51, 0.25)",
      padding: theme.spacing(3),
      "&> div:first-child": {
        //hack to add calendar padding space of first calendar
        "&> div:first-child": {
          paddingRight: theme.spacing(3),
          marginRight: theme.spacing(3),

          //hack to update day weeks style of first calendar
          "&> div:nth-child(2)": {
            "& span": {
              width: theme.spacing(5),
              color: "#1F1F1F",
            },
          },
        },

        //hack to update day weeks style of second calendar
        "&> div:nth-child(2)": {
          "&> div:nth-child(2)": {
            "& span": {
              width: theme.spacing(5),
              color: "#1F1F1F",
            },
          },
        },
      },
    },
    root: {
      padding: 2,
      "&:last-of-type": {
        "border-top-right-radius": "6px !important",
        "border-bottom-right-radius": "6px !important",
      },
      "&:first-of-type": {
        "border-top-left-radius": "6px !important",
        "border-bottom-left-radius": "6px !important",
      },
      "&.Mui-selected": {
        background: "#1771A6 !important",
        color: "#fff",
        borderRadius: "6px !important",
        "box-shadow":
          "0px 1.5px 1px rgba(0, 31, 112, 0.25), 0px 3px 2px rgba(0, 71, 255, 0.2)",
      },
    },
    day: {
      "font-weight": "500",
      fontSize: 14,
    },
    dayOutsideRangeInterval: {
      background: "#FFFFFF",
      "box-shadow": "0px 1px 1px rgba(0, 14, 51, 0.05)",
    },
    dayInsideRangeInterval: {
      color: "#1771A6",
    },
    intervalStartDay: {
      background: "#1771A6 !important",
      color: "#fff !important",
      "box-shadow":
        "0px 1.5px 1px rgba(0, 31, 112, 0.25), 0px 3px 2px rgba(0, 71, 255, 0.2)",
      borderRadius: 6,
    },
    rangeIntervalDayHighlight: {
      background: "#D8E3FF",
    },
    arrowButton: {
      width: theme.spacing(5),
      minWidth: theme.spacing(5),
      height: theme.spacing(5),
      background: "#FFFFFF",
      "box-shadow": "0px 1px 1px rgba(0, 14, 51, 0.05)",
      borderRadius: "50%",
    },
    actionsContainer: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
      zIndex: 10,
    },
    cancelActionButton: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      borderRadius: theme.spacing(0.5),
      "text-transform": "none",
      background: "#EBEEF0",
    },
    saveActionButton: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      borderRadius: theme.spacing(0.5),
      "text-transform": "none",
      background: "#007CA6",
      color: "#fff",
    },
  }),
)

interface CustomDateRangePickerProps {
  open: boolean
  rangeValue: DateRange<Date>
  onChange: (value: DateRange<Date>) => void
  closeDatePicker: () => void
  saveRangePoints: () => void
  children?: React.ReactNode
}

/**
 * Custom date range picker using DateRangePicker of mui
 * Used some hacks for customizing the calendar picker
 * TODO: Find a better library in the future to replace DateRangePicker of mui, to avoid hacks
 */
export const CustomDateRangePicker = ({
  open,
  rangeValue,
  onChange,
  closeDatePicker,
  saveRangePoints,
  children,
}: CustomDateRangePickerProps) => {
  const classes = useStyles()

  const renderWeekPickerDay = (
    date: Date,
    dateRangePickerDayProps: DateRangePickerDayProps<Date>,
  ) => {
    return (
      <MuiDateRangePickerDay
        {...dateRangePickerDayProps}
        classes={{
          root: classes.root,
          day: classes.day,
          dayInsideRangeInterval: classes.dayInsideRangeInterval,
          dayOutsideRangeInterval: classes.dayOutsideRangeInterval,
          rangeIntervalDayHighlight: classes.rangeIntervalDayHighlight,
        }}
      />
    )
  }

  const renderActions = () => {
    return (
      <Stack direction="row" spacing={3} className={classes.actionsContainer}>
        <Button
          className={classes.cancelActionButton}
          onClick={closeDatePicker}>
          Cancel
        </Button>

        <Button className={classes.saveActionButton} onClick={saveRangePoints}>
          Apply
        </Button>
      </Stack>
    )
  }

  const renderArrowButton = (props) => {
    return (
      <>
        <Button {...props} classes={{ root: classes.arrowButton }} />

        {/**
         * HACK to show dialog actions through an absolute view
         */}
        {renderActions()}
      </>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateMoment}>
      <DateRangePicker
        value={rangeValue}
        onChange={onChange}
        showTodayButton
        clearable
        className={classes.datePicker}
        PopperProps={{
          placement: "right",
        }}
        open={open}
        renderDay={renderWeekPickerDay}
        components={{
          LeftArrowButton: renderArrowButton,
          RightArrowButton: renderArrowButton,
        }}
        renderInput={() => <>{children}</>}></DateRangePicker>
    </LocalizationProvider>
  )
}
