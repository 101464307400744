import React from "react"
import { observer } from "mobx-react-lite"
import { useTheme } from "@material-ui/core/styles"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import { useStores } from "../../models/root-store"
import { sortCoachGroupsMetaList } from "../../models/pilot-trainer-store/utils"
import { appBarHeight } from "../app-bar/app-bar"
import { SupportPopup, contactSupportSectionHeight } from "../support-popup"
import { useInterval } from "../use-interval"
import { PilotDrawerCollapsableCoachItem } from "../pilot-drawer-collapsable-coach-item"

export const drawerWidth = 260
const drawerContentHeight = `100vh - ${contactSupportSectionHeight}px - ${appBarHeight}px`
const CHAT_LISTS_REFRESH_RATE = 30000

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: 0,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      position: "fixed",
      top: appBarHeight,
      borderRight: "1px solid #485359",
    },
    drawerContent: {
      height: `calc(${drawerContentHeight})`,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    listContainer: {
      padding: 0,
      borderTop: "1px solid #EBEEF0",
    },
    nestedBadgeContainer: {
      display: "flex",
      marginLeft: -8,
    },
    badgeContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  }),
)

interface PilotAppDrawerProps {}

export const PilotAppDrawer = observer((props: PilotAppDrawerProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { loginStore, pilotTrainerStore } = useStores()
  const { person } = loginStore
  const { coachGroupsMetaList, apiGetPilotCoachGroupsMeta } = pilotTrainerStore

  const refreshDirectChatsList = async () => {
    try {
      await apiGetPilotCoachGroupsMeta()
    } catch (error) {
      console.error(error)
    }
  }

  const formattedCoachGroupsMetaList = sortCoachGroupsMetaList(
    coachGroupsMetaList,
    person.id,
  )

  useInterval(refreshDirectChatsList, CHAT_LISTS_REFRESH_RATE, true)

  const drawer = (
    <>
      <List className={classes.listContainer}>
        {formattedCoachGroupsMetaList.map((meta, index) => (
          <PilotDrawerCollapsableCoachItem
            key={`${meta.coach.id}_${index}`}
            //keep the first group expanded
            defaultCollapseOpen={index === 0}
            coachMeta={meta}
          />
        ))}
      </List>
    </>
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <nav className={classes.drawer} aria-label="pilot coach folders">
      <Drawer
        container={container}
        variant="persistent"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerContent}>{drawer}</div>

        <SupportPopup />
      </Drawer>
    </nav>
  )
})
