import { useState, useEffect } from "react"
import { isEmpty } from "ramda"

import { focusFirstInvalidField } from "../../utils"
import { ValidationErrors } from "../../utils"

const useFormState = ({
  isModalOpen,
  errors,
}: {
  isModalOpen: boolean
  errors: ValidationErrors
}) => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!isModalOpen && submitted) {
      setSubmitted(false)
    }
  }, [isModalOpen, submitted])

  const validateErrors = () => {
    setSubmitted(true)
    if (!isEmpty(errors)) {
      focusFirstInvalidField(errors)
      return false
    }
    setSubmitted(false)
    return true
  }

  return { loading, setLoading, submitted, setSubmitted, validateErrors }
}

export { useFormState }
