import React from "react"
import {
  IconButton,
  FormControl,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import SendIcon from "@mui/icons-material/Send"
import { useStores } from "../../models/root-store"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageInputWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(3, 5.5),
      background: theme.palette.common.white,
      border: "1px solid #24645C1A",
    },
    messageInputRoot: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      marginRight: theme.spacing(5),
    },
    userAvatar: {
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
      borderRadius: "50%",
      marginRight: theme.spacing(8),
    },
    messageInput: {
      display: "flex",
      flex: 1,
      padding: 0,
      fontSize: theme.spacing(2.5),
    },
    messageInputNotchedOutline: {
      border: "0px solid !important",
    },
    sendButton: {
      alignSelf: "flex-end",
      color: "#3B85CC",
      textTransform: "none",
      padding: theme.spacing(1.5),
      borderRadius: theme.spacing(2),
      background: "#1771A6",
      "&:hover": {
        background: "#1771A6",
      },
    },
    sendIcon: {
      fill: theme.palette.common.white,
      fontSize: `${theme.spacing(3)} !important`,
    },
  }),
)

interface ChatInputProps extends OutlinedInputProps {
  hideInput?: boolean
  disabledSendButton?: boolean
  isLoading?: boolean
  sendMessage: () => void
}

export const ChatInput = ({
  hideInput,
  disabledSendButton,
  isLoading,
  sendMessage,
  ...inputProps
}: ChatInputProps) => {
  const classes = useStyles()
  const { loginStore } = useStores()
  const { person } = loginStore

  //Handle shortcut for sending new message when the user clicks CTRL(Control on OSX)+Enter
  const keydownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabledSendButton || isLoading) {
      return
    }
    if (e.key === "Enter" && e.ctrlKey) {
      sendMessage()
    }
  }

  if (hideInput) {
    return <div />
  }

  return (
    <FormControl fullWidth className={clsx(classes.messageInputWrapper)}>
      <div className={classes.messageInputRoot}>
        <img
          src={person.profile.avatarImageURL}
          className={classes.userAvatar}
          alt="Person Avatar"
        />

        <OutlinedInput
          {...inputProps}
          id="chat-input"
          type="text"
          placeholder="Write your message…."
          autoFocus
          multiline={true}
          minRows={1}
          maxRows={10}
          className={classes.messageInput}
          classes={{
            notchedOutline: classes.messageInputNotchedOutline,
          }}
          onKeyUp={keydownHandler}
        />
      </div>

      <IconButton
        color="primary"
        onClick={sendMessage}
        disabled={disabledSendButton || isLoading}
        className={classes.sendButton}>
        {isLoading ? (
          <CircularProgress color="secondary" size={24} />
        ) : (
          <SendIcon className={classes.sendIcon} />
        )}
      </IconButton>
    </FormControl>
  )
}
