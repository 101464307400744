import React, { useState } from "react"
import {
  Theme,
  Stack,
  Typography,
  Popover,
  Button,
  Divider,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { FullPersonDetails } from "../../models/admin-user-search/full-person-details"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    personIdText: {
      fontSize: theme.spacing(2.5),
      "font-weight": "500",
      color: "#485359",
      "word-break": "break-all",
      textAlign: "center",
    },
    uniqueIdText: {
      fontSize: theme.spacing(2.5),
      color: "#B0BABF",
      "word-break": "break-all",
      textAlign: "center",
    },

    toggleButtonRoot: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    toggleButtonText: {
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(3.5),
      "font-weight": "600",
      color: "#2D353B",
    },
    toggleButtonEndIcon: {
      color: "#2D353B",
    },
    memberIdSelect: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
    },
    paper: {
      boxShadow: "none",
      background: "#EBEEF0",
      border: "1px solid #B0BABF",
      borderRadius: 0,
      padding: `${theme.spacing(1.2)} 0 ${theme.spacing(1.2)} 0`,
    },
    option: {
      padding: `0 ${theme.spacing(2.5)} 0 ${theme.spacing(2.5)}`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }),
)

enum IdType {
  PersonId,
  UniqueId,
}

const MemberIdDropdown = ({
  selectedPerson,
}: {
  selectedPerson: FullPersonDetails
}) => {
  const classes = useStyles()
  //flag to handle which person id to show based on user selection
  const [selectedIdType, setSelectedIdType] = useState<IdType>(IdType.PersonId)
  const [
    idDropdownAnchorEl,
    setIdDropdownAnchorEl,
  ] = useState<HTMLButtonElement | null>(null)

  const openIdDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIdDropdownAnchorEl(event.currentTarget)
  }

  const closeIdDropdown = () => {
    setIdDropdownAnchorEl(null)
  }

  const open = Boolean(idDropdownAnchorEl)
  const id = open ? "id-select-dropdown" : undefined

  const { person } = selectedPerson

  const updateIdType = (newValue: IdType) => {
    setSelectedIdType(newValue)
    closeIdDropdown()
  }

  const renderId = (idTypeValue: IdType) => {
    if (idTypeValue === IdType.PersonId) {
      return (
        <Stack spacing={0.5} alignItems="center">
          <Typography className={classes.personIdText}>Person ID</Typography>

          <Typography className={classes.personIdText}>{person.id}</Typography>
        </Stack>
      )
    }
    return (
      <Stack spacing={0.5} alignItems="center">
        <Typography className={classes.uniqueIdText}>Unique ID</Typography>

        <Typography className={classes.uniqueIdText}>
          {person.profile.uniqueID}
        </Typography>
      </Stack>
    )
  }

  return (
    <div className={classes.memberIdSelect}>
      <Button
        aria-describedby={id}
        onClick={openIdDropdown}
        disableRipple
        classes={{
          root: classes.toggleButtonRoot,
          text: classes.toggleButtonText,
        }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          Member ID
          <ArrowDropDownIcon
            fontSize="large"
            className={classes.toggleButtonEndIcon}
          />
        </Stack>
      </Button>

      {
        /**
         * Render the person id for the selected id type `selectedIdType`
         */
        renderId(selectedIdType)
      }

      <Popover
        id={id}
        open={open}
        anchorEl={idDropdownAnchorEl}
        onClose={closeIdDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.paper,
        }}>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={1}>
          <Button
            classes={{ root: classes.option }}
            onClick={() => updateIdType(IdType.PersonId)}>
            {renderId(IdType.PersonId)}
          </Button>

          <Button
            classes={{ root: classes.option }}
            onClick={() => updateIdType(IdType.UniqueId)}>
            {renderId(IdType.UniqueId)}
          </Button>
        </Stack>
      </Popover>
    </div>
  )
}

export default MemberIdDropdown
