import React from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme } from "@material-ui/core"
import clsx from "clsx"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { ShortText } from "../admin-msl-events-list/short-text"
import SearchInput from "./search-input"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
      gap: theme.spacing(4),
    },
    claimedCodeRow: {
      background: "#66FF99",
    },
  }),
)

const AdminVerificationCodesList = observer(() => {
  const classes = useStyles()

  const { adminToolsStore } = useStores()
  const { verificationCodes } = adminToolsStore

  return (
    <div className={classes.root}>
      <SearchInput />

      <LogsTable
        tableLabel="Verification Codes"
        tableAriaLabel="verification-codes-table"
        tablePaginationProps={{
          count: !!verificationCodes ? verificationCodes.length : 0,
        }}
        defaultRowsPerPage={25}
        CustomTableHeader={() => <div />}>
        {({ page, rowsPerPage }) => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "16%" }} isStartEndPoint>
                    Code
                  </HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Status</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Email</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Phone number</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Twilio SID</HeaderCell>

                  <HeaderCell style={{ width: "16%" }}>Created</HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(verificationCodes.slice().length) ? (
                  verificationCodes
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      ({
                        id,
                        code,
                        claimed,
                        email,
                        mobilePhone,
                        twilioMessageID,
                        formattedCreatedAt,
                      }) => (
                        <TableRow
                          key={id}
                          className={clsx({
                            [classes.claimedCodeRow]: claimed,
                          })}>
                          <BodyCell isStartEndPoint>{code}</BodyCell>

                          <BodyCell>
                            {claimed ? "Claimed" : "Unclaimed"}
                          </BodyCell>

                          <BodyCell>
                            <ShortText value={email || "N/A"} textLimit={20} />
                          </BodyCell>

                          <BodyCell>
                            <ShortText
                              value={mobilePhone || "N/A"}
                              textLimit={20}
                            />
                          </BodyCell>

                          <BodyCell>
                            <ShortText
                              value={twilioMessageID || "N/A"}
                              textLimit={20}
                            />
                          </BodyCell>

                          <BodyCell>
                            <ShortText
                              value={formattedCreatedAt || "N/A"}
                              textLimit={20}
                            />
                          </BodyCell>
                        </TableRow>
                      ),
                    )
                ) : (
                  <Box pl={3} pt={3}>
                    <EmptyState title="When the search is completed, the verification codes will appear here" />
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>
    </div>
  )
})

export { AdminVerificationCodesList }
