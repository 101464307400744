import React from "react"

import { Stack, Typography, IconButton } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { useStores } from "../../models/root-store"
import { Note } from "../../models/note"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "#1771A6",
  },
  noteDate: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2.25),
    color: "#485359",
    fontWeight: 400,
    ...typography.circularXXBook,
  },
  noteText: {
    color: "#485359",
    fontWeight: 400,
    fontSize: theme.spacing(2),
    wordWrap: "break-word",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    ...typography.circularXXBook,
  },
  personAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  noteCreatorName: {
    color: "#485359",
    fontWeight: "bold",
    margin: theme.spacing(0, 1),
    ...typography.circularXXBold,
  },
  noteCreatorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: "2px solid #a6a6a6",
  },
}))

interface NoteItemProps {
  note: Note
  disableCreate?: boolean
  editNote: (selectedNote: Note) => void
}

const NoteItem = observer(
  ({ note, disableCreate, editNote }: NoteItemProps) => {
    const classes = useStyles()
    const { loginStore, pilotTrainerStore } = useStores()
    const { coachesMap } = pilotTrainerStore
    const { person } = loginStore

    const { noteText, updatedAt, id, coachID } = note
    const isCurrentCoachCreator = coachID === person.id
    const coachHasEditPermission = !disableCreate && isCurrentCoachCreator

    const authenticatedCoach = {
      ...person,
      avatarURL: person.profile.avatarImageURL,
    }
    const authorCoach = isCurrentCoachCreator
      ? authenticatedCoach
      : coachesMap[coachID]

    return (
      <Stack justifyContent="center" paddingBottom={4} key={id}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {coachHasEditPermission && (
            <IconButton onClick={() => editNote(note)}>
              <EditIcon className={classes.editIcon} fontSize="small" />
            </IconButton>
          )}

          {authorCoach && (
            <Stack className={classes.noteCreatorContainer}>
              <img
                src={authorCoach.avatarURL}
                className={classes.personAvatar}
                alt="NoteAvatar"
              />
              <Typography className={classes.noteCreatorName}>
                Coach {authorCoach.firstName}
              </Typography>
            </Stack>
          )}

          <Typography className={classes.noteDate}>
            {moment(updatedAt).format("MM/DD/YYYY")}
          </Typography>
        </Stack>

        <Typography className={classes.noteText}>{noteText}</Typography>
      </Stack>
    )
  },
)

export { NoteItem }
