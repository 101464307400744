import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"

export const BasicBehaviorModel = types.model("BasicBehavior").props({
  id: types.identifier,
  citation: types.maybeNull(types.string),
  createdAt: types.optional(customTypes.iso8601, moment().toDate()),
  iconBaseURI: types.maybeNull(types.string),
  iconFullURI: types.maybeNull(types.string),
  iconURI: types.maybeNull(types.string),
  summary: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
})

export interface BasicBehavior extends Instance<typeof BasicBehaviorModel> {}
export interface BasicBehaviorSnapshot
  extends SnapshotOut<typeof BasicBehaviorModel> {}
