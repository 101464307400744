import React from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Stack, Typography, Button, Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { useLogout } from "../../utils/hooks/useLogout"
import { typography } from "../../services/theme/typography"
import { ReactComponent as ShutDownIcon } from "../../assets/images/shutdown.svg"
import { ReactComponent as ChatShadowAvatar } from "../../assets/images/chat_shadow_avatar.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutButton: {
      padding: 0,
    },
    logoutButtonText: {
      color: theme.palette.common.white,
      fontSize: "18px",
      lineHeight: "22px",
      ...typography.circularXXBook,
    },
    profileButton: {
      padding: 0,
      paddingRight: theme.spacing(3),
      borderRight: "2px solid #fff",
      borderRadius: 0,
    },
    profileButtonText: {
      color: theme.palette.common.white,
      fontSize: "20px",
      lineHeight: "22px",
      ...typography.circularXXBook,
    },
    personAvatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  }),
)

export const LogoutButton = () => {
  const classes = useStyles()
  const { handleLogOut } = useLogout()

  return (
    <Button className={classes.logoutButton} onClick={handleLogOut}>
      <Stack spacing={1} alignItems="center">
        <ShutDownIcon />

        <Typography className={classes.logoutButtonText}>Sign Out</Typography>
      </Stack>
    </Button>
  )
}

export const ProfileButton = observer(() => {
  const classes = useStyles()

  return (
    <Link to="/profile-page">
      <Button className={classes.profileButton}>
        <Stack spacing={1} direction="row" alignItems="center">
          <ChatShadowAvatar className={classes.personAvatar} />

          <Typography className={classes.profileButtonText}>Profile</Typography>
        </Stack>
      </Button>
    </Link>
  )
})
