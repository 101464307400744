import React, { useCallback, useEffect } from "react"
import { Box } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import {
  List as VList,
  ListChildComponentProps,
  AutoSizer,
  InfiniteLoader,
  CellMeasurerCache,
  CellMeasurer,
} from "react-virtualized"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { PeopleListItem } from "./people-list-item"

const useStyles = makeStyles((theme) =>
  createStyles({
    boxListWrapper: {
      display: "flex",
      flex: "1 1 1px",
      overflow: "hidden",
      position: "relative",
      paddingTop: theme.spacing(4),
    },
  }),
)

const _cache = new CellMeasurerCache({
  defaultHeight: 75,
  fixedWidth: true,
})

export const AdminPeopleSearchList = observer(() => {
  const classes = useStyles()

  const { adminToolsStore } = useStores()
  const { userSearchList, resetUserSearchList } = adminToolsStore

  useEffect(() => {
    resetUserSearchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isRowLoaded = useCallback(
    ({ index }: ListChildComponentProps) => !!userSearchList[index],
    [userSearchList],
  )

  /* Pagination logic 
  const loadMoreRows = useCallback(() => {
    setApplicantIds(applicantIds.concat(Array(10).fill(0)))
  }, [applicantIds])
  */

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style, parent } = props

    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={0}
        key={index}
        rowIndex={index}
        parent={parent}>
        <PeopleListItem index={index} style={style} />
      </CellMeasurer>
    )
  }

  return (
    <Box className={classes.boxListWrapper}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        rowCount={userSearchList.length}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => {
              return (
                <VList
                  width={width}
                  height={height}
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  deferredMeasurementCache={_cache}
                  rowHeight={_cache.rowHeight}
                  rowRenderer={renderRow}
                  rowCount={userSearchList.length}
                />
              )
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Box>
  )
})
