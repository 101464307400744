import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import { uniqBy, prop } from "ramda"
import { validate, ValidationRules } from "../../utils/validate"
import { Person, PersonModel } from "../person"
import * as customTypes from "../types"

export const SiteModel = types
  .model("Site")
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    siteID: types.maybeNull(types.string),
    admins: types.optional(types.array(PersonModel), []),
    adminEmails: types.optional(types.array(types.string), []),
    invitees: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setAddress(value: string) {
      self.address = value
    },
    setSiteID(value: string) {
      self.siteID = value
    },
    setAdmins(value: Array<Person>) {
      self.admins.replace(value)
    },
    setInvitees(value: Array<string>) {
      self.invitees.replace(value)
    },
    removeAdmin(admin: Person) {
      self.admins.remove(admin)
    },
    setAdminEmails(value: Array<string>) {
      self.adminEmails.replace(value)
    },
    appendAdmins(value: Array<Person>) {
      self.admins.replace(uniqBy(prop("id"), [...self.admins, ...value]))
    },
  }))
  .views((self) => ({
    get createSiteErrors() {
      return validate(CREATE_SITE_VALIDATION_RULES, self)
    },
    get preventAccidentalModalClose() {
      // if there are more than 2 valid values,
      // then it is likely that the form has been modified
      // so we can prevent the user from accidently closing the modal.
      return Object.values(self).filter(Boolean).length > 2
    },
    get createdAtFromNow() {
      return moment(self.createdAt).fromNow()
    },
    get formattedCreatedAt() {
      return moment(self.createdAt).format("lll")
    },
  }))

const CREATE_SITE_VALIDATION_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  address: {
    presence: { allowEmpty: false, message: "required" },
  },
  siteID: {
    presence: { allowEmpty: false, message: "required" },
  },
  invitees: {
    presence: { allowEmpty: false, message: "required" },
    array: {
      email: true,
    },
  },
}

export interface Site extends Instance<typeof SiteModel> {}
export interface SiteSnapshot extends SnapshotOut<typeof SiteModel> {}
