import React from "react"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { GridCellProps } from "@mui/x-data-grid"
import { Theme, Stack, Button } from "@material-ui/core"

import { MslEvent } from "../../models/msl-event"
import { ShortText } from "./short-text"
import { EventActions, EventStatusAction } from "./event-actions"
import { Trainer } from "../../models/msl-event/trainer"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    posterImage: {
      width: "100%",
    },
    tableTrainerNameLabel: {
      textAlign: "center",
    },
    tableTrainerCircleImage: {
      width: "100%",
      maxWidth: 120,
    },
    tableTopicLabel: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    copyButton: {
      marginTop: "10px !important",
      maxWidth: 100,
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditEventModal?: (event: MslEvent) => void
  handleCopyToClipboard?: (value: string, label: string) => void
  trainersMap: { [id: string]: Trainer }
}

const CellComponent = ({
  openEditEventModal,
  handleCopyToClipboard,
  trainersMap,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()

  switch (props.field) {
    case "trainerIDV2":
      const trainer = !!props.value && trainersMap[props.value]
      return (
        <Stack
          alignItems="center"
          spacing={1}
          className={classes.tableTrainerCircleImage}>
          {!!trainer?.circleImageURL && (
            <img
              src={trainer?.circleImageURL}
              alt="trainer"
              className={classes.tableTrainerCircleImage}
            />
          )}

          <span className={classes.tableTrainerNameLabel}>
            {trainer?.fullName || "N/A"}
          </span>
        </Stack>
      )
    case "posterImageURL":
      return (
        <>
          {!!props.value ? (
            <img
              src={props.value}
              alt="poster"
              className={classes.posterImage}
            />
          ) : (
            "N/A"
          )}
        </>
      )

    case "topic":
      return (
        <Stack>
          <span className={classes.tableTopicLabel}>
            {props.value?.name || "N/A"}
          </span>

          <EventActions
            eventID={`${props?.rowId || ""}`}
            openEditEventModal={openEditEventModal}
          />
        </Stack>
      )

    case "id":
      return (
        <Stack>
          <ShortText value={props.value || "N/A"} textLimit={16} />

          {props.value && (
            <Button
              className={classes.copyButton}
              variant="contained"
              onClick={() => handleCopyToClipboard(props.value, "ID")}>
              Copy
            </Button>
          )}
        </Stack>
      )

    case "legacyID":
      return (
        <Stack>
          <ShortText value={props.value || "N/A"} textLimit={16} />
          {props.value && (
            <Button
              className={classes.copyButton}
              variant="contained"
              onClick={() => handleCopyToClipboard(props.value, "Legacy ID")}>
              Copy
            </Button>
          )}
        </Stack>
      )

    case "description":
      return <ShortText value={props.value} />

    case "isFeatured":
      return <>{props.value ? "Yes" : "No"}</>

    case "isPublished":
      return <EventStatusAction eventID={`${props?.rowId || ""}`} />

    default:
      return <>{props.value || "N/A"}</>
  }
}

export { CellComponent }
