import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { Person } from "../person"
import { Site, SiteModel } from "../site/site"

const VERSION = 1

/******************************************************************
 *
 *
 * SiteStore Model
 *
 *
 * ***************************************************************/

export const SiteStoreModel = types
  .model("SiteStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    newSite: types.optional(SiteModel, () => SiteModel.create({ id: "-1" })),
    selectedSite: types.optional(SiteModel, () =>
      SiteModel.create({ id: "-1" }),
    ),
    currentSiteId: types.maybeNull(types.string),
    sites: types.map(SiteModel),
  })
  .actions((self) => ({
    appendSite(id: string, value: Site) {
      self.sites.set(id, value)
    },
    setCurrentSiteId(value: string) {
      self.environment.api.setSiteId(value)
      self.currentSiteId = value
    },
    setSelectedSite(value: Site) {
      self.selectedSite = value
    },
    clearSites() {
      self.sites.clear()
    },
    resetNewSite() {
      self.newSite = SiteModel.create({ id: "-1" })
    },
    removeSite(id) {
      self.sites.delete(id)
    },
    reset() {
      self.newSite = SiteModel.create({ id: "-1" })
      self.currentSiteId = null
      self.selectedSite = SiteModel.create({ id: "-1" })
      self.sites.clear()
    },
  }))
  .actions((self) => ({
    async apiGetSite(siteId: string) {
      const { api } = self.environment
      const { data, kind } = await api.getSite(siteId)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setSelectedSite(data as Site)
    },
    async apiGetSites() {
      const { api } = self.environment
      const { data, kind } = await api.getSites()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      //@ts-ignore
      data?.forEach((site) => {
        self.appendSite(site.id, site)
      })
    },
    async apiCreateSite() {
      const { api } = self.environment
      const { data, kind } = await api.createSite({
        name: self.newSite.name,
        siteID: self.newSite.siteID,
        address: self.newSite.address,
        admins: self.newSite.invitees,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const site = data as Site

      self.appendSite(site?.id, site)
      self.resetNewSite()
    },

    async apiDeletSite(siteId: string) {
      const { api } = self.environment

      self.removeSite(siteId)
      return api.deleteSite(siteId)
    },

    async apiDeleteFtAdmin(siteId: string, siteAdminPersonId: string) {
      const { api } = self.environment

      return api.deleteFtAdmin({
        siteId,
        siteAdminPersonId,
      })
    },

    async apiInviteAdmin(adminID: string) {
      const { environment } = self
      const { api } = environment

      const { data, kind } = await api.resendAdminInvite({
        adminID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },

    async apiAddAdminsToSite() {
      const { environment, selectedSite } = self
      const { api } = environment

      if (selectedSite.id === "-1") throw new Error("no site selected")

      const { data, kind } = await api.inviteAdminsToSite({
        admins: selectedSite.adminEmails,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      selectedSite.appendAdmins(data as Array<Person>)
      return Promise.resolve()
    },
  }))
  .views((self) => ({
    get sitesAsArray() {
      return [...self.sites.values()]
    },
    get currentSite() {
      return !!self.currentSiteId && self.sites.get(self.currentSiteId)
    },
  }))

export interface SiteStore extends Instance<typeof SiteStoreModel> {}
export interface SiteStoreSnapshot extends SnapshotOut<typeof SiteStoreModel> {}
