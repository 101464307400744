import { useCallback } from "react"
import { GridSortModel, GridFilterModel } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import {
  SORT_TITLE,
  FILTER_ACTIVE,
} from "../../models/tri-ideas-pagination/filter-types"

interface UseTableParams {
  fetchIdeas: () => Promise<void>
}

const useTable = ({ fetchIdeas }: UseTableParams) => {
  const { adminMslEventStoreModel } = useStores()

  const { triIdeasPagination } = adminMslEventStoreModel

  const {
    resetPagination,
    setLoading,
    setSortBy,
    setFilterBy,
    filterBy,
  } = triIdeasPagination

  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      const sortOption = sortModel[0]
      if (!sortOption) {
        resetPagination()
        fetchIdeas()
        return
      }
      resetPagination()
      setSortBy(SORT_TITLE)
      fetchIdeas()
    },
    [fetchIdeas, resetPagination, setLoading, setSortBy],
  )

  const onFilterChange = useCallback(
    async (filterModel: GridFilterModel) => {
      const filterOption = filterModel?.items?.[0]?.value
      const isEmptyFilter = !filterOption
      const isFilterReset = filterBy !== FILTER_ACTIVE
      if (isEmptyFilter) {
        if (isFilterReset) {
          setLoading(true)
          await new Promise((resolve) => setTimeout(resolve, 100))
          resetPagination()
          fetchIdeas()
        }
        return
      }

      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))
      resetPagination()
      setFilterBy(filterOption)
      fetchIdeas()
    },
    [fetchIdeas, resetPagination, setLoading, setFilterBy, filterBy],
  )

  return {
    handleSortModelChange,
    onFilterChange,
  }
}

export { useTable }
