import React from "react"
import clsx from "clsx"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, TableCell, TableCellProps } from "@material-ui/core"
import { typography } from "../../services/theme/typography"

interface CellProps extends TableCellProps {
  children: React.ReactNode
  isStartEndPoint?: boolean
  extraStyle?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    fontSize: theme.spacing(2.5),
    lineHeight: theme.spacing(3),
    color: theme.palette.text.secondary,
    padding: theme.spacing(2, 0),
    borderBottom: "2px solid #B0BABF",
    textAlign: "left",
    ...typography.circularXXBook,
  },
  tableRowCell: {
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(3),
    color: "#677278",
    textAlign: "left",
    borderBottom: "1px solid #EBEEF0",
    paddingLeft: 0,
    alignItems: "center",
    ...typography.circularXXBook,
  },
  tableStartCell: {
    padding: theme.spacing(2, 8),
  },
}))

const HeaderCell = ({ children, isStartEndPoint, ...cellProps }: CellProps) => {
  const classes = useStyles()

  return (
    <TableCell
      className={clsx(classes.tableHeaderCell, {
        [classes.tableStartCell]: isStartEndPoint,
      })}
      {...cellProps}>
      {children}
    </TableCell>
  )
}

const BodyCell = ({
  children,
  isStartEndPoint,
  extraStyle,
  ...cellProps
}: CellProps) => {
  const classes = useStyles()

  return (
    <TableCell
      className={clsx(classes.tableRowCell, {
        [classes.tableStartCell]: isStartEndPoint,
        [extraStyle]: !!extraStyle,
      })}
      {...cellProps}>
      {children}
    </TableCell>
  )
}

export { HeaderCell, BodyCell }
