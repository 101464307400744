import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { ModuleModel } from "./module"

export const ProgramModel = types
  .model("Program")
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    summary: types.maybeNull(types.string),
    active: types.maybeNull(types.boolean),
    modules: types.optional(types.array(ModuleModel), []),
    label: types.optional(types.maybeNull(types.string), null),
    proEnabled: types.optional(types.maybeNull(types.boolean), null),
  })
  .actions((self) => ({}))

export interface Program extends Instance<typeof ProgramModel> {}
export interface ProgramSnapshot extends SnapshotOut<typeof ProgramModel> {}
