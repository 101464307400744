import { types, Instance, SnapshotOut } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"
import { UploadFileModel, UploadFile } from "../upload-file"

export const WhatsNextTilesModel = types
  .model("WhatsNextTiles")
  .props({
    id: types.identifier,
    label: types.optional(types.maybeNull(types.string), ""),
    friendlyName: types.optional(types.maybeNull(types.string), ""),
    type: types.optional(types.maybeNull(types.string), ""),
    actionURL: types.optional(types.maybeNull(types.string), ""),
    imageURL: types.optional(types.maybeNull(types.string), ""),
    active: types.optional(types.boolean, false),
    videoID: types.optional(
      types.maybeNull(types.union(types.string, types.number)),
      null,
    ),
    videoIDV2: types.optional(
      types.maybeNull(types.union(types.string, types.number)),
      null,
    ),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
    uploadFiles: types.optional(
      types.model({
        imageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "imageURL",
          }),
        ),
      }),
      {},
    ),
  })
  .actions((self) => ({
    setLabel(value: string) {
      self.label = value
    },
    setFriendlyName(value: string) {
      self.friendlyName = value
    },
    setType(value: string) {
      self.type = value
    },
    setActionUrl(value: string) {
      self.actionURL = value
    },
    setImageUrl(value: string) {
      self.imageURL = value
    },
    setIsActive(value: boolean) {
      self.active = value
    },
    setVideoId(value: string) {
      self.videoID = value ? parseInt(value) : null
    },
    setVideoIdV2(value: string) {
      self.videoIDV2 = value
    },
    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },

    setContentUrl(id: "imageURL", value: string) {
      self[id] = value
    },
    reset() {
      self.label = ""
      self.friendlyName = ""
      self.type = ""
      self.actionURL = ""
      self.imageURL = ""
      self.active = false
      self.videoID = null
    },
  }))
  .views((self) => ({
    get createTileErrors() {
      return validate(CREATE_NEW_TILES_RULES, {
        ...self,
        videoID: self.videoID ? `${self.videoID}` : null,
      })
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_TILES_RULES: ValidationRules = {
  label: {
    presence: { allowEmpty: false, message: "Required" },
  },
  friendlyName: {
    presence: { allowEmpty: false, message: "Required" },
  },
  type: {
    presence: { allowEmpty: false, message: "Required" },
  },
  imageURL: {
    fileUpload: {},
  },
  active: {
    presence: { allowEmpty: false, message: "Required" },
  },
  videoID: {
    format: {
      pattern: "^$|[1-9]+[0-9]*",
      flags: "i",
      message: "*please enter a valid id that is greater than zero",
    },
  },
}

export interface WhatsNextTiles extends Instance<typeof WhatsNextTilesModel> {}
export interface WhatsNextTilesSnapshot
  extends SnapshotOut<typeof WhatsNextTilesModel> {}
