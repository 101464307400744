import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const EnrolledProgramsModel = types.model("EnrolledPrograms").props({
  id: types.identifier,
  active: types.optional(types.boolean, false),
  label: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  proEnabled: types.optional(types.boolean, false),
  summary: types.maybeNull(types.string),
})

export interface EnrolledPrograms
  extends Instance<typeof EnrolledProgramsModel> {}
export interface EnrolledProgramsSnapshot
  extends SnapshotOut<typeof EnrolledProgramsModel> {}
