import React from "react"
import { Grid, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { CohortChatAside } from "../../components/cohort-chat-aside"
import { ChatRoom } from "../../components/chat-room"
import { DashboardHeaderBar } from "../../components/dashboard-header-bar"
import { MemberDetailsDrawer } from "../../components/member-details-drawer"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    container: {
      display: "flex",
      flex: 1,
    },
    inboxContainer: {
      "max-width": "356px",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      [theme.breakpoints.down("xl")]: {
        "max-width": "300px",
      },
    },
    chatContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    groupChatIcon: {
      color: theme.palette.text.primary,
    },
  }),
)

const Chat = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <DashboardHeaderBar title="">
        <MemberDetailsDrawer />
      </DashboardHeaderBar>

      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} className={classes.inboxContainer}>
          <CohortChatAside />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.chatContainer}>
          <ChatRoom />
        </Grid>
      </Grid>
    </div>
  )
}

export default Chat
