import React from "react"
import { observer } from "mobx-react-lite"
import { AppBar as MaterialAppBar, Toolbar, Stack } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Typography from "@mui/material/Typography"
import clsx from "clsx"
import { useStores } from "../../models/root-store"
import { AdminViewSwitcher } from "../admin-view-switcher"
import { LogoutButton, ProfileButton } from "../logout-button"
import { typography } from "../../services/theme/typography"

interface AdminAppBarProps {}

export const appBarHeight = 96

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(3),
      background: "#1771A6",
      height: appBarHeight,
      justifyContent: "center",
    },
    toolBar: {
      padding: 0,
    },
    logo: {
      width: 207,
    },
    logoDivider: {
      height: "100%",
      marginRight: theme.spacing(5.5),
      marginLeft: 26,
      borderLeft: "1px solid #fff",
    },
    personNameText: {
      fontSize: theme.spacing(3),
      lineHeight: "22px",
      color: theme.palette.common.white,
      letterSpacing: theme.spacing(0.5),
      ...typography.circularXXBold,
    },
    siteSwitcher: {
      display: "flex",
      marginLeft: theme.spacing(4),
      alignItems: "center",
    },
  }),
)

export const AdminAppBar = observer((props: AdminAppBarProps) => {
  const classes = useStyles()

  const { loginStore } = useStores()
  const { person } = loginStore

  return (
    <>
      <MaterialAppBar className={clsx(classes.appBar)} position="fixed">
        <Toolbar className={classes.toolBar}>
          <img
            alt="logo"
            src="/FreshTriProLogo_White.png"
            className={classes.logo}
          />

          <div className={classes.logoDivider} />

          <Typography className={classes.personNameText}>
            {person.firstName} {person.lastName}
          </Typography>

          <div className={classes.grow} />

          <Stack direction="row" alignItems="center" spacing={4}>
            <AdminViewSwitcher />

            <Stack direction="row" alignItems="center" spacing={5}>
              <ProfileButton />

              <LogoutButton />
            </Stack>
          </Stack>
        </Toolbar>
      </MaterialAppBar>
    </>
  )
})
