import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { ChatMessageModel, ChatMessage } from "../chat-message/chat-message"

export const MESSAGES_LIMIT = 20

/**
 * Represents a pagination model.
 */
export const PaginationModel = types
  .model("Pagination")
  .props({
    limit: types.optional(types.number, MESSAGES_LIMIT),
    next: types.maybeNull(types.string),
    isEndReached: types.optional(types.boolean, false),
    messages: types.optional(types.array(ChatMessageModel), []),
  })
  .actions((self) => ({
    setChats(value: Array<ChatMessage>) {
      self.messages.replace(value)
    },
    setIsEndReached(value: boolean) {
      self.isEndReached = value
    },
    extendLimit() {
      self.limit = self.limit + MESSAGES_LIMIT
    },
    appendChat(value: ChatMessage) {
      self.messages.replace([...self.messages, value])
    },
    unshiftChat(value: ChatMessage) {
      self.messages.replace([value, ...self.messages])
    },
    removeChat(messageId: string) {
      self.messages.replace(
        [...self.messages].filter((message) => message.id !== messageId),
      )
    },
    resetChats() {
      if (isAlive(self.messages)) {
        self.messages.clear()
      }
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetChats()
      self.limit = 20
      self.isEndReached = false
    },
  }))

export interface Pagination extends Instance<typeof PaginationModel> {}
export interface PaginationSnapshot
  extends SnapshotOut<typeof PaginationModel> {}
