import React from "react"
import type { FC, ReactNode } from "react"
import { Redirect } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

interface AuthGuardProps {
  children?: ReactNode
}

/**
 * Guard to redirect to login screen if the person is not authenticated
 */
export const AuthGuard: FC<AuthGuardProps> = observer(({ children }) => {
  const { loginStore } = useStores()
  const { isAuthenticated } = loginStore

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }

  return <>{children}</>
})
