import React from "react"
import { Paper } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"

import { AdminFeedList } from "../admin-feed-list"
import SearchPostDropdown from "./search-post-dropdown"
import FilterPostOptions from "./filter-post-options"
import { CreateUserPostModal } from "./create-user-post-modal"

const useStyles = makeStyles((theme) =>
  createStyles({
    paperRoot: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      "box-shadow": "none",
      paddingTop: theme.spacing(3.5),
      paddingLeft: theme.spacing(5.5),
      paddingRight: theme.spacing(5),
    },
  }),
)

export const AdminFeedWrapper = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.paperRoot}>
      <SearchPostDropdown />

      <FilterPostOptions />

      <CreateUserPostModal />

      <AdminFeedList />
    </Paper>
  )
}
