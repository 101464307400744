import React from "react"
import { Stack, Typography, TextField, Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { ReactComponent as TickIcon } from "../../assets/images/tick.svg"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles<Theme>((theme) => ({
  fieldLabel: {
    fontSize: theme.spacing(2),
    color: "#485359",
    display: "flex",
    ...typography.circularXXMedium,
    "&>span": {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(0.5),
    },
  },
  fieldRoot: {
    color: "#2D353B",
    "&.Mui-disabled": {
      color: "#2D353B",
    },
  },
  fieldInput: {
    padding: theme.spacing(1.25),
    color: "#2D353B",
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "#2D353B",
    },
  },
  fieldInputNotchedOutline: {
    border: `1px solid ${theme.palette.common.black} !important`,
    borderRadius: 0,
  },
  activeTick: {
    fill: theme.palette.success.main,
  },
}))

const FormInput = (textFieldProps) => {
  const classes = useStyles()

  return (
    <TextField
      variant="outlined"
      required
      fullWidth
      label=""
      {...textFieldProps}
      InputProps={{
        ...textFieldProps.InputProps,
        classes: {
          root: classes.fieldRoot,
          input: classes.fieldInput,
          notchedOutline: classes.fieldInputNotchedOutline,
        },
      }}
    />
  )
}

const FormField = ({ children, label, required, isFieldCompleted }) => {
  const classes = useStyles()

  return (
    <Stack spacing={0.5}>
      <Typography className={classes.fieldLabel}>
        {label} {required && <span>*</span>}
      </Typography>

      <Stack direction="row" alignItems="center" spacing={2}>
        {children}

        <TickIcon
          className={clsx({
            [classes.activeTick]: isFieldCompleted,
          })}
        />
      </Stack>
    </Stack>
  )
}

export { FormInput, FormField }
