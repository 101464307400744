import React from "react"
import { useRouteMatch } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import { RouteParams } from "../../AppRoutes"
import { appBarHeight } from "../app-bar/app-bar"
import { SupportPopup, contactSupportSectionHeight } from "../support-popup"
import { AdminDrawerListItem } from "./admin-drawer-list-item"
import { CategorySectionDropdown } from "./category-section-dropdown"

export const drawerWidth = 260
const drawerContentHeight = `100vh - ${contactSupportSectionHeight}px - ${appBarHeight}px`

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: 0,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      position: "fixed",
      top: appBarHeight,
      borderRight: "1px solid #B0BABF",
    },
    drawerContent: {
      height: `calc(${drawerContentHeight})`,
      overflow: "auto",
    },
  }),
)

export const AdminAppDrawer = () => {
  const classes = useStyles()
  const theme = useTheme()

  const container =
    window !== undefined ? () => window.document.body : undefined

  const ftAdminPosts = useRouteMatch<RouteParams>("/ftadmin/posts")
  const ftAdminNudges = useRouteMatch<RouteParams>("/ftadmin/nudges")
  const ftAdminTags = useRouteMatch<RouteParams>("/ftadmin/tags")
  const ftAdminRecipes = useRouteMatch<RouteParams>("/ftadmin/recipes")
  const ftAdminWhatVideos = useRouteMatch<RouteParams>("/ftadmin/what-videos")

  const isAdminPostsActive = ftAdminPosts?.isExact
  const isAdminNudgesActive = ftAdminNudges?.isExact
  const isAdminTagsActive = ftAdminTags?.isExact
  const isAdminRecipesActive = ftAdminRecipes?.isExact
  const isAdminWhatNextVideosActive = ftAdminWhatVideos?.isExact

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant="persistent"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <List component="div" disablePadding className={classes.drawerContent}>
          <CategorySectionDropdown
            categoryLabel="Tri content"
            categoryRoute="/ftadmin/tri-content"
            defaultSectionRoute="/programs"
            sectionRoutes={[
              { to: "/programs", label: "Programs" },
              { to: "/modules", label: "Modules" },
              { to: "/whats", label: "Whats" },
              { to: "/hows", label: "Hows" },
              {
                to: "/how-tos/products",
                label: "How-Tos",
                nestedSubCategories: [
                  { to: "/how-tos/recipes", label: "Recipes" },
                  { to: "/how-tos/products", label: "Products" },
                ],
              },
            ]}
          />

          <AdminDrawerListItem
            selected={isAdminPostsActive}
            to={"/ftadmin/posts"}
            label="Manage posts"
          />

          <CategorySectionDropdown
            categoryLabel="Users"
            categoryRoute="/ftadmin/users"
            defaultSectionRoute="/search"
            sectionRoutes={[
              { to: "/search", label: "Search" },
              { to: "/verification", label: "Verification Codes" },
            ]}
          />

          <CategorySectionDropdown
            categoryLabel="Groups"
            categoryRoute="/ftadmin/groups"
            defaultSectionRoute="/manage"
            sectionRoutes={[
              { to: "/manage", label: "Manage Groups" },
              { to: "/invitations", label: "Invitations" },
            ]}
          />

          <CategorySectionDropdown
            categoryLabel="Train"
            categoryRoute="/ftadmin/train"
            defaultSectionRoute="/episodes"
            sectionRoutes={[
              { to: "/categories", label: "Categories" },
              { to: "/episodes", label: "Episodes" },
              { to: "/series", label: "Series" },
              { to: "/themes", label: "Themes" },
              { to: "/topics", label: "Topics" },
              { to: "/trainers", label: "Trainers" },
            ]}
          />

          <CategorySectionDropdown
            categoryLabel="Rotating Content"
            categoryRoute="/ftadmin/rotating"
            defaultSectionRoute="/rak-cards"
            sectionRoutes={[
              { to: "/dyk-tiles", label: "DYK Tiles" },
              { to: "/rak-cards", label: "RAK Cards" },
              { to: "/wn-tiles", label: "WN Tiles" },
            ]}
          />

          <AdminDrawerListItem
            selected={isAdminNudgesActive}
            to={"/ftadmin/nudges"}
            label="Nudges"
          />

          <AdminDrawerListItem
            selected={isAdminTagsActive}
            to={"/ftadmin/tags"}
            label="Tags"
          />

          <AdminDrawerListItem
            selected={isAdminRecipesActive}
            to={"/ftadmin/recipes"}
            label="Recipes"
          />
          <AdminDrawerListItem
            selected={isAdminWhatNextVideosActive}
            to={"/ftadmin/what-videos"}
            label="What Videos"
          />
        </List>

        <SupportPopup />
      </Drawer>
    </nav>
  )
}
