import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useTagForm = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newTag,
    tagTypes,
    resetNewTag,
    apiAdminCreateTag,
    apiAdminEditTag,
  } = adminMslEventStoreModel

  const { type, isEditMode, createTagErrors: errors } = newTag

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewTag()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (isEditMode) {
        await apiAdminEditTag()
        setAndShowSnackbar({
          text: "Successfully edited tag !",
          severity: "success",
        })
      } else {
        await apiAdminCreateTag()
        setAndShowSnackbar({
          text: "Successfully created tag !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "displayName",
      onChangeMethod: "setDisplayName",
      label: "Display Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter display name here",
      },
    },
    {
      fieldName: "type",
      onChangeMethod: "setType",
      label: "Type",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        options: tagTypes.slice(),
        value: type,
        getOptionLabel: (option: { id: string; label: string }) => {
          return option
        },
        Input: {
          placeholder: "Select Type",
        },
      },
    },
    {
      fieldName: "label",
      onChangeMethod: "setLabel",
      label: "Label",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter label here",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Description",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter description here",
      },
    },

    {
      fieldName: "isUserFacing",
      onChangeMethod: "setIsUserFacing",
      label: "Is user facing ?",
      fieldType: FieldTypes.Switch,
    },
  ]

  return {
    isEditMode,
    loading,
    submitted,
    values: newTag,
    errors,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useTagForm }
