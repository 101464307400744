import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useTagForm } from "./useTagForm"
import { Tag } from "../../models/tag"
interface CreateTagModalProps {
  open: boolean
  closeModal: () => void
}

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new tag",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit tag", buttonLabel: "Save" },
}

const CreateTagModal = observer(({ open, closeModal }: CreateTagModalProps) => {
  const {
    isEditMode,
    values,
    errors,
    loading,
    submitted,
    onCancel,
    onSubmit,
    formConfig,
  } = useTagForm({
    isModalOpen: open,
    closeModal,
  })

  const modalMode = isEditMode ? ModalMode.EDIT : ModalMode.CREATE

  return (
    <FormModal
      open={open}
      modalTitle={modalModeLabels[modalMode].headerLabel}
      closeModal={onCancel}
      loading={loading}
      submitted={submitted}
      errors={errors}
      saveButtonLabel={modalModeLabels[modalMode].buttonLabel}
      onSubmit={onSubmit}>
      <Stack width="100%" spacing={2}>
        <AdminConfigForm<Tag>
          submitted={submitted}
          values={values}
          errors={errors}
          formConfig={formConfig}
        />
      </Stack>
    </FormModal>
  )
})
export { CreateTagModal }
