import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const ProgramInvitationModel = types.model("ProgramInvitation").props({
  id: types.identifier,
  status: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  program: types.maybeNull(types.string),
})

export interface ProgramInvitation
  extends Instance<typeof ProgramInvitationModel> {}
export interface ProgramInvitationSnapshot
  extends SnapshotOut<typeof ProgramInvitationModel> {}
