import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const VERIFIED = "verified"
export const NOT_VERIFIED = "not-verified"
export const EXPIRED = "expired"

export const InvitationModel = types.model("Invitation").props({
  id: types.identifier,
  email: types.string,
  status: types.union(
    types.literal(VERIFIED),
    types.literal(NOT_VERIFIED),
    types.literal(EXPIRED),
  ),
  invitationURL: types.maybeNull(types.string),
  groupID: types.maybeNull(types.string),
  acceptedBy: types.maybeNull(types.string),
})

export interface Invitation extends Instance<typeof InvitationModel> {}
export interface InvitationSnapshot
  extends SnapshotOut<typeof InvitationModel> {}
