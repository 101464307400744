import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { MslEvent, MslEventModel } from "../msl-event"
import { GetEventStreamingTokenDataType } from "../../services/api/api.types"
import { sortMslEvents } from "../admin-msl-events-store/utils"

const VERSION = 1

export const MindsetTrainerEventsStoreModel = types
  .model("MindsetTrainerEventsStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    mslEvents: types.optional(types.array(MslEventModel), []),
    currentStreamingEvent: types.optional(types.maybeNull(MslEventModel), null),
    currentStreamingSessionToken: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setMslEvents(value: MslEvent[]) {
      self.mslEvents.replace(value)
    },
    resetMslEvents() {
      self.mslEvents.clear()
    },
    setCurrentStreamingEvent(value: MslEvent) {
      self.currentStreamingEvent = clone(value)
    },
    resetCurrentStreamingEvent() {
      self.currentStreamingEvent = null
    },
    setCurrentStreamingSessionToken(value: string) {
      self.currentStreamingSessionToken = value
    },
    resetCurrentStreamingSessionToken() {
      self.currentStreamingSessionToken = ""
    },

    reset() {
      self.mslEvents.clear()
      self.currentStreamingSessionToken = ""
    },
  }))
  .actions((self) => ({
    async apiTrainerGetMslEvents(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminGetMslEvents({ limit: 100 })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setMslEvents(sortMslEvents(data.events as MslEvent[]))
    },
    async apiGetEventStreamingToken(eventId: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getEventStreamingToken({ eventId })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const { accessToken } = (data as GetEventStreamingTokenDataType) || {}

      self.setCurrentStreamingSessionToken(accessToken)
    },
    async apiAdminEndMslEvent(eventId: string) {
      const { api } = self.environment
      const { kind } = await api.adminEndMslEvent({ eventId })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
  }))
  .views((self) => ({
    get publishedEvents() {
      return self.mslEvents.filter((event) => event.isPublished)
    },
  }))

export interface MindsetTrainerEventsStore
  extends Instance<typeof MindsetTrainerEventsStoreModel> {}
export interface MindsetTrainerEventsStoreSnapshot
  extends SnapshotOut<typeof MindsetTrainerEventsStoreModel> {}
