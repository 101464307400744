import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { PersonModel } from "../../models/person"
import { PilotChatGroupModel } from "../pilot-coach-group"

export const PilotCoachMetaModel = types.model("PilotCoachMeta").props({
  coach: types.optional(PersonModel, () => PersonModel.create({ id: "-1" })),
  coachGroups: types.array(PilotChatGroupModel),
})

export interface PilotCoachMeta extends Instance<typeof PilotCoachMetaModel> {}
export interface PilotCoachMetaSnapshot
  extends SnapshotOut<typeof PilotCoachMetaModel> {}
