import React, { useState, useRef, useEffect } from "react"
import {
  Stack,
  OutlinedInput,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { debounce } from "rambdax"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import SearchIcon from "@mui/icons-material/Search"

const THROTTLE_DELAY = 2000

const useStyles = makeStyles((theme) =>
  createStyles({
    searchLabel: {
      fontSize: 22,
      lineHeight: "27px",
      color: "#2D353B",
      fontWeight: "bold",
    },
    searchInput: {
      width: "100%",
      background: "#FFFFFF",
      "box-shadow": "0px 2px 5px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      borderWidth: 0,
      color: "#677278",
    },
    searchInputNotchedOutline: {
      border: "0px solid !important",
    },
  }),
)

const SearchPeopleDropdown = observer(() => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  const { adminToolsStore } = useStores()
  const { apiAdminSearchUser, resetUserSearchList } = adminToolsStore

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  /**
   * Local flag which checks if the search term is empty or not
   */
  const isSearchCompleted = Boolean(searchTerm.trim().length)

  /**
   * Reset user search list on empty input
   */
  useEffect(() => {
    if (!isSearchCompleted) {
      resetUserSearchList()
    }
  }, [isSearchCompleted, resetUserSearchList])

  /**
   * Debounced method for search api, to avoid api calls while user is writing in the search field
   * The api call will be fired 2 seconds after the last debounced method
   */
  const debouncedAdminSearchUser = useRef(
    debounce(async (query: string) => {
      try {
        //avoid firing api call with empty input
        if (query.trim().length) {
          await apiAdminSearchUser({ query })
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }, THROTTLE_DELAY),
  )

  /**
   * Search Input `onChange` handler
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    setSearchTerm(event.target.value)

    //call debounced method on every input change
    debouncedAdminSearchUser.current(event.target.value)
  }

  return (
    <Stack spacing={3}>
      <Typography className={classes.searchLabel}>Search for a user</Typography>

      <Stack direction="row" spacing={2} alignItems={"center"}>
        <OutlinedInput
          id="outlined-basic"
          label=""
          autoFocus
          classes={{
            root: classes.searchInput,
            notchedOutline: classes.searchInputNotchedOutline,
            focused: classes.searchInputNotchedOutline,
          }}
          value={searchTerm}
          onChange={onInputChange}
          endAdornment={
            /**
             * While api call is loading, show the progress component
             */
            isSearchCompleted && loading ? (
              <CircularProgress size={20} />
            ) : (
              <SearchIcon />
            )
          }
        />
      </Stack>
    </Stack>
  )
})

export default SearchPeopleDropdown
