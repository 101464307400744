import React from "react"
import { Button, ButtonProps } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import DoneIcon from "@mui/icons-material/Done"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    sortOption: {
      justifyContent: "space-between",
      padding: 0,
      fontSize: theme.spacing(1.75),
      color: "#1A1921",
      ...typography.circularXXMedium,
    },
    selectedOption: {
      color: "#1771A6",
    },
    sortDivider: {
      border: "1px solid #F4F5F9",
      width: "100%",
    },
  }),
)

interface SortOptionProps extends ButtonProps {
  label: string
  selected?: boolean
}

const SortOption = ({ label, selected, ...props }: SortOptionProps) => {
  const classes = useStyles()

  return (
    <Button
      {...props}
      className={clsx(classes.sortOption, {
        [classes.selectedOption]: selected,
      })}>
      {label} {selected && <DoneIcon />}
    </Button>
  )
}

export { SortOption }
