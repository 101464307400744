import React from "react"
import type { FC, ReactNode } from "react"
import { Redirect } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

interface NotAuthGuardProps {
  children?: ReactNode
}

/**
 * Guard to redirect to initial screen if the person is authenticated
 */
export const NotAuthGuard: FC<NotAuthGuardProps> = observer(({ children }) => {
  const { loginStore } = useStores()
  const { isAuthenticated } = loginStore

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return <>{children}</>
})
