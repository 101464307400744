import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"

import * as customTypes from "../types"

export const BadgeModel = types
  .model("Badge")
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    category: types.maybeNull(types.string),
    iconURL: types.maybeNull(types.string),
    triIconURL: types.maybeNull(types.string),
    triName: types.optional(types.maybeNull(types.string), null),
    awardedAt: types.maybeNull(customTypes.iso8601),
  })
  .views((self) => ({
    get formattedAwardedAt() {
      return moment(self.awardedAt).format("MMM DD,YYYY")
    },
    get isHabitBadge() {
      return !!self.triIconURL
    },
  }))

export interface Badge extends Instance<typeof BadgeModel> {}
export interface BadgeSnapshot extends SnapshotOut<typeof BadgeModel> {}
