import React, { useState } from "react"

import { observer } from "mobx-react-lite"
import moment from "moment"
import { isEmpty } from "ramda"
import { Stack, Typography, Button, Paper } from "@material-ui/core"
import { FormInput } from "../admin-msl-events-list/form-input"
import makeStyles from "@material-ui/styles/makeStyles"

import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"
import { ReactComponent as AddNote } from "../../assets/images/filter.svg"

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(3),
    width: "50%",
  },
  headerTitle: {
    fontSize: theme.spacing(3.25),
    fontWeight: 500,
    color: "#2D353B",
    ...typography.circularXXBook,
  },

  cancelButton: {
    color: "#F36D4F",
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
    letterSpacing: 0.2,
    ...typography.circularXXBook,
  },
  deleteButton: {
    color: "white",
    background: "#F36D4F",
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
    letterSpacing: 0.2,
    marginRight: theme.spacing(1),
    ...typography.circularXXBook,
    "&:hover": {
      backgroundColor: "#F36D4F",
    },
  },

  saveNoteButton: {
    ...typography.circularXXBook,
    marginRight: theme.spacing(4),
  },
  noteButtonText: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
  },
  noteButtonIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  textArea: {
    maxHeight: "300px",
    minHeight: "300px",
    overflow: "unset !important",
    overflowY: "scroll",
    border: "1px solid #677278 !important",
  },
  inputRoot: {
    width: "100%",
  },
}))

interface EditContentProps {
  toggleModalOpen: (value: boolean) => void
}
const EditContent = observer(({ toggleModalOpen }: EditContentProps) => {
  const classes = useStyles()
  const { personStore } = useStores()

  const {
    currentPerson,
    apiCreateParticipantNotes,
    apiUpdateParticipantNotes,
    apiDeleteParticipantNote,
  } = personStore
  const { newNote, resetNewNote } = currentPerson
  const {
    updatedAt,
    noteText,
    setNoteText,
    isEditMode,
    createNewNoteErrors: errors,
  } = newNote

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const submitNote = async () => {
    setSubmitted(true)
    if (!isEmpty(errors)) {
      return
    }
    setSubmitted(false)

    try {
      let successMessage = ""
      setLoading(true)
      if (isEditMode) {
        await apiUpdateParticipantNotes({
          noteText: noteText,
          participantID: currentPerson.personID,
          groupID: currentPerson.groupID,
          id: newNote.id,
        })
        successMessage = "Successfully edited note !"
      } else {
        await apiCreateParticipantNotes({
          noteText: noteText,
          participantID: currentPerson.personID,
          groupID: currentPerson.groupID,
        })
        successMessage = "Successfully created note !"
      }
      setSnackbarSeverity("success")
      setSnackbarText(successMessage)
      setShowSnackbar(true)
      resetNewNote()
      toggleModalOpen(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  const deleteNote = async () => {
    try {
      let successMessage = ""
      setLoading(true)
      await apiDeleteParticipantNote({
        participantID: currentPerson.personID,
        groupId: currentPerson.groupID,
        id: newNote.id,
      })
      successMessage = "Successfully deleted note !"

      setSnackbarSeverity("success")
      setSnackbarText(successMessage)
      setShowSnackbar(true)
      resetNewNote()
      toggleModalOpen(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper className={classes.paper}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography className={classes.headerTitle}>Notes</Typography>
        <Stack flexDirection="row">
          {isEditMode && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={() => deleteNote()}
              disabled={loading}>
              Delete
            </Button>
          )}
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={() => {
              resetNewNote()
              toggleModalOpen(false)
            }}>
            Cancel
          </Button>
        </Stack>
      </Stack>

      <Stack flexDirection="row" alignItems="center" mb={3} mt={5}>
        <Button
          variant="contained"
          startIcon={<AddNote height="100%" width="100%" stroke="white" />}
          classes={{
            root: classes.saveNoteButton,
            textPrimary: classes.noteButtonText,
            startIcon: classes.noteButtonIcon,
          }}
          onClick={() => submitNote()}
          disabled={loading}>
          Save Note
        </Button>
        <Typography>
          {isEditMode
            ? moment(updatedAt).format("MM/DD/YYYY")
            : moment().format("MM/DD/YYYY")}
        </Typography>
      </Stack>

      <FormInput
        id="noteText"
        value={noteText || ""}
        error={submitted && Boolean(errors.noteText)}
        helperText={submitted && errors.noteText}
        onChange={(evt) => setNoteText(evt.target.value)}
        multiline
        classes={{ root: classes.inputRoot }}
        inputProps={{
          className: classes.textArea,
        }}
      />
    </Paper>
  )
})

export { EditContent }
