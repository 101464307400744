import React, { useState } from "react"
import { Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import CircularProgress from "@mui/material/CircularProgress"
import { CustomSwitch } from "../custom-switch"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { WhatVideo } from "../../models/what-videos"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switcherContainer: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)

interface WhatVideoActionProps {
  whatVideo?: WhatVideo
}

const WhatVideoAction = (props: WhatVideoActionProps) => {
  const classes = useStyles()

  const { whatVideo } = props

  const { adminMslEventStoreModel } = useStores()

  const { apiAdminEditWhatVideoStatus } = adminMslEventStoreModel
  const { active } = whatVideo

  const [loading, setLoading] = useState(false)
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const toggleChangeStatus = async () => {
    setLoading(true)

    try {
      await apiAdminEditWhatVideoStatus({ ...whatVideo, active: !active })

      const publishStatus = active ? "active" : "unactive"
      setSnackbarSeverity("success")
      setSnackbarText(`Successfully set to ${publishStatus}`)
      setShowSnackbar(true)
      setLoading(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      setLoading(false)
    }
  }

  const switcherLabel = active ? "On" : "Off"

  return (
    <div className={classes.switcherContainer}>
      {loading ? (
        <CircularProgress />
      ) : (
        <CustomSwitch
          checked={active}
          onChange={toggleChangeStatus}
          activeLabel=""
          inActiveLabel=""
          labelSwitchSpacing={1}
          activeViewColor={"#00CC7E"}
          inActiveViewColor={"#808080"}
        />
      )}
      <Typography ml={1} className={classes.label}>
        {switcherLabel}
      </Typography>
    </div>
  )
}

export { WhatVideoAction }
