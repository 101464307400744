import React from "react"
import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { ShortText } from "../admin-msl-events-list/short-text"
import { RecipeActions } from "./recipes-actions"
import { Recipe } from "../../models/recipes"
import { useStores } from "../../models/root-store"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditRecipeModal?: (event: Recipe) => void
  recipeID?: string
}
const CellComponent = ({
  openEditRecipeModal,
  recipeID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { recipesPagination } = adminMslEventStoreModel

  const recipe = recipesPagination.recipes.find(
    (recipe) => recipe.id === recipeID,
  )

  switch (props.field) {
    case "suggesticSearchKeyword":
      return <ShortText value={props.value} />
    case "suggesticRecipeId":
      return <ShortText value={props.value} />
    case "createdAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>
    case "updatedAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "actions":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditRecipeModal(recipe)}>
            Edit
          </Button>

          <RecipeActions recipeID={recipeID} />
        </Stack>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
