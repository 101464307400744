import React from "react"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Typography } from "@material-ui/core"
import { switcher } from "rambdax"
import moment from "moment"
import { VerificationCode } from "../../models/verification-code"

interface StyleProps {
  claimed?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    verificationCodeCard: {
      background: (props) =>
        switcher<string>(props.claimed)
          .is(true, theme.palette.success.light)
          .default(theme.palette.error.light),
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    verificationCodeLabel: {
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "30px",
      color: "#2D353B",
    },
    verificationCodeValue: {
      fontSize: 20,
      lineHeight: "30px",
      color: "#000",
    },
  }),
)

const CodeCard = ({
  verificationCode,
}: {
  verificationCode: VerificationCode
}) => {
  const { code, claimed, createdAt } = verificationCode
  const classes = useStyles({ claimed })

  return (
    <Stack spacing={1.5} className={classes.verificationCodeCard}>
      <Stack spacing={0.1}>
        <Typography className={classes.verificationCodeLabel}>Code</Typography>

        <Typography className={classes.verificationCodeValue}>
          {code}
        </Typography>
      </Stack>

      <Stack spacing={0.1}>
        <Typography className={classes.verificationCodeLabel}>Date</Typography>

        <Typography className={classes.verificationCodeValue}>
          {moment(createdAt).format("MMMM Do, YYYY, hh:mm A")}
        </Typography>
      </Stack>

      <Stack spacing={0.1}>
        <Typography className={classes.verificationCodeLabel}>
          Status
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography className={classes.verificationCodeValue}>
            {claimed ? "" : "Not"} Claimed
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CodeCard
