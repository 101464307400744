import React from "react"
import clsx from "clsx"
import Switch from "@mui/material/Switch"
import { Stack, Typography, Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { typography } from "../../services/theme/typography"

export const defaultTransitionDuration = 300

interface StyleProps {
  activeViewColor: string
  inActiveViewColor: string
  transitionDuration?: string
}

export interface CustomSwitchProps extends StyleProps {
  checked: boolean
  onChange: (e?: React.ChangeEvent) => void
  activeLabel: string
  inActiveLabel: string
  containerRowStyle?: string
  labelSwitchSpacing?: number
  switchCustomClass?: string
  labelCustomClass?: string
  activeLabelCustomClass?: string
  inActiveLabelCustomClass?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  listItemRoot: {
    borderBottom: "1px solid #EBEEF0",
    padding: 0,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "center",
  },
  listItemContainer: {
    listStyleType: "none",
  },
  personBoxContainer: {
    padding: theme.spacing(1, 2),
  },
  personName: {
    margin: 0,
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(2.75),
    color: theme.palette.text.secondary,
    ...typography.circularXXMedium,
  },
  personAvatar: {
    width: theme.spacing(5.4),
    height: theme.spacing(5.4),
  },
  switch: {
    width: theme.spacing(5),
    height: theme.spacing(3.5),
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      transitionDuration: (props) =>
        `${props.transitionDuration || defaultTransitionDuration}ms`,
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: (props) => props.activeViewColor,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
      margin: "1px",
    },
    "& .MuiSwitch-track": {
      height: theme.spacing(3),
      borderRadius: 14,
      backgroundColor: (props) => props.inActiveViewColor,
      opacity: 1,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },

  inActiveLabel: {
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(2.75),
    color: (props) => props.inActiveViewColor,
  },
  activeLabel: {
    color: (props) => props.activeViewColor,
    fontWeight: "bold",
  },
}))

/**
 * Custom Switch component
 * @param checked* - boolean value of the switch
 * @param onChange* - onChange method of the switch
 * @param activeLabel* - label of the right part of the switch which indicates the active switch
 * @param inActiveLabel* - label of the left part of the switch which indicates the inactive switch
 * @param labelSwitchSpacing - the number which represents the space between labels and the switch and will be used at theme.spacing
 * @param containerRowStyle - container custom class
 * @param switchCustomClass - switch component custom class
 * @param labelCustomClass - labels  custom class
 * @param activeLabelCustomClass - active label custom class, will be available only when switch is active (checked = true)
 * @param inActiveLabelCustomClass - inactive label custom class, will be available only when switch is inactive (checked = false)
 * @param activeViewColor* - color of the active switch
 * @param inActiveViewColor* - color of the inactive switch
 * @param transitionDuration - switch custom transition duration
 * @returns
 */

const CustomSwitch = ({
  checked,
  onChange,
  activeLabel,
  inActiveLabel,
  labelSwitchSpacing,

  containerRowStyle,
  switchCustomClass,
  labelCustomClass,
  activeLabelCustomClass,
  inActiveLabelCustomClass,

  activeViewColor,
  inActiveViewColor,
  transitionDuration,
}: CustomSwitchProps) => {
  const classes = useStyles({
    activeViewColor,
    inActiveViewColor,
    transitionDuration,
  })

  return (
    <Stack
      direction="row"
      spacing={labelSwitchSpacing || 2}
      alignItems="center"
      className={containerRowStyle}>
      <Typography
        className={clsx(classes.inActiveLabel, {
          [labelCustomClass]: Boolean(labelCustomClass),
          [inActiveLabelCustomClass]:
            Boolean(inActiveLabelCustomClass) && !checked,
        })}>
        {inActiveLabel}
      </Typography>

      <Switch
        className={clsx(classes.switch, {
          [switchCustomClass]: Boolean(switchCustomClass),
        })}
        checked={checked}
        defaultChecked={Boolean(checked)}
        onChange={onChange}
      />

      <Typography
        className={clsx(classes.inActiveLabel, {
          [classes.activeLabel]: checked,
          [labelCustomClass]: Boolean(labelCustomClass),
          [activeLabelCustomClass]: Boolean(activeLabelCustomClass) && checked,
        })}>
        {activeLabel}
      </Typography>
    </Stack>
  )
}

export { CustomSwitch }
