import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useTriIdeaForm } from "./useTriIdeaForm"
import { TriIdea } from "../../models/tri-idea"

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new How To",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit How To", buttonLabel: "Save" },
}

interface CreateTriHowToModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateTriIdeaModal = observer(
  ({ mode, open, closeModal }: CreateTriHowToModalProps) => {
    const {
      newTriIdea,
      loading,
      submitted,
      onCancel,
      onSubmit,
      triIdeaFormConfig,
    } = useTriIdeaForm({ mode, closeModal, isModalOpen: open })

    const { createTriIdeaErrors: errors } = newTriIdea

    const configFormFiltered = triIdeaFormConfig.filter(
      ({ fieldName }) => fieldName,
    )

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<TriIdea>
            submitted={submitted}
            values={newTriIdea}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT}
            formConfig={configFormFiltered}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateTriIdeaModal }
