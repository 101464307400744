import React from "react"
import clsx from "clsx"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack } from "@material-ui/core"
import { DataGrid, DataGridProps, GridCellProps } from "@mui/x-data-grid"

import { EmptyState } from "../empty-state"
import { BodyCell } from "../logs-table"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      height: "90%",
      minHeight: "90%",
      border: "1px solid #B0BABF",
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
    tableColumnHeaders: {
      padding: theme.spacing(0, 4),
      maxHeight: "none !important",
    },
    tableColumnHeadersInner: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      [`&>*:nth-child(1)`]: {
        flex: 1,
      },
    },
    tableColumnHeader: {
      width: "11% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
    tableColumnHeaderTitleContainer: {
      //@ts-ignore
      whiteSpace: "unset !important",
    },
    tableColumnHeaderTitle: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      padding: theme.spacing(2, 0),
      textAlign: "left",
      //@ts-ignore
      whiteSpace: "unset !important",
      ...typography.circularXXBook,
    },
    tableVirtualScrollerRenderZone: {
      width: "100%",
    },
    tableRow: {
      width: "100% !important",
      maxHeight: "none !important",
      padding: theme.spacing(0, 0, 0, 4),
      borderBottom: "1px solid #EBEEF0",
      [`&:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell`]: {
        width: "0 !important",
        borderBottom: "unset",
      },
    },
    tableCell: {
      width: "11%",
      maxWidth: "11%",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
    },
    tableOverlayWrapper: {
      position: "unset",
      height: "unset",
    },
    tableOverlayWrapperInner: {
      height: "100px !important",
      display: "flex",
    },
  }),
)

interface CustomGridTableProps extends DataGridProps {
  loading?: boolean
  emptyStateLabel?: string
  cell?: React.JSXElementConstructor<any>
  tableCellClass?: string
}

const TableCell = React.memo(
  (props: GridCellProps) => {
    const classes = useStyles()

    return (
      <BodyCell extraStyle={clsx(classes.tableCell, props.tableCellClass)}>
        {props.children}
      </BodyCell>
    )
  },
  (prevProps, nextProps) => {
    if (typeof nextProps.value === "object") {
      const areEqual =
        JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value)

      return areEqual
    }

    const areEqual = prevProps.value === nextProps.value

    return areEqual
  },
)

const CustomGridTable = ({
  loading,
  emptyStateLabel,
  cell,
  tableCellClass,
  ...props
}: CustomGridTableProps) => {
  const classes = useStyles()

  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
      }}
      pageSizeOptions={[25, 50, 100]}
      disableVirtualization
      disableRowSelectionOnClick
      {...props}
      classes={{
        root: classes.tableRoot,
        columnHeaders: classes.tableColumnHeaders,
        columnHeadersInner: classes.tableColumnHeadersInner,
        columnHeader: clsx(classes.tableColumnHeader, tableCellClass),
        columnHeaderTitleContainer: classes.tableColumnHeaderTitleContainer,
        columnHeaderTitle: classes.tableColumnHeaderTitle,
        virtualScrollerRenderZone: classes.tableVirtualScrollerRenderZone,
        row: classes.tableRow,
        "row--dynamicHeight": clsx(classes.tableCell, tableCellClass),
        overlayWrapper: classes.tableOverlayWrapper,
        overlayWrapperInner: classes.tableOverlayWrapperInner,
        ...props.classes,
      }}
      loading={loading}
      slots={{
        cell,
        noRowsOverlay: () => (
          <Stack flex={1} justifyContent="center" alignItems="center">
            <EmptyState title={emptyStateLabel || ""} />
          </Stack>
        ),
        ...props.slots,
      }}
    />
  )
}

export { CustomGridTable, TableCell }
