/* eslint-disable */
import { includes } from "rambdax"
import emojiRegexFn from "emoji-regex"
import moment from "moment"
import { isEmpty } from "ramda"

import { MslEvent } from "../models/msl-event"

const ValidateJS = require("validate.js")

const emojiRegex = emojiRegexFn()

// HACK(steve): wierd typescript situation because of strange typings
const Validate: any = ValidateJS.default ? ValidateJS.default : ValidateJS

/**
 * Validates that 1 attribute doesn't appear in another's attributes content.
 */
Validate.validators.excludes = function custom(
  value: any,
  options: any,
  key: any,
  attributes: any,
) {
  const list = attributes[options.attribute] || []
  if (value && includes(value, list)) {
    return options.message || `${value} is in the list`
  }
}

Validate.extend(Validate.validators.datetime, {
  parse: (value: any, options: any) => {
    return +moment.utc(value)
  },
  format: (value: any, options: any) => {
    var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss"
    return moment.utc(value).format(format)
  },
})

/**
 * Validates that another attribute isn't true.
 */
Validate.validators.tripped = function custom(
  value: any,
  options: any,
  key: any,
  attributes: any,
) {
  if (value && attributes[options.attribute] === true) {
    return options.message || `${options.attribute} is true`
  }
}

/**
 * Validates emoji
 */
Validate.validators.noEmoji = function custom(
  value: any,
  options: any,
  key: any,
  attriburtes: any,
) {
  if (value && !!value.match(emojiRegex)) {
    return options.message || "validations.noEmoji"
  }
}

/**
 * Validates that the emails on the list are valid.
 */
Validate.validators.validateEmails = function custom(
  value: any,
  options: any,
  key: any,
  attributes: any,
) {
  const areEmailNotValid =
    value && value.slice().some((email) => !validateEmail(email))
  if (areEmailNotValid) {
    return options.message || `${options.attribute} is true`
  }
}
/**
 * Validates if a file is selected for upload or
 * a url field is filled.
 */
Validate.validators.fileUpload = function custom(
  value: any,
  options: any,
  key: any,
  attributes: any,
) {
  const fieldIsValid = value || attributes.uploadFiles[key]?.file

  return fieldIsValid ? "" : "required"
}

/**
 * Validates that start Date is before the end Date
 */
Validate.validators.validateStartEndDates = function custom(
  value: any,
  options: { compare: string; message: string },
  key: any,
  attributes: any,
) {
  const { compare, message } = options
  const startDate = value
  const endDate = attributes[compare]
  if (endDate && startDate) {
    const datesDiff = moment(endDate).diff(moment(startDate), "minutes")
    return datesDiff <= 0 ? message : ""
  }
}

/**
 * Validates if the events sequence is on the correct order
 */
Validate.validators.validateEventsSequence = function custom(
  value: MslEvent[],
) {
  if (value.length) {
    //create an array containing sequence 1...N(length of the list) - this should be the correct sequence
    const sequenceOrder = Array.from({ length: value.length }, (_, i) => i + 1)

    //check if all numbers of the correct sequence are part of the events list
    const isCorrectSequence = sequenceOrder.every((nextSequence) =>
      value.find((event) => event.sequence === nextSequence),
    )

    return !isCorrectSequence
      ? `Please make sure to set the correct sequence from 1 to ${value.length}`
      : ""
  }
}

/**
 * Validates if the sequence value is positive
 */
Validate.validators.validateSequence = function custom(value: number | null) {
  if (value !== null) {
    const isCorrectSequence = value > 0

    return !isCorrectSequence
      ? `*Please enter number of seconds that is greater than zero`
      : ""
  }
}

ValidateJS.validators.array = (
  arrayItems: Array<any>,
  itemConstraints: any,
) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = ValidateJS.validate(item, itemConstraints)
    if (error) errors[index] = { error: error }
    return errors
  }, {})

  return isEmpty(arrayItemErrors) ? null : { errors: arrayItemErrors }
}

/**
 * Defines the rules for validating.
 *
 * Example:
 * ```ts
 * const RULES = {
 *   favoriteBand: {
 *     inclusion: { ['Weezer', 'Other'], message: 'Pick wisely.' }
 *   },
 *   name: {
 *     presence: { message: 'A developer has no name?' }
 *   }
 * }
 * validate(RULES, {})
 * ```
 *
 * See https://validatejs.org/#validators for more examples.
 *
 */
export interface ValidationRules {
  [key: string]: {}
}

/**
 * An object containing any errors found.
 *
 * Example:
 * ```js
 * {
 *   email: ['Invalid email address.'],
 *   password: [
 *     'Password must be 6 characters.',
 *     'Password must have at least 1 digit.'
 *   ]
 * }
 * ```
 */
export interface ValidationErrors {
  [key: string]: {}
}

/**
 * Runs the given rules against the data object.
 *
 * @param rules The rules to apply.
 * @param data The object to validate.
 */
export function validate(rules: ValidationRules, data: {}): ValidationErrors {
  if (typeof data !== "object") {
    return {}
  }
  return Validate(data, rules, { fullMessages: false }) || {}
}

export function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function focusFirstInvalidField(errors: ValidationErrors) {
  const [firstKey] = Object.keys(errors)
  document.getElementById(firstKey)?.scrollIntoView?.()
}
