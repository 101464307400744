import React from "react"
import type { FC, ReactNode } from "react"
import { Redirect } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

interface PilotTrainerGuardProps {
  children?: ReactNode
}

/**
 * Guard to redirect to initial screen if the person is not a pilot trainer
 */
export const PilotTrainerGuard: FC<PilotTrainerGuardProps> = observer(
  ({ children }) => {
    const { loginStore } = useStores()
    const { isAuthenticated, authentication } = loginStore

    if (!isAuthenticated) {
      return <Redirect to="/login" />
    }

    if (!authentication.isPilotTrainer) {
      return <Redirect to="/" />
    }

    return <>{children}</>
  },
)
