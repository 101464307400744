import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
  Grid,
  Stack,
  Paper,
  Modal,
  Button,
  Theme,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { drawerWidth } from "../app-drawer"
import { useStores } from "../../models/root-store"
import { ChatMessage } from "../../models/chat-message"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { ReactComponent as SuccessIcon } from "../../assets/images/success_icon.svg"
import { typography } from "../../services/theme/typography"

interface StyleProps {
  drawerWidth: number
  isDrawerOpen: boolean
}

interface DeleteMessageModalProps {
  open: boolean
  onClose: (event: any, reason: any) => void
  closeModal: () => void
  message: ChatMessage
  handleDeleteMessage: (messageId: string) => void
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  modalRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 7px 20px rgba(45, 53, 59, 0.25)",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4.5, 9),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: "auto",
      minHeight: 258,
      left: "40%",
      top: "50%",
      transform: (props) =>
        `translateX(calc(-50% + ${
          props.isDrawerOpen ? props.drawerWidth / 2 : 0
        }px)) translateY(-50%)`,
    },
  },
  formTitle: {
    fontSize: theme.spacing(3),
    color: "#485359",
    lineHeight: "29px",
    ...typography.circularXXBold,
  },
  messageText: {
    fontSize: theme.spacing(2),
    color: "#677278",
    textAlign: "center",
    lineHeight: "21px",
    ...typography.circularXXBook,
  },
  actionButton: {
    minWidth: 156,
    height: theme.spacing(5),
  },
  successTitle: {
    fontSize: theme.spacing(4.5),
    lineHeight: theme.spacing(3),
    color: "#0A8052",
    ...typography.circularXXMedium,
  },
  successSubTitle: {
    fontSize: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
}))

const DeleteMessageModal = observer(
  ({
    open,
    onClose,
    closeModal,
    message,
    handleDeleteMessage,
  }: DeleteMessageModalProps) => {
    const { uiStore } = useStores()
    const { isDrawerOpen } = uiStore

    const [loading, setLoading] = useState(false)
    const [showDeletedMessage, toggleDeletedMessage] = useState(false)

    const classes = useStyles({ drawerWidth, isDrawerOpen })

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    /**
     * Method to handle after the delete api fires successfully
     * Activate `showDeletedMessage` to show the success message component
     * Set a timeout to 2 sec to close the popover
     */
    const handleSuccessDeletion = () => {
      toggleDeletedMessage(true)
      setTimeout(() => {
        closeModal()
        toggleDeletedMessage(false)
      }, 2000)
    }

    const onDelete = async () => {
      try {
        setLoading(true)
        await handleDeleteMessage(message?.id)
        setSnackbarSeverity("success")
        setSnackbarText("Successfully deleted message")
        setShowSnackbar(true)
        handleSuccessDeletion()
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      } finally {
        setLoading(false)
      }
    }

    const renderDeleteMessage = () => {
      return (
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <Stack spacing={3} direction="row" alignItems="center">
            <Typography className={classes.successTitle}>Success!</Typography>

            <SuccessIcon />
          </Stack>

          <Typography className={classes.successSubTitle}>
            The messagee has been deleted
          </Typography>
        </Stack>
      )
    }

    const renderModalContent = () => {
      if (showDeletedMessage) {
        return renderDeleteMessage()
      }
      return (
        <Grid item xs={12}>
          <Stack spacing={3} alignItems="center">
            <Typography className={classes.formTitle}>
              Are you sure you want to delete this message?
            </Typography>

            <Typography className={classes.messageText}>
              {message?.message}
            </Typography>

            <Stack direction="row" flex={1} spacing={4}>
              <Button
                onClick={closeModal}
                variant="outlined"
                size="large"
                className={classes.actionButton}>
                Cancel
              </Button>

              <Button
                endIcon={
                  loading && <CircularProgress color="secondary" size={24} />
                }
                onClick={onDelete}
                disabled={loading}
                variant="contained"
                size="large"
                className={classes.actionButton}>
                Delete Message
              </Button>
            </Stack>
          </Stack>
        </Grid>
      )
    }

    return (
      <Modal
        open={open}
        onClose={onClose}
        classes={{
          root: classes.modalRoot,
        }}
        aria-labelledby="delete-message-modal"
        aria-describedby="delete-message-modal-desc">
        <Paper className={classes.paper}>{renderModalContent()}</Paper>
      </Modal>
    )
  },
)

export { DeleteMessageModal }
