import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"

import { uniqBy, prop } from "ramda"

import { TriIdeaRecipe, TriIdeaRecipeModel } from "../tri-idea-recipe"

export const LIMIT = 20

/**
 * Represents a pagination model.
 */
export const TriIdeaRecipesPaginationModel = types
  .model("TriIdeaRecipesPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    loading: types.optional(types.boolean, false),
    query: types.optional(types.maybeNull(types.string), ""),
    next: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.maybeNull(types.number), LIMIT),
    recipes: types.optional(types.array(TriIdeaRecipeModel), []),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },

    setQuery(value: string) {
      self.query = value
    },

    setNext(value: number) {
      self.next = value
    },

    setLimit(value: number) {
      self.limit = value
    },

    setPagination(value: { next: number; query: string }) {
      self.next = value.next
      self.query = value.query
    },

    setTriRecipes(value: TriIdeaRecipe[]) {
      self.recipes.replace(uniqBy(prop("id"), [...self.recipes, ...value]))
    },

    resetTriRecipes() {
      if (isAlive(self.recipes)) {
        self.recipes.clear()
      }
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriIdeaRecipes() {
      const { api } = self.environment
      const { next, limit, query, setLoading } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriIdeaRecipes({
        next,
        limit,
        query,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const triIdeaRecipesPagination = {
        next: data.data.next,
        previous: data.data.previous,
        limit: data.data.limit,
        query: query,
      }

      self.setTriRecipes(data.data.recipes)
      self.setPagination(triIdeaRecipesPagination)
      setLoading(false)
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetTriRecipes()
      self.limit = LIMIT
      self.next = null
      self.query = ""
    },
  }))

export interface TriIdeaRecipesPagination
  extends Instance<typeof TriIdeaRecipesPaginationModel> {}
export interface TriIdeaRecipesPaginationSnapshot
  extends SnapshotOut<typeof TriIdeaRecipesPaginationModel> {}
