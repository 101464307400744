import React from "react"
import { Theme, Autocomplete, AutocompleteProps } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { FormInput, FormInputProps } from "./form-input"

interface FormSelectProps<T>
  extends Omit<AutocompleteProps<T, boolean, boolean, boolean>, "renderInput"> {
  Input?: FormInputProps
  forbidDuplicates?: boolean
  hideTags?: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  formControl: {
    width: "100%",
  },
}))

//filter options that are already selected
const filterDuplicates = <T extends { id: string }>(
  options: T[],
  value: T[],
) => {
  return options.filter((option) => !value.find((e) => e.id === option.id))
}

const FormSelect = <T extends { id: string }>({
  value,
  options,
  Input,
  forbidDuplicates,
  hideTags,
  ...props
}: FormSelectProps<T>) => {
  const classes = useStyles()

  const selectOptions = forbidDuplicates
    ? filterDuplicates(options as T[], value as T[])
    : options
  //in case we're showing tags, and if the tags list is filled, hide the placeholder
  const hideInputPlaceholder = !hideTags && (value as T[])?.length > 0

  return (
    <Autocomplete
      {...props}
      value={value}
      options={selectOptions}
      freeSolo
      disableClearable
      classes={{
        root: classes.formControl,
      }}
      renderTags={hideTags ? () => <div /> : props.renderTags}
      renderInput={(params) => (
        <FormInput
          {...params}
          {...Input}
          placeholder={hideInputPlaceholder ? "" : Input?.placeholder}
          InputProps={{
            ...params.InputProps,
            ...Input.InputProps,
            type: "search",
          }}
        />
      )}
    />
  )
}

export { FormSelect }
