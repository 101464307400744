import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"

export enum ChatType {
  Weight = "weight",
  Exercise = "exercise",
  Message = "message",
  Idea = "idea",
  Gratitude = "gratitude",
  Train = "train_video",
}

export const chatTypeLiterals = Object.values(ChatType).map((v) =>
  types.literal(v),
)

export const ChatMessageLogModel = types
  .model("ChatMessageLog")
  .props({
    id: types.identifier,
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    imageURL: types.maybeNull(types.string),
    log: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    unit: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
    type: types.optional(types.union(...chatTypeLiterals), ChatType.Message),
    verified: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setVerified(value: boolean) {
      self.verified = value
    },
  }))
  .views((self) => ({
    get formattedLabel() {
      return `${self.value} ${self.unit}`
    },
    get formattedCreatedAt() {
      return moment(self.createdAt).format("LLL")
    },
    get formattedCreatedAtDate() {
      return moment(self.createdAt).format("MMMM Do, YYYY")
    },
    get formattedCreatedAtTime() {
      return moment(self.createdAt).format("hh:mm A")
    },
  }))

export interface ChatMessageLog extends Instance<typeof ChatMessageLogModel> {}
export interface ChatMessageLogSnapshot
  extends SnapshotOut<typeof ChatMessageLogModel> {}
