import React from "react"
import clsx from "clsx"
import { Stack, Input, IconButton, InputProps } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { ReactComponent as PickerUpAngleIcon } from "../../assets/images/picker_up_angle.svg"
import { typography } from "../../services/theme/typography"

interface TimeInputProps extends InputProps {
  increment?: () => void
  decrement?: () => void
  disabledOption?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    timeInput: {
      width: 54,
      height: 50,
      padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
      background: theme.palette.common.white,
      boxShadow: "0px 1px 1px rgba(0, 14, 51, 0.05)",
      borderRadius: theme.spacing(0.75),
      fontSize: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXBold,
      //focused mode styles
      '&.MuiInput-root[class*="MuiInputBase-root"]:after': {
        "border-bottom": "0px",
      },
      '&.MuiInput-root[class*="MuiInputBase-root"]:hover:not(.Mui-disabled):before': {
        "border-bottom": "0px solid rgba(0, 0, 0, 0.42)",
      },
      '&.MuiInput-root[class*="MuiInputBase-root"]:before': {
        "border-bottom": "0px solid rgba(0, 0, 0, 0.42)",
      },
    },
    timeFormatInput: {
      width: 62,
      color: "#677278",
    },
    arrowButton: {
      padding: 0,
      "&:hover": {
        background: "transparent",
      },
    },
    downArrow: {
      transform: "rotate(-180deg)",
    },
  }),
)

const TimeInput = ({
  increment,
  decrement,
  disabledOption,
  ...props
}: TimeInputProps) => {
  const classes = useStyles()

  return (
    <Stack spacing={2}>
      <IconButton
        className={classes.arrowButton}
        disableFocusRipple
        disableTouchRipple
        onClick={increment}>
        <PickerUpAngleIcon />
      </IconButton>

      <Input
        className={clsx(classes.timeInput, {
          [classes.timeFormatInput]: disabledOption,
        })}
        {...props}
      />

      <IconButton
        className={classes.arrowButton}
        disableFocusRipple
        disableTouchRipple
        onClick={decrement}>
        <PickerUpAngleIcon className={classes.downArrow} />
      </IconButton>
    </Stack>
  )
}

export default TimeInput
