import React from "react"
import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { ShortText } from "../admin-msl-events-list/short-text"
import { Nudges } from "../../models/nudges"
import { useStores } from "../../models/root-store"
import { NudgeAction } from "./nudges-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditNudgeModal?: (event: Nudges) => void
  nudgeID?: string
}
const CellComponent = ({
  openEditNudgeModal,
  nudgeID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { nudges } = adminMslEventStoreModel

  const nudge = nudges.find((nudge) => nudge.id === nudgeID)

  switch (props.field) {
    case "defaultSendAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "active":
      return <NudgeAction nudge={nudge} />

    case "edit":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditNudgeModal(nudge)}>
            Edit
          </Button>
        </Stack>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
