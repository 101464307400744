import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { Theme } from "../../models/theme"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"

import { useThemeForm } from "./useThemeForm"
interface CreateThemeModalProps {
  open: boolean
  closeModal: () => void
}

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new theme",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit theme", buttonLabel: "Save" },
}

const CreateThemeModal = observer(
  ({ open, closeModal }: CreateThemeModalProps) => {
    const {
      isEditMode,
      values,
      errors,
      loading,
      submitted,
      onCancel,
      onSubmit,
      formConfig,
    } = useThemeForm({
      isModalOpen: open,
      closeModal,
    })

    const modalMode = isEditMode ? ModalMode.EDIT : ModalMode.CREATE

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[modalMode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[modalMode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Theme>
            submitted={submitted}
            values={values}
            errors={errors}
            isEditMode={isEditMode}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateThemeModal }
