import { types, Instance, SnapshotOut } from "mobx-state-tree"

import { ConditionOptionModel } from "../condition-option"

export const PersonConditionModel = types.model("PersonCondition").props({
  availableOptions: types.optional(types.array(ConditionOptionModel), []),
  optionIDs: types.optional(types.array(ConditionOptionModel), []),
  questionConfigID: types.maybeNull(types.string),
  questionID: types.maybeNull(types.string),
  questionPrimaryText: types.maybeNull(types.string),
  questionSecondaryText: types.maybeNull(types.string),
  sessionID: types.maybeNull(types.string),
})

export interface PersonCondition
  extends Instance<typeof PersonConditionModel> {}
export interface PersonConditionSnapshot
  extends SnapshotOut<typeof PersonConditionModel> {}
