import { updatedDiff } from "deep-object-diff"
import { onSnapshot } from "mobx-state-tree"
import { toJS } from "mobx"
import { RootStoreModel, RootStore } from "./root-store"
import { Environment } from "../environment"
import { TOKEN_KEY, __DEV__ } from "../../config/constants"
import { dotify } from "../../utils/objects"
import { Api } from "../../services/api"
import { loadString } from "../../utils"
import { getLogger } from "../../utils/logging"

const logger = getLogger("core")

const ROOT_STATE_STORAGE_KEY = "root"

const getStateFromLocalStorage = async () => {
  try {
    //@ts-ignore
    return JSON.parse(await localStorage.getItem(ROOT_STATE_STORAGE_KEY)) || {}
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state instead of crashing.
    // if there's any problems loading, then let's at least fallback to an empty state instead of crashing.
    console.error("error in setup-root-store", e)
    return {}
  }
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore
  let data: any = {}
  let oldSnapshot: object = {}
  let currentSnapshot: object = {}

  logger.info("NODE_ENV=", process.env.NODE_ENV)

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment(new Api())
  try {
    // load data from storage
    data = await getStateFromLocalStorage()
    rootStore = RootStoreModel.create(data, env)
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    // @ts-ignore
    rootStore = RootStoreModel.create({}, env)
    logger.warn("Could not load RootStore state data from LocalStorage")
  }

  // reactotron logging
  if (__DEV__) {
    //@ts-ignore
    env.reactotron.setRootStore(rootStore)
  }

  await onSetupRootStore(rootStore)

  // track changes & save to storage [why is this called here?]
  onSnapshot(rootStore, (snapshot) => {
    oldSnapshot = currentSnapshot
    currentSnapshot = toJS(snapshot) as object

    const changes = dotify(updatedDiff(oldSnapshot, currentSnapshot as object))

    if (Object.entries(changes).length !== 0) {
      let v = "\n"
      Object.entries(changes).forEach(([key, value]) => {
        v += `${key} = ${value}\n`
      })
      logger.info(v)
    }

    localStorage.setItem(ROOT_STATE_STORAGE_KEY, JSON.stringify(snapshot))
  })

  return rootStore
}

/**
 * Setup environment.
 */
async function createEnvironment(api: Api) {
  const env = new Environment(api)

  // allow each service to setup
  await env.setup()
  return env
}

/**
 * Perform tasks after root-store has been created.
 */
async function onSetupRootStore(rootStore: RootStore) {
  const { loginStore, siteStore } = rootStore
  const { person, environment } = loginStore

  try {
    const token = await loadString(TOKEN_KEY)
    if (token) {
      // Set access token.
      environment.api.setAuthorizationHeader(token)
      environment.api.setPersonId(person.id)
      siteStore.currentSiteId &&
        environment.api.setSiteId(siteStore.currentSiteId)
      siteStore.clearSites()
      await siteStore.apiGetSites()
    } else {
      loginStore.reset()
    }
  } catch (error) {
    console.error(error)
  }
}
