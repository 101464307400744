import React, { useState } from "react"

import clsx from "clsx"
import { observer } from "mobx-react-lite"
import makeStyles from "@material-ui/styles/makeStyles"
import { Paper, Modal } from "@material-ui/core"

import { ReadContent } from "./read-content"
import { EditContent } from "./edit-content"

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(3),
    width: "100%",
    overflow: "auto",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

interface ParticipantNotesProps {
  closeModal?: () => void
  extraStyle?: string
  showHeader?: boolean
  disableCreate?: boolean
}

const ParticipantNotes = observer(
  ({
    closeModal,
    extraStyle,
    showHeader,
    disableCreate,
  }: ParticipantNotesProps) => {
    const classes = useStyles()
    const [isModalOpen, toggleModalOpen] = useState(false)

    const handleModalClose = (_, reason) => {
      if (reason === "backdropClick") {
      }
    }

    return (
      <Paper className={clsx(classes.paper, extraStyle)}>
        <ReadContent
          showHeader={showHeader}
          disableCreate={disableCreate}
          closeModal={closeModal}
          toggleModalOpen={toggleModalOpen}
        />

        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          className={classes.modalContainer}>
          <EditContent toggleModalOpen={toggleModalOpen} />
        </Modal>
      </Paper>
    )
  },
)

export { ParticipantNotes }
