import { types, Instance, SnapshotOut } from "mobx-state-tree"

import { validate, ValidationRules } from "../../utils/validate"
import { UploadFileModel, UploadFile } from "../upload-file"

export const TrainerModel = types
  .model("Trainer")
  .props({
    id: types.string,
    personID: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    bannerImageURL: types.maybeNull(types.string),
    circleImageURL: types.maybeNull(types.string),
    v2BannerImageURL: types.maybeNull(types.string),
    v2CircleImageURL: types.maybeNull(types.string),
    bio: types.optional(types.maybeNull(types.string), ""),
    professionalTitle: types.optional(types.maybeNull(types.string), ""),
    uploadFiles: types.optional(
      types.model({
        circleImageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "circleImageURL",
          }),
        ),
        bannerImageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "bannerImageURL",
          }),
        ),
        v2CircleImageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "v2CircleImageURL",
          }),
        ),
        v2BannerImageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "v2BannerImageURL",
          }),
        ),
      }),
      {},
    ),
  })
  .actions((self) => ({
    setPersonID(value: string) {
      self.personID = value
    },
    setFullName(value: string) {
      self.fullName = value
    },
    setBannerImageURL(value: string) {
      self.bannerImageURL = value
    },
    setCircleImageURL(value: string) {
      self.circleImageURL = value
    },
    setV2BannerImageURL(value: string) {
      self.v2BannerImageURL = value
    },
    setV2CircleImageURL(value: string) {
      self.v2CircleImageURL = value
    },
    setBio(value: string) {
      self.bio = value
    },
    setProfessionalTitle(value: string) {
      self.professionalTitle = value
    },

    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },

    setContentUrl(
      id:
        | "circleImageURL"
        | "bannerImageURL"
        | "v2CircleImageURL"
        | "v2BannerImageURL",
      value: string,
    ) {
      self[id] = value
    },

    reset() {
      self.id = "-1"
      self.personID = ""
      self.fullName = ""
      self.bannerImageURL = ""
      self.circleImageURL = ""
      self.v2BannerImageURL = ""
      self.v2CircleImageURL = ""
      self.bio = ""
      self.professionalTitle = ""
    },
  }))
  .views((self) => ({
    get isEditMode() {
      return self.id !== "-1"
    },
    get createMslTrainerErrors() {
      return validate(CREATE_MSL_TRAINER_VALIDATION_RULES, self)
    },
  }))

const CREATE_MSL_TRAINER_VALIDATION_RULES: ValidationRules = {
  fullName: {
    presence: { allowEmpty: false, message: "required" },
  },
  bannerImageURL: {
    fileUpload: {},
  },
  circleImageURL: {
    fileUpload: {},
  },
}

export interface Trainer extends Instance<typeof TrainerModel> {}
export interface TrainerSnapshot extends SnapshotOut<typeof TrainerModel> {}
