import { useState } from "react"

import { useStores } from "../../models/root-store"
import { Note } from "../../models/note"

export enum SORT_KEYS {
  LATEST,
  OLDEST,
}
export const sortOptions = [
  {
    key: SORT_KEYS.LATEST,
    label: "Latest First",
  },
  {
    key: SORT_KEYS.OLDEST,
    label: "Oldest First",
  },
]

const sortList = (list: Note[], activeSortKey: SORT_KEYS) => {
  switch (activeSortKey) {
    case SORT_KEYS.OLDEST:
      return list.reverse()

    default:
      return list
  }
}

export const useNotesSort = () => {
  const [activeSortKey, setActiveSortKey] = useState<SORT_KEYS>(
    SORT_KEYS.LATEST,
  )

  const { personStore } = useStores()
  const { currentPerson } = personStore

  const list = (currentPerson?.notes || []).slice()
  const sortedList = sortList(list, activeSortKey)

  return {
    activeSortKey,
    setActiveSortKey,
    formattedList: sortedList,
  }
}
