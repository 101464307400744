import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { MslEvent, MslEventModel, MslEventsPaginationModel } from "../msl-event"
import { Trainer, TrainerModel } from "../msl-event/trainer"
import { Topic, TopicModel } from "../msl-event/topic"
import { Invitation, InvitationModel } from "../invitation"
import { NewInvitationModel } from "../invitation/new-invitation"
import { InvitationPaginationModel } from "../invitation/invitation-pagination"
import { Group, GroupModel } from "../group"
import { Entitlement, EntitlementModel } from "../entitlement"
import { Program, ProgramModel } from "../program"
import { Coach, CoachModel } from "../coach"
import { Tag, TagModel } from "../tag"
import { Series, SeriesModel } from "../series"
import { Category, CategoryModel } from "../category"
import { Theme, ThemeModel } from "../theme"
import { Nudges, NudgesModel } from "../nudges"
import { DidYouKnowTile, DidYouKnowTileModel } from "../did-you-know-tile"
import { WhatsNextTiles, WhatsNextTilesModel } from "../whats-next-tiles"
import { RakCard, RakCardModel } from "../rak-card"
import { Recipe, RecipeModel } from "../recipes"
import { RecipePaginationModel, PAGE, PAGE_SIZE } from "../recipe-pagination"
import { WhatVideo, WhatVideosModel } from "../what-videos/what-videos"
import { sortTags, transformMslEventParams } from "./utils"
import { adminUploadFile } from "../../utils/adminUploadFile"
import {
  WhatVideoCategories,
  WhatVideoCategoriesModel,
} from "../what-videos-categories"
import { PublishedVideo, PublishedVideosModel } from "../published-videos"
import { TriIdea, TriIdeaModel } from "../tri-idea"
import { TriIdeasPaginationModel } from "../tri-ideas-pagination"
import { TriIdeaProductsPaginationModel } from "../tri-idea-products-pagination"
import { TriIdeaRecipesPaginationModel } from "../tri-idea-recipes-pagination"
import { TriIdeaRecipe } from "../tri-idea-recipe"

const VERSION = 1

export const AdminMslEventStoreModel = types
  .model("AdminMslEventStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    mslEventsPagination: types.optional(MslEventsPaginationModel, () =>
      MslEventsPaginationModel.create(),
    ),
    newMslEvent: types.optional(MslEventModel, () =>
      MslEventModel.create({
        id: "-1",
      }),
    ),
    editMslEvent: types.optional(MslEventModel, () =>
      MslEventModel.create({
        id: "-1",
      }),
    ),
    mindsetTrainers: types.optional(types.array(TrainerModel), []),
    newTrainer: types.optional(TrainerModel, () =>
      TrainerModel.create({
        id: "-1",
      }),
    ),
    eventTopics: types.optional(types.array(TopicModel), []),
    newTopic: types.optional(TopicModel, () =>
      TopicModel.create({
        id: "-1",
      }),
    ),
    invitations: types.optional(types.array(InvitationModel), []),
    invitationsPagination: types.optional(InvitationPaginationModel, () =>
      InvitationPaginationModel.create(),
    ),
    newInvitation: types.optional(NewInvitationModel, () =>
      NewInvitationModel.create(),
    ),
    nonDppGroups: types.optional(types.array(GroupModel), []),
    newNonDppGroup: types.optional(GroupModel, () =>
      GroupModel.create({
        id: "-1",
      }),
    ),
    entitlements: types.optional(types.array(EntitlementModel), []),
    programs: types.optional(types.array(ProgramModel), []),
    coaches: types.optional(types.array(CoachModel), []),
    tags: types.optional(types.array(TagModel), []),
    newTag: types.optional(TagModel, () =>
      TagModel.create({
        id: "-1",
      }),
    ),
    series: types.optional(types.array(SeriesModel), []),
    newSeries: types.optional(SeriesModel, () =>
      SeriesModel.create({
        id: "-1",
      }),
    ),
    categories: types.optional(types.array(CategoryModel), []),
    newCategory: types.optional(CategoryModel, () =>
      CategoryModel.create({
        id: "-1",
      }),
    ),
    themes: types.optional(types.array(ThemeModel), []),
    newTheme: types.optional(ThemeModel, () =>
      ThemeModel.create({
        id: "-1",
      }),
    ),
    nudges: types.optional(types.array(NudgesModel), []),
    newNudge: types.optional(NudgesModel, () =>
      NudgesModel.create({
        id: "-1",
      }),
    ),
    nudgeTypes: types.optional(types.array(types.string), []),
    didYouKnowTiles: types.optional(types.array(DidYouKnowTileModel), []),
    newDidYouKnowTile: types.optional(DidYouKnowTileModel, () =>
      DidYouKnowTileModel.create({
        id: "-1",
      }),
    ),
    tagTypes: types.optional(types.array(types.string), []),
    tiles: types.optional(types.array(WhatsNextTilesModel), []),
    newTile: types.optional(WhatsNextTilesModel, () =>
      WhatsNextTilesModel.create({
        id: "-1",
      }),
    ),
    tileTypes: types.optional(types.array(types.string), []),
    rakCards: types.optional(types.array(RakCardModel), []),
    newRakCard: types.optional(RakCardModel, () =>
      RakCardModel.create({
        id: "-1",
      }),
    ),
    recipesPagination: types.optional(RecipePaginationModel, () =>
      RecipePaginationModel.create({
        page: PAGE,
        pageSize: PAGE_SIZE,
        recipes: [],
      }),
    ),
    newRecipe: types.optional(RecipeModel, () =>
      RecipeModel.create({
        id: "-1",
      }),
    ),
    whatVideos: types.optional(types.array(WhatVideosModel), []),
    newWhatVideo: types.optional(WhatVideosModel, () =>
      WhatVideosModel.create({
        id: "-1",
      }),
    ),
    whatVideosCategories: types.optional(
      types.array(WhatVideoCategoriesModel),
      [],
    ),
    publishedVideos: types.optional(types.array(PublishedVideosModel), []),
    triIdeasPagination: types.optional(TriIdeasPaginationModel, () =>
      TriIdeasPaginationModel.create({
        ideas: [],
      }),
    ),
    newTriIdea: types.optional(TriIdeaModel, () =>
      TriIdeaModel.create({
        id: "-1",
      }),
    ),
    triIdeaTypes: types.optional(types.array(types.string), []),
    triIdeaProductsPagination: types.optional(
      TriIdeaProductsPaginationModel,
      () =>
        TriIdeaProductsPaginationModel.create({
          products: [],
        }),
    ),
    triIdeaRecipesPagination: types.optional(
      TriIdeaRecipesPaginationModel,
      () =>
        TriIdeaRecipesPaginationModel.create({
          recipes: [],
        }),
    ),
  })
  .actions((self) => ({
    setMindsetTrainers(value: Trainer[]) {
      self.mindsetTrainers.replace(value)
    },
    appendTrainer(value: Trainer) {
      self.mindsetTrainers.replace([value, ...self.mindsetTrainers])
    },
    setNewTrainer(value: Trainer) {
      self.newTrainer = clone(value)
    },
    setEventTopics(value: Topic[]) {
      self.eventTopics.replace(value)
    },
    appendTopic(value: Topic) {
      self.eventTopics.replace([value, ...self.eventTopics])
    },
    setEditMslEvent(value: MslEvent) {
      self.editMslEvent = clone(value)
    },
    setInvitations(value: Invitation[]) {
      self.invitations.replace(value)
    },
    setNonDppGroups(value: Group[]) {
      self.nonDppGroups.replace(value)
    },
    setEntitlements(value: Entitlement[]) {
      self.entitlements.replace(value)
    },
    setPrograms(value: Program[]) {
      self.programs.replace(value)
    },
    setCoaches(value: Coach[]) {
      self.coaches.replace(value)
    },
    setTags(value: Tag[]) {
      self.tags.replace(sortTags(value))
    },
    setNewTag(value: Tag) {
      self.newTag = clone(value)
    },
    setSeries(value: Series[]) {
      self.series.replace(value)
    },
    setNewSeries(value: Series) {
      self.newSeries = clone(value)
    },
    setCategories(value: Category[]) {
      self.categories.replace(value)
    },
    setNewCategory(value: Category) {
      self.newCategory = clone(value)
    },
    setThemes(value: Theme[]) {
      self.themes.replace(value)
    },
    setNewTheme(value: Theme) {
      self.newTheme = clone(value)
    },
    setNudges(value: Nudges[]) {
      self.nudges.replace(value)
    },
    setNewNudge(value: Nudges) {
      self.newNudge = clone(value)
    },
    setNudgeTypes(value: string[]) {
      self.nudgeTypes.replace(value)
    },
    setTagTypes(value: string[]) {
      self.tagTypes.replace(value)
    },
    setDidYouKnowTiles(value: DidYouKnowTile[]) {
      self.didYouKnowTiles.replace(value)
    },
    setNewDidYouKnowTile(value: DidYouKnowTile) {
      self.newDidYouKnowTile = clone(value)
    },
    setTiles(value: WhatsNextTiles[]) {
      self.tiles.replace(value)
    },
    setNewTile(value: WhatsNextTiles) {
      self.newTile = clone(value)
    },
    setTileTypes(value: string[]) {
      self.tileTypes.replace(value)
    },
    setRakCards(value: RakCard[]) {
      self.rakCards.replace(value)
    },
    setNewRakCard(value: RakCard) {
      self.newRakCard = clone(value)
    },
    setNewRecipe(value: Recipe) {
      self.newRecipe = clone(value)
    },
    setWhatVideos(value: WhatVideo[]) {
      self.whatVideos.replace(value)
    },
    setWhatVideoCategories(value: WhatVideoCategories[]) {
      self.whatVideosCategories.replace(value)
    },
    setPublishedVideos(value: PublishedVideo[]) {
      self.publishedVideos.replace(value)
    },
    setNewWhatVideo(value: WhatVideo) {
      self.newWhatVideo = clone(value)
    },
    setNewTriIdea(value: TriIdea) {
      self.newTriIdea = clone(value)
    },
    setTriIdeaTypes(value: string[]) {
      self.triIdeaTypes.replace(value)
    },
    resetNewMslEvent() {
      self.newMslEvent.reset()
    },
    resetNewTrainer() {
      self.newTrainer.reset()
    },
    resetNewTopic() {
      self.newTopic.reset()
    },
    resetNewInvitation() {
      self.newInvitation.reset()
    },
    resetNewNonDppGroup() {
      self.newNonDppGroup.reset()
    },
    resetNewSeries() {
      self.newSeries.reset()
    },
    resetNewCategory() {
      self.newCategory.reset()
    },
    resetNewTheme() {
      self.newTheme.reset()
    },
    resetNewTag() {
      self.newTag = TagModel.create({
        id: "-1",
      })
    },
    resetNewNudge() {
      self.newNudge = NudgesModel.create({
        id: "-1",
      })
    },
    resetNewDidYouKnowTile() {
      self.newDidYouKnowTile = DidYouKnowTileModel.create({
        id: "-1",
      })
    },
    resetNewTile() {
      self.newTile = WhatsNextTilesModel.create({
        id: "-1",
      })
    },
    resetNewRakCard() {
      self.newRakCard = RakCardModel.create({
        id: "-1",
      })
    },
    resetNewRecipe() {
      self.newRecipe = RecipeModel.create({
        id: "-1",
      })
    },
    resetNewWhatVideo() {
      self.newWhatVideo = WhatVideosModel.create({
        id: "-1",
      })
    },
    resetNewTriIdea() {
      self.newTriIdea = TriIdeaModel.create({
        id: "-1",
      })
    },
    reset() {
      self.mslEventsPagination.resetMslEvents()
    },
  }))
  .actions((self) => ({
    async apiAdminGetMslEvents(): Promise<void> {
      const { api } = self.environment
      const {
        sortBy,
        orderBy,
        filtered,
        pageSize,
        next,
        setPagination,
        setMslEvents,
        setLoading,
      } = self.mslEventsPagination
      setLoading(true)
      const { data, kind } = await api.adminGetMslEvents({
        sortBy,
        orderBy,
        limit: pageSize,
        next,
        ...(typeof filtered === "boolean" ? { filtered } : {}),
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      setPagination({
        totalCount: data.total,
        next: data.next,
      })
      setMslEvents(data.events)
      setLoading(false)
    },
    async apiAdminSearchMslEvents({ query }: { query: string }): Promise<void> {
      const { api } = self.environment
      const { setMslEvents, setLoading } = self.mslEventsPagination
      const { data, kind } = await api.adminSearchMslEvents({
        query,
        limit: 20,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      setMslEvents(data.events)
      setLoading(false)
    },
    async apiAdminCreateMslEvent(): Promise<void> {
      const { api } = self.environment
      const { id, ...params } = transformMslEventParams(self.newMslEvent)
      const { data, kind } = await api.adminCreateMslEvent(params)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.mslEventsPagination.appendMslEvent(data as MslEvent)
      self.resetNewMslEvent()
    },
    async apiAdminEditMslEvent(): Promise<void> {
      const { api } = self.environment
      const params = transformMslEventParams(self.editMslEvent)
      const { data, kind } = await api.adminEditMslEvent(params)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.mslEventsPagination.updateMslEvent(data as MslEvent)
    },
    async apiAdminPublishMslEvent(eventId: string) {
      const { api } = self.environment
      const { kind } = await api.adminPublishMslEvent({ eventId })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const selectedEvent = self.mslEventsPagination.mslEvents.find(
        (event) => event.id === eventId,
      )
      selectedEvent?.setIsPublished?.(true)
    },
    async apiAdminUnPublishMslEvent(eventId: string) {
      const { api } = self.environment
      const { kind } = await api.adminUnPublishMslEvent({ eventId })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const selectedEvent = self.mslEventsPagination.mslEvents.find(
        (event) => event.id === eventId,
      )
      selectedEvent?.setIsPublished?.(false)
    },
    async apiAdminDeleteMslEvent(eventId: string) {
      const { api } = self.environment
      const { kind } = await api.adminDeleteMslEvent({ eventId })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.mslEventsPagination.deleteMslEvent(eventId)
    },
    async apiAdminGetMindsetTrainers() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetMindsetTrainers()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const trainers = (data as Trainer[]).reverse()
      self.setMindsetTrainers(trainers)
    },
    async apiAdminGetEventTopics() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetEventTopics()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const topics = (data as Topic[]).reverse()
      self.setEventTopics(topics)
    },
    async apiAdminCreateEventTopic(): Promise<void> {
      const { api } = self.environment
      const { name } = self.newTopic
      const { data, kind } = await api.adminCreateEventTopic({
        name,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.appendTopic(data as Topic)
      self.resetNewTopic()
    },
    async apiAdminGetInvitations() {
      const { api } = self.environment
      const {
        limit,
        next,
        setLoading,
        setInvitations,
        setNextPage,
      } = self.invitationsPagination

      setLoading(true)
      const { kind, data } = await api.adminGetInvitations({
        limit,
        timestamp: next || undefined,
      })

      if (kind !== "ok") {
        //@ts-ignore
        setLoading(false)
        throw new Error(data?.reason)
      }

      const isEndReached = data.invitations.length < limit
      setInvitations(data.invitations)
      setNextPage({
        next: isEndReached ? 0 : data.next,
        previous: data.previous,
        isEndReached,
      })
      setLoading(false)
    },
    async apiAdminGetNonDppGroups() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetNonDppGroups()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const groups = (data as Group[]).reverse()
      self.setNonDppGroups(groups)
    },
    async apiAdminGetEntitlements() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetEntitlements()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const entitlements = data as Entitlement[]
      self.setEntitlements(entitlements)
    },
    async apiAdminGetPrograms() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetPrograms()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const programs = data as Program[]
      self.setPrograms(programs)
    },
    async apiAdminGetCoaches() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetCoaches()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const coaches = data as Coach[]
      self.setCoaches(coaches)
    },
    async apiAdminGetTags() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTags()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const tags = (data as Tag[]).reverse()
      self.setTags(tags)
    },
    async apiAdminGetSeries() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetSeries()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const series = data as Series[]
      self.setSeries(series)
    },
    async apiAdminGetCategories() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetCategories()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const categories = data as Category[]
      self.setCategories(categories)
    },
    async apiAdminGetThemes() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetThemes()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const themes = (data as Theme[]).reverse()
      self.setThemes(themes)
    },
    async apiAdminGetNudges() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetNudges()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const nudges = data as Nudges[]
      self.setNudges(nudges)
    },
    async apiAdminGetNudgeTypes() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetNudgeTypes()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const nudgeTypes = data as string[]
      self.setNudgeTypes(nudgeTypes)
    },
    async apiAdminGetTagTypes() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTagTypes()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const tagTypes = data as string[]
      self.setTagTypes(tagTypes)
    },

    async apiAdminGetDidYouKnowTiles() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetDidYouKnowTiles()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const tiles = data as DidYouKnowTile[]

      self.setDidYouKnowTiles(tiles)
    },
    async apiAdminGetTiles() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTiles()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const tiles = data as WhatsNextTiles[]

      self.setTiles(tiles)
    },
    async apiAdminGetRakCards() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetRakCards()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const rakCards = (data as RakCard[]).reverse()
      self.setRakCards(rakCards)
    },
    async apiAdminGetRecipes() {
      const { api } = self.environment
      const { page, pageSize, setLoading } = self.recipesPagination

      setLoading(true)

      const { kind, data } = await api.adminGetRecipes({
        page,
        pageSize,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const recipesPagination = {
        page: data.paginationMetadata.page,
        pageSize: data.paginationMetadata.pageSize,
        totalCount: data.paginationMetadata.totalCount,
      }

      self.recipesPagination.setRecipes(data.data)
      self.recipesPagination.setPagination(recipesPagination)
      setLoading(false)
    },
    async apiAdminGetWhatVideos() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetWhatVideos()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const sortedData = data.data.sort((a, b) => {
        if (a.categoryName < b.categoryName) return -1
        if (a.categoryName > b.categoryName) return 1

        return a.sequenceNumber - b.sequenceNumber
      })

      self.setWhatVideos(sortedData as WhatVideo[])
    },
    async apiAdminGetWhatVideoCategories() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetWhatVideoCategories()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setWhatVideoCategories(data.data as WhatVideoCategories[])
    },
    async apiAdminGetPublishedVideos() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetPublishedVideos()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setPublishedVideos(data.data as PublishedVideo[])
    },

    async apiAdminGetTriIdeaTypes() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTriIdeaTypes()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setTriIdeaTypes(data)
    },
  }))
  .actions((self) => ({
    async apiAdminSendInvitations(): Promise<void> {
      const { api } = self.environment
      const { emails, groupId, membershipDurationValue } = self.newInvitation
      const { data, kind } = await api.adminSendInvitations({
        emails,
        groupId,
        membershipDuration: membershipDurationValue,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.invitationsPagination.resetPagination()
      self.apiAdminGetInvitations()
      self.resetNewInvitation()
    },

    async apiAdminCreateGroup(): Promise<void> {
      const { api } = self.environment
      const {
        name,
        membershipDurationDaysValue,
        coachID,
        programID,
        entitlementsIDs,
      } = self.newNonDppGroup
      const { data, kind } = await api.adminCreateGroup({
        name,
        coachID,
        programID,
        entitlements: entitlementsIDs,
        expirationDays: membershipDurationDaysValue,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetNonDppGroups()
      self.resetNewNonDppGroup()
    },
    async apiAdminAddTagToEvent(eventID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminAddTagToEvent({
        eventID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetMslEvents()
    },
    async apiAdminRemoveTagToEvent(eventID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminRemoveTagToEvent({
        eventID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetMslEvents()
    },
    async apiAdminCreateTrainer(): Promise<void> {
      const { api } = self.environment
      const { id, personID, uploadFiles, ...newTrainerParams } = self.newTrainer
      const { data, kind } = await api.adminCreateTrainer({
        ...newTrainerParams,
        ...(personID && { personID }),
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetMindsetTrainers()
      self.resetNewTrainer()
    },
    async apiAdminEditTrainer(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminUpdateTrainer(self.newTrainer)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetMindsetTrainers()
      self.resetNewTrainer()
    },
    async apiAdminDeleteTrainer(trainerID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteTrainer({
        id: trainerID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetMindsetTrainers()
      self.resetNewTrainer()
    },
    async apiAdminCreateSeries(): Promise<void> {
      const { api } = self.environment
      const { id, uploadFiles, ...newSeriesParams } = self.newSeries
      const { data, kind } = await api.adminCreateSeries(newSeriesParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
      self.resetNewSeries()
    },

    async apiAdminSeriesUploadContent(): Promise<void> {
      const { api } = self.environment
      const { uploadFiles, setContentUrl } = self.newSeries
      const { imageUrl } = uploadFiles

      if (imageUrl.file) {
        const { setUploadFileError } = imageUrl

        try {
          const url = await adminUploadFile(api.adminUploadContent, imageUrl)
          setContentUrl("imageUrl", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }
    },
    async apiAdminEditSeries(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditSeries(self.newSeries)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
      self.resetNewSeries()
    },
    async apiAdminPublishSeries(seriesID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditSeries({
        id: seriesID,
        isPublished: true,
      } as Series)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
      self.resetNewSeries()
    },
    async apiAdminUnPublishSeries(seriesID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditSeries({
        id: seriesID,
        isPublished: false,
      } as Series)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
      self.resetNewSeries()
    },

    async apiAdminEditIsFeaturedSeries(
      seriesID: string,
      value: boolean,
    ): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditSeries({
        id: seriesID,
        isFeatured: value,
      } as Series)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
      self.resetNewSeries()
    },

    async apiAdminUpdateSeriesEvents(): Promise<void> {
      const { api } = self.environment
      const events = self.newSeries.events.map((event) => ({
        eventId: event.id,
        sequence: event.sequence,
      }))
      const { data, kind } = await api.adminUpdateSeriesEvents({
        id: self.newSeries.id,
        events,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
    async apiAdminDeleteSeries(seriesID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteSeries({
        id: seriesID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
      self.resetNewSeries()
    },
    async apiAdminAddTagToSeries(seriesID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminAddTagToSeries({
        seriesID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
    },
    async apiAdminRemoveTagToSeries(seriesID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminRemoveTagToSeries({
        seriesID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSeries()
    },
    async apiAdminCreateCategory(): Promise<void> {
      const { api } = self.environment
      const { id, uploadFiles, ...newCategoryParams } = self.newCategory
      const { data, kind } = await api.adminCreateCategory(newCategoryParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
      self.resetNewCategory()
    },
    async apiAdminCategoriesUploadContent(): Promise<void> {
      const { api } = self.environment
      const { uploadFiles, setContentUrl } = self.newCategory
      const { imageUrl } = uploadFiles

      if (imageUrl.file) {
        const { setUploadFileError } = self.newCategory.uploadFiles.imageUrl

        try {
          const url = await adminUploadFile(api.adminUploadContent, imageUrl)
          setContentUrl("imageUrl", url)
        } catch {
          setUploadFileError("Upload Failed")

          throw new Error("Upload Failed")
        }
      }
    },
    async apiAdminEditCategory(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditCategory(self.newCategory)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
      self.resetNewCategory()
    },
    async apiAdminPublishCategory(categoryID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditCategory({
        id: categoryID,
        isPublished: true,
      } as Category)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
      self.resetNewCategory()
    },
    async apiAdminUnPublishCategory(categoryID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditCategory({
        id: categoryID,
        isPublished: false,
      } as Category)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
      self.resetNewCategory()
    },
    async apiAdminDeleteCategory(categoryID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteCategory({
        id: categoryID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
      self.resetNewCategory()
    },
    async apiAdminAddTagToCategory(categoryID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminAddTagToCategory({
        categoryID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
    },
    async apiAdminRemoveTagToCategory(categoryID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminRemoveTagToCategory({
        categoryID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
    },
    async apiAdminAddEpisodeToCategory(categoryID: string, episodeID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminAddEpisodeToCategory({
        categoryID,
        episodeID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
    },
    async apiAdminRemoveEpisodeToCategory(
      categoryID: string,
      episodeID: string,
    ) {
      const { api } = self.environment
      const { kind, data } = await api.adminRemoveEpisodeToCategory({
        categoryID,
        episodeID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetCategories()
    },
    async apiAdminCreateTheme(): Promise<void> {
      const { api } = self.environment
      const { id, ...newThemeParams } = self.newTheme
      const { data, kind } = await api.adminCreateTheme(newThemeParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
      self.resetNewTheme()
    },
    async apiAdminEditTheme(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditTheme(self.newTheme)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
      self.resetNewTheme()
    },
    async apiAdminPublishTheme(themeID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditTheme({
        id: themeID,
        isPublished: true,
      } as Theme)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
      self.resetNewTheme()
    },
    async apiAdminUnPublishTheme(themeID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditTheme({
        id: themeID,
        isPublished: false,
      } as Theme)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
      self.resetNewTheme()
    },
    async apiAdminDeleteTheme(themeID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteTheme({
        id: themeID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
      self.resetNewTheme()
    },
    async apiAdminAddTagToTheme(themeID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminAddTagToTheme({
        themeID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
    },
    async apiAdminRemoveTagToTheme(themeID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminRemoveTagToTheme({
        themeID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetThemes()
    },
    async apiAdminUpdateThemeEvents(): Promise<void> {
      const { api } = self.environment
      const events = self.newTheme.events.map((event) => ({
        eventId: event.id,
        sequence: event.sequence,
      }))
      const { data, kind } = await api.adminUpdateThemeEvents({
        id: self.newTheme.id,
        events,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
    async apiAdminCreateTag(): Promise<void> {
      const { api } = self.environment
      const { id, ...newTagParams } = self.newTag
      const { data, kind } = await api.adminCreateTag(newTagParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetTags()
      self.resetNewTag()
    },
    async apiAdminEditTag(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditTag(self.newTag)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetTags()
      self.resetNewTag()
    },
    async apiAdminDeleteTag(tagID: string): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteTag({
        id: tagID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTags()
      self.resetNewTag()
    },
    async apiAdminCreateNudge(): Promise<void> {
      const { api } = self.environment
      const { id, ...newNudgeParams } = self.newNudge

      const { data, kind } = await api.adminCreateNudge(newNudgeParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetNudges()
      self.resetNewNudge()
    },
    async apiAdminEditNudge(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditNudge(self.newNudge)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetNudges()
      self.resetNewNudge()
    },
    async apiAdminEditNudgeStatus(nudge: Nudges): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditNudge(nudge)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentNudge = self.nudges.find(
        (nudge) => nudge.id === self.newNudge.id,
      )
      currentNudge?.setIsActive?.(self.newNudge.active)

      await self.apiAdminGetNudges()
      self.resetNewNudge()
    },
    async apiAdminCreateDidYouKnowTile(): Promise<void> {
      const { api } = self.environment
      const {
        id,
        uploadFiles,
        ...newDidYouKnowTileParams
      } = self.newDidYouKnowTile

      const { data, kind } = await api.adminCreateDidYouKnowTile(
        newDidYouKnowTileParams,
      )
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.resetNewDidYouKnowTile()
      await self.apiAdminGetDidYouKnowTiles()
    },

    async apiAdminDYKTilesUploadContent(): Promise<void> {
      const { api } = self.environment
      const { uploadFiles, setContentUrl } = self.newDidYouKnowTile
      const { image } = uploadFiles

      if (image.file) {
        const { setUploadFileError } = image

        try {
          const url = await adminUploadFile(api.adminUploadContent, image)
          setContentUrl("image", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }
    },

    async apiAdminCreateTile(): Promise<void> {
      const { api } = self.environment
      const { id, uploadFiles, ...newTileParams } = self.newTile

      const { data, kind } = await api.adminCreateTile(newTileParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetTiles()
      self.resetNewTile()
    },
    async apiAdminWNTilesUploadContent(): Promise<void> {
      const { api } = self.environment
      const { uploadFiles, setContentUrl } = self.newTile
      const { imageURL } = uploadFiles

      if (imageURL.file) {
        const { setUploadFileError } = imageURL

        try {
          const url = await adminUploadFile(api.adminUploadContent, imageURL)
          setContentUrl("imageURL", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }
    },

    async apiAdminEditDidYouKnowTile(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditDidYouKnowTile(
        self.newDidYouKnowTile,
      )
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetDidYouKnowTiles()
      self.resetNewDidYouKnowTile()
    },
    async apiAdminGetTileTypes() {
      const { api } = self.environment
      const { kind, data } = await api.adminGetTileTypes()

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const tileTypes = data as string[]
      self.setTileTypes(tileTypes)
    },
    async apiAdminEditDidYouKnowTileStatus(
      tile: DidYouKnowTile,
    ): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditDidYouKnowTile(tile)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentDidYouKnowTile = self.didYouKnowTiles.find(
        (tile) => tile.id === self.newDidYouKnowTile.id,
      )
      currentDidYouKnowTile?.setIsActive?.(self.newDidYouKnowTile.active)

      await self.apiAdminGetDidYouKnowTiles()
      self.resetNewDidYouKnowTile()
    },

    async apiAdminEditTile(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditTile(self.newTile)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      await self.apiAdminGetTiles()
      self.resetNewTile()
    },
    async apiAdminCreateRakCard(): Promise<void> {
      const { api } = self.environment
      const { id, uploadFiles, ...newRakCardParams } = self.newRakCard
      const { data, kind } = await api.adminCreateRakCard(newRakCardParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetRakCards()
      self.resetNewRakCard()
    },
    async apiAdminRAKCardUploadContent(): Promise<void> {
      const { api } = self.environment
      const { uploadFiles, setContentUrl } = self.newRakCard
      const { imageURL } = uploadFiles

      if (imageURL.file) {
        const { setUploadFileError } = imageURL

        try {
          const url = await adminUploadFile(api.adminUploadContent, imageURL)
          setContentUrl("imageURL", url)
        } catch {
          setUploadFileError("Upload Failed")

          throw new Error("Upload Failed")
        }
      }
    },
    async apiAdminEditRakCard(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditRakCard(self.newRakCard)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentRakCard = self.rakCards.find(
        (rakCard) => rakCard.id === self.newRakCard.id,
      )
      currentRakCard?.setActive?.(self.newRakCard.active)
      self.apiAdminGetRakCards()
      self.resetNewRakCard()
    },
    async apiAdminToggleActiveRakCard(rakCardItem: RakCard): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditRakCard(rakCardItem)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetRakCards()
      self.resetNewRakCard()
    },
    async apiAdminCreateRecipe(): Promise<void> {
      const { api } = self.environment

      const { id, ...newRecipeParams } = self.newRecipe
      const { data, kind } = await api.adminCreateRecipe(newRecipeParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetRecipes()
      self.resetNewRecipe()
    },
    async apiAdminEditRecipe(): Promise<void> {
      const { api } = self.environment
      const { updateRecipe } = self.recipesPagination
      const { data, kind } = await api.adminEditRecipe(self.newRecipe)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      updateRecipe(data)
      self.resetNewRecipe()
    },
    async apiAdminDeleteRecipe(recipeID: string): Promise<void> {
      const { api } = self.environment
      const { deleteRecipe } = self.recipesPagination

      const { data, kind } = await api.adminDeleteRecipe({
        recipeId: recipeID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      deleteRecipe(recipeID)
    },
    async apiAdminEpisodesUploadContent({
      isEditMode,
    }: {
      isEditMode: boolean
    }): Promise<void> {
      const { api } = self.environment
      const {
        uploadFiles,
        setPosterImageURL,
        setAudioOnlyImageURL,
        setRecordingURL,
      } = isEditMode ? self.editMslEvent : self.newMslEvent
      const { posterImageURL, recordingURL, audioOnlyImageURL } = uploadFiles

      if (posterImageURL.file) {
        const { setUploadFileError } = uploadFiles.posterImageURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            posterImageURL,
          )
          setPosterImageURL(url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }

      if (recordingURL.file) {
        const { setUploadFileError } = uploadFiles.recordingURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            recordingURL,
          )
          setRecordingURL(url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }

      if (audioOnlyImageURL.file) {
        const { setUploadFileError } = uploadFiles.audioOnlyImageURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            audioOnlyImageURL,
          )
          setAudioOnlyImageURL(url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }
    },
    async apiAdminTrainerUploadContent(): Promise<void> {
      const { api } = self.environment
      const { uploadFiles, setContentUrl } = self.newTrainer
      const {
        circleImageURL,
        bannerImageURL,
        v2CircleImageURL,
        v2BannerImageURL,
      } = uploadFiles

      if (circleImageURL.file) {
        const { setUploadFileError } = circleImageURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            circleImageURL,
          )
          setContentUrl("circleImageURL", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }

      if (bannerImageURL.file) {
        const { setUploadFileError } = bannerImageURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            bannerImageURL,
          )
          setContentUrl("bannerImageURL", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }

      if (v2BannerImageURL.file) {
        const { setUploadFileError } = v2BannerImageURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            v2BannerImageURL,
          )
          setContentUrl("v2BannerImageURL", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }

      if (v2CircleImageURL.file) {
        const { setUploadFileError } = v2CircleImageURL

        try {
          const url = await adminUploadFile(
            api.adminUploadContent,
            v2CircleImageURL,
          )
          setContentUrl("v2CircleImageURL", url)
        } catch {
          setUploadFileError("Upload Failed")
          throw new Error("Upload Failed")
        }
      }
    },
    async apiAdminCreateWhatVideos(): Promise<void> {
      const { api } = self.environment

      const { id, ...rest } = self.newWhatVideo
      const { data, kind } = await api.adminCreateWhatVideo(rest)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetWhatVideos()
      self.resetNewWhatVideo()
    },
    async apiAdminEditWhatVideo(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditWhatVideo(self.newWhatVideo)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetWhatVideos()
      self.resetNewWhatVideo()
    },
    async apiAdminEditWhatVideoStatus(whatVideo: WhatVideo): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminEditWhatVideo(whatVideo)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const currentVideo = self.whatVideos.find(
        (video) => video.id === self.newWhatVideo.id,
      )
      currentVideo?.setActive?.(self.newWhatVideo.active)

      await self.apiAdminGetWhatVideos()
      self.resetNewWhatVideo()
    },

    async apiAdminCreateTriIdea(): Promise<void> {
      const { api } = self.environment

      const { id, createdAt, updatedAt, ...newIdeaParams } = self.newTriIdea
      const { data, kind } = await api.adminCreateTriIdea(newIdeaParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
      self.resetNewTriIdea()
    },
    async apiAdminEditTriIdea(): Promise<void> {
      const { api } = self.environment
      const { updateTriIdea } = self.triIdeasPagination

      const {
        createdAt,
        updatedAt,
        product,
        recipe,
        ...updateParams
      } = self.newTriIdea
      const { data, kind } = await api.adminEditTriIdea(updateParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      updateTriIdea(data)
      self.resetNewTriIdea()
    },

    async apiAdminUpdateTriIdeaType(type: string): Promise<void> {
      const { api } = self.environment
      const { updateTriIdea } = self.triIdeasPagination

      const {
        createdAt,
        updatedAt,
        product,
        recipe,
        ...updateParams
      } = self.newTriIdea

      const { data, kind } = await api.adminEditTriIdea({
        ...updateParams,
        type,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.newTriIdea.setType(data.type)

      updateTriIdea(data)
    },

    async apiAdminDeleteTriIdea(ideaID: string): Promise<void> {
      const { api } = self.environment
      const { deleteTriIdea } = self.triIdeasPagination

      const { data, kind } = await api.adminDeleteTriIdea({
        ideaID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      deleteTriIdea(ideaID)
    },

    async apiAdminAddTagToTriIdea(ideaID: string, tagID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminAddTagToTriIdea({
        ideaID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
    },
    async apiAdminDeleteTriIdeaTag(
      ideaID: string,
      tagID: string,
    ): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminDeleteTriIdeaTag({
        ideaID,
        tagID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
    },
    async apiAdminAddRecipeToTriIdea(ideaID: string, recipe: TriIdeaRecipe) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddRecipeToTriIdea({
        ideaID,
        recipeID: recipe.id,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
    },
    async apiAdminDeleteTriIdeaRecipe(
      ideaID: string,
      recipeID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriIdeaRecipe({
        ideaID,
        recipeID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
    },
    async apiAdminAddProductToTriIdea(ideaID: string, productID: string) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddProductToTriIdea({
        ideaID,
        productID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
    },
    async apiAdminDeleteTriIdeaProduct(
      ideaID: string,
      productsID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriIdeaProduct({
        ideaID,
        productsID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.triIdeasPagination.apiAdminGetTriIdeas()
    },
  }))
  .views((self) => ({
    //flag for showing if the new topic already exists on the topics list
    get isNewTopicDuplicate() {
      return self.eventTopics.some(
        (topic) =>
          topic.name.trim().toLocaleLowerCase() ===
          (self.newTopic.name || "").trim().toLocaleLowerCase(),
      )
    },
    // Derived value for getting the categories of a msl event
    get editMslCategories() {
      return self.categories.filter((category) =>
        category.events.find((event) => event.id === self.editMslEvent.id),
      )
    },
    get trainersMap() {
      return self.mindsetTrainers.reduce((acc, nextTrainer) => {
        return {
          ...acc,
          [nextTrainer.id]: nextTrainer,
        }
      }, {})
    },
    get tagsByType() {
      return self.tags.reduce((acc, nextTag) => {
        return {
          ...acc,
          [nextTag.type]: [...(acc[nextTag.type] || []), nextTag],
        }
      }, {})
    },
  }))

export interface AdminMslEventStore
  extends Instance<typeof AdminMslEventStoreModel> {}
export interface AdminMslEventStoreSnapshot
  extends SnapshotOut<typeof AdminMslEventStoreModel> {}
