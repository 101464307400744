import React, { FC } from "react"
import { Person } from "../../models/person"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import makeStyles from "@material-ui/styles/makeStyles"
import { Stack, Typography } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { CustomSwitch } from "../../components/custom-switch"
import { ReactComponent as ChatShadowAvatar } from "../../assets/images/chat_shadow_avatar.svg"
import { typography } from "../../services/theme/typography"

const absentViewColor = "#BDBCC4"
const presentViewColor = "#22878D"

interface AttendanceRowProps {
  participant: Person
}

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    borderBottom: "1px solid #EBEEF0",
    padding: 0,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "center",
  },
  listItemContainer: {
    listStyleType: "none",
  },
  personBoxContainer: {
    padding: theme.spacing(1, 2),
  },
  personName: {
    margin: 0,
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(2.75),
    color: theme.palette.text.secondary,
    ...typography.circularXXMedium,
  },
  personAvatar: {
    width: theme.spacing(5.4),
    height: theme.spacing(5.4),
  },
  switch: {
    "& .MuiSwitch-track": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
}))

export const SetAttendanceRow: FC<AttendanceRowProps> = observer((props) => {
  const { participant } = props
  const { cohortStore } = useStores()
  const { currentCohort } = cohortStore
  const { attendanceHistory } = currentCohort
  const classes = useStyles()

  const participantAttendanceHistory = attendanceHistory.find(
    (atH) => atH.membershipID === participant.id,
  )
  const present = Boolean(participantAttendanceHistory?.attended)

  const onParticipantToggle = () => {
    participantAttendanceHistory.toggleAttended()
  }

  return (
    <ListItem
      classes={{
        root: classes.listItemRoot,
        container: classes.listItemContainer,
      }}
      key={`attendance-${participant.id}`}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        className={classes.personBoxContainer}>
        <ListItemAvatar>
          <ChatShadowAvatar className={classes.personAvatar} />
        </ListItemAvatar>

        <Stack spacing={1.25}>
          <Typography className={classes.personName}>
            {participant.fullName}
          </Typography>

          <CustomSwitch
            checked={present}
            onChange={onParticipantToggle}
            activeLabel="Present"
            inActiveLabel="Absent"
            activeViewColor={presentViewColor}
            inActiveViewColor={absentViewColor}
            switchCustomClass={classes.switch}
          />
        </Stack>
      </Stack>
    </ListItem>
  )
})
