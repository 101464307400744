import React, { FC } from "react"

import { Grid, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"

import { DetailTitle } from "./member-detail-title-card"
import { typography } from "../../services/theme/typography"

interface MemberDetailsProps {
  title: string
  value: string | number
  index: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    memberDetail: {
      height: theme.spacing(8),
      fontSize: theme.spacing(2),
      color: "#485359",
      fontWeight: 400,
      marginTop: theme.spacing(1),
      ...typography.circularXXBook,
    },
    coachingActive: {
      color: "#00CC7E",
      fontWeight: 700,
    },
    coachingExpired: {
      color: "#F23D3C",
      fontWeight: 700,
    },
  }),
)

export const MemberDetail: FC<MemberDetailsProps> = ({
  title,
  value,
  index,
}) => {
  const classes = useStyles()

  const columnsPerRow = index < 4 ? 3 : 4
  const isCoaching = title === "Coaching"
  return (
    <Grid
      item
      md={columnsPerRow}
      xs={columnsPerRow}
      display={isCoaching ? "flex" : ""}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      key={index}>
      <DetailTitle title={title} />
      <Typography
        className={clsx(classes.memberDetail, {
          [classes.coachingActive]: isCoaching && value === "Active",
          [classes.coachingExpired]: isCoaching && value === "Expired",
        })}>
        {value ?? "N/A"}
      </Typography>
    </Grid>
  )
}
