import React, { useEffect, useRef, useState } from "react"
import { useParams, useRouteMatch } from "react-router-dom"
import { Paper, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { isAlive } from "mobx-state-tree"
import { trim } from "ramda"
import { RouteParams } from "../../AppRoutes"
import { useStores } from "../../models/root-store"
import { useInterval } from "../use-interval"
import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { ChatBubbleGroup } from "../chat-bubble-group"
import { ChatInput } from "../chat-input"
import { EmptyRoom } from "./empty-room"

export const CHAT_REFRESH_RATE = 2000

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      border: 0,
      alignItems: "center",
    },
    chatListContainer: {
      display: "flex",
      justifyContent: "center",
      flex: "1 1 1px",
      overflow: "hidden",
      position: "relative",
      width: "100%",
      padding: theme.spacing(3, 5.5, 0, 4.5),
    },
  }),
)

interface ChatRoomProps {
  roomTitle?: string
  chatListContainerStyle?: string
  showAuthorName?: boolean
}

export const ChatRoom = observer(
  ({ roomTitle, showAuthorName, chatListContainerStyle }: ChatRoomProps) => {
    const classes = useStyles()
    const params = useParams<RouteParams>()
    const { cohortStore } = useStores()
    const [message, setMessage] = useState<string>("")
    const [isRoomUpdating, toggleRoomUpdating] = useState<boolean>(true)
    const [isPaginating, toggleIsPaginating] = useState<boolean>(false)
    const [
      isLoadingSendMessage,
      toggleIsLoadingSendMessage,
    ] = useState<boolean>(false)
    const inputRef = useRef(null)
    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    // Focus the text input when chat room/cohort changes.
    useEffect(() => {
      inputRef?.current?.focus()
    }, [params.cohortId, params.roomId])

    useEffect(() => {
      const refreshCohort = async () => {
        try {
          await cohortStore.apiGetCohort(params.cohortId)
        } catch (error) {
          setSnackbarSeverity("error")
          setSnackbarText(error.message)
          setShowSnackbar(true)
        }
      }

      if (params.cohortId !== "-1") {
        refreshCohort()
      }
    }, [
      params.cohortId,
      cohortStore,
      setShowSnackbar,
      setSnackbarSeverity,
      setSnackbarText,
    ])

    const matchChatRoute = useRouteMatch<RouteParams>(
      "/site/:siteId/cohorts/:cohortId/chat/:roomId",
    )

    const isIndividualChat = matchChatRoute?.isExact

    const currentCohort = cohortStore.cohorts.find(
      (c) => c.id === params.cohortId,
    )
    const currentRoomPagination = currentCohort?.currentRoomPagination

    //use effeect method to save the focused room id or the flag which detects if group chat is focused
    useEffect(() => {
      if (isIndividualChat) {
        cohortStore.setCurrentRoom(params.roomId)
      } else {
        cohortStore.toggleGroupChatFocused(true)
      }

      return () => {
        if (isIndividualChat) {
          cohortStore.setCurrentRoom(null)
        } else {
          cohortStore.toggleGroupChatFocused(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.roomId, isIndividualChat])

    const getChatMeta = () => {
      if (isIndividualChat) {
        return currentCohort?.getDirectChatByRoomId(params.roomId)?.meta
      } else {
        return currentCohort?.groupChatMeta
      }
    }

    const chatMessages = currentRoomPagination?.messages || []

    const chatMeta = getChatMeta()

    const trimmedMessage = trim(message)

    const hideChatInput = () => {
      if (isIndividualChat) {
        const selectedParticipant = currentCohort?.registeredParticipants.find(
          (p) => p.personID === params.roomId,
        )
        return !Boolean(selectedParticipant)
      } else {
        return !Boolean(currentCohort?.registeredParticipants?.length)
      }
    }

    const refreshChatLists = async () => {
      if (params?.cohortId === "-1") return
      try {
        await cohortStore.apiGetChatsLists(params.cohortId)
      } catch (error) {
        console.error(error)
      }
    }

    const refreshDirectChat = async () => {
      //let's make sure there's a cohort and a roomID.
      if (!params.roomId || params?.cohortId === "-1") return
      try {
        await cohortStore.apiGetDirectChats(params.cohortId, params.roomId)
        toggleRoomUpdating(false)
      } catch (error) {
        if (error.message) {
          setSnackbarSeverity("error")
          setSnackbarText(error.message)
          setShowSnackbar(true)
        }
      }
    }

    const refreshGroupChat = async () => {
      if (params?.cohortId === "-1") return
      try {
        await cohortStore.apiGetGroupChats(params.cohortId)
        toggleRoomUpdating(false)
      } catch (error) {
        if (error.message) {
          setSnackbarSeverity("error")
          setSnackbarText(error.message)
          setShowSnackbar(true)
        }
      }
    }

    const refreshChat = async () => {
      if (isIndividualChat) {
        await refreshDirectChat()
      } else {
        await refreshGroupChat()
      }
    }

    useInterval(refreshChat, CHAT_REFRESH_RATE)

    const sendMessage = async () => {
      if (!!!trimmedMessage.length) return

      toggleIsLoadingSendMessage(true)
      if (isIndividualChat) {
        await cohortStore.apiCreateCohortChat(
          message,
          params.cohortId,
          isIndividualChat,
          params.roomId,
        )
        setMessage("")
        toggleIsLoadingSendMessage(false)
        return
      }

      await cohortStore.apiCreateCohortChat(
        message,
        params.cohortId,
        isIndividualChat,
      )
      setMessage("")
      toggleIsLoadingSendMessage(false)
      return
    }

    const handleMessageChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      setMessage(event.target.value)
    }

    const handleScrollPagination = async () => {
      if (isPaginating) {
        return
      }
      toggleIsPaginating(true)

      if (!currentRoomPagination?.isEndReached) {
        currentRoomPagination?.extendLimit?.()
      }
      await refreshChat()
      toggleIsPaginating(false)
    }

    const handleDeleteMessage = async (messageId: string) => {
      try {
        await currentCohort.apiDeleteChatMessage({
          messageId: messageId,
          isDirectChat: isIndividualChat,
          roomId: params.roomId,
        })
      } catch (error) {
        throw error
      }
    }

    //refresh chat lists api when a new chat room is focused, after refreshing the conversation api
    //in order to remove the new message badge
    useEffect(() => {
      if (currentRoomPagination && isAlive(currentRoomPagination)) {
        currentRoomPagination?.resetPagination?.()
      }
      toggleRoomUpdating(true)
      const refresh = async () => {
        toggleRoomUpdating(true)
        try {
          await refreshChat()
          await refreshChatLists()
        } catch (error) {
          if (error.message) {
            setSnackbarSeverity("error")
            setSnackbarText(error.message)
            setShowSnackbar(true)
          }
        }
      }

      refresh()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.roomId])

    const messages = chatMessages?.slice() ?? []

    return (
      <>
        <Paper variant="outlined" className={classes.root}>
          <div
            className={clsx(classes.chatListContainer, {
              [chatListContainerStyle]: Boolean(chatListContainerStyle),
            })}>
            <ChatBubbleGroup
              roomTitle={roomTitle}
              showAuthorName={showAuthorName}
              messages={messages}
              chatMeta={chatMeta}
              isEndReached={currentRoomPagination?.isEndReached}
              isPaginating={isPaginating}
              isLoading={isRoomUpdating}
              handleScrollPagination={handleScrollPagination}
              handleDeleteMessage={handleDeleteMessage}
              EmptyComponent={
                <EmptyRoom
                  isIndividualChat={isIndividualChat}
                  isChatUnavailable={hideChatInput()}
                />
              }
            />
          </div>

          <ChatInput
            inputRef={inputRef}
            value={message}
            onChange={handleMessageChange}
            hideInput={hideChatInput()}
            disabledSendButton={!trimmedMessage.length}
            isLoading={isLoadingSendMessage}
            sendMessage={sendMessage}
          />
        </Paper>
      </>
    )
  },
)
